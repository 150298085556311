import { Device } from '@capacitor/device';
import { MenuItem, TextField } from "@material-ui/core";
import firebase from 'firebase/compat/app';
import Lottie from "lottie-react";
import { useContext, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from "react-router";
import { AppContext } from "../../app-context";
import { contestSignup, signup } from "../../feature/user-dashboard/user-service";
import { isEmpty, isNotEmpty } from "../../services/util-service";
import { ACCESS_TOKEN, CONTEST_CODE, EMPTY_STRING, GRADE_LIST, MANDATORY_FIELD_MSG, REFERRAL_CODE } from "../../components/constants/constants";
import { doSignInWithPhoneNumber } from "../../components/firebase/firebase.service";
import Timer from "../../components/games/timer/timer.component";
import phoneNumber from "../../components/phone-number/phone-number";
import register from "./animations/register.json";
import './login.scss';

const CudentsSignupComponent = (props) => {
  const tempPath = 'https://www.cudents.com/prod/images/avatars/avatar' + (Math.floor(Math.random() * 24) + 1) + '.png';
  const contestCode = sessionStorage.getItem(CONTEST_CODE);
  const refCode = sessionStorage.getItem(REFERRAL_CODE);
  const initProfile = {
    name: EMPTY_STRING, displayName: EMPTY_STRING, confirmPassword: EMPTY_STRING, password: EMPTY_STRING, grade: EMPTY_STRING,
    contestCode: contestCode, profilePicPath: tempPath, referralCode: refCode
  };
  const [formError, setFormError] = useState({});
  const history = useHistory()
  const [formData, updateFormData] = useState(initProfile);
  const appContext = useContext(AppContext);
  const [isOtpAvailable, setOtpAvailable] = useState(false);
  const [appVerifier, setAppVerifier] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [sendOtpText, setSendOtpText] = useState('Send OTP');
  const [phone, setPhone] = useState(props.phone);
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const [deviceId, setDeviceId] = useState(null);

  useEffect(() => {

    if (!appContext.userData) {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        localStorage.removeItem(ACCESS_TOKEN);
      }
    }
    if(deviceId === null){
      Device.getId().then(result => {
        setDeviceId(result.uuid);
      });
    }
   

    if (appVerifier === null) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recapcha-container', {
        'size': 'invisible',
        'callback': (response) => {
        }
      });
      setAppVerifier(window.recaptchaVerifier);
    }
  }, []);

  const onCudentsSignup = () => {

    if (handleValidation()) {
      confirmationResult.confirm(formData.code).then((result) => {
        setShowTimer(false);
        const user = result.user['_delegate'];
        const userModel = {
          providerId: user.uid,
          name: formData.name,
          userId: user.phoneNumber,
          password: formData.password,
          provider: result.additionalUserInfo.providerId,
          authToken: user.accessToken,
          displayName: formData.displayName,
          grade: formData.grade,
          contestCode: formData.contestCode,
          profilePicPath: formData.profilePicPath,
          deviceId: deviceId
        }
        signup(userModel).subscribe({
          next: (response) => {
            localStorage.setItem(ACCESS_TOKEN, response.data.authToken);
            appContext.setUserData(response.data);
            if (isEmpty(contestCode)) {
              saveProfile();
            } else {
              registerContest(userModel);
            }
          }, error : (error ) => {
            setErrorMessage(error.message);
          }});
      }).catch( (error) => {
        setErrorMessage('Invalid OTP');
      });
    }
  }

  const registerContest = (userModel) => {
    contestSignup(userModel).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        const tempUser = {
          ...appContext.userData,
          profilePicPath: response.data.imagePath,
          displayName: response.data.name,
          grade: response.data.grade,
          provider: response.data.provider
        };
        appContext.setUserData(tempUser);
        history.push(`/contest/code/${contestCode}`);
      }, error : (error ) => {
        setErrorMessage(error.message);
      }});
  }

  const saveProfile = () => {
    const userProfile = {
      imagePath: formData.profilePicPath,
      name: formData.displayName,
      referralCode: formData.referralCode,
      grade: formData.grade
    }
    props.onSaveUserProfile(userProfile);
  }

  const sendOTP = () => {
    setOtpAvailable(false);
    if (handleValidation()) {
      // if (formData.password === formData.confirmPassword) {
        setShowTimer(true);
        setSendOtpText('Resend OTP')
        doSignInWithPhoneNumber(phone, appVerifier)
          .then((result) => {
            window.confirmationResult = result;
            setConfirmationResult(result);
            setOtpAvailable(true);
            appContext.setSuccessMessage('Please enter the OTP sent to your mobile number');
          }).catch((error) => {
            console.log(error.message);
            setShowTimer(false);
            setErrorMessage('Please enter a valid mobile number');
          });
      // } else {
      //   setErrorMessage('Password mismatch');
      // }
    }
  }

  const openSigninForm = () => {
    history.push('/signin');
  }
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    appContext.setErrorMessage(EMPTY_STRING);
    let errors = {};
    let formIsValid = true;
    // if (!formData["name"]) {
    //   formIsValid = false;
    //   errors["name"] = MANDATORY_FIELD_MSG;
    // }
    if (!phone) {
      formIsValid = false;
      errors["phoneNumber"] = MANDATORY_FIELD_MSG;
    } else if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      errors["phoneNumber"] = 'Invalid phone number'
    }
    if (!formData["password"]) {
      formIsValid = false;
      errors["password"] = MANDATORY_FIELD_MSG;
    }
    // if (!formData["confirmPassword"]) {
    //   formIsValid = false;
    //   errors["confirmPassword"] = MANDATORY_FIELD_MSG;
    // }
    // if (isOtpAvailable && !formData["code"]) {
    //   formIsValid = false;
    //   errors["code"] = MANDATORY_FIELD_MSG;
    // }
    if (!formData["displayName"]) {
      formIsValid = false;
      errors["displayName"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["grade"]) {
      formIsValid = false;
      errors["grade"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const timeOut = () => {
    setShowTimer(false);
  }

  const cudentsLogin = () => {
    return <form noValidate autoComplete="off">
      <div id="recapcha-container"></div>
      <div className="formField">
        <PhoneInput defaultCountry="IN" country="IN" value={phone || EMPTY_STRING} international countryCallingCodeEditable={false} disabled={isOtpAvailable}
          required onChange={setPhone} limitMaxLength={10} inputComponent={phoneNumber}
        />
        <span className="error-text">{formError["phoneNumber"]}</span>
      </div>
      {/* <div className="formField"><TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange}
        disabled={isOtpAvailable} required label="Parent/Guardian Name" variant="outlined" fullWidth size="small" />
        <span className="error-text">{formError["name"]}</span>
      </div> */}
      <div className="formField"><TextField name="password" value={formData.password || EMPTY_STRING} onChange={handleChange}
        autoComplete='new-password' disabled={isOtpAvailable} required label="Password" type="password" minLength={6} variant="standard" fullWidth size="small" />
        <span className="error-text">{formError["password"]}</span></div>
      {/* <div className="formField"><TextField name="confirmPassword" value={formData.confirmPassword || EMPTY_STRING} onChange={handleChange}
        autoComplete='new-password' disabled={isOtpAvailable} required label="Confirm Password" type="password" variant="outlined" fullWidth size="small" />
        <span className="error-text">{formError["confirmPassword"]}</span></div> */}

      <div className="formField">
        <TextField name="displayName" value={formData.displayName || EMPTY_STRING}
          onChange={handleChange} required fullWidth label={isEmpty(contestCode) ? "Kid's Name" : "Participant's Name"}
          variant="standard" size="small" />
        <span className="error-text">{formError["displayName"]}</span>
        {isNotEmpty(contestCode) ? <span className="text-text"><br />This name will appear on the certificate</span> : null}
      </div>
      <div className="formField">
        <TextField name="grade" className="cp-dropdown" value={formData.grade || EMPTY_STRING} select label="Class"
          required onChange={handleChange} variant="standard" size="small" fullWidth>
          {GRADE_LIST.map((grade) => (
            <MenuItem key={grade.value} value={grade.value}>
              {grade.name}
            </MenuItem>
          ))}
        </TextField>
        <div className="error-text">{formError['grade']}</div>
      </div>
      {/* {isEmpty(formData.contestCode) ?
        <div className="formField">
          <TextField name="referralCode" value={formData.referralCode || EMPTY_STRING} onChange={handleChange} fullWidth label="Referral Code"
            variant="outlined" size="small" />
        </div>
        : <div className="formField">
          <TextField name="contestCode" value={formData.contestCode || EMPTY_STRING} onChange={handleChange} label="Contest code"
            required variant="outlined" size="small" fullWidth disabled={true} />
          <div className="error-text">{formError['contestCode']}</div>
        </div>} */}

      {isOtpAvailable ?
        <div className="formField"><TextField name="code" value={formData.code || EMPTY_STRING} onChange={handleChange} required label="OTP" minLength={3} variant="standard" size="small" fullWidth />
          <span className="error-text">{formError["code"]}</span>
          
      <div className="tnc">By registering you agree to our <a href="https://www.cudents.com/TnC/TermsAndConditions.html" target="_">T&C </a></div>
          <div id="customBtn" className="login-submit-button" onClick={onCudentsSignup}>
            {/* <span className="icon"><img src="https://www.cudents.com/prod/images/login/logo192.png" alt="" /></span> */}
            <span className="buttonText">Submit</span></div>
        </div>
        : null}
      {showTimer ? <div className="otp-timer"><Timer timeOut={timeOut} initialSeconds={59}></Timer></div> : <div id="customBtn" className="login-submit-button" onClick={sendOTP}><span className="buttonText">{sendOtpText}</span></div>}
    
      <div className="link-text" onClick={() => { props.onBackToLogin() }}>Back</div>
    </form>
  }


  return (
    <div className="login-page">
      <div className="login-container-responsive">
        <div className="login-page-animation">
          <Lottie className="login-page-top-animation" animationData={register} loop={true} />
        </div>
        <div className="login-title-container">
          <div className="login-page-title">New User Registration</div>
        </div>
        {errorMessage === EMPTY_STRING ? null : <div className="error-msg">{errorMessage}</div>}
        <div className="bottom-form">
          {cudentsLogin()}
        </div>
      </div>
    </div>
  );
}

export default CudentsSignupComponent;

