import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { findAllAdminVideo, saveVideo } from '../../services/video-service';
import { ALL, EMPTY_STRING, MANDATORY_FIELD_MSG, STATUS_TYPES, SUBSCRIPTION_TYPES, VIDEO_TYPE } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import './admin-video.styles.scss';

const AdminVideo = () => {
  const initData = {
    description: EMPTY_STRING, name: EMPTY_STRING, type: EMPTY_STRING,  thumbnailImagePath: EMPTY_STRING, url: EMPTY_STRING
  };
  const [videoList, setVideoList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    findAllAdminVideo(ALL, selectedPage - 1).subscribe({
      next: (response) => {
        setVideoList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveVideo(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const editVideo = (video) => {
    updateFormData(video);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["description"]) {
      formIsValid = false;
      errors["description"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["type"]) {
      formIsValid = false;
      errors["type"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["url"]) {
      formIsValid = false;
      errors["url"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["thumbnailImagePath"]) {
      formIsValid = false;
      errors["thumbnailImagePath"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="videoFormContainer">
        <div className="videoTitle">Video Details</div>
        <div className="videoForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange} required fullWidth label="Video Name" variant="outlined" size="small" />
              <span className="error-text">{formError["name"]}</span>
            </div>
            <div className="formField">
              <TextField name="description" value={formData.description || EMPTY_STRING} onChange={handleChange} required fullWidth rows="3" multiline="true"
              label="Description" variant="outlined" size="small" />
              <span className="error-text">{formError["description"]}</span>
            </div>
            <div className="formField">
              <TextField name="type" value={formData.type || EMPTY_STRING} select label="Video Type" onChange={handleChange} fullWidth variant="outlined" size="small">
                {VIDEO_TYPE.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="isFree" value={formData.isFree || 0} select label="Subscription Type" onChange={handleChange} fullWidth variant="outlined" size="small">
                {SUBSCRIPTION_TYPES.map((subscription) => (
                <MenuItem key={subscription.value} value={subscription.value}>
                  {subscription.name}
                </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="isActive" value={formData.isActive || 0} select label="Status" onChange={handleChange} fullWidth variant="outlined" size="small">
              {STATUS_TYPES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.name}
                </MenuItem>
              ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="thumbnailImagePath" value={formData.thumbnailImagePath || EMPTY_STRING} onChange={handleChange} required fullWidth label="Thumbnail Link" variant="outlined" size="small" />
              <span className="error-text">{formError["thumbnailImagePath"]}</span>
            </div>
            <div className="formField">
              <TextField name="url" value={formData.url || EMPTY_STRING} onChange={handleChange} required fullWidth label="Video Link" variant="outlined" size="small" />
              <span className="error-text">{formError["url"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getVideosGrid = () => {
    return (
      <div className="videoFormContainer">
          <span>Total =  {totalCount}</span>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Subccription Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoList.map((video) => (
                <StyledTableRow key={video.name}>
                  <StyledTableCell>{video.name}</StyledTableCell>
                  <StyledTableCell>{video.type}</StyledTableCell>
                  <StyledTableCell>{video.isFree === 1 ? 'FREE' : 'PREMIUM'}</StyledTableCell>
                  <StyledTableCell>{video.isActive === 1 ? 'ACTIVE' : 'INACTIVE'}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editVideo(video) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-video-details">
      {showLoader ? <div className="showLoading"><Loader /></div>
        : <>
          {renderContact()}
          {getVideosGrid()}
        </>
      }
    </div>
  );
}


export default AdminVideo;


