import { Button, TextField } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG, REFERRAL_CODE } from '../../../components/constants/constants';
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import SocialShare from '../../../components/social-share/social-share.component';
import { useStyles } from '../../../services/grid-style';
import { getReferralCode, getReferralTransactions, redeemReferralCode } from '../../user-dashboard/referral/coupon-service';
import './referral.styles.scss';
import { getReferralPoints } from '../point-service';

const ReferralComponent = (props) => {
  const [referralList, setReferralList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [pointsRedeemed, setPointsRedeemed] = useState(0);
  const [referralCode, setReferralCode] = useState(EMPTY_STRING);
  const [couponCode, setCouponCode] = useState(EMPTY_STRING);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [formError, setFormError] = useState({});
  const [showAds, setShowAds] = useState(false);

  useEffect(() => {
    if (referralCode === EMPTY_STRING) {
      loadReferralCode();
    }

    if (couponCode === EMPTY_STRING) {
      updateReferralCode();
    }

    loadReferralPoint();
  }, []);

  const loadReferralPoint = () => {
    getReferralPoints().subscribe({
      next: (response) => {
        console.log(response);
        setPointsEarned(response.data.pointsEarned);
        setPointsRedeemed(response.data.pointsRedeemed);
      }
    });

  }

  useEffect(() => {
    search(page);
  }, [page]);

  const updateReferralCode = () => {
    const refCode = sessionStorage.getItem(REFERRAL_CODE);
    if (refCode) {
      setCouponCode(refCode);
    }
  }
  const handlePage = (event, value) => {
    setPage(value);
  };

  const loadReferralCode = () => {
    setShowLoader(true);
    getReferralCode().subscribe({
      next: (response) => {
        setReferralCode(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const search = (selectedPage) => {
    setShowLoader(true);
    getReferralTransactions(selectedPage - 1).subscribe({
      next: (response) => {
        setReferralList(response.data.data);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const applyCoupon = () => {
    if (handleValidation()) {
      setShowLoader(true);
      redeemReferralCode(couponCode).subscribe({
        next: (response) => {
          appContext.setDialogMessage(response.data);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!couponCode) {
      formIsValid = false;
      errors["couponCode"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    setCouponCode(e.target.value.toUpperCase());
  };

  const getShareUrl = () => {
    //   return `${process.env.REACT_APP_SHARE_URL}/refer/${referralCode}`;
    return `${process.env.REACT_APP_SHARE_URL}`;
  }
  const getTitle = () => {
    return `Download Cudents app to provide a great learning and engagement platform to your kids. Use the code "${referralCode}" to earn bonus points.`;
  }

  const getAttemptReportGrid = () => {
    return (
      <div>

        {referralCode === EMPTY_STRING ? null : <div>
          <div className="referral-image"><img src="https://www.cudents.com/prod/assets/images/userdashboard/referral.png" alt="" /></div>
          <div className="point-box"><div className="referral-code-tag">Referral Points</div>{pointsEarned}</div>
          <div className="point-box redeem-point-box"><div className="referral-code-tag">Redeemed Points</div>{pointsRedeemed * -1}</div>
          <div>Refer your friends and earn points</div>
          <div className="point-box referral-code-box"><div className="referral-code-tag">Your Referral Code</div>{referralCode}</div>
          <div className="social-share"><SocialShare title={getTitle()} shareUrl={getShareUrl()}></SocialShare></div>

        </div>}
        <div className="referred-by">If you are referred by your friend, apply his/her referral code below</div>
        <div className="formField">

          <TextField name="couponCode" value={couponCode} onChange={handleChange} label="Apply Referral Code" variant="outlined" size="small" />
          <Button variant="contained" onClick={applyCoupon} color="secondary">
            Apply
          </Button>
          <div className="error-text">{formError['couponCode']}</div>
        </div>
        {referralList !== null && referralList.length > 0 &&
          <>
            Your Referrals
            <div className="referral-list">{referralList.map((referral, idx) => (
              <div key={idx} className="referral-card">
                <div className="heading">
                  {referral.displayName}
                </div>
                <div className="referral-date" >{moment(referral.date).format('lll')}
                </div>
              </div>
            ))}</div>
            <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
          </>}
      </div>
    );
  }

  return <div className="referral">
    {showLoader ? <div className="showLoading"><Loader /></div> : getAttemptReportGrid()}
    {showAds && <AdComponent />}
  </div>
}

export default ReferralComponent;