import { PAGE_SIZE } from '../../components/constants/constants';
import { get, post, remove } from '../../services/http-services';


export function findAllNotification(pageNumber) {
    return get(`/notification/page?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function saveNotification(support) {
    return post('/notification', support);
}

export function deleteNotification(id) {
    return remove('/notification?id='+id);
}