import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { isNotEmpty, isNotNull } from '../../services/util-service';
import CloseIcon from '@material-ui/icons/Close';
import './home-banner.styles.scss';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

const HomeBanner = (props) => {
  const history = useHistory();

  const handleOk = (link) => {
    history.push(link);
    props.onClose();
  }

  const handleClose = (onClose) => {
    onClose();
  }
  
  return (
    <Dialog {...props}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
      <div className="homeBannerContainer">
        <CloseIcon className="close-button" onClick={() => { handleClose(props.onClose) }} />
        {isNotNull(props.bannerData) && isNotEmpty(props.bannerData.imagePath) ? <div className="message-box-animation"><img src={props.bannerData.imagePath} alt="" /></div> : null}
        {isNotNull(props.bannerData) && isNotEmpty(props.bannerData.description) ? <div className="loginMessage">{props.bannerData.description}</div> : null}
        {isNotNull(props.bannerData) && isNotEmpty(props.bannerData.link) ? <div className="loginButton">
          <Button variant="contained" onClick={() => { handleOk(props.bannerData.link) }} color="primary">{isNotEmpty(props.bannerData.btnText) ? props.bannerData.btnText : 'Ok'}</Button>
        </div> : null}
      </div>
    </Dialog>
  );
}

export default HomeBanner;