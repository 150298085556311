import { PAGE_SIZE } from '../../components/constants/constants';
import { get } from '../../services/http-services';

export function getPointReport(userId, pageNumber) {
    return get(`/point/report?userId=${userId}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getPointTransactions(pageNumber) {
    return get('/point/transactions?page=' + pageNumber + '&size=' + PAGE_SIZE + '&sort=creationDate,desc');
}

export function getReferralPoints() {
    return get('/point/referralpoints');
}