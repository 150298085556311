import { Button, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { EMPTY_STRING } from '../../components/constants/constants';
import { findByConfigName, saveConfigName } from '../../services/config-service';
import { isNotNull } from '../../services/util-service';
import { AppContext } from '../../app-context';

const AdminAppUpdate = (props) => {

  const [showLoader, setShowLoader] = useState(true);
  const [configModel, setConfigModel] = useState(EMPTY_STRING);
  const appContext = useContext(AppContext);

  const handleVersionChange = (e) => {
    setConfigModel({...configModel, configValue: e.target.value});
  };

  useEffect(() => {
    findByConfigName('appLatestVersion').subscribe({
      next: (response) => {
        if (isNotNull(response) && isNotNull(response.data)) {
          console.log(response.data.configValue);
          setConfigModel(response.data);
        }
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }, []);
  

  const saveData = () => {
    saveConfigName(configModel).subscribe({
      next: (response) => {
        if (isNotNull(response)) {
          appContext.setSuccessMessage(response.message);
        }
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const getFormPanel = () => {
    return (
      <div className="controlBox">
        <form noValidate autoComplete="off">
          <div className="admin-part-control">
            <TextField name="configValue" label="Update latest app version" required variant="outlined" size="small" fullWidth
              value={configModel.configValue} onChange={handleVersionChange}
            />
          </div>
          <div className="admin-part-control sendButton">
          <Button type="button" variant="contained" color="secondary" onClick={saveData}>
              Save
            </Button>
          </div>
        </form >
      </div >
    );
  }

  return (
    <div className="whatsapp-main">
      <div className="whatsapp-title">App Version</div>
      {!showLoader && getFormPanel()}
    </div>
  );
}

export default AdminAppUpdate;