import { App as CapacitorApp } from '@capacitor/app';
import { BottomNavigation, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import AdminPanel from './admin/admin-panel/admin-panel.component';
import { AppContext } from './app-context';
import { EMPTY_STRING } from './components/constants/constants';
import CudentsVideoPlayer from './components/cudents-video-player/cudents-video-player.component';
import AppFooter from './components/footer/footer.component';
import GameEngine from './components/games/engine/game-engine/game-engine.component';
import InfiniteGameEngine from './components/games/infinite-games/infinite-game-engine/infinite-game-engine.component';
import SearchMaster from './components/games/search-master/search-master.component';
import CorrectSequenceGame from './components/games/the-correct-sequence/correct-sequence-game.component';
import Loader from './components/loader/loader.component';
import MessageComponent from './components/message-dialog/message-component';
import MessageDialog from './components/message-dialog/message-dialog.component';
import YesNoDialog from './components/message-dialog/yes-no-dialog.component';
import NoMatchPage from './components/no-match-page/no-match-page';
import RouteChangeTracker from './components/route-change-tracker/route-change-tracker';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import AssessmentQuiz from './feature/assessment/assessment.component';
import ContestListing from './feature/contest/listing/contest-listing.component';
import ContestCertificate from './feature/contest/platform/cp-certificate/cp-certificate.component';
import ContestLeaderboard from './feature/contest/platform/cp-leaderboard/cp-leaderboard.component';
import ContestPlatformPage from './feature/contest/platform/cp-main-page/cp-main-page.component';
import ContestResult from './feature/contest/platform/cp-result/cp-result.component';
import ContestStartPage from './feature/contest/platform/cp-start-page/cp-start-page.component';
import DailyGkListing from './feature/daily-gk/daily-gk-listing/daily-gk-listing.component';
import DailyGkPage from './feature/daily-gk/daily-gk.component';
import DailyRiddleListing from './feature/daily-riddle/daily-riddle-listing/daily-riddle-listing.component';
import DailyRiddle from './feature/daily-riddle/daily-riddle.component';
import Feedback from './feature/feedback/feedback.component';
import GamesListing from './feature/game/games-listing/games-listing.component';
import GamesSelection from './feature/game/games-listing/games-selection/games-selection.component';
import GroupForm from './feature/group/group-form.component';
import GroupLandingPage from './feature/group/group-landing.component';
import GroupListing from './feature/group/group-listing.component';
import GroupMainPage from './feature/group/group-main.component';
import GroupLeaderboardHistory from './feature/group/leaderboard/history/leaderboard-history.component';
import GuessMasterList from './feature/guess-master/guess-master-list/guess-master-list.component';
import GuessMasterMain from './feature/guess-master/guess-master-main/guess-master-main.component';
import GuessMasterPLayer from './feature/guess-master/guess-master-player/guess-master-player.component';
import DailyHistory from './feature/history/history.component';
import { getHomeBanner } from './feature/home-banner/home-banner-service';
import HomeBanner from './feature/home-banner/home-banner.component';
import AppHomePage from './feature/home-page/app-home-page/app-home-page.component';
import Grade from './feature/home-page/grade/grade.component';
import WebHomePage from './feature/home-page/web-home-page/web-home-page.component';
import LearningListing from './feature/learning/learning-listing.component';
import Login from './feature/login/login';
import NewsListing from './feature/news/news-listing.component';
import NotificationPage from './feature/notification/notification.component';
import PaymentComponent from './feature/payment/payment.component';
import LeaderboardListing from './feature/quiz/leaderboard/leaderboard-listing/leaderboard-listing.component';
import Leaderboard from './feature/quiz/leaderboard/leaderboard.component';
import LevelListing from './feature/quiz/level-listing/level-listing.component';
import QuizListing from './feature/quiz/quiz-listing/quiz-listing.component';
import QuizPackage from './feature/quiz/quiz-package/quiz-package.component';
import Quiz from './feature/quiz/quiz.component';
import Quizzes from './feature/quiz/quizzes/quizzes.component';
import AttemptList from './feature/quiz/scorecard/attempt-list.component';
import ScoreCard from './feature/quiz/scorecard/scorecard.component';
import TopicListing from './feature/quiz/topic-listing/topic-listing.component';
import RedeemHistory from './feature/redeem/history/redeem-history.component';
import MyTransactions from './feature/redeem/my-transactions/my-transactions.component';
import OrderList from './feature/redeem/order-list/order-list.component';
import Redeem from './feature/redeem/redeem.component';
import RedemptionZonePage from './feature/redeem/redemption-zone/redemption-zone.components';
import RhymesListing from './feature/rhymes/rhymes-listing.component';
import MyStoryForm from './feature/story/my-story/my-story-form/my-story-form.component';
import MyStoryListing from './feature/story/my-story/my-story-listing.component';
import MyStory from './feature/story/my-story/my-story/my-story.component';
import StoriesListing from './feature/story/stories-listing.component';
import Support from './feature/support/support.component';
import ToysListing from './feature/toys-listing/toys-listing.component';
import UserBadges from './feature/user-dashboard/badges/badges.components';
import BottomNavList from './feature/user-dashboard/bottom-nav-list/bottom-nav-list.component';
import StudentProfile from './feature/user-dashboard/student-profile/student-profile.component';
import UserDashboard from './feature/user-dashboard/user-dashboard.component';
import { getCurrentUser } from './feature/user-dashboard/user-service';
import { isNotEmpty, isNotNull, isWebPlatform } from './services/util-service';
import AppUrlListener from './app-listener';


ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

const App = () => {

  const [showLoader, setShowLoader] = useState(true);
  const [userData, setUserData] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [showBannerDialog, setShowBannerDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const [successMessage, setSuccessMessage] = useState(EMPTY_STRING);
  const [dialogMessage, setDialogMessage] = useState(EMPTY_STRING);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (userData === null) {
      initUserData();
      initHomeBanner();
      if(!isWebPlatform()){
        window.screen.orientation.lock('portrait');
      }
      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        switch (window.location.pathname) {
          case '/':
            setMessage('Do you want to close the App?');
            setShowMsgDialog(true);
            break;
          default:
            window.history.back();
            break;
        }
      });
    }
  }, []);


  // const onBackClick = () => {
  //   if (window.location.pathname.includes('/gameslisting') || window.location.pathname.includes('/dailygkhistory')
  //     || window.location.pathname.includes('/dailyriddle') || window.location.pathname.includes('/riddlehistroy')
  //     || window.location.pathname.includes('/dailygk')) {
  //     console.log('~~~~~~~~~~~~~~~~onBackClick IF~~~~~~~~~~~~~~~~~');
  //     // window.history.pushState({}, '', '/');
  //     // window.location.reload();
  //     window.location.assign('/');
  //   } else {
  //     console.log('~~~~~~~~~~~~~~~~onBackClick ELSE~~~~~~~~~~~~~~~~~');
  //     switch (window.location.pathname) {
  //       case '/':
  //         console.log('~~~~~~~~~~~~~~~~In home page~~~~~~~~~~~~~~~~~');
  //         setMessage('Do you want Exit from App?');
  //         setShowMsgDialog(true);
  //         break;
  //       case '/userdashboard':
  //       case '/notification':
  //       case '/leaderboard':
  //       case '/news':
  //       case '/toys':
  //       case '/rhymes':
  //       case '/stories':
  //       case '/contestlist':
  //       case '/dailygk':
  //       case '/learning':
  //       case '/dailyriddle':
  //       case '/learning':
  //       case '/mystorylisting':
  //       case '/pricing':
  //         console.log('~~~~~~~~~~~~~~~~In other page~~~~~~~~~~~~~~~~~');
  //         window.location.assign('/');
  //         // window.location.reload();
  //         break;
  //       default:
  //         console.log('~~~~~~~~~~~~~~~~Before Back~~~~~~~~~~~~~~~~~');
  //         window.history.back();
  //         break;
  //     }
  //   }
  // }

  const onOkClick = () => {
    CapacitorApp.exitApp();
  }

  const initUserData = () => {
    getCurrentUser().subscribe({
      next: (response) => {
        if (isNotNull(response) && isNotEmpty(response.displayName)) {
          setUserData(response);
        }
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }


  const initHomeBanner = () => {
    getHomeBanner().subscribe({
      next: (response) => {
        if (!isWebPlatform() && isNotNull(response.data) && (isNotEmpty(response.data.imagePath) || isNotEmpty(response.data.description))) {
          setBannerData(response.data);
          setShowBannerDialog(true);
        }
      }, error: (error) => {
      }
    });
  }

  const showMessageDialog = () => {
    return dialogMessage !== EMPTY_STRING;
  }

  const mainView = () => {
    return (
      <div>
        <AppContext.Provider value={{
          userData, setUserData, errorMessage, setErrorMessage, successMessage, setSuccessMessage,
          setDialogMessage, showHeader, setShowHeader, showFooter, setShowFooter
        }}>
          <BrowserRouter>
            {/* {showHeader ? <AppHeader /> : null} */}
            <MessageComponent />
            <MessageDialog open={showMessageDialog()} onClose={() => { setDialogMessage(EMPTY_STRING) }} message={dialogMessage} />
            <AppUrlListener></AppUrlListener>
            {showLoader ? <div className="showLoading" style={{ minHeight: "calc(100vh - 100px" }}><Loader /></div> : <div style={{ minHeight: "60vh" }}>
              <ScrollToTop></ScrollToTop>
              {isWebPlatform() ? <Switch>
                <Route exact path='/' component={WebHomePage} />
                <Route path='/admin' component={isNotNull(userData) ? AdminPanel : Login} />
                <Route path="/signin" component={Login} />
                <Route path='/download' component={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.ink.cudents'; return null; }} />
                <Route path="*" component={NoMatchPage} />
              </Switch> :
                <Switch>
                  <Route exact path='/' component={isNotNull(userData) ? AppHomePage : Login} />
                  <Route path='/admin' component={isNotNull(userData) ? AdminPanel : Login} />
                  <Route path='/download' component={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.ink.cudents'; return null; }} />
                  <Route path='/feedback' component={Feedback} />
                  <Route path='/profile' component={StudentProfile} />
                  <Route path='/userdashboard' component={UserDashboard} />
                  <Route exact path='/redeem' component={Redeem} />
                  <Route exact path='/notification' component={NotificationPage} />
                  <Route exact path='/redeemhistory' component={RedeemHistory} />
                  <Route path='/myorders' component={OrderList} />
                  <Route path='/mytransactions' component={MyTransactions} />
                  <Route exact path='/payment/:paymentId' component={PaymentComponent} />
                  <Route exact path='/payment/:paymentId/:couponCode' component={PaymentComponent} />
                  <Route path='/leaderboardlisting/:grade' component={LeaderboardListing} />
                  <Route exact path='/leaderboard/:grade/:date' component={Leaderboard} />
                  <Route exact path='/leaderboard' component={Leaderboard} />
                  <Route path='/pricing' component={QuizPackage} />
                  <Route path='/quizlisting' component={QuizListing} />
                  <Route path='/quizzes' component={Quizzes} />
                  <Route path='/news' component={NewsListing} />
                  <Route path='/toys' component={ToysListing} />
                  <Route path='/rhymes' component={RhymesListing} />
                  <Route path='/stories' component={StoriesListing} />
                  <Route path='/guess' component={GuessMasterMain} />
                  <Route path='/guesslisting/:subject' component={GuessMasterList} />
                  <Route path='/guessPlayer/:guessMasterId' component={GuessMasterPLayer} />
                  <Route path='/learning' component={LearningListing} />
                  <Route path='/contestlist' component={ContestListing} />
                  <Route path='/grade/:grade' component={Grade} />
                  <Route path='/quiz/:grade/:subject/:topic/:level' component={Quiz} />
                  <Route path='/topics/:grade/:subject' render={TopicListing} />
                  <Route path='/support' component={Support} />
                  <Route path='/assessment' component={AssessmentQuiz} />
                  <Route path='/contests/:masterContestId' component={ContestPlatformPage} />
                  <Route path='/badges' component={UserBadges} />
                  <Route path='/gamesselection' component={GamesSelection} />
                  <Route path='/gameslisting/:classType/:grade' component={GamesListing} />
                  <Route path='/mystorylisting' component={MyStoryListing} />
                  <Route exact path='/dailygk' component={DailyGkPage} />
                  <Route exact path='/dailygk/:grade/:gkDate' component={DailyGkPage} />
                  <Route path='/dailygkhistory/:grade' component={DailyGkListing} />
                  <Route exact path='/dailyriddle' component={DailyRiddle} />
                  <Route exact path='/dailyriddle/:riddleDate' component={DailyRiddle} />
                  <Route exact path='/riddlehistroy' component={DailyRiddleListing} />
                  <Route exact path='/dailyhistory' component={DailyHistory} />
                  <Route path='/mystoryform/:storyId/:gender' component={MyStoryForm} />
                  <Route path='/mystory/:storyId/:kidName' component={MyStory} />
                  <Route path='/videoplayer/:type/:videoId' component={CudentsVideoPlayer} />
                  <Route exact path='/contest/:contestCode' component={Login} />
                  <Route exact path='/contest/code/:contestCode' component={ContestStartPage} />
                  <Route exact path='/contestpage/:contestId' component={ContestStartPage} />
                  <Route exact path='/contestpage/leaderboard/:contestType/:contestId/:gradeType/:winnerCount' component={ContestLeaderboard} />
                  <Route exact path='/contestpage/result/:contestType/:contestId/:gradeType/:winnerCount' component={ContestResult} />
                  <Route exact path='/contestpage/certificate/:contestType/:contestId/:gradeType/:winnerCount' component={ContestCertificate} />
                  <Route path='/redemptionzone' component={RedemptionZonePage} />
                  <Route path="/levels/:grade/:subject/:topic" component={LevelListing} />
                  <Route path="/refer/:referralCode" component={Login}></Route>
                  <Route path="/signin" component={Login}></Route>
                  <Route path="/attempts/:grade/:subject/:topic/:level" component={AttemptList}></Route>
                  <Route path="/scorecard/:grade/:subject/:topic/:level/:attemptId" component={ScoreCard}></Route>
                  <Route path="/game/infinitegame/:gameId/:grade/:subject/:topic" component={InfiniteGameEngine}></Route>
                  <Route path="/gamezone/:gameId/:grade/:subject/:topic" component={GameEngine}></Route>
                  <Route path="/sequencegame/:gameId/:grade/:subject/:topic" component={CorrectSequenceGame}></Route>
                  <Route path="/searchgame/:gameId/:grade/:subject/:topic" component={SearchMaster}></Route>
                  <Route exact path='/group' component={GroupLandingPage} />
                  <Route exact path='/group/view/:groupId' component={GroupMainPage} />
                  <Route exact path='/group/view/:groupId/:date' component={GroupMainPage} />
                  <Route exact path='/group/edit/:groupId' component={GroupForm} />
                  <Route exact path='/group/new' component={GroupForm} />
                  <Route exact path='/group/list' component={GroupListing} />
                  <Route exact path='/group/leaderboard/history/:groupId' component={GroupLeaderboardHistory} />
                  <Route path="*" component={NoMatchPage}></Route>
                </Switch>}
            </div>}
            {showFooter && isWebPlatform() ? <AppFooter /> : null}
            {!isWebPlatform() && showFooter && isNotNull(userData) ?
              <Paper className="main-app-bottom-nav-container" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
                <BottomNavigation className="main-app-bottom-nav" style={{ justifyContent: 'space-around', backgroundColor: '#C8245C', height: '50px' }}>
                  <BottomNavList></BottomNavList>
                </BottomNavigation>
              </Paper>
              : null}
            <RouteChangeTracker />
            <HomeBanner open={showBannerDialog} onClose={() => { setShowBannerDialog(false) }} onOk={() => { setShowBannerDialog(false); }} bannerData={bannerData} />
          </BrowserRouter>
        </AppContext.Provider>
        <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      </div>
    );
  }

  return (
    <div>{mainView()}</div>
  );
}

export default App;
