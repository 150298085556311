import React from 'react';
import Switch from '@material-ui/core/Switch';
import './game-start-screen.styles.scss';
import { isNotNull } from '../../../../services/util-service';

function GameStartScreen({ gameImage, gameBackgroundImage, gameStory, musicFlag, toggleMusic, gameStart, exitGame }) {
  return (
    <div className="game-start-screen">
      <div className="game-start-screen-background">
       {isNotNull(gameBackgroundImage) ? <img src={gameBackgroundImage} alt="" /> : null}
      </div>
      <div className="game-start-screen-container">
        <div className="game-start-screen-image"><img src={gameImage} alt="" /></div>
        <div className="game-start-screen-story">{gameStory}</div>
        <div className="game-start-screen-start-button"><button onClick={gameStart}>Start The Game</button></div>
        <div className="game-start-screen-music-button">
          <span>Sound</span>
          <Switch
            checked={musicFlag}
            onChange={toggleMusic}
            color="primary"
          />
        </div>
      </div>
    </div>
  );
}

export default GameStartScreen;
