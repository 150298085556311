import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import { saveFeedback } from './feedback-service';
import RequestContest from '../contest/request-contest/request-contest.component';
import './feedback.styles.scss';

const Feedback = () => {
  const initData = {
    name: EMPTY_STRING, emailId: EMPTY_STRING, message: EMPTY_STRING
  };
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);
  const [showLoader, setShowLoader] = useState(false);
  const [showAds, setShowAds] = useState(false);

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveFeedback(formData).subscribe({
        next: (response) => {
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["emailId"]) {
      formIsValid = false;
      errors["emailId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["message"]) {
      formIsValid = false;
      errors["message"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="contactFormContainer">
        <div className="contactTitle">{appContext.userData ? 'Feedback' : 'Contact us'}</div>
        <div className="contactForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField"><TextField name="name" value={formData.name} onChange={handleChange} required fullWidth label="Full Name" size="small" />
              <span className="error-text">{formError["name"]}</span></div>
            <div className="formField"><TextField type="email" name="emailId" value={formData.emailId} onChange={handleChange} required fullWidth label="Email Id" size="small" />
              <span className="error-text">{formError["emailId"]}</span></div>
            <div className="formField">
              <TextField name="message" label="Message" fullWidth required multiline rows={7}
                value={formData.message} onChange={handleChange}
              />
              <span className="error-text">{formError["message"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
            {appContext.userData ? <div className="formField sendButton">
              {/* <Button className="request-button" variant="contained" color="primary" onClick={requestForContest}>
                Request For Free Contest</Button> */}
            </div> : <div className="formField sendButton">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button className="request-button" variant="contained" color="primary">
                  Back</Button>
              </Link></div>}
          </form>
        </div>
        <div className="contactInfo">
          <div><span><EmailOutlinedIcon /></span> info@cudents.com</div>
          <span><a href="https://wa.me/919380353392?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20Cudents." target="_blank"><WhatsAppIcon /></a> <span className="whatsapp">+91 938 035 3392</span></span>
        </div>
      </div>
    );
  }
  return (
    <div className="contactContainer">
      {renderContact()}
    </div>
  );
}

export default Feedback;