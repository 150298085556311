// import { Http } from "@capacitor-community/http";
import { CapacitorHttp as Http } from '@capacitor/core';
import { from, map } from "rxjs";
import { ACCESS_TOKEN } from '../components/constants/constants';
import { isEmpty } from "./util-service";

const getAuthHeader = () => {
    return {
        'Accept': 'application/json, text/plain, */*',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        'Content-Type': 'application/json'
    };

}
const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
};

const getResponseData = (response) => {
    if(response){
        if (response.status === 200) { 
            return response.data;
        } else { 
            throw new Error(response.data.message);
        } 
    } else {
        return response;
    }
}
export function get(url) {
    if (isEmpty(localStorage.getItem(ACCESS_TOKEN))) {
        return from(Promise.reject('No access token set.'));
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: getAuthHeader()
    };

    return from(Http.get(options)).pipe(map((response) => {
        return getResponseData(response);
    }));
}

export function whitelistGet(url) {
    let header = headers;
    if (localStorage.getItem(ACCESS_TOKEN)) {
        header = getAuthHeader();
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: header
    };

    return from(Http.get(options)).pipe(map((response) => {
        return getResponseData(response);
    }));
}

export function post(url, data) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return from(Promise.reject('No access token set.'));
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: getAuthHeader(),
        data: data
    };
    return from(Http.post(options)).pipe(map((response) => {
        return getResponseData(response);
   }));
}

export function put(url, data) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return from(Promise.reject('No access token set.'));
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: getAuthHeader(),
        data: data
    };
    return from(Http.put(options)).pipe(map((response) => {
        return getResponseData(response);
   }));
}

export function whitelistPost(url, data) {
    let header = headers;
    if (localStorage.getItem(ACCESS_TOKEN)) {
        header = getAuthHeader();
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: header,
        data: data
    };
    return from(Http.post(options)).pipe(map((response) => {
        return getResponseData(response);
   }));
}

export function downloadPost(url, data) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return from(Promise.reject('No access token set.'));
    }

    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: getAuthHeader(),
        data: data,
        responseType: 'arraybuffer'
    };
    return from(Http.downloadFile(options)).pipe(map((response) => response ? response.data : response));
}

export function remove(url) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return from(Promise.reject('No access token set.'));
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: getAuthHeader()
    };
    return from(Http.delete(options)).pipe(map((response) => {
        return getResponseData(response);
   }));
}

export function whitelistRemove(url) {
    let header = headers;
    if (localStorage.getItem(ACCESS_TOKEN)) {
        header = getAuthHeader();
    }
    const options = {
        url: process.env.REACT_APP_BASE_URL + url,
        headers: header
    };
    return from(Http.del(options)).pipe(map((response) => {
        return getResponseData(response);
   }));
}
