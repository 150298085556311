import { Button, MenuItem, TextField } from '@material-ui/core';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import { EMPTY_STRING, STATUS, STATUS_TYPES, USER_ROLE } from '../../components/constants/constants';
import GridComponent from '../../components/grid/grid.component';
import Loader from '../../components/loader/loader.component';
import { deleteGroup, getAllGroupForAdmin, updateGroup } from './admin-group.service';
import './admin-group.styles.scss';

const AdminGroup = () => {

  const [groupList, updateGroupList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    loadAllGroupForAdmin();
  }, [page]);


  const loadAllGroupForAdmin = () => {
    setShowLoader(true);
    getAllGroupForAdmin(page - 1).subscribe({
      next: (response) => {
        updateGroupList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  }

  const approve = (group) => {
    setShowLoader(true);
    updateGroup(group).subscribe({
      next: (response) => {
        console.log(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const reject = (group) => {
    setShowLoader(true);
    updateGroup(group).subscribe({
      next: (response) => {
        console.log(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const removeGroup = (group) => {
    console.log(group.name);
    setShowLoader(true);
    deleteGroup(group.rowId).subscribe({
      next: (response) => {
        console.log(response.message);
        loadAllGroupForAdmin();
        setShowLoader(false);
      }, error: (error) => {
        console.log(error.message);
        setShowLoader(false);
      }
    });
  }

  const handleEdit = (group) => {

    const newList = groupList.map((item) => {
      if (item.rowId === group.rowId) {
        const updatedItem = {
          ...item,
          isActive: group.isActive,
          reason: group.isActive ===1 ? EMPTY_STRING : group.reason
        };

        return updatedItem;
      }

      return item;
    });
    updateGroupList(newList);
  };

  const colDef = [
    { headerName: 'Name', headerObj: 'name' },
    { headerName: 'Description', headerObj: 'description' },
    { headerName: 'Group Code', headerObj: 'groupCode' },
    { headerName: 'Member Count', headerObj: 'memberCount' },  
    { headerName: 'Group Creator', headerObj: 'groupCreator' },
    { headerName: 'Creation Date', content: (group) => moment(group.creationDate).format('DD-MM-YYYY hh:mm:ss A') },
    // { headerName: 'Status', content: (group) => group.isActive === 1 ? 'Active' : 'Inactive' },
    {
      headerName: 'Status', content: (group) => <>
        <TextField className='inline-text' name="status" value={group.isActive} select onChange={(e) => { group.isActive = e.target.value; handleEdit(group); }}>
        {STATUS_TYPES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.name}
                </MenuItem>
              ))}
        </TextField>
       { group.isActive === 0 && <TextField className='inline-text' name="reason" value={group.reason || EMPTY_STRING} onChange={(e) => { group.reason = e.target.value; handleEdit(group) }} label="Reason" variant="outlined" size="small" />}
      </>
    },
    {
      headerName: 'Action', content: (group) => <>
        {
          <Button onClick={() => { approve(group) }} variant="contained" color="primary">
            Submit
          </Button>
        }
        <Button onClick={() => { removeGroup(group) }} variant="contained" color="secondary">
          Delete
        </Button></>
    }
  ]


  const renderSupport = () => {
    return <GridComponent gridData={groupList} colDef={colDef} pageCount={pageCount} page={page} handlePageChange={handlePageChange} title={'Groups'} />
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderSupport()}
    </div>
  );
}

export default AdminGroup;