import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from '../../../components/constants/constants';
import GameFilterDialog from '../../../components/games/filter-dialog/filter-dialog.component';
import { GAME_TYPE_INFINITE, GAME_TYPE_MATCH, GAME_TYPE_SEARCH, GAME_TYPE_SELECT, GAME_TYPE_SEQUENCE } from "../../../components/games/game-constants";
import Loader from '../../../components/loader/loader.component';
import YesNoDialog from "../../../components/message-dialog/yes-no-dialog.component";
import { findAllGameByGrade } from '../game-service';
import { logService } from "../../../services/logging-service";
import { validGameForUser } from "../../../services/validation-util";
import player from "./animations/player.json";
import './games-listing.styles.scss';

const GamesListing = (props) => {

  const classType = props.match.params.classType;
  const grade = props.match.params.grade;
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [gameList, setGameList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [game, setGame] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    if (gameList.length === 0) {
      window.screen.orientation.lock('portrait');
    }
  }, []);


  useEffect(() => {
    loadGameList(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const loadGameList = (selectedPage) => {
    findAllGameByGrade(classType, grade, selectedPage - 1).subscribe({
      next: (response) => {
        setGameList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const showFilterDialog = (game) => {
    if (game.isFree === 1) {
      startGame(game);
    } else {
      const msg = validGameForUser(appContext.userData);
      if (msg === EMPTY_STRING) {
        startGame(game);
      } else {
        // appContext.setDialogMessage(msg);
        setMessage(msg);
        setShowMsgDialog(true);
      }
    }
  }

  const onOkClick = () => {
    history.push('/pricing');
  }

  const startGame = (game) => {
    setGame(game);
    setShowFilters(true);
  }

  const goToGame = (grade, subject, topic, gameId, type) => {
    setShowFilters(false);
    logGameService(grade, subject, topic, type);
    if (topic !== EMPTY_STRING) {
      switch (type) {
        case GAME_TYPE_SELECT:
          case GAME_TYPE_SEQUENCE:
          case GAME_TYPE_SEARCH:
        case GAME_TYPE_MATCH:
          window.screen.orientation.lock('landscape');
          history.push(`/gamezone/${gameId}/${grade}/${subject}/${topic}`);
          break;
        case GAME_TYPE_INFINITE:
          window.screen.orientation.lock('landscape');
          history.push(`/game/infinitegame/${gameId}/${grade}/${subject}/${topic}`);
          break;
        default:
          appContext.setDialogMessage(`Game of ${type} not available`);
          break;
      }
    }
  }

  const logGameService = (grade, subject, topic, type) => {
    logService("Game", `${type}-${game.name}-${grade}-${subject}-${topic}`).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });
  }

  const getCardImagePath = (gameImagePath) => {
    return `https://www.cudents.com/prod/assets/images/games/${gameImagePath}/main_card.webp`;
  }

  return (<> {showLoader ? <div className="showLoading"><Loader /></div> :
    <div className="games-user-dashboard-listing">
      <div className="game-listing-page-top">
        <img src="https://www.cudents.com/prod/assets/images/homepage/newhome/newdesign/curve2.png" alt="" />
        <div className="game-listing-top-animation">
          <Lottie className="game-listing-animation" animationData={player} loop={true} />
        </div>
      </div>
      {
        gameList.map((game, idx) => (
          <div key={idx} className="game-card" onClick={() => { showFilterDialog(game) }}>
            {game.isFree === 1 ? null :
              <div className="premium-icon"><img src="/assets/images/homepage/newhome/icons/crown.png" alt="" /></div>
            }
            <div className="game-image"><img src={getCardImagePath(game.gameFolderName)} alt="" /></div>
            <div className="game-name">{game.name}</div>
            <div className="game-tag">{game.tagLine}</div>
          </div>
        ))
      }

      <div className="game-paging">
        {/* <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} /> */}
      </div>
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      <GameFilterDialog open={showFilters} onClose={() => { setShowFilters(false) }} game={game} grade={grade} goToGame={goToGame}></GameFilterDialog>
    </div>
  }</>
  );

}

export default GamesListing;