import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../app-context";
import { EMPTY_STRING } from "../../../constants/constants";
import YesNoDialog from "../../../message-dialog/yes-no-dialog.component";
import { getInfiniteGame, saveInfiniteGameAttempt } from '../../../../feature/game/game-service';
import PortraitLandscape from "../../../portrait-to-landscape/portrait-to-landscape";
import GameConsoleCatch from "../game-console-catch/game-console-catch.component";
import GameConsoleFire from "../game-console-fire/game-console-fire.component";
import GameConsoleJump from "../game-console-jump/game-console-jump.component";
import './infinite-game-engine.styles.scss';
import { isWebPlatform } from "../../../../services/util-service";
function InfiniteGameEngine(props) {
  const gameId = props.match.params.gameId;
  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const history = useHistory();
  const [gameStart, setGameStart] = useState(false);
  const [gameLoading, setGameLoading] = useState(true);
  const [gameData, setGameData] = useState(null);
  // const [backgroundMusic, setBackgroundMusic] = useState(null);
  const [winMusic, setWinMusic] = useState(null);
  const [shootMusic, setShootMusic] = useState(null);
  const [looseMusic, setLooseMusic] = useState(null);
  const [applaudSound, setApplaudSound] = useState(false);
  // const [infiniteGameMusicFlag, setInfiniteGameMusicFlag] = useState(2);
  const [musicFlag, setMusicFlag] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);
  const [currentScore, setCurrentScore] = useState(0);


  useEffect(() => {
    getInfiniteGame(gameId, grade, subject, topic).subscribe({
      next: (response) => {
        const game = response.data;
        // setBackgroundMusic(new Audio(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/music/background_music.mp3`));
        setWinMusic(new Audio(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/music/win.mp3`));
        setLooseMusic(new Audio(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/music/loose.mp3`));
        setShootMusic(new Audio(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/music/shoot.mp3`));

        const gameFolderName = game.gameFolderName;
        game["wrongOptions"] = getWrongImages(game);
        game["correctOptions"] = getCorrectImages(game);
        game["backgroundImage"] = getImage(gameFolderName, "backgroundImage");
        game["infoImage"] = getImage(gameFolderName, "infoImage");
        game["bulletImage"] = getImage(gameFolderName, "bulletImage");
        game["pointImage"] = getImage(gameFolderName, "pointImage");
        game["music"] = {};
        game["music"]["on"] = getImage(gameFolderName, "music_on");
        game["music"]["off"] = getImage(gameFolderName, "music_off");
        game["scoreboard"] = {};
        game["scoreboard"]["image"] = getImage(gameFolderName, "scoreboard");
        game["scoreboard"]["playButton"] = getImage(gameFolderName, "playButton");

        setGameData(game);
        setGameLoading(false);
        // playBackgroundMusic();
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
    return function cleanup() {
      // if (backgroundMusic != null) {
      //   backgroundMusic.pause();
      // }
    };
  }, [])



  useEffect(() => {
  }, [musicFlag]);



  const getImage = (gameFolderName, val) => {
    switch (val) {
      case 'backgroundImage':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/game_bg.webp`;
      case 'pointImage':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/point.webp`;
      case 'infoImage':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/game_info.webp`;
      case 'scoreboard':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/scoreboard.webp`;
      case 'playButton':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/play.webp`;
      case 'bulletImage':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/bullet.webp`;
      case 'music_on':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/music_on.webp`;
      case 'music_off':
        return `https://www.cudents.com/prod/assets/images/games/${gameFolderName}/music_off.webp`;
      default:
        break;
    }
  }
  const getCorrectImages = (game) => {
    const images = [];
    for (let index = 0; index < game.correctAnswer; index++) {
      images.push(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/options/correct/${index + 1}.webp`)
    }
    return images;
  }

  const getWrongImages = (game) => {
    const images = [];
    for (let index = 0; index < game.wrongAnswer; index++) {
      images.push(`https://www.cudents.com/prod/assets/images/games/${game.gameFolderName}/options/wrong/${index + 1}.webp`)
    }
    return images;
  }


  // useEffect(() => {
  //   playBackgroundMusic();
  // }, [infiniteGameMusicFlag])

  const startGame = () => {
    setGameLoading(false);
  }

  const restartGame = () => {
    setGameStart(true);
  }

  const stopGame = (currentScore) => {
    setGameStart(false);
    if (appContext.userData) {
      // Send if the score is greater then higher score, send it to backend.
      if (currentScore > gameData.highScore) {
        saveInfiniteGameAttempt(gameData.gameId, gameData.grade, gameData.subject, gameData.topic, currentScore).subscribe({
          next: (response) => {
            updateHScore(currentScore);
            setShowLoader(false);
          }, error: (error) => {
            setShowLoader(false);
          }
        });
      }
    }
  }

  const updateHScore = (currentScore) => {
    setGameData({
      ...gameData,
      highScore: currentScore
    });
  };


  const updateCurrentScore = (currentScore) => {
    setCurrentScore(currentScore);
  };

  const applaudPlayer = () => {
    // add play applause music
  }

  // const playBackgroundMusic = () => {
  //   if (infiniteGameMusicFlag == 3 && backgroundMusic != null) {
  //     backgroundMusic.volume = .3;
  //     backgroundMusic.loop = true;
  //     backgroundMusic.play();
  //   } else {
  //     if (backgroundMusic != null) {
  //       backgroundMusic.pause();
  //     }
  //   }
  // }

  const playMusic = (val) => {
    if (musicFlag) {
      switch (val) {
        case 'won':
          winMusic.play();
          break;
        case 'lost':
          looseMusic.play();
          break;
        case 'shoot':
          shootMusic.play();
          break;
        default:
          break;
      }
    }
  }
  // Exit game
  const exitGame = () => {
    setMessage('Do you want to exit the Game?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    stopGame(currentScore);
    history.goBack();
    if(!isWebPlatform()){
      window.screen.orientation.lock('portrait');
    }
  }

  return (
    <>
      {gameLoading ?
        <div className="infinite-game-loader">
          Loading...
        </div>
        :
        <div className="infinite-game-container" id="infinite-game-container">
          <div className="infinite-game-buttons">
            {!gameStart ?
              <>{musicFlag ?
                <div className="infinite-game-music-button" onClick={() => { setMusicFlag(false) }}><img src={gameData.music.on} alt="" /></div>
                :
                <div className="infinite-game-music-button" onClick={() => { setMusicFlag(true) }}><img src={gameData.music.off} alt="" /></div>
              }</> : null}
            <div className="infinite-game-exit-button" onClick={exitGame}><img src="https://www.cudents.com/prod/assets/images/games/infinite_game_exit.webp" alt="" />
            </div>
          </div>
          {gameData.gameType === 'jump' &&
            <GameConsoleJump gameStart={gameStart} musicFlag={musicFlag} gameData={gameData} stopGame={stopGame} updateCurrentScore={updateCurrentScore} 
            restartGame={restartGame} applaudPlayer={applaudPlayer} playMusic={playMusic}></GameConsoleJump>}
          {gameData.gameType === 'catch' &&
            <GameConsoleCatch gameStart={gameStart} musicFlag={musicFlag} gameData={gameData} stopGame={stopGame} updateCurrentScore={updateCurrentScore} 
            restartGame={restartGame} applaudPlayer={applaudPlayer} playMusic={playMusic}></GameConsoleCatch>}
          {gameData.gameType === 'fire' &&
            <GameConsoleFire gameStart={gameStart} musicFlag={musicFlag} gameData={gameData} stopGame={stopGame} updateCurrentScore={updateCurrentScore} 
            restartGame={restartGame} applaudPlayer={applaudPlayer} playMusic={playMusic}></GameConsoleFire>}
        </div>
      }

      <div className="infinite-game-portrait">
        <PortraitLandscape></PortraitLandscape>
      </div>
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} isHorizontal={true} />
    </>
  );
}

export default InfiniteGameEngine;