import { get, post } from '../../services/http-services';
import { PAGE_SIZE } from "../../components/constants/constants";

export function saveAssessQuestion(questionModel) {
    return post('/assess/saveattempt', questionModel);
}

export function getAttemptAssess() {
    return get('/assess/getattempt');
}

export function getAssessQuestions(grade) {
    return get('/assess/question?grade=' + grade);
}

export function getAssessAttempt(pageNumber) {
    return get(`/assess/trend?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function saveQuestion(questionModel) {
    return post('/question', questionModel);
}

export function getQuestionHistory(questionId) {
    return get(`/question/history?questionId=${questionId}`);
}

export function getQuestionReport(pageNumber) {
    return get(`/question/report?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function getQuestionCounts(pageNumber) {
    return get(`/question/counts?page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function getQuestions(grade, subject, topic, level) {
    return get('/question?grade=' + grade + '&subject=' + subject + '&topic=' + topic + '&level=' + level);
}

export function approveQuestions(grade, subject, topic, level) {
    return post('/question/approve?grade=' + grade + '&subject=' + subject + '&topic=' + topic + '&level=' + level, null);
}

export function getAllQuestions(grade, subject, topic, level) {
    return get('/question/allquestions?grade=' + grade + '&subject=' + subject + '&topic=' + topic + '&level=' + level);
}

export function getApprovedQuestions(grade, subject, topic, level) {
    return get('/question/approvedquestions?grade=' + grade + '&subject=' + subject + '&topic=' + topic + '&level=' + level);
}

export function isLevelAttempted(grade, subject, topic, level){
    return get('/attempt/levelattempted?grade=' + grade + '&subject=' + subject + '&topic=' + topic + '&level=' + level);
}

export function getLevels(grade, subject, topic) {
    return get('/question/levels?grade=' + grade + '&subject=' + subject + '&topic=' + topic);
}

export function getQuestionLevels(grade, subject, topic) {
    return get('/question/questionlevels?grade=' + grade + '&subject=' + subject + '&topic=' + topic);
}

export function saveAllAttempts(data) {
    return post('/attempt/saveall', data);
}

export function saveAllReattempts(data) {
    return post('/attempt/retake', data);
}
