import React, { useEffect, useState } from 'react';
import { getGrades } from '../../../feature/quiz/category-service';
import { AdminSubjects } from '../admin-subject/admin-subject.component';
import './admin-grade.styles.scss';
const AdminGrades = () => {
  const [gradeList, setGradeList] = useState([]);

  useEffect(() => {
    if (gradeList) {
      loadGrade();
    }
  }, []);

  const loadGrade = () => {
    getGrades(1).subscribe({
      next: (response) => {
          setGradeList(response.data);
      }});
  }

    return (
      <div className="App">
        {gradeList.map(grade => (
          <div>
            <div className="Title">Class {grade.grade}
            </div>
            <AdminSubjects grade={grade.grade}/>
            <br/><br/>
          </div>
        ))}
      </div>
    );
}

export default AdminGrades;
