import { SMALL_PAGE_SIZE } from "../../components/constants/constants";
import { get, post, remove, whitelistGet, whitelistPost } from "../../services/http-services";

export function getCurrentUser() {
    return get("/users/me");
}

export function findByUser() {
    return get("/profile/findbyuser");
}

export function saveUserProfile(profile) {
    return post("/profile", profile);
}

export function changePassword(oldPassword, newPassword) {
    return post(`/users/changepassword?oldPassword=${oldPassword}&newPassword=${newPassword}`, null);
}

export function signin(authModel) {
    return whitelistPost("/users/signin", authModel);
}

export function signup(user) {
    return whitelistPost("/users/signup", user);
}


export function isUserExist(userId) {
    return whitelistGet(`/users/mobile/exist?userId=${userId}`);
}

export function contestSignup(user) {
    return post("/contest/signup", user);
}

export function userSaveFromAdmin(user) {
    return post("/users/admin/save", user);
}

export function resetDefaultPassword(userId, password) {
    return post(`/users/defaultpassword?userId=${userId}&password=${password}`, null);
}

export function createNewPassword(user) {
    return whitelistPost("/users/createnewpassword", user);
}

export function socialSignin(user) {
    return whitelistPost("/users/social/login", user);
}

export function findAllUser(searchText, pageNumber) {
    return get(`/users/page?searchText=${searchText}&page=${pageNumber}&size=${SMALL_PAGE_SIZE}&sort=creationDate,desc`);
}

export function getUserTrend(period) {
    return get(`/users/trend?period=${period}`);
}

export function deleteAccount() {
    return remove(`/users/deleteaccount`);
}

export function deleteAccountAdmin(userId) {
    return remove(`/users/deleteaccountadmin?userId=${userId}`);
}
