import './subject-card.styles.scss';

export const SubjectCard = props => {
  return (
    <div className='card-container'>
      <img src={props.subject.imagePath} alt="Subject" />
      <h3>{props.subject.name}</h3>
      {1 === props.isAdmin && <span>{props.subject.approvedCount} Approved</span>}
      {1 === props.isAdmin && <span>{props.subject.pendingCount} Pending</span>}
      {1 !== props.isAdmin && <div className="subject-info">{props.subject.quizCount === 0 ? 'Coming Soon' : `${props.subject.quizCount} Quizzes`}</div>}
      {(1 === props.isDashboard && props.subject.quizCount !== 0) && <div className="subject-info">{`${props.subject.attemptCount} Attempted`}</div>}
    </div>
  );
}

export default SubjectCard;
