import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { AppContext } from '../../app-context';
import AdminActivity from '../admin-activity/admin-activity.component';
import AdminAttemptsDashboard from '../attempts/admin-attempts-dashboard';
import AdminContestPosters from '../admin-contest-posters/admin-contest-posters.component';
import ContestQuestion from '../admin-contest-question/contest-question.component';
import ContestStatus from '../admin-contest-status/admin-contest-status.component';
import AdminContest from '../admin-contest/admin-contest.component';
import AdminCoupon from '../admin-coupon/admin-coupon.component';
import AdminDashboard from '../admin-dashboard.component';
import AdminFeedback from '../admin-feedback/feedback.component';
import AdminGame from '../admin-game/admin-game.component';
import GKQuestion from '../admin-gk/admin-gk.component';
import AdminGuessMaster from '../admin-guess-master/admin-guess-master.component';
import AdminGuessQuestion from '../admin-guess-question/admin-guess-question.component';
import AdminHomeBanner from '../admin-home-banner/admin-home-banner.component';
import AdminInfiniteGame from '../admin-infinite-game/admin-infinite-game.component';
import AdminInstitutions from '../admin-institution/institution.component';
import AdminLogDashboard from '../logging-dashboard/admin-log-dashboard';
import AdminNotification from '../admin-notification/admin-notification.component';
import AdminParticipant from '../admin-participant/admin-participant.component';
import AdminPayment from '../admin-payment/admin-payment.component';
import AdminGrades from '../admin-question-bank/admin-grade/admin-grade.component';
import AdminLevels from '../admin-question-bank/admin-level/admin-level.component';
import AdminQuestionBank from '../admin-question-bank/admin-question-bank.component';
import AdminTopics from '../admin-question-bank/admin-topic/admin-topic.component';
import AdminRedeem from '../admin-redeem/admin-redeem.component';
import AdminReferral from '../admin-referral/admin-referral.component';
import AdminRequestContest from '../admin-request-contest/admin-request-contest.component';
import AdminRiddle from '../admin-riddle/admin-riddle.component';
import AdminSales from '../admin-sales/admin-sales.component';
import SelectGameQuestion from '../admin-select-game/select-game-question.component';
import AdminSubscription from '../admin-subscription/admin-subscription.component';
import AdminSupport from '../admin-support/admin-support.component';
import AdminTDTY from '../admin-tdty/admin-tdty.component';
import AdminVideo from '../admin-video/admin-video.component';
import BulkPayment from '../bulk-payment/bulk-payment.component';
import Logging from '../logging-dashboard/logging/logging.component';
import PartnerContest from '../partner-contest/partner-contest.component';
import PartnerReferral from '../partner-referral/partner-referral.component';
import PartnerSales from '../partner-sales/partner-sales.component';
import QuestionCounts from '../question-counts/question-counts.component';
import QuestionReport from '../question-report/question-report.component';
import ScoreReport from '../score-report/score-report.component';
import UserList from '../user-list/user-list.component';
import { ROLE_ADMIN, ROLE_PARTNER, ROLE_TUTOR } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import ContestCertificate from '../../feature/contest/platform/cp-certificate/cp-certificate.component';
import HomePage from '../../feature/home-page/home-page.component';
import './admin-panel.styles.scss';
import Attempt from '../attempts/attempt/attempt.component';
import AttemptTrend from '../attempts/attempt-trend/attempt-trend.component';
import Assessment from '../attempts/assess-attempt/assess-attempt.component';
import AdminReportDashboard from '../report/admin-report-dashboard';
import AdminWhatsapp from '../admin-whatsapp/admin-whatsapp.component';
import AdminGroup from '../group/admin-group.component';
import AdminAppUpdate from '../admin-app-update/admin-app-update.component';


const AdminPanel = () => {

  const [value, setValue] = useState('/admin/questionBank');
  const [userData, setUserData] = useState({});
  const appContext = useContext(AppContext);


  useEffect(() => {
    if (appContext.userData) {
      setUserData(appContext.userData);
    }
  }, [appContext]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs = () => {
    if (userData && userData.role) {
      if (userData.role === ROLE_TUTOR) {
        return (
          <>
            <Tab label="Quiz Questions" value="/admin/questionBank" component={Link} to="/admin/questionBank" />
            <Tab label="Game Questions" value="/admin/selectgamequestion" component={Link} to="/admin/selectgamequestion" />
            <Tab label="GK" value="/admin/gk" component={Link} to="/admin/gk" />
            <Tab label="Riddle" value="/admin/riddle" component={Link} to="/admin/riddle" />
            <Tab label="TDTY" value="/admin/history" component={Link} to="/admin/history" />
          </>)
      } else if (userData.role === ROLE_PARTNER) {
        return (
          <>
            <Tab label="Partner Sales" value="/admin/partnersales" component={Link} to="/admin/partnersales" />
            <Tab label="Partner Referrals" value="/admin/partnerreferral" component={Link} to="/admin/partnerreferral" />
            <Tab label="Contest Participants" value="/admin/partnerparticipant" component={Link} to="/admin/partnerparticipant" />
          </>)
      } else if (userData.role === ROLE_ADMIN) {
        return (
          <>
            <Tab label="Dashboard" value="/admin/dashboard" component={Link} to="/admin/dashboard" />
            <Tab label="Report" value="/admin/report" component={Link} to="/admin/report" />
            <Tab label="Attempts" value="/admin/attempts" component={Link} to="/admin/attempts" />
            <Tab label="Log Details" value="/admin/logdetails" component={Link} to="/admin/logdetails" />
            <Tab label="Quiz Questions" value="/admin/questionBank" component={Link} to="/admin/questionBank" />
            <Tab label="Group" value="/admin/group" component={Link} to="/admin/group" />
            <Tab label="Game" value="/admin/game" component={Link} to="/admin/game" />
            <Tab label="Game Questions" value="/admin/selectgamequestion" component={Link} to="/admin/selectgamequestion" />
            <Tab label="Infinite Game" value="/admin/infinitegame" component={Link} to="/admin/infinitegame" />
            <Tab label="GK" value="/admin/gk" component={Link} to="/admin/gk" />
            <Tab label="Riddle" value="/admin/riddle" component={Link} to="/admin/riddle" />
            <Tab label="TDTY" value="/admin/history" component={Link} to="/admin/history" />
            <Tab label="Home Banner" value="/admin/homrbanner" component={Link} to="/admin/homrbanner" />
            <Tab label="Contest Questions" value="/admin/contestquestion" component={Link} to="/admin/contestquestion" />
            <Tab label="Participants" value="/admin/adminparticipant" component={Link} to="/admin/adminparticipant" />
            <Tab label="Institutions" value="/admin/institutions" component={Link} to="/admin/institutions" />
            <Tab label="Contests" value="/admin/admincontest" component={Link} to="/admin/admincontest" />
            <Tab label="Contest Status" value="/admin/conteststatus" component={Link} to="/admin/conteststatus" />
            <Tab label="Contest Requests" value="/admin/requestcontest" component={Link} to="/admin/requestcontest" />
            <Tab label="Payments" value="/admin/payment" component={Link} to="/admin/payment" />
            <Tab label="Bulk Payment" value="/admin/bulkpayment" component={Link} to="/admin/bulkpayment" />
            <Tab label="Coupons" value="/admin/admincoupon" component={Link} to="/admin/admincoupon" />
            <Tab label="Subscription Code" value="/admin/adminsubscription" component={Link} to="/admin/adminsubscription" />
            <Tab label="Support" value="/admin/support" component={Link} to="/admin/support" />
            <Tab label="Notification" value="/admin/notification" component={Link} to="/admin/notification" />
            <Tab label="Activity" value="/admin/activity" component={Link} to="/admin/activity" />
            <Tab label="Video" value="/admin/video" component={Link} to="/admin/video" />
            <Tab label="Guess Master" value="/admin/guessmaster" component={Link} to="/admin/guessmaster" />
            <Tab label="Guess Question" value="/admin/guessquestion" component={Link} to="/admin/guessquestion" />
            <Tab label="Whatsapp" value="/admin/whatsapp" component={Link} to="/admin/whatsapp" />
            <Tab label="App version" value="/admin/appupdate" component={Link} to="/admin/appupdate" />
            {/* <Tab label="Question Report" value="/admin/questionreport" component={Link} to="/admin/questionreport" /> */}
            {/* <Tab label="User" value="/admin/user" component={Link} to="/admin/user" /> */}
            {/* <Tab label="Referral" value="/admin/adminreferral" component={Link} to="/admin/adminreferral" /> */}
            {/* <Tab label="Sales" value="/admin/adminsales" component={Link} to="/admin/adminsales" /> */}
            {/* <Tab label="Logging" value="/admin/logging" component={Link} to="/admin/logging" /> */}
            {/* <Tab label="Redeem" value="/admin/redeem" component={Link} to="/admin/redeem" /> */}
            {/* <Tab label="Game Attempt" value="/admin/gameattempt" component={Link} to="/admin/gameattempt" /> */}
            {/* <Tab label="Attempt Report" value="/admin/attempt" component={Link} to="/admin/attempt" /> */}
            {/* <Tab label="Attempt Trend" value="/admin/attempttrend" component={Link} to="/admin/attempttrend" /> */}
            {/* <Tab label="Assessment" value="/admin/assessment" component={Link} to="/admin/assessment" /> */}
            {/* <Tab label="Feedback" value="/admin/feedback" component={Link} to="/admin/feedback" /> */}
          </>
        )
      } else {
        return (<Tab label="blank"></Tab>);
      }
    } else {
      return (<Tab label="blank"></Tab>)
    }
  }

  const renderView = () => {
    return (
      <BrowserRouter>
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
            >
              {
                renderTabs()
              }
            </Tabs>
          </AppBar>

          <Switch>
            <Route path='/admin/dashboard' render={() => ((userData.role === ROLE_ADMIN) ? <AdminDashboard /> : <HomePage />)} />
            <Route path='/admin/support' render={() => ((userData.role === ROLE_ADMIN) ? <AdminSupport /> : <HomePage />)} />
            <Route path='/admin/notification' render={() => ((userData.role === ROLE_ADMIN) ? <AdminNotification /> : <HomePage />)} />
            <Route path='/admin/feedback' render={() => ((userData.role === ROLE_ADMIN) ? <AdminFeedback /> : <HomePage />)} />
            <Route exact path='/admin/selectgamequestion' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <SelectGameQuestion /> : <HomePage />)} />
            <Route exact path='/admin/infinitegame' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminInfiniteGame /> : <HomePage />)} />
            <Route exact path='/admin/gk' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <GKQuestion /> : <HomePage />)} />
            <Route exact path='/admin/riddle' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminRiddle /> : <HomePage />)} />
            <Route exact path='/admin/homrbanner' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminHomeBanner /> : <HomePage />)} />
            <Route exact path='/admin/history' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminTDTY /> : <HomePage />)} />
            <Route exact path='/admin/questionBank' render={() => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminGrades /> : <HomePage />)} />
            <Route exact path='/admin/questionBank/:grade/:subject' render={(props) => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminTopics  {...props} /> : <HomePage />)} />
            <Route exact path='/admin/questionBank/:grade/:subject/:topic' render={(props) => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminLevels  {...props} /> : <HomePage />)} />
            <Route exact path='/admin/questionBank/:grade/:subject/:topic/:level' render={(props) => ((userData.role === ROLE_ADMIN || userData.role === ROLE_TUTOR) ? <AdminQuestionBank  {...props} /> : <HomePage />)} />
            <Route exact path='/admin/payment' render={() => ((userData.role === ROLE_ADMIN) ? <AdminPayment /> : <HomePage />)} />
            <Route exact path='/admin/user' render={() => ((userData.role === ROLE_ADMIN) ? <UserList /> : <HomePage />)} />
            <Route exact path='/admin/contestquestion' render={() => ((userData.role === ROLE_ADMIN) ? <ContestQuestion /> : <HomePage />)} />
            <Route exact path='/admin/redeem' render={() => ((userData.role === ROLE_ADMIN) ? <AdminRedeem /> : <HomePage />)} />
            <Route exact path='/admin/scorereport' render={() => ((userData.role === ROLE_ADMIN) ? <ScoreReport /> : <HomePage />)} />
            <Route exact path='/admin/attempts' render={() => ((userData.role === ROLE_ADMIN) ? <AdminAttemptsDashboard /> : <HomePage />)} />
            <Route exact path='/admin/assessment' render={() => ((userData.role === ROLE_ADMIN) ? <Assessment /> : <HomePage />)} />
            <Route exact path='/admin/questionreport' render={() => ((userData.role === ROLE_ADMIN) ? <QuestionReport /> : <HomePage />)} />
            <Route exact path='/admin/questioncounts' render={() => ((userData.role === ROLE_ADMIN) ? <QuestionCounts /> : <HomePage />)} />
            <Route exact path='/admin/attempt' render={() => ((userData.role === ROLE_ADMIN) ? <Attempt /> : <HomePage />)} />
            <Route exact path='/admin/attempttrend' render={() => ((userData.role === ROLE_ADMIN) ? <AttemptTrend /> : <HomePage />)} />
            <Route exact path='/admin/partnersales' render={() => ((userData.role === ROLE_PARTNER) ? <PartnerSales /> : <HomePage />)} />
            <Route exact path='/admin/adminsales' render={() => ((userData.role === ROLE_ADMIN) ? <AdminSales /> : <HomePage />)} />
            <Route exact path='/admin/institutions' render={() => ((userData.role === ROLE_ADMIN) ? <AdminInstitutions /> : <HomePage />)} />
            <Route exact path='/admin/requestcontest' render={() => ((userData.role === ROLE_ADMIN) ? <AdminRequestContest /> : <HomePage />)} />
            <Route exact path='/admin/adminparticipant' render={() => ((userData.role === ROLE_ADMIN) ? <AdminParticipant /> : <HomePage />)} />
            <Route exact path='/admin/admincontest' render={() => ((userData.role === ROLE_ADMIN) ? <AdminContest /> : <HomePage />)} />
            <Route exact path='/admin/conteststatus' render={() => ((userData.role === ROLE_ADMIN) ? <ContestStatus /> : <HomePage />)} />
            <Route exact path='/admin/partnerparticipant' render={() => ((userData.role === ROLE_PARTNER) ? <PartnerContest /> : <HomePage />)} />
            <Route exact path='/admin/partnerreferral' render={() => ((userData.role === ROLE_PARTNER) ? <PartnerReferral /> : <HomePage />)} />
            <Route exact path='/admin/adminreferral' render={() => ((userData.role === ROLE_ADMIN) ? <AdminReferral /> : <HomePage />)} />
            <Route exact path='/admin/logdetails' render={() => ((userData.role === ROLE_ADMIN) ? <AdminLogDashboard /> : <HomePage />)} />
            <Route exact path='/admin/logging' render={() => ((userData.role === ROLE_ADMIN) ? <Logging /> : <HomePage />)} />
            <Route exact path='/admin/bulkpayment' render={() => ((userData.role === ROLE_ADMIN) ? <BulkPayment /> : <HomePage />)} />
            <Route exact path='/admin/contestposters' render={() => ((userData.role === ROLE_ADMIN) ? <AdminContestPosters /> : <HomePage />)} />
            <Route exact path='/admin/admincoupon' render={() => ((userData.role === ROLE_ADMIN) ? <AdminCoupon /> : <HomePage />)} />
            <Route exact path='/admin/adminsubscription' render={() => ((userData.role === ROLE_ADMIN) ? <AdminSubscription /> : <HomePage />)} />
            <Route exact path='/admin/activity' render={() => ((userData.role === ROLE_ADMIN) ? <AdminActivity /> : <HomePage />)} />
            <Route exact path='/admin/video' render={() => ((userData.role === ROLE_ADMIN) ? <AdminVideo /> : <HomePage />)} />
            <Route exact path='/admin/game' render={() => ((userData.role === ROLE_ADMIN) ? <AdminGame /> : <HomePage />)} />
            <Route exact path='/admin/guessmaster' render={() => ((userData.role === ROLE_ADMIN) ? <AdminGuessMaster /> : <HomePage />)} />
            <Route exact path='/admin/guessquestion' render={() => ((userData.role === ROLE_ADMIN) ? <AdminGuessQuestion /> : <HomePage />)} />
            <Route exact path='/contestpage/certificate/:contestType/:contestId/:gradeType/:winnerCount/:userId' component={ContestCertificate} />
            <Route exact path='/admin/report' render={() => ((userData.role === ROLE_ADMIN) ? <AdminReportDashboard /> : <HomePage />)} />
            <Route exact path='/admin/whatsapp' render={() => ((userData.role === ROLE_ADMIN) ? <AdminWhatsapp /> : <HomePage />)} />
            <Route exact path='/admin/group' render={() => ((userData.role === ROLE_ADMIN) ? <AdminGroup /> : <HomePage />)} />
            <Route exact path='/admin/appupdate' render={() => ((userData.role === ROLE_ADMIN) ? <AdminAppUpdate /> : <HomePage />)} />

            
           </Switch>
        </div>
      </BrowserRouter>
    );
  }

  return (
    <div>
      {
        userData && userData.role ? renderView() : <div className="showLoading"><Loader /></div>
      }
    </div>
  );

}

export default AdminPanel;