
import { Pagination } from "@material-ui/lab";
import Lottie from "lottie-react";
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../../../app-context";
import star from "../../animations/star.json";
import './leaderboard-history.styles.scss';
import Loader from "../../../../components/loader/loader.component";
function GroupLeaderboardHistory(props) {

  const groupId = props.match.params.groupId;
  const history = useHistory();
  const [previousMonths, setPreviousMonths] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
      loadMonthsTillToday();
  }, [page]);

  const loadMonthsTillToday = () => {

    const dateList = [];
    const today = moment();
    const startDate = moment('01/02/2024', 'DD/MM/yyyy');
    let m = today
    m.subtract(1, 'months');
    for (; startDate.diff(m, 'months', true) < 0; m.subtract(1, 'months')) 
    {
      dateList.push(m.toDate());
    }
    setPreviousMonths(dateList);
    setShowLoader(false);
  }
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const navigateTo = (date) => {
    history.push(`/group/view/${groupId}/${date}`);
  }

  return (<>{showLoader ? <div className="showLoading"><Loader /></div>
    :
    <div className="leaderboard-history-page">
      <div className="leaderboard-history-button">
          <span onClick={() => { history.push(`/group/view/${groupId}`); }}>Current Month</span>
        </div>

      <div className="top-animation">
        <Lottie className="leaderboard-page-top-animation" animationData={star} loop={true} />
      </div>
      <div className="leaderboard-listing">
        {
          previousMonths.map((date, idx) => (
            <div key={idx} className="leaderboard-card" onClick={() => navigateTo(date)}>
            <div className="leaderboard-header">
            {moment(date).format('yyyy')}
            </div>
              <div className="leaderboard-body">
              {moment(date).format('MMMM')}
              </div>
            </div>
          ))
        }
      </div> 
      <div className="gk-listing-pagination"><Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" /></div>
    </div>
  } </>
  );
}

export default GroupLeaderboardHistory;