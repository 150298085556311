import { Button, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SearchIcon from "@material-ui/icons/Search";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../app-context';
import { exportParticipantsAdmin, getContestsAdmin, getParticipantsAdmin } from '../../feature/contest/contest-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { getWhatsApp, isNotEmpty } from '../../services/util-service';
import { ALL, EMPTY_STRING, GRADE_TYPE_INDIVIDUAL, PARTICIPANT_SORT_LIST } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import './admin-participant.styles.scss';

const AdminParticipant = (props) => {

  const [participantList, setContestList] = useState([]);
  const [contests, setContests] = useState([]);
  const [contest, setContest] = useState(EMPTY_STRING);
  const [wpMessage, setWPMessage] = useState(EMPTY_STRING);
  const [showLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [sort, setSort] = useState('creationDate,asc');
  const history = useHistory();

  useEffect(() => {
    if (contests.length === 0) {
      loadContestCodes();
    }
  }, []);

  const loadContestCodes = () => {
    getContestsAdmin(ALL).subscribe({
      next: (response) => {
        setContests(response.data);
      }
    });
  }

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    if (contest.contestCode) {
      setShowLoader(true);
      const sortStr = 'EMPTY_STRING' === sort ? EMPTY_STRING : sort;
      getParticipantsAdmin(contest.contestCode, selectedPage - 1, sortStr).subscribe({
        next: (result) => {
          setContestList(result.data.data);
          setPageCount(result.data.totalPages);
          setTotalCount(result.data.totalElements);
          setShowLoader(false);
        }
      });
    } else {
      setShowLoader(false);
    }
  }

  const exportToExcel = (event) => {
    setShowLoader(true);
    exportParticipantsAdmin(contest.contestCode).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Participant.xlsx');
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }
    });
  }

  const searchData = (event) => {
    event.preventDefault();
    if (page === 1) {
      search(1);
    } else {
      setPageCount(1);
      setPage(1);
    }
  }

  const handleChange = (e) => {
    setContest(e.target.value);
  };

  const handleMsgChange = (e) => {
    setWPMessage(e.target.value);
  };

  const handleSortChange = (e) => {
    setSort(e.target.value);
  };

  const getFormPanel = () => {
    return (
      <div className="controlBox">
        <form noValidate autoComplete="off">
          <div className="admin-part-control wp-box">
            <TextField name="message" label="WhatsApp Message" required multiline rows={3} variant="outlined" size="small" fullWidth
              value={wpMessage} onChange={handleMsgChange}
            />
          </div>
          <div className="admin-part-control">
            <TextField name="contestCode" value={contest} select label="Contest Code" variant="outlined" size="small"
              fullWidth onChange={handleChange}>
              {contests.map((contest) => (
                <MenuItem key={contest.contestCode} value={contest}>
                  {contest.contestCode} - {contest.institutionName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="admin-part-control">
            <TextField name="sort" value={sort} select label="Sort"
              required onChange={handleSortChange} variant="outlined" size="small">
              {PARTICIPANT_SORT_LIST.map((sort) => (
                <MenuItem key={sort.value} value={sort.value}>
                  {sort.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="admin-part-control sendButton">
            <Button type="submit" variant="contained" color="primary" onClick={searchData}>
              <SearchIcon /> Search
            </Button>
          </div>
          <div className="admin-part-control  sendButton">
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              <GetAppOutlinedIcon /> Export To Excel
            </Button>
          </div>
          <div className="admin-part-control">
            <span className="institutionLabel">Total Users : </span>
            <span className="institutionValue">{totalCount}</span>
          </div>
        </form >
      </div >
    );
  }

  const goToCertificate = (participant) => {
    const gradeType = isNotEmpty(contest.gradeType) ? contest.gradeType : GRADE_TYPE_INDIVIDUAL;
    const winnerCount = isNotEmpty(contest.winnerCount) ? contest.winnerCount : 3;
    history.push(`/contestpage/certificate/${contest.contestType}/${contest.contestId}/${gradeType}/${winnerCount}/${participant.userRowId}`);
  }
  const getContestGrid = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>User Id</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Start Time</StyledTableCell>
                <StyledTableCell>End Time</StyledTableCell>
                <StyledTableCell>Score</StyledTableCell>
                <StyledTableCell>Time Taken</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Attempt Count</StyledTableCell>
                <StyledTableCell>Package</StyledTableCell>
                <StyledTableCell>Validity</StyledTableCell>
                <StyledTableCell>Primary Owner</StyledTableCell>
                <StyledTableCell>Secondary Owner</StyledTableCell>
                <StyledTableCell>Register Date</StyledTableCell>
                <StyledTableCell>Certificate</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participantList.map((participant) => (
                <StyledTableRow key={participant.name}>
                  <StyledTableCell>{participant.name}</StyledTableCell>
                  <StyledTableCell>{participant.displayName}</StyledTableCell>
                  <StyledTableCell>
                    <a href={getWhatsApp(participant.userId, wpMessage)} target="_blank"><WhatsAppIcon /></a> <span className="whatsapp">{participant.userId}</span>
                  </StyledTableCell>
                  <StyledTableCell>{participant.grade}</StyledTableCell>
                  <StyledTableCell>{moment(participant.startTime).format('lll')}</StyledTableCell>
                  <StyledTableCell>{moment(participant.endTime).format('lll')}</StyledTableCell>
                  <StyledTableCell>{participant.score}</StyledTableCell>
                  <StyledTableCell>{participant.timeTaken}</StyledTableCell>
                  <StyledTableCell>{participant.status}</StyledTableCell>
                  <StyledTableCell>{participant.attemptCount}</StyledTableCell>
                  <StyledTableCell>{participant.packageName}</StyledTableCell>
                  <StyledTableCell>{participant.validity}</StyledTableCell>
                  <StyledTableCell>{participant.primaryOwner}</StyledTableCell>
                  <StyledTableCell>{participant.secondaryOwner}</StyledTableCell>
                  <StyledTableCell>{moment(participant.registerDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>
                    <PersonOutlineOutlinedIcon className="icon" onClick={() => { goToCertificate(participant) }} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="participant-main">
      <div className="participant-title">Contest Participants</div>
      {getFormPanel()}
      {showLoader ? <div className="showLoading"><Loader /></div> : getContestGrid()}
    </div>
  );
}

export default AdminParticipant;