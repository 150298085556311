
import { Button, TextField } from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import { EMPTY_STRING, ROLE_ADMIN } from "../../components/constants/constants";
import Loader from '../../components/loader/loader.component';
import { isNotEmpty, isNotEmptyArray, isNotNull } from "../../services/util-service";
import './group-listing.styles.scss';
import { findAllGroupByUser, joinGroup } from "./group.service";
function GroupListing() {

  const history = useHistory();
  const appContext = useContext(AppContext);
  const [groupList, setGroupList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [groupCode, setGroupCode] = useState(EMPTY_STRING);

  useEffect(() => {
    if (groupList.length === 0) {
      loadgroup();
    }
  }, []);

  const loadgroup = () => {
    findAllGroupByUser().subscribe({
      next: (response) => {
        setGroupList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }
    });
  }

  const navigateTo = (group) => {
    const user = appContext.userData;
    if (group.isActive === 1 || (user.rowId === group.createdBy)) {
      history.push(`/group/view/${group.rowId}`);
    }
  }

  const editGroup = (group) => {
    history.push(`/group/edit/${group.rowId}`);
  }

  // const isEditAllowed = (group) => {
  //   const user = appContext.userData;
  //   return user.role === ROLE_ADMIN  || user.rowId === group.createdBy;
  // }

  const getTag = (group) => {
    if (group.isActive === 1) {
      return `Code: ${group.groupCode}`;
    } else if (isNotEmpty(group.reason)) {
      return 'Rejected';
    } else {
      return 'Pending Approval';
    }
  }

  const handleChange = (e) => {
    setGroupCode(e.target.value.toUpperCase());
  }
  

  const joinOtherGroup = (e) => {
    joinGroup(groupCode).subscribe({
      next: (response) => {
        loadgroup();
        appContext.setDialogMessage(response.message);
        setGroupCode(EMPTY_STRING);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }
    });
  };

  return (
    <>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="group-listing-page">
          <div className="top-animation">
            <img src="https://www.cudents.com/prod/assets/images/userdashboard/referral.png" alt="" />
          </div>
          <div className="group-title">
            Please enter the code to join a group
          </div>
          <div className="formField">
            <TextField name="groupCode" value={groupCode || EMPTY_STRING} onChange={handleChange} required fullWidth label="Enter Group Code" variant="outlined" size="small" />
            <Button variant="contained" onClick={joinOtherGroup} color="secondary">
              Join
            </Button>
          </div>

          <div className="group-title my-group">
            My Groups
          </div>
          {isNotEmptyArray(groupList) ? <div className="group-listing">
            {
              groupList.map((group, idx) => (
                <div key={idx} className="group-card" onClick={() => { navigateTo(group) }}>
                  <div className="group-card-image"><img src={group.imagePath} alt="" />
                  {/* {isEditAllowed(group) && <div className="group-card-edit"> <EditOutlinedIcon className="icon" onClick={() => { editGroup(group) }} /></div>} */}
                  </div>
                  <div className="group-card-name">{group.name}</div>
                  <div className="group-card-tag">{getTag(group)}</div>
                 
                </div>
              ))
            }
          </div> :  <div className="group-title">
          No group found. Please create or join a group.
          </div>}
        </div>
      }
    </>
  );
}

export default GroupListing;