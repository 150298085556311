import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post, put } from "../../services/http-services";

export function findAllGroupByUser() {
    return get(`/group/allbyuser`);
}

export function findGroupById(id) {
    return get(`/group/byid?id=${id}`);
}

export function saveGroup(groupModel) {
    return post(`/group`, groupModel);
}

export function userUpdate(groupModel) {
    return put(`/group/update`, groupModel);
}

export function joinGroup(groupCode) {
    return post(`/group/join?groupCode=${groupCode}`, null);
}

export function leaveGroup(groupId) {
    return post(`/group/leave?groupId=${groupId}`, null);
}

export function getLeaderBoardGroup(year, month, groupId, pageNumber) {
    return get(`/score/leaderboardgroup?year=${year}&month=${month}&groupId=${groupId}&page=${pageNumber}&size=${PAGE_SIZE}`);
}
export function getRankGroup(year, month, groupId) {
    return get(`/score/rankgroup?year=${year}&month=${month}&groupId=${groupId}`);
}