import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import PortraitLandscape from "../../portrait-to-landscape/portrait-to-landscape";
import correctAnswer from "./animations/correct_answer.json";
import search1 from "./animations/search1.json";
import search2 from "./animations/search2.json";
import wrongAnswer from "./animations/wrong_answer.json";
import "./search-master.styles.scss";


function SearchMaster(props) {

  const [showLoader, setShowLoader] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [showAnimation, setShowAnimation] = useState(false);
  const [levelFailed, setLevelFailed] = useState(false);
  const [backgroundMusic, setBackgroundMusic] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/thecorrectsequence/background.mp3'));
  const [correctAnswerMusic, setCorrectAnswerMusic] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/thecorrectsequence/correct.mp3'));
  const [winLevelMusic, setWinLevelMusic] = useState(new Audio('https://www.cudents.com/prod/assets/images/games/thecorrectsequence/levelwin.mp3'));


  const resetGame = () => {
    setOptionsList([]);
    setTimeout(() => {
      const options = props.questionDetails.options
      setOptionsList(options);
      setShowLoader(false);
    }, 500);
  }

  useEffect(() => {
    setShowLoader(true);
    resetGame();
  }, [props.questionDetails]);

  useEffect(() => {
    setShowAnimation(true);
  }, [optionsList]);

  useEffect(() => {
    setShowAnimation(true);
  }, [props.currentLevel])

  const getRandomAnimation = () => {
    if (currentLevel % 2 == 0)
      return <Lottie animationData={search2} loop={true} />
    else if (currentLevel % 3 == 0)
      return <Lottie animationData={search2} loop={true} />
    else
      return <Lottie animationData={search1} loop={true} />
  }



  // Verify if the level is complte. If yes then show celebration animation and next level button
  const verifyAnswer = (isCorrectOption) => {
    if (isCorrectOption === 1) {
      if (props.music) {
        backgroundMusic.pause();
        winLevelMusic.volume = .2;
        winLevelMusic.play();
      }
      // setLevelClear(true);
      setShowAnimation(false);
      props.correctAnswer();
    } else {
      setLevelFailed(true);
    }

  }

  return (
    <>
      <div className="search-master-game-container">
          <>
              <div className="search-master-game-play-area">
                <div className="sequence-game-level-button">
                        Level {props.currentLevel}
                </div>
                {optionsList.length > 0 ? <div className="search-master-game-bg">
                  <img src={optionsList[5].imagePath} alt="" />
                </div>: null}
                {!props.nextLevelButton && !levelFailed ?
                  <div className="search-master-game-show-question">
                    <div className="search-master-game-show-question-label">{props.questionDetails.questionText}</div>
                    <div className="search-master-game-show-question-image">
                      <img src={props.questionDetails.questionImagePath} alt="" />
                    </div>
                  </div> : null
                }
                {!props.nextLevelButton && !levelFailed ?
                  <div className="search-master-game-options-area">
                    <div className="search-master-game-options-question">
                      <div className="search-master-game-options-question-label">{props.questionDetails.questionText}</div>
                      <div className="search-master-game-options-question-image">
                        <img src={props.questionDetails.questionImagePath} alt="" />
                      </div>
                    </div>
                    <div className="search-master-game-options-list">
                      {optionsList.map((optionData, index) => (
                        optionData["imagePath"] !== null && index < 5? <div className="search-master-game-options-card" onClick={() => { verifyAnswer(optionData.isCorrect) }}>
                          <img src={optionData.imagePath} alt="" />
                        </div> : null
                      ))}
                    </div>
                    <div className="search-master-game-options-animation">
                      {showAnimation ?
                        <div className="search-master-game-animation">
                          {getRandomAnimation()}
                        </div> : <></>
                      }
                    </div>
                  </div>
                  :
                  <>
                    {props.nextLevelButton ?
                      <div className="search-master-game-options-area">
                        <div className="search-master-game-celebration">
                          <div className="search-master-game-level-animation">
                            <Lottie className="search-master-game-level-over" animationData={correctAnswer} loop={true} />
                          </div>
                          <div className="search-master-game-next-level" onClick={props.playNextLevel}>
                            NEXT
                          </div>
                        </div> </div> : null}
                    {levelFailed ?
                      <div className="search-master-game-options-area">
                        <div className="search-master-game-celebration">
                          <div className="search-master-game-level-animation">
                            <Lottie className="search-master-game-level-over" animationData={wrongAnswer} loop={true} />
                          </div>
                          <div className="search-master-game-next-level" onClick={() => { setLevelFailed(false) }}>
                            RE-PLAY
                          </div>
                        </div></div> : null}
                  </>
                }
              </div>
          </>
      </div>
      <div className="search-master-game-portrait">
        <PortraitLandscape></PortraitLandscape>
      </div>
    </>
  );
}

export default SearchMaster;