import Grid from '@material-ui/core/Grid';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../app-context';
import Timer from "../../../../components/games/timer/timer.component";
import { NEXT, PREVIOUS, SUBMIT } from '../cp-constant';
import './cp-quiz-player.styles.scss';
import { Rif, isNotEmpty, isNullCheck, replaceUnderscore, speakTheText } from '../../../../services/util-service';
import { saveContestAttempt } from '../../quiz-contest-service';

const CPQuizPlayer = (props) => {
  const [quizDetails, setQuizDetails] = useState(null);
  const [ansDetails, setAnsDetails] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (quizDetails === null) {
      setQuizDetails(props.quizDetails.models);
      initializeAnswerList(props.quizDetails.models);
      setCurrentPosition(props.quizDetails.latestAttemptedQuestion);
    }
  }, []);

  const initializeAnswerList = (qData) => {
    const tempAns = [];
    qData.map(question => {
      tempAns.push({
        questionId: question.questionId,
        attemptId: question.attemptId,
        rowId: question.attemptId,
        grade: props.grade,
        contestId: props.contestId,
        isOption1Marked: question.isOption1Marked,
        isOption2Marked: question.isOption2Marked,
        isOption3Marked: question.isOption3Marked,
        isOption4Marked: question.isOption4Marked,
        isOption5Marked: question.isOption5Marked,
        answerSelected: isSelected(question)
      });
    });
    setAnsDetails(tempAns);
  }

  const isSelected = (ques) => {
    if (!ques) {
      return false;
    } else {
      return !(isNullCheck(ques.isOption1Marked) && isNullCheck(ques.isOption2Marked) && isNullCheck(ques.isOption3Marked)
        && isNullCheck(ques.isOption4Marked) && isNullCheck(ques.isOption5Marked));
    }
  }

  const onPrevious = () => {
    if (isSelected(ansDetails[currentPosition])) {
      saveAttemptedQuiz(ansDetails[currentPosition], 0, PREVIOUS);
    } else {
      setCurrentPosition(currentPosition - 1);
    }
  }

  const onNext = () => {
    if (isSelected(ansDetails[currentPosition])) {
      saveAttemptedQuiz(ansDetails[currentPosition], 0, NEXT);
    } else {
      setCurrentPosition(currentPosition + 1);
    }
  }

  const onSubmit = () => {
    saveAttemptedQuiz(ansDetails[currentPosition], 1, SUBMIT);
  }

  const timeOut = () => {
    // saveAttemptedQuiz(ansDetails[currentPosition], 1, SUBMIT);
    appContext.setDialogMessage('Contest is over. Please wait for the results to be announced.');
    // history.goBack();
    onSubmit();
  }

  const saveAttemptedQuiz = (attemptedModel, isSubmitted, actionType) => {
    saveContestAttempt(attemptedModel, isSubmitted).subscribe({
      next: (response) => {
        attemptedModel.rowId = response.data.rowId;
        quizDetails[currentPosition].attemptId = response.data.rowId;
        if (actionType === NEXT) {
          setCurrentPosition(currentPosition + 1);
        } else if (actionType === PREVIOUS) {
          setCurrentPosition(currentPosition - 1);
        } else {
          props.onSubmit();
        }
      }, error : (error ) => {
        appContext.setDialogMessage(error.message);
        console.log(error.message);
      }});
  }

  const onAnswerSelection = (ansSelected) => {
    const tempAns = [...ansDetails];
    const currUser = appContext.userData;
    const newData = {
      ...tempAns[currentPosition],
      userId: currUser.rowId,
      isOption1Marked: ansSelected === 'isOption1Marked' ? 1 : 0,
      isOption2Marked: ansSelected === 'isOption2Marked' ? 1 : 0,
      isOption3Marked: ansSelected === 'isOption3Marked' ? 1 : 0,
      isOption4Marked: ansSelected === 'isOption4Marked' ? 1 : 0,
      isOption5Marked: ansSelected === 'isOption5Marked' ? 1 : 0,
      answerSelected: true
    };
    tempAns[currentPosition] = newData;
    setAnsDetails(tempAns);
    setQuizDetails(quizDetails);
  }


  const previousButton = () => {
    if ((currentPosition) !== 0) {
      return (
        <span className="next-prev-button">
          <img src="/assets/images/quiz/previous.png" onClick={onPrevious} alt="" />
        </span>
      );
    }
  }

  const submitNextButton = () => {
    if ((currentPosition + 1) !== ansDetails.length) {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/next.png" onClick={onNext} alt="" />
            :
            <img src="/assets/images/quiz/next_disabled.png" alt="" />
          }
        </span>
      );
    } else {
      return (
        <span className="next-prev-button">
          {ansDetails[currentPosition].answerSelected ?
            <img src="/assets/images/quiz/submit.png" onClick={onSubmit} alt="" />
            :
            <img src="/assets/images/quiz/submit_disabled.png" alt="" />
          }
        </span>
      );
    }
  }

  const getAnswerClass = (answerOption) => {
    // Return the color class for answer: Purple if selected, Green for correct
    let answerClass = 'answer';
    if (ansDetails[currentPosition][answerOption] === 1) {
      answerClass = 'selectedAnswer';
    }
    return answerClass;
  }

  const renderQuiz = () => {
    return (
      <div>
        <div className="quiz-info">
          <div className="quiz-subject-details">
            {/* <div>Class {grade} - {subject} - </div>
            <div>{topic} (Level {level})</div> */}
            {/* <div>{props.quizTitle}</div> */}
            <div className="timer-section">
              <Timer timeOut={timeOut} initialMinute={Math.floor(props.quizDetails.remainingTime / 60)} initialSeconds={props.quizDetails.remainingTime % 60}></Timer>
          </div>
          </div>
          <div className="questionNumber">{currentPosition + 1} / {quizDetails.length}</div>
         
          {props.grade < 3 ?
            <div className="quiz-question-audio">
               <VolumeUpIcon className="icon" onClick={() => { speakTheText(replaceUnderscore(quizDetails[currentPosition].questionText)) }} />
            </div> : null}
          <div className="exit-quiz" onClick={() => { props.setShowExitDialog(true) }}>
            <img src="/assets/images/quiz/exit.png" alt="" />
          </div>
        </div>
        <div className="quizQuestion">{quizDetails[currentPosition].questionText}
        </div>
        <div className="quizImage">
          <Rif boolean={quizDetails[currentPosition].questionImagePath !== null}>
            <img src={quizDetails[currentPosition].questionImagePath} alt="Q" />
          </Rif>

        </div>
        <div className="quizAnswers">
          <Grid container spacing={3}>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1Text) || isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={getAnswerClass('isOption1Marked')} onClick={() => { onAnswerSelection('isOption1Marked') }}> A. &nbsp; {quizDetails[currentPosition].option1Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option1ImagePath)}>
                    <img src={quizDetails[currentPosition].option1ImagePath} alt="O" />
                  </Rif>
                </div>
              </Grid>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2Text) || isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={getAnswerClass('isOption2Marked')} onClick={() => { onAnswerSelection('isOption2Marked') }}> B. &nbsp; {quizDetails[currentPosition].option2Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option2ImagePath)}>
                    <img src={quizDetails[currentPosition].option2ImagePath} alt="O" />
                  </Rif>
                </div>
              </Grid>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3Text) || isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={getAnswerClass('isOption3Marked')} onClick={() => { onAnswerSelection('isOption3Marked') }}> C. &nbsp; {quizDetails[currentPosition].option3Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option3ImagePath)}>
                    <img src={quizDetails[currentPosition].option3ImagePath} alt="O" />
                  </Rif>
                </div>
              </Grid>
            </Rif>
            <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4Text) || isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={getAnswerClass('isOption4Marked')} onClick={() => { onAnswerSelection('isOption4Marked') }}> D. &nbsp; {quizDetails[currentPosition].option4Text}
                  <Rif boolean={isNotEmpty(quizDetails[currentPosition].option4ImagePath)}>
                    <img src={quizDetails[currentPosition].option4ImagePath} alt="O" />
                  </Rif>
                </div>
              </Grid>
            </Rif>
          </Grid>
        </div>
        <div className="quiz-buttons">
          {previousButton()}{submitNextButton()}
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      {quizDetails !== null ? renderQuiz() : <div>Loading...</div>}
    </div>
  );
}

export default CPQuizPlayer;