import { Button, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { exportParticipantsPartner,  getParticipantsPartner, getContestsPartner } from '../../feature/contest/contest-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import Loader from '../../components/loader/loader.component';
import './partner-contest.styles.scss';
import { EMPTY_STRING } from '../../components/constants/constants';

const PartnerContest = (props) => {

  const [contestList, setContestList] = useState([]);
  const [contests, setContests] = useState([]);
  const [contest, setContest] = useState(EMPTY_STRING);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (contests.length === 0) {
      loadContestCodes();
    }
  }, []);

  const loadContestCodes = () => {
    getContestsPartner().subscribe({
      next: (response) => {
        setContests(response.data);
      }});
  }

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    if (contest.contestCode) {
      setShowLoader(true);
      getParticipantsPartner(contest.contestCode, selectedPage - 1).subscribe({
        next: (result) => {
          setContestList(result.data.data);
          setPageCount(result.data.totalPages);
          setTotalCount(result.data.totalElements);
          setShowLoader(false);
        }});
    } else {
      setShowLoader(false);
    }
  }

  const exportToExcel = (event) => {
    setShowLoader(true);
    exportParticipantsPartner(contest.contestCode).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Participant.xlsx');
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const searchData = (event) => {
    event.preventDefault();
    search(1);
  }

  const handleChange = (e) => {
    setContest(e.target.value);
  };

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off" onSubmit={searchData}>
        <div className="controlBox">
          <div className="partner-ref-Control">
            <TextField name="contestCode" value={contest} select label="Contest Code" variant="outlined" size="small"
              fullWidth onChange={handleChange}>
              {contests.map((contest) => (
                <MenuItem key={contest.contestCode} value={contest}>
                  {contest.contestCode} - {contest.institutionName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="partner-ref-Control sendButton">
            <Button type="submit" variant="contained" color="primary">
              <SearchIcon /> Search
            </Button>
          </div>
          <div className="partner-part-control  sendButton">
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              <GetAppOutlinedIcon /> Export To Excel
            </Button>
          </div>
          <div className="partner-ref-Control">
            <span className="institutionLabel">Total Users: </span>
            <span className="institutionValue">&nbsp;{totalCount}</span>
          </div>
        </div>
      </form>
    );
  }

  const getContestGrid = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>User Id</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Package</StyledTableCell>
                <StyledTableCell>Validity</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contestList.map((contest) => (
                <StyledTableRow key={contest.name}>
                  <StyledTableCell>{contest.name}</StyledTableCell>
                  <StyledTableCell>{contest.displayName}</StyledTableCell>
                  <StyledTableCell>{contest.userId}</StyledTableCell>
                  <StyledTableCell>{contest.grade}</StyledTableCell>
                  <StyledTableCell>{contest.status}</StyledTableCell>
                  <StyledTableCell>{contest.packageName}</StyledTableCell>
                  <StyledTableCell>{contest.validity}</StyledTableCell>
                  <StyledTableCell>{moment(contest.date).format('lll')}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="participant-main">
      <div className="participant-title">Contest Participants</div>
      {getFormPanel()}
      {showLoader ? <div className="showLoading"><Loader /></div> : getContestGrid()}
    </div>
  );
}

export default PartnerContest;