import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import LevelCard from '../../../components/quiz/level/level-card/level-card.component';
import { getQuestionLevels } from '../../../feature/quiz/question-service';
import './admin-level.styles.scss';

const AdminLevels = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const [levelList] = useState([{ isLocked: 0, isReviewed: 0, level: 1, questionCount: 0 },
  { isLocked: 0, isReviewed: 0, level: 2, questionCount: 0 },
  { isLocked: 0, isReviewed: 0, level: 3, questionCount: 0 }]);
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    loadQuestionLevels();
  }, []);

  const loadQuestionLevels = () => {
    getQuestionLevels(grade, subject, topic).subscribe({
      next: (response) => {
        var questionLevelList = response.data;
        for (var i = 0; i < questionLevelList.length; ++i) {
          for (var j = 0; j < levelList.length; ++j) {
            if (questionLevelList[i].level === levelList[j].level) {
              levelList[j].isReviewed = questionLevelList[i].isReviewed;
              levelList[j].questionCount = questionLevelList[i].questionCount;
              break;
            }
          }
        }
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const onLevelSelect = (level) => {
    history.push(`/admin/questionBank/${grade}/${subject}/${topic}/${level.level}`);
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="App">
          <div className="levelListHeader">
            <div className="gradeName">Class {grade}</div>
            <div className="spacing"></div>
            <div className="subjectName">{subject} ({topic})</div>
          </div>
          <div className="levelList">
            <Grid container spacing={5}>
              {
                levelList.map(level => (

                  <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                    <div onClick={() => { onLevelSelect(level) }}>
                      <LevelCard level={level} isAdmin={1} />
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>
      }
    </div>
  );
}

export default AdminLevels;