import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

const AdminDashboardChart = (props) => {
  const canvasRef = React.useRef();
  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          min: 10,
          max: 100,
          stepSize: 20,
          callback: function (value) {
            return value;
          },
        }
      }
    }
  };

  return <Bar data={props.data} options={options} ref={canvasRef} />

};

export default AdminDashboardChart;