import { Accordion, AccordionDetails, AccordionSummary, Hidden } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { a11yProps, TabPanel, useStyles } from '../../services/tab-util-service';
import ActiveUserContentReport from './active-users-content/active-users-content-report.component.jsx';
import ActiveUserGradeReport from './active-users-grade/active-users-grade-report.component.jsx';
import ActiveUserReport from './active-users/active-user-report.component.jsx';
import './admin-report-dashboard.styles.scss';
import DailyActiveUserReport from './daily-active-users/daily-active-users.component.jsx';
import NewUserReport from './new-users/new-user-report.component.jsx';

  const AdminReportDashboard = () => {
  
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(false);
  
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const renderView = () => {
    return (<div className={classes.root}>
        <Tabs
          className="tab-header"
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
        <Tab label="Active User" {...a11yProps(0)} />
          <Tab label="New User" {...a11yProps(1)} />
          <Tab label="Active Users Content" {...a11yProps(2)} />
          <Tab label="Active User Grade" {...a11yProps(3)} />
          <Tab label="Daily Active User" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="dashboard-container-box">
          <ActiveUserReport></ActiveUserReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="dashboard-container-box">
          <NewUserReport></NewUserReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="dashboard-container-box">
          <ActiveUserContentReport></ActiveUserContentReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="dashboard-container-box">
          <ActiveUserGradeReport></ActiveUserGradeReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="dashboard-container-box">
          <DailyActiveUserReport></DailyActiveUserReport>
          </div>
        </TabPanel>
      </div>

    );
  }


  const renderMobileView = () => {
    return <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Active User</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <ActiveUserReport></ActiveUserReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">New User</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <NewUserReport></NewUserReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Active Users Content</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <ActiveUserContentReport></ActiveUserContentReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Active User Grade</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <ActiveUserGradeReport></ActiveUserGradeReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Daily Active User</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <DailyActiveUserReport></DailyActiveUserReport>
          </div>
        </AccordionDetails>
      </Accordion>
      
    </>
  }

  return (
    <div>
         <Hidden only={['xs', 'sm']}>
            {renderView()}
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
            {renderMobileView()}
        </Hidden>
    </div>
  );
}

export default AdminReportDashboard;