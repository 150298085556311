import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import moment from 'moment';
import React from 'react';
import { INSTITUTE_NAME_CUDENTS } from '../../../../components/constants/constants';
import './cp-card.styles.scss';

const ContestCard = (props) => {
  return (
    <div className="contest-platform-card" onClick={props.contestCardClicked}>
      {/* {props.contestData.status === 'Not started' ?
        <div className="coming-soon">Coming Soon</div>
        : null} */}
      <div className="contest-platform-image">
      {props.contestData.institutionName === INSTITUTE_NAME_CUDENTS ?  
        <img src={`https://www.cudents.com/prod/images/contests/${props.contestData.contestId}/contest_card.webp`} alt="" />  
        : <img src={props.contestData.imagePath} alt="" />
      }
        </div>
      <div className="contest-date-time">
        <div className="contest-platform-tag"><SchoolOutlinedIcon></SchoolOutlinedIcon> &nbsp;{moment(props.contestData.startDate).format('DD-MM-YY')}</div>
        <div className="contest-platform-tag"><QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon> &nbsp;{moment(props.contestData.startDate).format('hh:mm A')}</div>
      </div>
      {props.contestData.institutionName === INSTITUTE_NAME_CUDENTS ? null : <div className="contest-details">
        <div className="contest-platform-name">{props.contestData.institutionName}</div>
      </div>}
      {props.contestData.count ? <span>Participants: {props.contestData.count}</span>: null}
    </div>
  );

}

export default ContestCard;