import { AccordionDetails, AccordionSummary, Hidden, Accordion } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import { a11yProps, TabPanel, useStyles } from '../../services/tab-util-service';
import './admin-log-dashboard.styles.scss';
import LoggingException from './exceptions/exceptions.component';
import Logging from './logging/logging.component';
import LoggingTrend from './logging-trend/logging-trend.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoggingStats from './logging-stats/logging-stats.component';

  const AdminLogDashboard = () => {
  
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(false);
  
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const renderView = () => {
    return (<div className={classes.root}>
        <Tabs
          className="tab-header"
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Logging" {...a11yProps(0)} />
          <Tab label="Logging Stats" {...a11yProps(1)} />
          <Tab label="Exception" {...a11yProps(2)} />
          <Tab label="Logging Trend" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="dashboard-container-box">
          <Logging></Logging>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="dashboard-container-box">
          <LoggingStats></LoggingStats>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="dashboard-container-box">
          <LoggingException></LoggingException>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="dashboard-container-box">
          <LoggingTrend></LoggingTrend>
          </div>
        </TabPanel>
      </div>

    );
  }


  const renderMobileView = () => {
    return <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Logging</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <Logging></Logging>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Logging Stats</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <LoggingStats></LoggingStats>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Logging Exception</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <LoggingException></LoggingException>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Logging Trend</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
          <LoggingTrend></LoggingTrend>
          </div>
        </AccordionDetails>
      </Accordion>
      
    </>
  }

  return (
    <div>
         <Hidden only={['xs', 'sm']}>
            {renderView()}
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
            {renderMobileView()}
        </Hidden>
    </div>
  );
}

export default AdminLogDashboard;