import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { getAttemptTrend } from '../../../feature/quiz/scorecard/score-service';
import Loader from '../../../components/loader/loader.component';
import './attempt-trend.styles.scss';

const AttemptTrend = (props) => {
  const [reportList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getAttemptTrend(selectedPage - 1).subscribe({
      next: (response) => {
        setReportList(response.data.data);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const getAttemptTrendGrid = () => {
    return (
      <div>
      <div className="total-count">
        <span className="total-label">Total Count : </span>
        <span className="total-value">{totalCount}</span>
      </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Day</StyledTableCell>
                <StyledTableCell>User Count</StyledTableCell>
                <StyledTableCell>Quiz Count</StyledTableCell>
                <StyledTableCell>Game Count</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportList.map((report) => (
                <StyledTableRow key={report.name}>
                  <StyledTableCell>{null !== report.attemptDate && moment(report.attemptDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>{report.day}</StyledTableCell>
                  <StyledTableCell>{report.userCount}</StyledTableCell>
                  <StyledTableCell>{report.quizCount}</StyledTableCell>
                  <StyledTableCell>{report.gameCount}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="attempt-trend">
      {showLoader ? <div className="showLoading"><Loader /></div> : getAttemptTrendGrid()}
    </div>
  );
}

export default AttemptTrend;