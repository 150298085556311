import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { isNotEmpty } from '../../../../../services/util-service';
import Checkbox from '@material-ui/core/Checkbox';
import { Rif } from '../../../../../services/util-service';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './cp-all-answers.styles.scss';

export const CPAllAnswers = ({ quizResult, onExit, buttonLabel }) => {
  const [quizDetails, setQuizDetails] = useState(quizResult);
  const [showWrongAnswers, setShowWrongAnswers] = useState(false);

  const filterWrong = (val) => {
    setShowWrongAnswers(val)
  }

  const getOption = (optionNum, optionText, optionImage, isOptionMarked, isOptionCorrect) => {
    let answerClass = 'all-answers-option-text';
    if (isOptionMarked === 1) {
      answerClass += ' selected-answer';
    }
    if (isOptionCorrect === 1) {
      answerClass += ' correct-answer';
    }
    return (
      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
        {isNotEmpty(optionText) ? <div className={answerClass}>{`${optionNum})`} {optionText}</div>
          :
          isNotEmpty(optionImage) ? <div className={answerClass} >{`${optionNum})`} <img src={optionImage} /> </div> : null
        }
      </Grid>
    )
  }

  return (
    <div className='all-answers-container'>
      <div className="view-scorecard" onClick={onExit}><span>{buttonLabel}</span></div>
      <div className="checkbox-control">
        <FormControlLabel
          control={
            <Checkbox
              checked={showWrongAnswers}
              onChange={() => { filterWrong(!showWrongAnswers) }}
              name="checkedB"
              color="primary"
            />
          }
          label="View only wrong answers"
        />
      </div>
      <div className="all-answers-details">
        {quizDetails.models.map((question, index) => (
          <Rif boolean={!showWrongAnswers || question.isCorrect !== 1}>
            <div className="all-answers-row">
              <div className="all-answers-label">Question {index + 1}</div>
              <div className="all-answers-question">{question.questionText}
                <div><img src={question.questionImagePath} alt="" /></div>
              </div>
              <div className="all-answers-label">Options</div>
              <Grid container xs={12}>
                {getOption('a', question.option1Text, question.option1ImagePath, question.isOption1Marked, question.isOption1Correct)}
                {getOption('b', question.option2Text, question.option2ImagePath, question.isOption2Marked, question.isOption2Correct)}
                {getOption('c', question.option3Text, question.option3ImagePath, question.isOption3Marked, question.isOption3Correct)}
                {getOption('d', question.option4Text, question.option4ImagePath, question.isOption4Marked, question.isOption4Correct)}
                {getOption('e', question.option5Text, question.option5ImagePath, question.isOption5Marked, question.isOption5Correct)}
              </Grid>
            </div>
          </Rif>
        ))}
      </div>
      <div className="view-scorecard" onClick={onExit}><span>{buttonLabel}</span></div>
    </div>
  );
}
