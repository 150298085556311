import { Button, FormControl } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import HistoryIcon from '@material-ui/icons/History';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { approveQuestions } from '../../feature/quiz/question-service';
import { EMPTY_STRING, ROLE_ADMIN, ROLE_USER } from '../../components/constants/constants';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import ResultDialog from '../../components/quiz/result-dialog/result-dialog.component';
import QuestionHistory from './admin-question-history/admin-question-history.component';
const QuestionsList = (props) => {
  const grade = props.params.grade;
  const subject = props.params.subject;
  const topic = props.params.topic;
  const level = props.params.level;

  const appContext = useContext(AppContext);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [userRole, setRole] = useState(ROLE_USER);
  const [showPreview, setShowPreview] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  

  useEffect(() => {
    if(appContext.userData){
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  
  const showQuestionDialog = (question) => {
    setCurrentQuestion(question);
    setShowPreview(true);
  }

  const showQuestionHistory = (question) => {
    setCurrentQuestion(question);
    setShowHistory(true);
  }

  const onApprove = () => {
      setMessage('Do you want to approve this level ?');
      setShowMsgDialog(true);
  }

  const onOkClick = () => {
    approveQuestions(grade, subject, topic, level).subscribe({
        next: (response) => {
            appContext.setSuccessMessage(response.message);
            props.onQuestionBankSelection();
        }});
  }

  return (
    <div>
      <List className="questionListContainer">
        {props.questions.map((question, index) => (
          <ListItem>
            <ListItemText className="questionList"
              primary={question.number + ".   " + question.questionText}
            />
            <ListItemSecondaryAction className="questionListIcon">
              <EditOutlinedIcon className="icon" onClick={() => { props.editQuestion(question) }}/>
              <VisibilityOutlinedIcon className="icon" onClick={() => { showQuestionDialog(question) }}/>
              {userRole === ROLE_ADMIN ?  <HistoryIcon className="icon" onClick={() => { showQuestionHistory(question) }}/> : null}
            </ListItemSecondaryAction>
          </ListItem>
        ))
        }
      </List>
      <FormControl className="buttonPanel">
       {userRole === ROLE_ADMIN ?  <Button variant="contained" color="secondary" onClick={onApprove} disabled={props.questionCount !== 15} > Approve Question Set </Button> : null}
      </FormControl>
      <YesNoDialog open={showMessageDialog} onClose={()=>{setShowMsgDialog(false)}} onOk={()=>{setShowMsgDialog(false); onOkClick();}} message={message}/>
      <ResultDialog open={showPreview} onClose={() => { setShowPreview(false) }} result={currentQuestion} />
      <QuestionHistory open={showHistory} onClose={() => { setShowHistory(false) }} questionId={currentQuestion.rowId}  isGameQuestion={false}/>
    </div>
  );
}

export default QuestionsList;