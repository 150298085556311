import { Button, MenuItem, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { AppContext } from '../../app-context';
import { findAllActivitiesByContestIdAndGrade, findAllActivitiesByContestIdAndGradeRange, saveAdminActivity } from '../../feature/contest/activity-contest-service';
import { getContestsAdmin } from '../../feature/contest/contest-service';
import { isNotEmptyArray, isNotNull } from '../../services/util-service';
import { CONTEST_TYPE_ACTIVITY, EMPTY_STRING, GRADE_LIST, GRADE_RANGE_LIST, GRADE_TYPE_INDIVIDUAL, MANDATORY_FIELD_MSG, RANGE_2, SORT_LIST } from '../../components/constants/constants';
import ImageViewerDialog from '../../components/image-viewer/image-viewer';
import Loader from '../../components/loader/loader.component';
import './admin-activity.styles.scss';

const AdminActivity = (props) => {
  const grade = props.grade;
  const code = props.code;
  const contestId = props.contestId;
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [activityList, setActivityList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchContest, setSearchContest] = useState(null);
  const [sort, setSort] = useState('creationDate,desc');
  const [currGrade, setCurrGrade] = useState(1);
  const [currRange, setCurrRange] = useState(RANGE_2.value);
  const [contests, setContests] = useState([]);
  const [formError, setFormError] = useState({});
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [imagePath, setImagePath] = useState(EMPTY_STRING);
  const ref = useRef();

  useEffect(() => {
    if (contests.length === 0) {
      loadActiveContests();
    }
  }, []);

  const loadActiveContests = () => {
    getContestsAdmin(CONTEST_TYPE_ACTIVITY).subscribe({
      next: (response) => {
        setContests(response.data);
        if(isNotEmptyArray(response.data)){
          setSearchContest(response.data[0]);
        }
      }
    });
  }

  useEffect(() => {
    if (contests.length > 0) {
      loadActivities(page);
    }
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const loadActivities = () => {
    const sortStr = 'EMPTY_STRING' === sort ? EMPTY_STRING : sort;
    if(handleValidation()){
      setShowLoader(true);
      if(GRADE_TYPE_INDIVIDUAL === searchContest.gradeType){
        findAllActivitiesByContestIdAndGrade(searchContest.contestId, currGrade, page - 1, sortStr).subscribe({
          next: (response) => {
            setActivityList(response.data.content);
            setPageCount(response.data.totalPages);
            setTotalCount(response.data.totalElements);
            setShowLoader(false);
          }, error: (error) => {
            setShowLoader(false);
            appContext.setDialogMessage(error.message);
          }
        });
      } else {
        findAllActivitiesByContestIdAndGradeRange(searchContest.contestId, currRange, page - 1, sortStr).subscribe({
          next: (response) => {
            setActivityList(response.data.content);
            setPageCount(response.data.totalPages);
            setTotalCount(response.data.totalElements);
            setShowLoader(false);
          }, error: (error) => {
            setShowLoader(false);
            appContext.setDialogMessage(error.message);
          }
        });
      }
    }
  }

  const saveScore = (activity) => {
    setShowLoader(true);
    saveAdminActivity(activity).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
        appContext.setErrorMessage(error.message);
      }
    });
  }
  
  const handleEdit = (activity) => {
    const newList = activityList.map((item) => {
      if (item.rowId === activity.rowId) {
        const updatedItem = {
          ...item,
          score: activity.score,
        };
        return updatedItem;
      }

      return item;
    });
    setActivityList(newList);
  };

  const handleSearchChange = (e) => {
    setSearchContest(e.target.value);
  };

  const handleGradeChange = (e) => {
     setCurrGrade(e.target.value);
  };

  const handleRangeChange = (e) => {
     setCurrRange(e.target.value);
  };

  const handleSortChange = (e) => {
     setSort(e.target.value);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!searchContest) {
      formIsValid = false;
      errors["searchText"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }
  
  const openImageViewer = (contentPath) => {
    setImagePath(contentPath);
    setShowImageViewer(true);
  }

  const renderView = () => {
    return (
      <div className="activity-list-page">
        <div className="activity-search">
          <div className="formField">
            <TextField name="searchText" value={searchContest || EMPTY_STRING} onChange={handleSearchChange} select label="Search By Contest" variant="outlined" size="small">
              {contests.map((contest) => (
                <MenuItem key={contest.contestId} value={contest}>
                  {contest.institutionName} - {contest.contestCode} ({contest.contestId})
                </MenuItem>
              ))}
            </TextField>
          { isNotNull(searchContest) && searchContest.gradeType === GRADE_TYPE_INDIVIDUAL ? 
          <TextField name="gradeRange" value={currGrade} select label="Class"
              required onChange={handleGradeChange} variant="outlined" size="small">
              {GRADE_LIST.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  {grade.name}
                </MenuItem>
              ))}
            </TextField>
            :
            <TextField name="gradeRange" value={currRange} select label="Range"
              required onChange={handleRangeChange} variant="outlined" size="small">
              {GRADE_RANGE_LIST.map((range) => (
                <MenuItem key={range.value} value={range.value}>
                  {range.name}
                </MenuItem>
              ))}
            </TextField>}
            <TextField name="sort" value={sort} select label="Sort"
              required onChange={handleSortChange} variant="outlined" size="small">
              {SORT_LIST.map((sort) => (
                <MenuItem key={sort.value} value={sort.value}>
                  {sort.name}
                </MenuItem>
              ))}
            </TextField>
            <Button variant="contained" onClick={loadActivities} color="secondary">
              Search
            </Button>
            <span className='search-total'>Total : {totalCount}</span>
            <div className="error-text">{formError['searchText']}</div>
          </div>
        </div>
        <div className="activity-image-list">
          {activityList.map((activity) => (
            <div className='activity-image'>
              <div className="uploaded-image-box"><img className="uploaded-image" src={activity.contentPath} alt=""  onClick={(e) => {openImageViewer(activity.contentPath)}}/></div>
              <div className="participate-name">{activity.participantName}</div>
              <div className="control">
                <TextField className="score" type="number" name="score" value={activity.score} onChange={(e) => { activity.score = parseFloat(e.target.value); handleEdit(activity); }}>
                </TextField>
                  <Button type="submit" variant="contained" color="secondary" onClick={(e) => { saveScore(activity) }}>
                    save
                  </Button>
              </div>
            </div>))}</div>
      <ImageViewerDialog open={showImageViewer} onClose={() => { setShowImageViewer(false) }} imagePath={imagePath}></ImageViewerDialog>
        <Pagination className="pagination" count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }
  return (
    <div ref={ref}>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
    </div>
  );
}

export default AdminActivity;