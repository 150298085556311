import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../app-context';
import { getTopics } from '../../../feature/quiz/category-service';
import Loader from '../../../components/loader/loader.component';
import { TopicCard } from '../../../components/quiz/topic-card/topic-card.component';
import './admin-topic.styles.scss';

export const AdminTopics = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const [topicList, setTopicList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appData = useContext(AppContext);

  useEffect(() => {
    if (topicList.length === 0) {
      loadTopics();
    }
  }, []);

  const loadTopics = () => {
    getTopics(grade, subject, 1).subscribe({
      next: (response) => {
        setTopicList(response.data);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const onTopicSelect = (topic) => {
    if (appData.userData) {
      props.history.push(`/admin/questionBank/${grade}/${subject}/${topic}`);
    } else {
      props.history.push('/signin');
    }
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="admin-topic">
          <div className="quizListHeader">
            <div className="gradeName">Class {grade}</div>
            <div className="spacing"></div>
            <div className="subjectName">{subject}</div>
          </div>
          <div className="quizList">

            <Grid container spacing={5}>
              {
                topicList.map(topic => (
                  <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
                    <div onClick={() => { onTopicSelect(topic.name) }}>
                      <TopicCard topic={topic} isAdmin={1} />
                    </div>
                  </Grid>
                ))
              }
            </Grid>
          </div>
        </div>
      }

    </div>
  );
}

export default AdminTopics;