import React, { useEffect, useState } from 'react';
import { getSalesTrend } from '../../feature/payment/payment-service';
import { getActivityTrend } from '../../feature/quiz/scorecard/score-service';
import { getUserTrend } from '../../feature/user-dashboard/user-service';
import { isNullData } from '../../services/util-service';
import AdminDashboardChart from '../../components/charts/admin-dashboard-charts.component';
import Loader from '../../components/loader/loader.component';
import { TREND_ACTIVITY, TREND_SALES, TREND_USER } from '../../components/constants/constants';
import './admin-trend.styles.scss';

const AdminTrend = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [trendList, setTrendList] = useState([]);

  useEffect(() => {
    if(trendList.length === 0 && props.trend===TREND_USER){
      loadUserTrend();
    }
    if(trendList.length === 0 && props.trend===TREND_SALES){
      loadSalesTrend();
    }
    if(trendList.length === 0 && props.trend===TREND_ACTIVITY){
      loadActivityTrend();
    }
  }, []);

  const loadUserTrend = () => {
    setShowLoader(true);
    getUserTrend(props.chartType).subscribe({
      next: (response) => {
        setTrendList(getTrendData(response.data));
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const loadSalesTrend = () => {
    setShowLoader(true);
    getSalesTrend(props.chartType).subscribe({
      next: (response) => {
        setTrendList(getTrendData(response.data));
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const loadActivityTrend = () => {
    setShowLoader(true);
    getActivityTrend(props.chartType).subscribe({
      next: (response) => {
        setTrendList(getTrendData(response.data));
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const getTrendData = (data) => {
    if (isNullData(data)) {
      return null;
    }
    let tempLabels = [];
    let tempValues = [];
    data.map((subInfo) => {
      tempLabels.push(subInfo.period);
      tempValues.push(subInfo.value);
    });

    return (
      {
        labels: tempLabels,
        datasets: [
          {
            id: 1,
            data: tempValues,
            maxBarThickness: 50,
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(153, 102, 255, 0.5)',
              'rgba(255, 159, 64, 0.5)',
              'rgba(255, 99, 102, 0.5)',
              'rgba(54, 162, 205, 0.5)',
              'rgba(255, 206, 186, 0.5)',
              'rgba(75, 192, 142, 0.5)',
              'rgba(153, 102, 205, 0.5)',
              'rgba(255, 159, 164, 0.5)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(255, 99, 102, 0.5)',
              'rgba(54, 162, 205, 0.5)',
              'rgba(255, 206, 186, 0.5)',
              'rgba(75, 192, 142, 0.5)',
              'rgba(153, 102, 205, 0.5)',
              'rgba(255, 159, 164, 0.5)',
            ],
            borderWidth: 1,
          },
        ],
      }
    );
  }

  return (
    <div className="admin-trend">
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <AdminDashboardChart data={trendList} />
      }
    </div>
  );
}

export default AdminTrend;