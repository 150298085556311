import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import DashboardSidebar from '../../../components/dashboard/dashboard-sidebar/dashboard-sidebar.component';
import { isEmpty, isNotNull } from '../../../services/util-service';
import './dashboard.styles.scss';

const Dashboard = () => {

  const appContext = useContext(AppContext);
  const history = useHistory();
  // const [dashboardDetail, setDashboardDetail] = useState({});
  // const [topicScores, setTopicScores] = useState(null);
  // const [subjectScores, setSubjectScores] = useState(null);
  // const [gradeSubjects, setGradeSubjects] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  // const [giftList, setGiftList] = useState(null);
  // const [showAds, setShowAds] = useState(false);
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const [wish, setWish] = useState('');
  const [wishImage, setWishImage] = useState(null);
  useEffect(() => {
    setWishDetails();
    return () => {
      setWish(null);
      setWishImage(null);
    }
  }, [])

  const setWishDetails = () => {
    const dt = new Date();
    const hour = dt.getHours();
    if (hour < 4) {
      setWish('Time to go to bed');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/night.png');
    } else if (hour < 12) {
      setWish('Good Morning');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/morning.png');
    } else if (hour < 16) {
      setWish('Good Afternoon')
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/afternoon.png');
    } else if (hour < 21) {
      setWish('Good Evening');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/evening.png');
    } else {
      setWish('Time to go to bed');
      setWishImage('https://www.cudents.com/prod/assets/images/userdashboard/night.png');
    }
  }

  const getClass = (item) => {
    if (isEmpty(item)) {
      return item;
    } else {
      return item < 1 ? 'KG ' + (item + 3) : 'Class ' + item;
    }
  }
  useEffect(() => {
    if (appContext.userData) {
      // fetchDashboardDetail();
      // fetchGiftList();
      // fetchSubjectScores();
      // loadSubjects(appContext.userData.grade);
    } else {
      history.push('/signin');
    }

  }, []);

  // useEffect(() => {
  //   if (isNotNull(selectedSubject)) {
  //     fetchTopicScores();
  //   }
  // }, [selectedSubject]);


  // const loadSubjects = (grade) => {
  //   getSubjects(grade, 0).subscribe({
  //     next: (response) => {
  //       setGradeSubjects(response.data);
  //       if (response.data && response.data.length > 0) {
  //         setSelectedSubject(response.data[0].name);
  //       }
  //     }
  //   });
  // }

  // const fetchDashboardDetail = () => {
  //   getDashboardDetails().subscribe({
  //     next: (response) => {
  //       setDashboardDetail(response.data);
  //     }, error: (error) => {
  //       appContext.setDialogMessage(error.message);
  //     }
  //   });
  // }

  // const fetchGiftList = () => {
  //   getRedeemItems(0).subscribe({
  //     next: (response) => {
  //       setGiftList(response.data);
  //     }
  //   });
  // }

  // const fetchTopicScores = () => {
  //   getTopicScores(selectedSubject).subscribe({
  //     next: (response) => {
  //       setTopicScores(response.data);
  //     }, error: (error) => {
  //       appContext.setDialogMessage(error.message);
  //     }
  //   });
  // }

  // const fetchSubjectScores = () => {
  //   getSubjectScores().subscribe({
  //     next: (response) => {
  //       setSubjectScores(response.data)
  //     }, error: (error) => {
  //       appContext.setDialogMessage(error.message);
  //     }
  //   });
  // }

  // const chartSubjectChange = (subject) => {
  //   setSelectedSubject(subject);
  // }

  return <div>

    <div className="dashboard-top-user-section">
      <div className="dashboard-top-user-section-info">
        <div className="dashboard-top-user-section-image">
          <img src={appContext.userData.profilePicPath} alt="" />
        </div>
        <div className="dashboard-top-user-section-wish">{wish}</div>
        <div className="dashboard-top-user-section-username">{appContext.userData.displayName}</div>
        <div className="dashboard-top-user-section-usergrade">{getClass(appContext.userData.grade)}</div>
      </div>
    </div>

    {/* <div className="user-dashboard-top-widgets">
        <div className="user-dashboard-card-container">
          <DashboardWidget label={"Rank"} value={dashboardDetail.rank ? dashboardDetail.rank : 'NA'} image={"https://www.cudents.com/prod/assets/images/userdashboard/rank.png"} color="blue-card" />
        </div>
        <div className="user-dashboard-card-container">
          <DashboardWidget label={"Score"} value={dashboardDetail.score ? dashboardDetail.score : 'NA'} image={"https://www.cudents.com/prod/assets/images/userdashboard/score.png"} color="yellow-card" />
        </div>
        <div className="user-dashboard-card-container">
          <DashboardWidget label={dashboardDetail.quizLeft < 2 ? "Day valid" : "Days valid"} value={dashboardDetail.quizLeft} image={"https://www.cudents.com/prod/assets/images/userdashboard/quiz.png"} color="green-card" />
        </div> 
        <div className="user-dashboard-card-container">
          <DashboardWidget label={"Points"} value={dashboardDetail.points} image={"https://www.cudents.com/prod/assets/images/userdashboard/rewardpoints.png"} color="red-card" />
        </div>
      </div> */}
    {/* <div className="user-dashboard-menu-list"> */}
      <DashboardSidebar></DashboardSidebar>
    {/* </div> */}
    {/* <div className="user-dashboard-charts-area"> */}
    {/* <div className="user-dashbaord-card">
          <div className="card-title">
            Milestones
            <div className="card-info">
              <InfoDialog title={'Milestones'}>
                <div>Complete different milestones in the journeys to reach the treasure and win bonus points.</div>
                <br />
                <div><b>How to reach a milestone?</b></div>
                <div>You need to complete 4 quizzes to reach the next milestone.</div>
                <br />
                <div><b>What happens once you reach the treasure?</b></div>
                <div>You win 100 points and the next zone is unlocked.</div>
              </InfoDialog>
            </div>
          </div>
          <ZoneCard quizNextMilestone={dashboardDetail.quizNextMilestone} zone={dashboardDetail.zone} milestone={dashboardDetail.milestone} />
        </div> */}
    {/* <Grid justifyContent="center" container spacing={3} xs={12}> */}

    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="user-dashbaord-card">
              <div className="card-title">
                Redeem
                <div className="card-info">
                  <InfoDialog title={'Redeem'}>
                    <div>Exchange the points you have collected in return for gifts of your choice. Isn't that GREAT!!!</div>
                    <br />
                    <div><b>Will I get an actual or virtual gift?</b></div>
                    <div>You will get the real gift delivered at your doorstep. So study well to maximise your points to get a gift of your choice.</div>
                    <br />
                    <div><b>How do I redeem the points?</b></div>
                    <div>Once you have enough points to get the gift of your choice, go to the redeem page and select that gift. Fill in the basic details and place the order.</div>
                  </InfoDialog>
                </div>
              </div>
              <div className="redeem-section">
                {giftList !== null ?
                  giftList.map((giftItem, index) => {
                    if (giftItem.priority === 1) {
                      return (
                        <div className="redeem-item" onClick={() => { history.push('/userdashboard/redeem'); }}>
                          <img src={giftItem.imagePath} alt="" />
                          <div>{giftItem.name}</div>
                          <div className="redeem-item-value">{giftItem.value} points</div>
                        </div>
                      )
                    }
                  })
                  :
                  <div>Loading...</div>
                }
                <div className="redeem-view-all" onClick={() => { history.push('/userdashboard/redeem'); }}>View All</div>
              </div>
            </div>
          </Grid> */}


    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="user-dashbaord-card">
              <div className="card-title">
                Badges
                <div className="card-info">
                  <InfoDialog title={'Badges'}>
                    <div>You can win badges for a grade by clearing quiz levels and earning stickers for that grade.</div>
                    <br />
                    <div><b>How do I earn these stickers?</b></div>
                    <div>Everytime you clear a quiz level, you earn a sticker (Cycle for level 1, Scooter for level 2 and Car for level 3)</div>
                    <br />
                    <div><b>How many Cycle, Scooter and Car stickers do I need to win a badge?</b></div>
                    <div><span style={{ color: '#c144c1', fontWeight: 'bold', letterSpacing: '1px' }}>Novice: </span> 1 of each</div>
                    <div><span style={{ color: '#c144c1', fontWeight: 'bold', letterSpacing: '1px' }}>GrandMaster: </span> 5 of each</div>
                    <div><span style={{ color: '#c144c1', fontWeight: 'bold', letterSpacing: '1px' }}>Ninja: </span>10 of each</div>
                    <div><span style={{ color: '#c144c1', fontWeight: 'bold', letterSpacing: '1px' }}>Warrior: </span> 15 of each</div>

                    <br />
                    <div><b>Do I get any points for winning a badge?</b></div>
                    <div>Yes you do.</div>
                    <div>Novice - 50, GrandMaster - 100, Ninja - 150, Warrior - 200</div>
                    <br />
                    <div><b>Are these badges virtual only?</b></div>
                    <div>No. Once you reach the Warrior badge for a given grade, you will get a physical badge/magnet delivered at your doorstep along with an appreciation certificate.</div>
                  </InfoDialog>
                </div>
              </div>

              {dashboardDetail.badges ? <Badges dashboardDetail={dashboardDetail.badges} grade={dashboardDetail.grade} /> : null}
            </div>
          </Grid> */}

    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="user-dashbaord-card">
              <div className="card-title">
                Text to Speech
                <div className="card-info">
                  <InfoDialog title={'Pronunciation'}>
                    <div>Type a Word/Sentence in the given field and click the button, it will pronounce  the Word/Sentence.</div>
                  </InfoDialog>
                </div>
              </div>
              <PronunciationCard />
            </div>
          </Grid> */}
    {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="user-dashbaord-card">
              <div className="card-title">Subject Score</div>
              <div className="chart-section">
                {isNotEmptyArray(subjectScores) ?
                  <div className="subject-list">
                    <div className="subject-button active-button">Class-{subjectScores[0].grade}</div>
                  </div>
                  :
                  null
                }
                <DashboardChart data={getSubjectWiseData(subjectScores)} id={'subject-wise-chart'} />
              </div>
            </div>
          </Grid> */}
    {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="user-dashbaord-card">
              <div className="card-title">Topic Score</div>
              <div className="chart-section">
                {gradeSubjects !== null ?
                  <div className="subject-list">
                    {gradeSubjects.map((sub) => (<div className={sub.name === selectedSubject ? "subject-button active-button" : "subject-button"} onClick={() => { chartSubjectChange(sub.name) }}>{sub.name}</div>))}
                  </div>
                  :
                  null
                }
                <DashboardChart data={getTopicWiseData(topicScores, selectedSubject)} id={'topic-wise-chart'} />
              </div>
            </div>
          </Grid> */}

    {/* </Grid>
      </div> */}
    {/* <div className="user-dashboard-charts-area">
        {appContext.userData.grade ? <Grid justifyContent="center" container spacing={3} xs={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="user-dashbaord-card">
              <div className="card-title">Quizzes</div>
              <div className="quizzes-section">
                <SubjectList grade={appContext.userData.grade} isDashboard={1} />
              </div>
            </div>
          </Grid>
        </Grid> : null}
      </div> */}

    {/* {showAds ? <><AdComponent></AdComponent></> : null} */}
  </div>
}

export default Dashboard;