import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG, OTHER, STATUS_TYPES, SUBSCRIPTION_TYPES } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import { findAllGuessMasterTopicAdmin, findGuessMasterSubjectsAdmin, saveGuessMaster } from '../../feature/guess-master/guess-master-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import ImageTextfield from '../../components/text-field/image-textfield.component';
import './admin-guess-master.styles.scss';

const AdminGuessMaster = () => {

  const appContext = useContext(AppContext);
  const initData = {
    subject: EMPTY_STRING, subjectImagePath: EMPTY_STRING, topic: EMPTY_STRING, topicImagePath: EMPTY_STRING
  };
  const [guessMasterList, setGuessMasterList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState(EMPTY_STRING);

  useEffect(() => {
    loadSubjects();
  }, [formData.name, formData.grade]);

  const loadSubjects = () => {
    setShowLoader(true);
    findGuessMasterSubjectsAdmin().subscribe({
      next: (response) => {
        setSubjectList([...response.data, { subject: OTHER }]);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    findAllGuessMasterTopicAdmin(selectedPage - 1).subscribe({
      next: (response) => {
        setGuessMasterList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveGuessMaster(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          setSubject(EMPTY_STRING);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const editGuessMaster = (guessMaster) => {
    const subject = getSubjectFromList(guessMaster.subject);
    setSubject(subject);
    updateFormData(guessMaster);
  }

  const getSubjectFromList = (subject) => {
    return subjectList.find(element => element.subject === subject);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    const imageFieldNamePath = imageFieldName + 'Path';
    updateFormData({
      ...formData,
      [imageFieldName]: null,
      [imageFieldNamePath]: null
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["topic"]) {
      formIsValid = false;
      errors["topic"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    if (e.target.value.subject !== OTHER) {
      updateFormData({
        ...formData,
        subjectImagePath: e.target.value.subjectImagePath,
        subject: e.target.value.subject
      });
    }
  };

  const renderContact = () => {
    return (
      <div className="guessMasterFormContainer">
        <div className="guessMasterTitle">Guess Master</div>
        <div className="guessMasterForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="subject" value={subject || EMPTY_STRING} select label="Subject" fullWidth
                required onChange={handleSubjectChange} variant="outlined" size="small">
                {subjectList.map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject.subject}
                  </MenuItem>
                ))}
              </TextField>
              <img src={subject.subjectImagePath}></img>
            </div>
            {subject.subject === OTHER ? 
            <div className="formField">
              <ImageTextfield id="other-subject" label="Other Subject" value={formData.subject}
                image={formData.subjectImagePath} textFieldName="subject" imageFieldName="subjectImage"
                handleTextChange={handleChange} handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }}
                onDeleteImage={handleDeleteImage} imagePath="subjectImagePath"></ImageTextfield>
            </div>: null}
            <div className="formField"><ImageTextfield id="topic" label="Topic" value={formData.topic} image={formData.topicImagePath}
              textFieldName="topic" imageFieldName="topicImage" handleTextChange={handleChange}
              handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
              imagePath="topicImagePath"></ImageTextfield> </div>
            <div className="formField">
              <TextField name="isFree" value={formData.isFree || 0} select label="Subscription Type" onChange={handleChange} fullWidth variant="outlined" size="small">
                {SUBSCRIPTION_TYPES.map((subscription) => (
                  <MenuItem key={subscription.value} value={subscription.value}>
                    {subscription.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="isActive" value={formData.isActive || 0} select label="Status" onChange={handleChange} fullWidth variant="outlined" size="small">
                {STATUS_TYPES.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}

            </div>
          </form>
        </div>
      </div>
    );
  }

  const getGuessMasterGrid = () => {
    return (
      <div className="guessMasterFormContainer">
        <span>Total =  {totalCount}</span>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Subject</StyledTableCell>
                <StyledTableCell>Topic</StyledTableCell>
                <StyledTableCell>Subccription Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guessMasterList.map((guessMaster) => (
                <StyledTableRow key={guessMaster.subject}>
                  <StyledTableCell>{guessMaster.subject}</StyledTableCell>
                  <StyledTableCell>{guessMaster.topic}</StyledTableCell>
                  <StyledTableCell>{guessMaster.isFree === 1 ? 'FREE' : 'PREMIUM'}</StyledTableCell>
                  <StyledTableCell>{guessMaster.isActive === 1 ? 'ACTIVE' : 'INACTIVE'}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editGuessMaster(guessMaster) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="guessMasterContainer">
      {showLoader ? <div className="showLoading"><Loader /></div>
        :
        <>
          {renderContact()}
          {getGuessMasterGrid()}
        </>
      }
    </div>
  );
}


export default AdminGuessMaster;


