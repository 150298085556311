import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import React, { useContext, useState } from "react";
import { useHistory } from 'react-router';
import { EMPTY_STRING } from "../../../components/constants/constants";
import { doSignOut } from "../../../components/firebase/firebase.service";
import YesNoDialog from "../../../components/message-dialog/yes-no-dialog.component";
import './delete-user.styles.scss';
import { AppContext } from "../../../app-context";
import Lottie from "lottie-react";
import notifications from "./animations/notifications.json";
const DeleteUserComponent = (props) => {

  const appContext = useContext(AppContext);
  const history = useHistory();
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  const deleteAccount = () => {
    setShowMsgDialog(true);
    setMessage('Your data can not be recovered. Do you want to continue?');
  }

  const onOkClick = () => {
    handleDeleteAccount();
  }

  const handleDeleteAccount = () => {
    // deleteAccount().subscribe({
    //   next: (response) => {
    //     handleLogout();
    //   }, error: (error) => {
    //   }
    // });
    handleLogout();
  }

  const handleLogout = () => {
    doSignOut();
    appContext.setUserData(null);
    localStorage.clear();
    navigateToHome();
  }

  const navigateToHome = () => {
    history.push('/');
  }

  const renderView = () => {
    return (
      <div className="wrapper">
        
        <div className="notifications-animation">
          <Lottie className="notifications-page-animation" animationData={notifications} loop={true} />
        </div>
        <div className="delete-user">
          <div className="delete-user-form">
            <div className="delete-user-title">
              Delete Account
            </div>
            <div className="delete-user-success">
              You won't be able to login again once you delete your account and all your data will be erased. Do you want to delete?
              <div className="delete-user-buttons">
                <Button variant="contained" color="secondary" className="cancel-button" href="/userdashboard/userprofile">Cancel</Button>
                <Button variant="contained" color="primary" className="confirm-button" onClick={deleteAccount}>Confirm</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="delete-user-page">
      <Grid justifyContent="center" container spacing={3} xs={12}>
        <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
          <div className="delete-user-container">
            {renderView()}
          </div>
        </Grid>
      </Grid>
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
  );
}

export default DeleteUserComponent;