import React, { useEffect } from 'react';
import './no-match-page.styles.scss';
const NoMatchPage = () => {
  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.ink.cudents';
  }, []);
  return null;
  // <div className="noMatchPage">
  //   <img src="/assets/images/site/404.png" alt="" />
  // </div>
}

export default NoMatchPage;