import { Button } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../app-context";
import Loader from "../../../components/loader/loader.component";
import { getInvoice } from "../payment-service";
import { numberFormat, Rif } from "../../../services/util-service";
import './invoice.styles.scss';
import * as htmlToImage from 'html-to-image';
import { Filesystem, Directory } from '@capacitor/filesystem';
const PricingComponent = (props) => {

  const appContext = useContext(AppContext);
  const orderId = props.match.params.orderId;
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderBtn, setShowLoaderBtn] = useState(false);

  useEffect(() => {
    if (invoiceDetail === null) {
      fetchInvoiceDetail();
    }
  }, []);

  const fetchInvoiceDetail = () => {
    getInvoice(orderId).subscribe({
      next: (response) => {
        setInvoiceDetail(response.data);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const downloadInvoice = () => {
    setShowLoaderBtn(true);
    htmlToImage.toJpeg(document.getElementById('section-to-print'))
      .then(function (dataUrl) {
        Filesystem.writeFile({
          path: 'Invoice.jpeg',
          data: dataUrl,
          directory: Directory.Documents
        }).then((res) =>{
          console.log(res);
          appContext.setDialogMessage('File saved in your Document folder');
          setShowLoaderBtn(false);
        }, (err)=>{
          console.log(err);
          appContext.setDialogMessage('Error in download');
          setShowLoaderBtn(false);
        });
      });
  }

  const renderView = () => {
    return (
      <div className="wrapper" id="section-to-print">
        <div className="payments">
          <div className="payments-form" >
            <div>
              <div className="payments-title">
                ORDER DETAILS
              </div>
              {/* <div className="formLabel"><span className="paymentLabel">GSTIN : </span><span className="paymentValue">21AAECI7128Q1ZA</span></div> */}
              <div className="formLabel"><span className="paymentLabel">Order Id : </span><span className="paymentValue">{invoiceDetail.cudentsOrderId}</span></div>
              <div className="formLabel"><span className="paymentLabel">Package Name : </span><span className="paymentValue">{invoiceDetail.packageName}</span></div>
              <div className="formLabel"><span className="paymentLabel">Package Price : </span><span className="paymentValue">{numberFormat(invoiceDetail.packagePrice)}</span></div>
              <div className="line-break">&nbsp;</div>
              <div className="formLabel"><span className="paymentLabel">Taxable Amount : </span><span className="paymentValue">{numberFormat(invoiceDetail.taxableAmount)}</span></div>
              <Rif boolean={invoiceDetail.couponDiscountPercentage > 0}>
                <div className="formLabel"><span className="paymentLabel">Discount ({invoiceDetail.couponDiscountPercentage}%) : </span><span className="paymentValue">{invoiceDetail.discount}</span></div>
              </Rif>
              <div className="formLabel"><span className="paymentLabel">IGST (18%) : </span><span className="paymentValue">{numberFormat(invoiceDetail.igstValue)}</span></div>
              <div className="line-break">&nbsp;</div>
              <div className="formLabel"><span className="paymentLabel">Final Price : </span><span className="paymentValue">{numberFormat(invoiceDetail.invoiceValue)}</span></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Hidden only={['xs', 'sm']}>
        <div className='invoice'>
          {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
          {showLoaderBtn ? <div className="showLoading"><Loader /></div> : <div id="no-print" className="invoiceButtonContainer">
            <Button variant="contained" color="secondary" className="purchaseButton" onClick={() => {downloadInvoice()}}>Download Invoice</Button>
          </div>}
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        <div className='invoice invoicemobile'>
          {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
          {showLoaderBtn ? <div className="showLoading"><Loader /></div> :<div id="no-print" className="invoiceButtonContainer">
            <Button variant="contained" color="secondary" className="purchaseButton" onClick={() => {downloadInvoice()}}>Download Invoice</Button>
          </div>}
        </div>
      </Hidden>

    </div>


  );
}

export default PricingComponent;