import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../app-context';
import ContestCard from '../cp-card/cp-card.component';
import './cp-main-page.styles.scss';
import { getActiveContests, getCompletedContests } from '../../contest-service';

const ContestPlatformPage = (props) => {
  const masterContestId = props.match.params.masterContestId;
  const [activeContestList, setActiveContestList] = useState([]);
  const [completedContestList, setCompletedContestList] = useState([]);
  const history = useHistory();
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (activeContestList.length === 0) {
      loadActiveContests();
    }
    if (completedContestList.length === 0) {
      loadCompletedContests();
    }
  }, []);

  const loadActiveContests = () => {
    getActiveContests(masterContestId).subscribe({
      next: (response) => {
        setActiveContestList(response.data);
      }});
  }

  const loadCompletedContests = () => {
    getCompletedContests(masterContestId).subscribe({
      next: (response) => {
        setCompletedContestList(response.data);
      }});
  }

  const goToContest = (selectedContestData) => {
    if (appContext.userData) {
      history.push(`/contestpage/${selectedContestData.contestId}`);
    } else {
      appContext.setDialogMessage('Please login to view the contest.');
      history.push('/signin');
    }
  }

  return (
    <>
      <div className="contest-page-container">
        {/* <div className="button-bar">
          <div className="cp-how-to-register">
            <VideoPlayerComponent videoTitle="HOW TO REGISTER FOR A CONTEST?" videoLink="https://www.youtube.com/embed/3Tg9NGbQuMw">
            </VideoPlayerComponent>
          </div>
        </div> */}
        <div className="contest-page-section">
          <div className="contest-page-section-title">Active</div>
          <div className="contest-page-listing">
            {activeContestList ?
              <>
                {activeContestList.map((contestData) => (
                  <ContestCard contestData={contestData} contestCardClicked={() => { goToContest(contestData) }}></ContestCard>
                ))}
              </> : null
            }
          </div>
        </div>
        <div className="contest-page-section">
          <div className="contest-page-section-title">Completed</div>
          <div className="contest-page-listing">
            {completedContestList ?
              <>
                {completedContestList.map((contestData) => (
                  <ContestCard contestData={contestData} contestCardClicked={() => { goToContest(contestData) }}></ContestCard>
                ))}
              </> : null
            }
          </div>
        </div>
      </div>
    </>
  );

}

export default ContestPlatformPage;