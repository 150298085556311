
import Dialog from '@material-ui/core/Dialog';
import Lottie from "lottie-react";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { CLASS_TYPE_PRESCHOOL, CLASS_TYPE_PRIMARY } from '../../../components/games/game-constants';
import Loader from '../../../components/loader/loader.component';
import angel from "./animations/angel.json";
import faq from "./animations/faq.json";
import timeanimation from "./animations/timeanimation3.json";
import trophy1 from "./animations/trophy1.json";
import './app-home-page.styles.scss';
import { findHistoryByToday } from '../../history/history-service';
import { findDailyRiddle } from '../../daily-riddle/riddle-service';
import { isNotEmpty } from '../../../services/util-service';
import { ACCESS_TOKEN } from '../../../components/constants/constants';

function AppHomePage() {

  const history = useHistory();
  const [historyData, setHistoryData] = useState([{ description: "Hold On. We are loading this information." }]);
  const [riddleData, setRiddleData] = useState({ todayRiddle: { question: "Hold On. We are loading the riddle." } });
  const [showAnimation, setShowAnimation] = useState(0);
  // const [backgroundMusic, setBackgroundMusic] = useState(new Audio('/assets/music/forest.mp3'));
  const [musicFlag, setMusicFlag] = useState(false);
  const [showGameDialog, setShowGameDialog] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  // useEffect(() => {
  //   if (musicFlag) {
  //     backgroundMusic.volume = .1;
  //     backgroundMusic.play();
  //   }
  //   return () => {
  //     backgroundMusic.pause();
  //   }
  // }, [])

  useEffect(() => {
    if (isNotEmpty(localStorage.getItem(ACCESS_TOKEN))) {
      loadTodayHistory();
      loadTodayRiddle();
    }
  }, []);



  // const musicToggle = () => {
  //   if (musicFlag) {
  //     backgroundMusic.pause();
  //     setMusicFlag(false);
  //   } else {
  //     backgroundMusic.volume = .1;
  //     backgroundMusic.play();
  //     setMusicFlag(true);
  //   }
  // }

  const loadTodayHistory = () => {
    findHistoryByToday().subscribe({
      next: (response) => {
        setHistoryData([...response.data]);
      }
    });
  }

  const loadTodayRiddle = () => {
    findDailyRiddle().subscribe({
      next: (response) => {
        setRiddleData(response.data);
      }
    });
  }

  const navigateTo = (whereTo) => {
    switch (whereTo) {
      case 'rhymes':
        history.push('/rhymes');
        break;
      case 'news':
        history.push('/news');
        break;
      case 'toys':
        history.push('/toys');
        break;
      case 'stories':
        history.push('/stories');
        break;
      case 'quizzes':
        history.push('/quizzes');
        break;
      case 'group':
        history.push('/group');
        break;
      case 'games':
        history.push('/gamesselection');
        break;
      case 'learning':
        history.push('/learning');
        break;
      case 'dailygk':
        history.push('/dailygk');
        break;
      case 'pregames':
        history.push(`/gameslisting/${CLASS_TYPE_PRESCHOOL}`);
        break;
      case 'primarygames':
        history.push(`/gameslisting/${CLASS_TYPE_PRIMARY}`);
        break;
      case 'mystory':
        history.push('/mystorylisting');
        break;
      case 'riddle':
        history.push('/dailyriddle');
        break;
      case 'history':
        history.push('/dailyhistory');
        break;
      case 'contestlist':
        history.push('/contestlist');
        break;
      case 'guess':
        history.push('/guess');
        break;
      default:
        break;
    }
  }

  const renderTopSection = () => {
    const corousals = [];
    if (historyData && historyData.length > 0) {
      corousals.push(getHistory());
    }
    if (riddleData && riddleData.todayRiddle) {
      corousals.push(getRiddle());
    }
    return corousals;
  }

  return (
    <div className="app-home-page">
      {/* <div className="home-page-background">
        <img src="/assets/images/homepage/newhome/home_old.png" alt="" />
      </div> */}

      {/* {musicFlag ?
        <div className="music-icon" onClick={musicToggle}>
          <img src="/assets/images/homepage/newhome/music_on.png" alt="" />
        </div> :
        <div className="music-icon" onClick={musicToggle}>
          <img src="/assets/images/homepage/newhome/music_off.png" alt="" />
        </div>
      } */}

      <div className="home-page-top">
        <img src="/assets/images/homepage/newhome/newdesign/curve1.png" alt="" />
        {/* <div className="home-top-animation">
          <img src="/assets/images/homepage/newhome/hanging_monkey.png" alt="" />
        </div> */}
      </div>

      {/* {historyData && historyData.length > 0 ? <div className="home-top-section" onClick={() => { navigateTo('history') }}>
        <div className="home-top-section-image">
          <img src="/assets/images/homepage/newhome/newdesign/giraffe.png" alt="" />
        </div>
        <div className="home-top-section-info">
          <div className="home-top-section-info-title">
            This Day That Year
          </div>
          <div className="home-top-section-info-text">
            {historyData[0].description}
          </div>
        </div>
      </div> : null} */}

      {/* <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        keyBoardControl={true}
        transitionDuration={500}
        autoPlay={true}
        autoPlaySpeed={15000}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        slidesToSlide={1}
      >
        {renderTopSection().map((item) => (item))}
      </Carousel> */}
      {historyData && historyData.length > 0 ?
        <div className="home-top-section" onClick={() => { if (historyData.length > 1) { navigateTo('history'); } }}>
          <div className="home-top-animation"> <Lottie className="home-top-section-animation" animationData={timeanimation} loop={true} /></div>
          <div className="home-top-section-image">
            <img src={historyData[0] ? historyData[0].url : "assets\images\homepage\newhome\newdesign\tdty\Sunday.png"} alt="" />
          </div>
          <div className="home-top-section-info">
            <div className="home-top-section-info-title">
              This Day That Year
            </div>
            <div className="home-top-section-year">{moment(historyData[0].historyDate).format('Do MMMM yyyy')}</div>
            <div className="home-top-section-info-text">
              {historyData[0].description}
            </div>
          </div>
        </div> : <div className="home-top-section"><Loader /></div>}

      <div className="home-sections-list">
        {/* <div className="home-section-card" onClick={() => { setShowGameDialog(true) }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/games_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Games
          </div>
        </div> */}
        <div className="home-section-card-new-design card-blue" onClick={() => { navigateTo('games') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/games.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Games
          </div>
        </div>

        <div className="home-section-card-new-design card-yellow" onClick={() => { navigateTo('quizzes') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/quizzes.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Quizzes
          </div>
        </div>

        <div className="home-section-card-new-design  card-teal card-full-width" onClick={() => { navigateTo('group') }}>
          <div className="home-section-card-image-new-design group-image">
            <img src="/assets/images/homepage/newhome/newdesign/group.png" alt="" />
            <div className="angel-animation"> <Lottie animationData={angel} loop={true} /></div>
          </div>
          <div className="home-section-card-title-new-design">
            Group Play
          </div>
        </div>

        <div className="home-section-card-new-design card-purple" onClick={() => { navigateTo('guess') }}>
        
          <div className="home-section-card-image-new-design">

            <img src="/assets/images/homepage/newhome/newdesign/guess.png" alt="" />
          </div>  
          <div className="home-section-card-title-new-design">
            {/* <div className="question-animation"> <Lottie animationData={faq} loop={true} /></div> */}
            Guess Master
          </div>
        </div>

        <div className="home-section-card-new-design card-blue" onClick={() => { navigateTo('dailygk') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/dailygk.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Daily GK
          </div>
        </div>

        <div className="home-section-card-new-design card-pink card-full-width" onClick={() => { navigateTo('contestlist') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/contests.png" alt="" />
            <div className="trophy-animation"> <Lottie animationData={trophy1} loop={true} /></div>
          </div>
          <div className="home-section-card-title-new-design">
            Contests
          </div>
        </div>

        <div className="home-section-card-new-design card-pastel-purple" onClick={() => { navigateTo('learning') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/learn.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Learn
          </div>
        </div>

        <div className="home-section-card-new-design card-red" onClick={() => { navigateTo('riddle') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/riddles.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Riddles
          </div>
        </div>

        {/* <div className="home-section-card-new-design card-purple " onClick={() => { navigateTo('mystory') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/mystory.png" alt="" /> */}
        {/* <div className="star-animation"> <Lottie animationData={stars} loop={true} /></div> */}
        {/* </div>
          <div className="home-section-card-title-new-design">
            My Story
          </div>
        </div> */}


        <div className="home-section-card-new-design card-brown" onClick={() => { navigateTo('rhymes') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/rhymes.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Rhymes
          </div>
        </div>

        <div className="home-section-card-new-design card-green" onClick={() => { navigateTo('stories') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/stories.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Stories
          </div>
        </div>

        {/* <div className="home-section-card-new-design card-grey" onClick={() => { navigateTo('toys') }}>
          <div className="home-section-card-image-new-design">
            <img src="/assets/images/homepage/newhome/newdesign/toys.png" alt="" />
          </div>
          <div className="home-section-card-title-new-design">
            Toys
          </div>
        </div> */}

        {/* <div className="home-section-card-new-design card-teal card-full-width" onClick={() => { navigateTo('news') }}>
          <div className="home-section-card-title-new-design">
            <div className="camera-animation"> <Lottie animationData={camera} loop={true} /></div>
            Current Affairs
          </div>
          <div className="home-section-card-image-new-design">

            <img src="/assets/images/homepage/newhome/newdesign/news.png" alt="" />
          </div>
        </div> */}

        {/* 
        <div className="home-section-card" onClick={() => { navigateTo('quizzes') }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/quiz_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Quizzes
          </div>
        </div>
        <div className="home-section-card" onClick={() => { navigateTo('riddles') }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/riddles_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Riddles
          </div>
        </div>
        <div className="home-section-card" onClick={() => { navigateTo('learning') }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/learning_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Learning
          </div>
        </div>
        <div className="home-section-card" onClick={() => { navigateTo('stories') }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/stories_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Stories
          </div>
        </div>
        <div className="home-section-card test" onClick={() => { navigateTo('rhymes') }}>
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/rhymes_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Rhymes
          </div>
        </div>
        <div className="home-section-card">
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/toys_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            Toys
          </div>
        </div>
        <div className="home-section-card">
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/news_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            News
          </div>
        </div>
        <div className="home-section-card">
          <div className="home-section-card-image">
            <img src="/assets/images/homepage/newhome/mystory_section.png" alt="" />
          </div>
          <div className="home-section-card-title">
            My Story
          </div>
        </div> */}
      </div>

      <Dialog open={showGameDialog} onClose={() => { setShowGameDialog(false) }}>
        <div className="game-class-selection-box">
          <div className="game-class-selection-button" onClick={() => { navigateTo('pregames') }}>
            <div><img src="/assets/images/homepage/newhome/preschool.png" alt="" /></div>
            <div>Pre-School</div>
          </div>
          <div className="game-class-selection-button" onClick={() => { navigateTo('primarygames') }}>
            <div>Primary</div>
            <div><img src="/assets/images/homepage/newhome/primary.png" alt="" /></div>
          </div>
        </div>
      </Dialog>
    </div>
  );

  function getRiddle() {
    return <div className="home-top-section" onClick={() => { navigateTo('riddle'); }}>
      <div className="home-top-section-info">
        <div className="home-top-section-info-title">
          Daily Riddle
        </div>
        <div className="home-top-section-info-text">
          {riddleData.todayRiddle.question}
        </div>
      </div>
      <div className="home-top-section-image">
        <img src={`/assets/images/homepage/newhome/newdesign/riddle/${moment(Date()).format('dddd')}.png`} alt="" />
      </div>
    </div>;
  }

  function getHistory() {
    return <div className="home-top-section" onClick={() => { navigateTo('history'); }}>
      <div className="home-top-section-image">
        <img src={`/assets/images/homepage/newhome/newdesign/tdty/${moment(Date()).format('dddd')}.png`} alt="" />
      </div>
      <div className="home-top-section-info">
        <div className="home-top-section-info-title">
          This Day That Year
        </div>
        <div className="home-top-section-info-text">
          {historyData[0].description}
        </div>
      </div>
    </div>;
  }
}

export default AppHomePage;