import { Button, MenuItem, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from "@material-ui/icons/Search";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { findAllUser, resetDefaultPassword, userSaveFromAdmin } from '../../feature/user-dashboard/user-service';
import { getWhatsApp } from '../../services/util-service';
import { EMPTY_STRING, USER_ROLE } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import UserDetails from './user-details.component';
import './user-list.styles.scss';

const UserList = (props) => {
  const appContext = useContext(AppContext);
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchText, setSearchText] = useState(EMPTY_STRING);
  const [currentUser, setCurrentUser] = useState(EMPTY_STRING);
  const [showUser, setShowUser] = useState(false);
  const [wpMessage, setWPMessage] = useState(EMPTY_STRING);
  const classes = useStyles();
  const [message, setMessage] = useState(EMPTY_STRING);
  const [currUser, setCurrUser] = useState(null);
  const [showMsgDialog, setShowMsgDialog] = useState(false);

  useEffect(() => {
    searchData(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const searchUser = () => {
    if (page === 1) {
      searchData(1);
    } else {
      setPageCount(1);
      setPage(1);
    }
  }

  const searchData = (selectedPage) => {
    setShowLoader(true);
    findAllUser(searchText, selectedPage - 1).subscribe({
      next: (response) => {
        setUserList(response.data.content);
        setTotalUsers(response.data.totalElements);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleEdit = (user) => {

    const newList = userList.map((item) => {
      if (item.rowId === user.rowId) {
        const updatedItem = {
          ...item,
          role: user.role,
          participantName: user.participantName
        };

        return updatedItem;
      }

      return item;
    });
    setUserList(newList);
  };

  const showUserdetails = (user) => {
    setCurrentUser(user);
    setShowUser(true);
  }

  const saveUser = (user) => {
    setShowLoader(true);
    userSaveFromAdmin(user).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false); appContext.setErrorMessage('Error while saving user data');
      }
    });
  }

  const resetPassword = (user) => {
    setCurrUser(user);
    setMessage('Do you want to Reset the Password?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    setShowLoader(true);
    resetDefaultPassword(currUser.rowId, 'cudents').subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false); appContext.setErrorMessage('Error while reset password');
      }
    });
  }
  
  const handleMsgChange = (e) => {
    setWPMessage(e.target.value);
  };

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off">
        <div className="userControlBox">
          <div className="user-control">
            <TextField name="searchText" value={searchText} label="Search Text" variant="outlined"
              size="small" fullWidth onChange={handleChange} />
          </div>
          <div className="user-control formButton">
            <Button type="submit" variant="contained" color="primary" onClick={searchUser}>
              <SearchIcon /> Search
            </Button>
          </div>
          <div className="user-control">
            <span className="userLabel">Total Users : </span>
            <span className="userValue">{totalUsers}</span>
          </div>
          <div className="user-control wp-box">
            <TextField name="message" label="WhatsApp Message" required multiline rows={3} variant="outlined" size="small" fullWidth
              value={wpMessage} onChange={handleMsgChange}
            />
          </div>
        </div>
      </form>
    );
  }

  const getUserGrid = () => {
    return (
      <div>
        {getFormPanel()}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>Participant Name</StyledTableCell>
                <StyledTableCell>User Id</StyledTableCell>
                <StyledTableCell>Institution Name</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Package Id</StyledTableCell>
                <StyledTableCell>Validity</StyledTableCell>
                <StyledTableCell>Role</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user) => (
                <StyledTableRow key={user.name}>
                  <StyledTableCell component="th" scope="row">
                    {user.rowId}
                  </StyledTableCell>
                  <StyledTableCell>{user.displayName}</StyledTableCell>
                  <StyledTableCell>
                    <TextField name="participantName" value={user.participantName || EMPTY_STRING} fullWidth
                      onChange={(e) => { user.participantName = e.target.value; handleEdit(user); }} variant="outlined" size="small" />
                  </StyledTableCell>
                  <StyledTableCell>
                    <a href={getWhatsApp(user.userId, wpMessage)} target="_blank"><WhatsAppIcon /></a> <span className="whatsapp">{user.userId}</span>
                    {/* <a href={getWhatsAppMessage(user.userId)} target="_blank"><WhatsAppIcon /></a> <span className="whatsapp">{user.userId}</span> */}
                  </StyledTableCell>
                  <StyledTableCell>{user.institutionName}</StyledTableCell>
                  <StyledTableCell>{moment(user.creationDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>{user.packageId}</StyledTableCell>
                  <StyledTableCell>{user.validity}</StyledTableCell>
                  <StyledTableCell>
                    <TextField name="status" value={user.role} select onChange={(e) => { user.role = e.target.value; handleEdit(user); }}>
                      {USER_ROLE.map((role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell>
                    <PersonOutlineOutlinedIcon className="icon" onClick={() => { showUserdetails(user) }} />
                    <SaveIcon className="icon" onClick={() => { saveUser(user) }} />
                    <RotateLeftIcon className="icon" onClick={() => { resetPassword(user) }} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
        <br></br>
        <UserDetails open={showUser} onClose={() => { setShowUser(false) }} user={currentUser} /> 
        <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      </div>
    );
  }


  return (
    <div className="admin-user-list">
      {showLoader ? <div className="showLoading"><Loader /></div> : getUserGrid()}
    </div>
  );
}

export default UserList;