import { PAGE_SIZE } from '../../components/constants/constants';
import { get, post, whitelistGet } from '../../services/http-services';

export function getRedeemItems(pageNumber) {
    return whitelistGet('/redemption/items?page=' + pageNumber + '&size=70&sort=value,asc');
}

export function getReferralItems(pageNumber) {
    return whitelistGet('/redemption/referralitems?page=' + pageNumber + '&size=70&sort=value,asc');
}

export function redeemItem(itemId) {
    return post("/redemption/redeem?itemId=" + itemId, null);
}

export function redeemReferral(itemId) {
    return post("/redemption/redeemreferral?itemId=" + itemId, null);
}

export function getRedemptions(pageNumber) {
    return get('/redemption?page=' + pageNumber + '&size=' + PAGE_SIZE + '&sort=creationDate,asc');
}

export function getRedemptionRequests(redemptionId, status, pageNumber) {
    return get('/redemption/request?redemptionId=' + redemptionId + '&status=' + status + '&page=' + pageNumber + '&size=' + PAGE_SIZE + '&sort=creationDate,asc');
}

export function saveRedemptionDetail(redemptionDetail) {
    return post("/redemption", redemptionDetail);
}

