import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { isNotNull } from '../../../services/util-service';
import { EMPTY_STRING } from '../../constants/constants';
import { doSignOut } from '../../firebase/firebase.service';
import YesNoDialog from '../../message-dialog/yes-no-dialog.component';
import './dashboard-sidebar.styles.scss';


const DashboardSidebar = ({ orientation = 'vertical' }) => {

  const appContext = useContext(AppContext);
  const history = useHistory();
  const [currentLocation, setCurrentLocation] = useState('/userdashboard');
  const [message, setMessage] = useState(EMPTY_STRING);
  const [showMsgDialog, setShowMsgDialog] = useState(false);
  const [menuLabel, setMenuLabel] = useState(EMPTY_STRING);


  const getMenuClass = (val) => {
    // Return the color class for answer: Purple if selected, Green for correct
    let menuClass = EMPTY_STRING;
    if (currentLocation === val) {
      menuClass = 'active-menu';
    }
    return menuClass;
  }

  const menuLink = (val, img, label) => (
    <Link to={val} style={{ textDecoration: 'none' }} onClick={() => { onMenuClick(val, label) }}>
      <div className="dashboard-menu-item">
        <div className="dashboard-menu-item-icon">
          <img src={img} alt="" />
        </div>
        <div className="dashboard-menu-item-text">
          {label}
        </div>
      </div>
      {/* <ListItem button>
        <ListItemIcon>
          <img src={img} alt="" />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem> */}
    </Link>
  )

  const onMenuClick = (val, label) => {
    setCurrentLocation(val);
    setMenuLabel(label);
    if (label === 'Logout') {
      setShowMsgDialog(true);
      setMessage('Do you want to logout');
      // handleLogout();
    // } else if (label === 'Delete Account') {
    //   setShowMsgDialog(true);
    //   setMessage('You are not able to login again once you delete your account and all your personal data will be erased. Do you want to delete?');
      // handleDeleteAccount();
    }
  }

  const onOkClick = () => {
    if (menuLabel === 'Logout') {
      handleLogout();
    // } else if (menuLabel === 'Delete Account') {
    //   handleDeleteAccount();
    }
  }

  const handleLogout = () => {
    doSignOut();
    appContext.setUserData(null);
    localStorage.clear();
    navigateToHome();
  }

  const navigateToHome = () => {
    history.push('/');
  }

  return (
    <div className={orientation === 'horizontal' ? "user-dashboard-left-area horizontal-container" : "user-dashboard-left-area"}>
      <div className="dashboard-left-top-area">

        {/* Wishing the user here */}
        {/* <div className="dashboard-left-user">
          <div className="dashboard-left-wish-image">
            <img src={wishImage} alt="" />

            <div className="dashboard-left-user-wish">{wish}</div>
          </div>
          <div className="dashboard-left-info">
            <div className="dashboard-left-username">{appContext.userData.displayName}</div>
            <div className="dashboard-left-usergrade">Class-{appContext.userData.grade}</div>
          </div>
        </div> */}

        {/* Dashboard menu image */}
        {/* <div className="dashboard-menu-image">
          <img src="https://www.cudents.com/prod/assets/images/userdashboard/menu_image.png" alt="" />
        </div> */}

        {/* Sidebar menu list */}
        <div className="dashboard-left-menu">
          {/* <div>
            {menuLink('/userdashboard', 'https://www.cudents.com/prod/assets/images/userdashboard/idashboard.png', 'Dashboard')}
          </div> */}
          {/* <div>
            {menuLink('/userdashboard/games', 'https://www.cudents.com/prod/assets/images/userdashboard/games.png', 'Games')}
          </div>
          <div>
            {menuLink('/userdashboard/quizzes', 'https://www.cudents.com/prod/assets/images/userdashboard/quizzes.png', 'Quizzes')}
          </div> */}
          <div>
            {menuLink('/userdashboard/userprofile', '/assets/images/account/profile.png', 'Profile')}
          </div>
          {/* <div>
            {menuLink('/userdashboard/redeem', 'https://www.cudents.com/prod/assets/images/userdashboard/iredeem.png', 'Redeem')}
          </div> */}
          <div>
            {menuLink('/userdashboard/transactions', '/assets/images/account/points.png', 'Points')}
          </div>
          {/* <div>
            {menuLink('/userdashboard/orders', '/assets/images/account/orders.png', 'Orders')}
          </div> */}
          {/* <div>
            {menuLink('/userdashboard/pointsystem', 'https://www.cudents.com/prod/assets/images/userdashboard/ipoints.png', 'Points Info')}
          </div> */}
          <div>
            {menuLink('/userdashboard/referral', '/assets/images/account/refer.png', 'Referrals')}
          </div>
          {isNotNull(appContext.userData) && appContext.userData.provider === 'phone' ? <div>
            {menuLink('/userdashboard/changepassword', '/assets/images/account/password.png', 'Password')}
          </div> : null}
          <div>
            {menuLink('/userdashboard', '/assets/images/account/logout.png', 'Logout')}
          </div>
          <div>
            {menuLink('/redeem', '/assets/images/account/orders.png', 'Redeem')}
          </div>
        </div>
      </div>
      {/* The suggested Quiz section
      {orientation === 'vertical' ?
        <div className="user-dashboard-suggestion">
          <div className="suggestion-title">Suggested Quiz</div>
          <div className="suggestion-quiz">
            <img src="/assets/images/grade3/Science/body.png" alt="" />
            <div className="suggestion-quiz-name">Human Body</div>
          </div>
        </div> : null} */}
      {/* <div className="user-dashboard-image">
          <img src="/assets/images/account/dashboard.png" alt="" />
        </div> */}
      <YesNoDialog open={showMsgDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
  );
}

export default DashboardSidebar;