import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import MobileMenu from '../mobile-menu/mobile-menu.component';
import './bottom-nav-list.styles.scss';
import { isWebPlatform } from '../../../services/util-service';

function BottomNavList() {

  const history = useHistory()
  const appContext = useContext(AppContext);
  const [activeItem, setActiveItem] = useState('home');

  const menuClicked = (selItem) => {
    if (!isWebPlatform()) {
      window.screen.orientation.lock('portrait');
    }
    switch (selItem) {
      case 'home':
        setActiveItem('home');
        history.push('/');
        break;
      case 'notification':
        setActiveItem('notification');
        history.push('/notification');
        break;
      case 'redeem':
        setActiveItem('notiredeemfication');
        history.push('/redeem');
        break;
      case 'news':
        setActiveItem('news');
        break;
      case 'leaderboard':
        setActiveItem('leaderboard');
        history.push('/leaderboard');
        break;
      case 'badges':
        setActiveItem('badges');
        history.push('/badges');
        break;
      case 'account':
        setActiveItem('account');
        history.push('/userdashboard');
        break;
      case 'login':
        setActiveItem('account');
        history.push('/signin');
        break;
      default:
        setActiveItem('home');
        break;
    }
  }

  return (
    <>
      <div className={activeItem == 'home' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { menuClicked('home') }}>
        <div className="bottom-nav-list-item-icon">
          <img src="/assets/images/homepage/bn_home.png" alt="" />
        </div>
        <div className="bottom-nav-list-item-label">
          Home
        </div>
      </div>

      {/* <div className={activeItem == 'notification' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { menuClicked('notification') }}>
  <div className="bottom-nav-list-item-icon">
    <img src="/assets/images/homepage/bn_notifications.png" alt="" />
  </div>
  <div className="bottom-nav-list-item-label">
    Notifications
  </div>
</div> */}

      <div className={activeItem == 'leaderboard' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { menuClicked('leaderboard') }}>
        <div className="bottom-nav-list-item-icon">
          <img src="/assets/images/homepage/newhome/icons/leaderboard.png" alt="" />
        </div>
        <div className="bottom-nav-list-item-label">
          Leaderboard
        </div>
      </div>

      <div className={activeItem == 'news' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { menuClicked('news') }}>
        <div className="bottom-nav-list-item-icon menu-icon">
          {/* <img src="/assets/images/homepage/bn_menu.png" alt="" /> */}
          <MobileMenu></MobileMenu>
        </div>
      </div>

      <div className={activeItem == 'notification' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { menuClicked('redeem') }}>
        <div className="bottom-nav-list-item-icon">
          <img src="https://www.cudents.com/prod/assets/images/userdashboard/iredeem.png" alt="" />
        </div>
        <div className="bottom-nav-list-item-label">
          Redeem
        </div>
      </div>

      <div className={activeItem == 'account' ? "bottom-nav-list-item bottom-nav-list-item-isactive" : "bottom-nav-list-item"} onClick={() => { appContext.userData ? menuClicked('account') : menuClicked('login') }}>
        <div className="bottom-nav-list-item-icon">
          <img src="/assets/images/homepage/bn_dashboard.png" alt="" />
        </div>
        <div className="bottom-nav-list-item-label">
          {appContext.userData ? 'Account' : 'Login'}

        </div>
      </div>
    </>
  );
}

export default BottomNavList;