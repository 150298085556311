import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
// import GaugeChart from 'react-gauge-chart';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../app-context';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import QuizPlayer from '../../components/quiz/quiz-player/quiz-player.component';
import { AllAnswers } from '../quiz/scorecard/all-answers/all-answers.component';
import { getAssessQuestions, getAttemptAssess, saveAssessQuestion } from '../quiz/question-service';
import { isNull } from '../../services/util-service';
import './assessment.styles.scss';

const AssessmentQuiz = (props) => {
  const [quizDetails, setQuizDetails] = useState([]);
  const [assessResult, setAssessResult] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [showAssessmentQuiz, setShowAssessmentQuiz] = useState(false);
  const [showAssessmentResult, setShowAssessmentResult] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showAllAnswers, setShowAllAnswers] = useState(false);
  useEffect(() => {
    appContext.setShowHeader(false);
    appContext.setShowFooter(false);
    if (assessResult === null) {
      loadAttemptAssess();
    }
    return () => {
      appContext.setShowHeader(true);
      appContext.setShowFooter(true);
    }
  }, []);

  const loadAttemptAssess = () => {
    getAttemptAssess().subscribe({
      next: (response) => {
        if (isNull(response.data)) {
          loadOnlyQuestion();
        } else {
          setAssessResult(response.data);
          setShowConfirmation(true);
        }
        setShowLoader(false);
      }, error : (error ) => {
        appContext.setDialogMessage("There is some server issue. Please try after some time");
        history.goBack();
      }});
  }


  const loadOnlyQuestion = () => {
    if (appContext.userData) {
      setShowLoader(true);
      const grade = appContext.userData.grade;
      getAssessQuestions(grade).subscribe({
        next: (response) => {
          setQuizDetails(response.data);
          setShowLoader(false);
          setShowAssessmentQuiz(true);
        }, error : (error ) => {
          setShowLoader(false);
        }});
    } else {
      setShowLoader(false);
    }
  }

  const onSubmit = (ansDetails) => {
    // submit the assessment answers
    // fetch the results
    // show the result panel
    const data = { models: ansDetails }
    setShowLoader(true);
    saveAssessQuestion(data).subscribe({
      next: (response) => {
        setAssessResult(response.data);
        setShowLoader(false);
        setShowAssessmentQuiz(false);
        setShowAssessmentResult(true);
      }, error : (error ) => {
        setShowLoader(false);
      }});

  }

  const onOkClick = () => {
    history.push(`/userDashboard`);
  }

  const showResult = () => {
    setShowConfirmation(false);
    setShowAllAnswers(false);
    setShowAssessmentResult(true);
  }

  const onViewAllAnswers = () => {
    setShowConfirmation(false);
    setShowAssessmentResult(false);
    setShowAllAnswers(true);
  }

  const renderView = () => {
    return (
      <div className="assessment-page">
        <div className="top-right-shape"><img src="/assets/images/quiz/topright.png" alt="" /></div>
        <div className="bottom-left-shape"><img src="/assets/images/quiz/bottomleft.png" alt="" /></div>
        {showAssessmentQuiz ?
          <div className="assessment-quiz-container">
            <Grid justifyContent="center" container xs={12}>
              <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
                {quizDetails.length > 1 ? <QuizPlayer quizDetails={quizDetails} setShowExitDialog={setShowExitDialog} onSubmit={onSubmit} quizTitle={'Assessment Test'} /> : null}
              </Grid>
            </Grid>
          </div>
          : null
        }
        {showConfirmation ?
          <Grid justifyContent="center" container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <div className="assessment-result-container">
                <div className="assessment-done-image">
                  <img src="/assets/images/assessment/assessmentdone.png" alt="" />
                  <div className="result-title">
                    You have already taken the assessment.
                  </div>
                </div>
                <div className="confirmation-buttons">
                  <div className="dashboard-button">
                    <span onClick={showResult}>View Results</span>
                  </div>
                  <div className="dashboard-button">
                    <span onClick={onOkClick}>Go To Dashbaord</span>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          : null
        }
        {showAssessmentResult ?
          <Grid justifyContent="center" container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <div className="assessment-result-container">
                <div className="assessment-result-title">Assessment Result <div className="result-label">Total Score: {assessResult.scorePercentage}%</div></div>
                <div className="sub-title">Skills</div>
                <Grid justifyContent="center" container xs={12}>
                  {assessResult.skill.map((result, index) => (
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                      <div className="result-speedometer">
                        {/* <GaugeChart id="gauge-chart5"
                          arcsLength={[0.3, 0.4, 0.3]}
                          colors={['#EA4228', '#F5CD19', '#5BE12C']}
                          percent={parseFloat(result.scorePercentage) / 100}
                          arcPadding={0.02}
                          cornerRadius={0}
                          arcWidth={0.4}
                          textColor={"#3e4bbb"}
                          needleColor={"#c144c1"}
                          hideText={true}
                        /> */}
                      </div>
                      <div className="result-percentage">{result.scorePercentage}%</div>
                      <div className="result-label">{result.area}</div>
                    </Grid>
                  ))}
                </Grid>
                <Grid justifyContent="center" container xs={12}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="subject-section">
                      <div className="section-title">
                        <div>Strong Areas</div>
                        <img src="/assets/images/assessment/strengths.png" alt="" />
                      </div>
                      <div className="subject-list">
                        {assessResult.strength.length > 0 ?
                          assessResult.strength.map((result, index) => (
                            <span>{result.area} - {result.scorePercentage}%</span>
                          ))
                          :
                          <div>You need improvement in all the subjects</div>
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className="subject-section">
                      <div className="section-title">
                        <div>Improvement Areas</div>
                        <img src="/assets/images/assessment/areaofimprovement.png" alt="" />
                      </div>
                      <div className="subject-list">
                        {assessResult.improvement.length > 0 ?
                          assessResult.improvement.map((result, index) => (
                            <span>{result.area} - {result.scorePercentage}%</span>
                          ))
                          : <div> You are strong in all the subjects </div>
                        }

                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className="result-info-section">
                  <div className="result-section-label">What can cudents offer you?</div>
                  <div className="result-section-details">
                    Practice makes one perfect and we provide the platform to practice.
                   <br/> If you are strong in an area, you can become stronger.
                   <br/> If you need improvement in an area, you can be strong.
                   <br/> Play our Quizzes to learn, have fun, collect points, and redeem for gifts.
                  </div>
                </div>
                <div className="dashboard-button">
                  <span onClick={onViewAllAnswers}>View All Answers</span>
                  <span onClick={onOkClick}>Go To Dashbaord</span>
                </div>
                <div className="assessment-result-image">
                  <img src="/assets/images/assessment/improvement.png" alt="" />
                </div>
              </div>
            </Grid>
          </Grid> : null
        }
        {showAllAnswers ?
          <Grid justifyContent="center" container xs={12}>
            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <AllAnswers quizResult={assessResult} onExit={showResult} buttonLabel={"View Assessment Report"} />
            </Grid>
          </Grid>
          : null
        }
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderView()}
      <YesNoDialog open={showExitDialog} onClose={() => { setShowExitDialog(false) }} onOk={() => { setShowExitDialog(false); onOkClick(); }} message={'Do you really want to exit the quiz?'} />
    </div>
  );
}

export default AssessmentQuiz;