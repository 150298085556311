import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { EMPTY_STRING } from '../../components/constants/constants';
import { isEmpty } from '../../services/util-service';
import './admin-question-bank.styles.scss';
import ImageTextfield from '../../components/text-field/image-textfield.component';

const NewQuestionForm = (props) => {
  const grade = props.params.grade;
  const subject = props.params.subject;
  const topic = props.params.topic;
  const level = props.params.level;

  const appContext = useContext(AppContext);
  const initFormData = {
    rowId: null,
    grade: grade,
    subject: subject,
    topic: topic,
    level: level,
    isReviewed: 0,
    number: 0,
    questionText: EMPTY_STRING,
    questionImagePath: null,
    questionImage: [],
    option1Text: EMPTY_STRING,
    option1ImagePath: null,
    option1Image: [],
    isOption1Correct: 0,
    option2Text: EMPTY_STRING,
    option2ImagePath: null,
    option2Image: [],
    isOption2Correct: 0,
    option3Text: EMPTY_STRING,
    option3ImagePath: null,
    option3Image: [],
    isOption3Correct: 0,
    option4Text: EMPTY_STRING,
    option4ImagePath: null,
    option4Image: [],
    isOption4Correct: 0,
    option5Text: EMPTY_STRING,
    option5ImagePath: null,
    option5Image: [],
    isOption5Correct: 0,
    correctOptionExplanation: EMPTY_STRING
  };
  const [formData, updateFormData] = useState(initFormData);
  useEffect(() => {
    if (props.selectedQuestion != null) {
      updateFormData(props.selectedQuestion);
    }
  }, [props.selectedQuestion]);


  useEffect(() => {
    if (props.selectedQuestion === null) {
    updateFormData({
      ...initFormData,
      grade: grade,
      subject: subject,
      topic: topic,
      level: level,
      isReviewed: 0,
    });
  }
  }, []);


  const onAnswerSelection = (ansSelected) => {
    const tempAns = { ...formData };
    const newData = {
      ...tempAns,
      isOption1Correct: ansSelected === 'isOption1Correct' ? 1 : 0,
      isOption2Correct: ansSelected === 'isOption2Correct' ? 1 : 0,
      isOption3Correct: ansSelected === 'isOption3Correct' ? 1 : 0,
      isOption4Correct: ansSelected === 'isOption4Correct' ? 1 : 0,
      isOption5Correct: ansSelected === 'isOption5Correct' ? 1 : 0,
    };
    updateFormData(newData);
  }

  const handleTextChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
      number: formData.number === 0 ? props.questionNumber : formData.number
    });
  };

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage,
      number: formData.number === 0 ? props.questionNumber : formData.number
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    const imageFieldNamePath = imageFieldName+'Path';
    updateFormData({
      ...formData,
      [imageFieldName] : null,
      [imageFieldNamePath]: null
    });
  };

  const isValidQuestion = () => {
    let valid = false;
    if(isEmpty(formData.questionText)){
      appContext.setDialogMessage('Please add \'Question Description\' to add the question');
      valid = false;
    } else {
      valid = true;
    } 
    return valid;
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if(isValidQuestion()){
      props.onSubmit(formData);
      // if( isNull(formData.rowId) && formData.number > 15){
      //   appContext.setDialogMessage('You can\'t add more than 15 question in this level');
      // } else {
      //   saveQuestion(formData).subscribe({
      //     next: (response) => {
      //         updateFormData(response.data);
      //         appContext.setSuccessMessage(response.message);
      //         props.onQuestionBankSelection();
      //     }, error : (error ) => {
      //       appContext.setErrorMessage(error.message);
      //     }});
      // }
    }
  }

  return (
    <div className="newQuestionForm">

      <ImageTextfield id="question-description" label="Question Description" value={formData.questionText}
        image={formData.questionImagePath} multiline={true} rows={3} textFieldName="questionText" imageFieldName="questionImage"
        handleTextChange={handleTextChange} handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }}
        onDeleteImage ={handleDeleteImage} imagePath="questionImagePath"></ImageTextfield>

      <ImageTextfield id="answer-a-details" label="Answer (a)" value={formData.option1Text} image={formData.option1ImagePath}
        checked={formData.isOption1Correct === 1} onSelection={() => { onAnswerSelection('isOption1Correct') }}
        textFieldName="option1Text" imageFieldName="option1Image" checkName="checkedA" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage ={handleDeleteImage}
        imagePath="option1ImagePath"></ImageTextfield>

      <ImageTextfield id="answer-b-details" label="Answer (b)" value={formData.option2Text} image={formData.option2ImagePath}
        checked={formData.isOption2Correct === 1} onSelection={() => { onAnswerSelection('isOption2Correct') }}
        textFieldName="option2Text" imageFieldName="option2Image" checkName="checkedB" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage ={handleDeleteImage}
        imagePath="option2ImagePath"></ImageTextfield>

      <ImageTextfield id="answer-c-details" label="Answer (c)" value={formData.option3Text} image={formData.option3ImagePath}
        checked={formData.isOption3Correct === 1} onSelection={() => { onAnswerSelection('isOption3Correct') }}
        textFieldName="option3Text" imageFieldName="option3Image" checkName="checkedC" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage ={handleDeleteImage}
        imagePath="option3ImagePath"></ImageTextfield>

      <ImageTextfield id="answer-d-details" label="Answer (d)" value={formData.option4Text} image={formData.option4ImagePath}
        checked={formData.isOption4Correct === 1} onSelection={() => { onAnswerSelection('isOption4Correct') }}
        textFieldName="option4Text" imageFieldName="option4Image" checkName="checkedD" handleTextChange={handleTextChange}
        handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage ={handleDeleteImage}
        imagePath="option4ImagePath"></ImageTextfield>

      <br /> <br />
      <FormControl className="formFields">
        <Button variant="contained" color="secondary" onClick={onSubmit}> {formData.rowId === null ? 'Add Question' : 'Update Question'} </Button>
      </FormControl>
    </div>
  );
}

export default NewQuestionForm;