import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import { StyledTableRow, useStyles } from '../../../services/grid-style';
import './my-transactions.styles.scss';
import { getPointTransactions } from '../../user-dashboard/point-service';

const MyTransactions = () => {

  const [transactionList, updateTransactionList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [showAds, setShowAds] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    loadTransactionList();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadTransactionList = () => {
    setShowLoader(true);
    getPointTransactions(page - 1).subscribe({
      next: (response) => {
        updateTransactionList(response.data.content);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const renderTransactions = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Points Earned</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionList.map((transaction) => (
              <StyledTableRow key={transaction.name}>
                <TableCell component="th" scope="row">
                  {transaction.details}
                </TableCell>
                <TableCell>{transaction.point}</TableCell>
                <TableCell>{moment(transaction.creationDate).format('lll')}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const renderView = () => {
    return (
      <div>
        {transactionList.length > 0 ?
          <>
            <div className="transactions-list">
              {renderTransactions()}
            </div>
            <div className="transactions-container-pagination">
              <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handleChange} />
            </div>
          </>
          :
          <div className="no-transactions-data">
            <img src="/assets/images/errors/nodata.png" alt="" />
            No Points History Found
          </div>

        }

        {showAds ? <><AdComponent></AdComponent></> : null}
      </div>
    );
  }

  return (
    <div className="transactions-container">
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default MyTransactions;