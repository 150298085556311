import { PAGE_SIZE } from '../../components/constants/constants';
import { get, post, remove } from '../../services/http-services';

export function findAllHomeBanner(pageNumber) {
    return get(`/homebanner/findall?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getHomeBanner() {
    return get(`/homebanner/findlatest`);
}

export function saveHomeBanner(data) {
    return post('/homebanner', data);
}

export function deleteHomeBanner(id) {
    return remove(`/homebanner?id=${id}`);
}
