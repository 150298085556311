import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { createSubscriptionCodes, getSubscriptionCodes } from '../../feature/quiz/subscription-service';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import DateTimeComponent from '../../components/date-time/date-time.component';
import './admin-subscription.styles.scss';

const AdminSubscription = () => {
  const initData = {
    isRedeemable: 0, codeValidity: EMPTY_STRING,  partner: EMPTY_STRING, packageId: EMPTY_STRING, count: EMPTY_STRING, code: EMPTY_STRING
  };
  const [subscriptionList, setCouponList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const appContext = useContext(AppContext);
  const [searchText, setSearchText] = useState(EMPTY_STRING);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    const searchTxt = searchText ? searchText : formData.partner;
    getSubscriptionCodes(searchTxt, selectedPage - 1).subscribe({
      next: (response) => {
        setCouponList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      createSubscriptionCodes(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const handleStartDateChange = (e) => {
    const codeValidity = moment(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: codeValidity
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["codeValidity"]) {
      formIsValid = false;
      errors["codeValidity"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["partner"]) {
      formIsValid = false;
      errors["partner"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["packageId"]) {
      formIsValid = false;
      errors["packageId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["count"]) {
      formIsValid = false;
      errors["count"] = MANDATORY_FIELD_MSG;
    }
    
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="subscriptionFormContainer">
        <div className="subscriptionTitle">Subscription Details</div>
        <div className="subscriptionForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
           <div className="formField">
              <TextField name="partner" value={formData.partner || EMPTY_STRING} onChange={handleChange} required fullWidth label="Partner" variant="outlined" size="small" />
              <span className="error-text">{formError["partner"]}</span>
            </div>
            <div className="formField">
              <TextField name="packageId" value={formData.packageId || EMPTY_STRING} onChange={handleChange} fullWidth label="Package Id" variant="outlined" size="small" />
              <span className="error-text">{formError["packageId"]}</span>
            </div>
            <div className="formField">
              <TextField name="count" value={formData.count || EMPTY_STRING} onChange={handleChange} fullWidth label="Count" variant="outlined" size="small" />
              <span className="error-text">{formError["count"]}</span>
            </div>
            <div className="formField">
              <TextField name="code" value={formData.code || EMPTY_STRING} onChange={handleChange} fullWidth label="Code" variant="outlined" size="small" />
            </div>
             <div className="formField">
              <DateTimeComponent name="codeValidity" label="Code Validity" onDateSelection={handleStartDateChange} defaultDate={formData.codeValidity ? moment(formData.codeValidity) : null} />
              <span className="error-text">{formError["codeValidity"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getCouponsGrid = () => {
    return (
      <div className="subscriptionFormContainer">
         <div className="subscription-search">
          <div className="formField">
            <TextField name="searchText" value={searchText} onChange={handleSearchChange} label="Search By Partner" variant="outlined" size="small" />
            <Button variant="contained" onClick={search} color="secondary">
              Search
            </Button>
            <span className='search-total'>Total : {totalCount}</span>
            <div className="error-text">{formError['searchText']}</div>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Subscription Code</StyledTableCell>
                <StyledTableCell>Redeemable</StyledTableCell>
                <StyledTableCell>Partener</StyledTableCell>
                <StyledTableCell>Package Id</StyledTableCell>
                <StyledTableCell>Use</StyledTableCell>
                <StyledTableCell>Code Validity</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionList.map((subscription) => (
                <StyledTableRow key={subscription.code}>
                  <StyledTableCell>{subscription.code}</StyledTableCell>
                  <StyledTableCell>{subscription.isRedeemable}</StyledTableCell>
                  <StyledTableCell>{subscription.partner}</StyledTableCell>
                  <StyledTableCell>{subscription.packageId}</StyledTableCell>
                  <StyledTableCell>{subscription.isUsed === 1 ? 'Used' : 'Not Used'}</StyledTableCell>
                  <StyledTableCell>{moment(subscription.codeValidity).format('DD-MM-YYYY hh:mm:ss A')}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-subscription-details">
      {showLoader ? <div className="showLoading"><Loader /></div>
        : <>
          {renderContact()}
          {getCouponsGrid()}
        </>
      }
    </div>
  );
}


export default AdminSubscription;


