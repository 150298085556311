import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post } from "../../services/http-services";

export function createSubscriptionCodes(subscriptionCodeModel) {
    return post(`/subscription/createcodes`, subscriptionCodeModel);
}

export function getSubscriptionCodes(partner, pageNumber) {
    return get(`/subscription/getcodes?partner=${partner}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function redeemSubscription(redemptionCode) {
    return post(`/subscription/redeemsubscription?redemptionCode=${redemptionCode}`);
}

export function getSubscriptionValidity() {
    return get(`/subscription/validity`);
}

export function isSubscriptionPresent() {
    return get(`/subscription/present`);
}

export function saveSubscriptionAdmin(userId, packageId, isRedeemable) {
    return post(`/subscription/savesubscriptionadmin?userIdentification=${userId}&packageId=${packageId}&isRedeemable=${isRedeemable}`, null);
}

