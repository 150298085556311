import { FormControl, MenuItem, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { approveGuessQuestions, findAllGuessMasterTopicsAdmin, findAllGuessQuestionAdmin, saveGuessQuestion } from '../../feature/guess-master/guess-master-service';
import { AppContext } from '../../app-context';
import { EMPTY_STRING, MANDATORY_FIELD_MSG, ROLE_ADMIN, ROLE_USER } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { isNotEmpty, isNotEmptyArray } from '../../services/util-service';
import ImageTextfield from '../../components/text-field/image-textfield.component';
import './admin-guess-question.styles.scss';

const AdminGuessQuestion = () => {

  const appContext = useContext(AppContext);
  const initData = {
    answerImage: EMPTY_STRING, guessMasterId: EMPTY_STRING, number: 0, isReviewed: 0, questionImage: EMPTY_STRING, subject: EMPTY_STRING, 
    topic: EMPTY_STRING, answerImagePath: EMPTY_STRING, questionImagePath: EMPTY_STRING
  }
  const [guessQuestionList, setGuessQuestionList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [isReviewed, setIsReviewed] = useState(0);
  const [guessMaster, setGuessMaster] = useState({});
  const [guessMasterList, setGuessMasterList] = useState([]);
  const [userRole, setRole] = useState(ROLE_USER);
  const [topic, setTopic] = useState(EMPTY_STRING);

  useEffect(() => {
    if (appContext.userData) {
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  useEffect(() => {
    search(page);
  }, [guessMaster.rowId, isReviewed, page]);

  // useEffect(() => {
  //   if (subjectList.length === 0) {
  //     loadAllSubject();
  //   }
  // }, []);

  useEffect(() => {
    if (guessMasterList.length === 0) {
      loadAllGuessMaster();
    }
  }, []);


  // useEffect(() => {
  //   loadAllTopic();
  // }, [formData.subject]);

  const loadAllGuessMaster = () => {
    setShowLoader(true);
    findAllGuessMasterTopicsAdmin().subscribe({
      next: (response) => {
        setGuessMasterList(response.data);
        setShowLoader(false);
      }
    });
  }


  // const loadAllSubject = () => {
  //   setShowLoader(true);
  //   findGuessMasterSubjectsAdmin().subscribe({
  //     next: (response) => {
  //       setSubjectList(response.data);
  //       setShowLoader(false);
  //     }
  //   });
  // }

  // const loadAllTopic = () => {
  //   if(isNotEmpty(formData.subject)){
  //     setShowLoader(true);
  //     findGuessMasterTopicBySubjectAdmin(formData.subject).subscribe({
  //       next: (response) => {
  //         setTopicList(response.data);
  //         const temp = response.data.find(element => element.guessMasterId === topic.guessMasterId);
  //         console.log(temp);
  //         setTopic(temp);
  //         setShowLoader(false);
  //       }
  //     });
  //   }
  // }

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    if(isNotEmpty(guessMaster.rowId) && isNotEmpty(isReviewed)){
      setShowLoader(true);
      const reviewed = isReviewed === true ? 1 : 0;
      findAllGuessQuestionAdmin(guessMaster.rowId, reviewed).subscribe({
      next: (response) => {
        setGuessQuestionList(response.data);
        // setPageCount(response.data.totalPages);
        // setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveGuessQuestion(formData).subscribe({
        next: (response) => {
          search(1);
          updateFormData(initData);
          setTopic(EMPTY_STRING);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const editGuessQuestion = (guessQuestion) => {
    const guessMaster = getGuessMasterFromList(guessQuestion.guessMasterId); 
     setTopic(guessMaster);
    updateFormData({
      ...guessQuestion,
      topic: guessMaster.topic,
      subject: guessMaster.subject,
      guessMasterId: guessMaster.rowId
    });   
  }

  const getGuessMasterFromList = (guessMasterId) => {
    return guessMasterList.find(element => element.rowId === guessMasterId);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]:  e.target.value
    });
  };

  const handleTopicChange = (e) => {
    setTopic(e.target.value)
    updateFormData({
      ...formData,
      subject: e.target.value.subject,
      topic: e.target.value.topic,
      guessMasterId: e.target.value.rowId
    });
  };

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    const imageFieldNamePath = imageFieldName + 'Path';
    updateFormData({
      ...formData,
      [imageFieldName]: null,
      [imageFieldNamePath]: null
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["subject"]) {
      formIsValid = false;
      errors["subject"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["topic"]) {
      formIsValid = false;
      errors["topic"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["number"]) {
      formIsValid = false;
      errors["number"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["isReviewed"]) {
      formIsValid = false;
      errors["isReviewed"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }


  const renderContact = () => {
    return (
      <div className="guessQuestionFormContainer">
        <div className="guessQuestionTitle">Guess Question</div>
        <div className="guessQuestionForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            {/* <div className="formField">
              <TextField name="subject" value={formData.subject || EMPTY_STRING} select label="Subject" onChange={handleChange} fullWidth variant="outlined" size="small" required>
                {subjectList.map((subjectObj) => (
                  <MenuItem key={subjectObj.subject} value={subjectObj.subject}>
                    {subjectObj.subject}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["subject"]}</span>
            </div> */}
            <div className="formField">
              <TextField name="topic" value={topic || formData.topic} select label="Topic" onChange={handleTopicChange} fullWidth variant="outlined" size="small" required>
                {guessMasterList.map((topicObj) => (
                  <MenuItem key={topicObj.topic} value={topicObj}>
                     {topicObj.subject + ' - ' + topicObj.topic}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["topic"]}</span>
            </div>
            <div className="formField">
              <TextField name="number" value={formData.number || EMPTY_STRING} label="Number" onChange={handleChange} fullWidth variant="outlined" size="small" required/>
              <span className="error-text">{formError["number"]}</span>
            </div>
            {appContext.userData.role === ROLE_ADMIN ? <div className="formField">
              <TextField name="isReviewed" value={formData.isReviewed || EMPTY_STRING} label="Is Reviewed" required onChange={handleChange} fullWidth variant="outlined" size="small">
              </TextField>
              <span className="error-text">{formError["isReviewed"]}</span>
            </div> : null}
            <div className="formField">
              <ImageTextfield id="question-description" label="Guess Question" value={formData.questionText}
                image={formData.questionImagePath} textFieldName="questionText" imageFieldName="questionImage"
                handleTextChange={handleChange} handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }}
                onDeleteImage={handleDeleteImage} imagePath="questionImagePath"></ImageTextfield></div>
            <div className="formField">
              <ImageTextfield id="answer-details" label="Guess Answer" value={formData.answerText} image={formData.answerImagePath}
                textFieldName="answerText" imageFieldName="answerImage" handleTextChange={handleChange}
                handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
                imagePath="answerImagePath"></ImageTextfield>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}

            </div>
          </form>
        </div>
      </div>
    );
  }

  const onApprove = () => {
    setMessage('Do you want to approve all question?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    approveGuessQuestions().subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        search(0);
        setIsReviewed(true);
      }
    });
  }

  const handleGuessMaster = (e) => {
    setGuessMaster(e.target.value);
  };

  const handleToggle = (e) => {
    setIsReviewed(e.target.checked);
    setPage(1);
  };

  const getGuessQuestionGrid = () => {
    return (
      <div className="guessQuestionFormContainer">
          {isNotEmptyArray(guessMasterList) ? <div className="formField">
              <TextField name="guessMaster" value={guessMaster || EMPTY_STRING} select label="Guess Master" onChange={handleGuessMaster} fullWidth variant="outlined" size="small">
                {guessMasterList.map((guessMaster) => (
                  <MenuItem key={guessMaster.rowId} value={guessMaster}>
                    {guessMaster.subject + ' - ' + guessMaster.topic}
                  </MenuItem>
                ))}
              </TextField>
            </div>: null}
            <div>Is Reviewed <Switch checked={isReviewed} onChange={handleToggle} />
          <span>
            <FormControl className="header-field">
              {userRole === ROLE_ADMIN && !isReviewed ? <Button variant="contained" color="primary" onClick={onApprove} > Approve All </Button> : null}
            </FormControl>
          </span>
          <span>Total =  {totalCount}</span>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Number</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>Answer Text</StyledTableCell>
                <StyledTableCell>Answer</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guessQuestionList.map((guessQuestion) => (
                <StyledTableRow key={guessQuestion.number}>
                  <StyledTableCell>{guessQuestion.number}</StyledTableCell>
                  <StyledTableCell><img src={guessQuestion.questionImagePath} /></StyledTableCell>
                  <StyledTableCell>{guessQuestion.answerText}</StyledTableCell>
                  <StyledTableCell><img src={guessQuestion.answerImagePath} /></StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editGuessQuestion(guessQuestion) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
        <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
    );
  }

  return (
    <div className="guessQuestionContainer">
      {showLoader ? <div className="showLoading"><Loader /></div>
        :
        <>
          {renderContact()}
          {getGuessQuestionGrid()}
        </>
      }
    </div>
  );
}


export default AdminGuessQuestion;


