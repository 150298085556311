import { PAGE_SIZE } from '../../components/constants/constants';
import { get, whitelistGet } from '../../services/http-services';

export function getDashboardDetails() {
    return get('/dashboard/details');
}

export function getQuizScores(pageNumber) {
    return get('/dashboard/quizscore?page=' + pageNumber+ '&size=' + PAGE_SIZE +'&sort=creationDate,desc');
}

export function getSubjectScores() {
    return get('/dashboard/subjectscore');
}

export function getTopicScores(subject) {
    return get('/dashboard/topicscore?subject='+subject);
}

export function getStats() {
    return whitelistGet('/dashboard/stats');
}

export function getActiveUsers(days) {
    return get('/dashboard/activeusers?days='+days);
}

export function getNewUsers(days) {
    return get('/dashboard/newusers?days='+days);
}

export function getActiveUsersContent(days) {
    return get('/dashboard/activeuserscontent?days='+days);
}

export function getActiveUsersGrade(days) {
    return get('/dashboard/activeusersgrade?days='+days);
}

export function getDailyActiveUsers(days) {
    return get('/dashboard/dailyactiveusers?days='+days);
}
