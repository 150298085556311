import { Grid, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { getInstitutions, saveInstitution } from '../service/institution-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { COUNTRIES, EMPTY_STRING, MANDATORY_FIELD_MSG, STATES } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import ImageTextfield from '../../components/text-field/image-textfield.component';
import './institution.styles.scss';

const AdminInstitutions = () => {

  const appContext = useContext(AppContext);
  const initData = {
    adminId: appContext.userData.rowId, name: EMPTY_STRING, contact: EMPTY_STRING,
     address: EMPTY_STRING, city: EMPTY_STRING, phone: EMPTY_STRING, email: EMPTY_STRING, 
     state: EMPTY_STRING, pin: EMPTY_STRING,      country: EMPTY_STRING
  };
  const [institutionList, setInstitutionList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getInstitutions(selectedPage - 1).subscribe({
      next: (response) => {
        setInstitutionList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveInstitution(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }});
    }
  }

  const editInstitution = (institution) => {
    updateFormData(institution);
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    const imagePath = imageFieldName + 'Path';
    updateFormData({
      ...formData,
      [imageFieldName]: null,
      [imagePath]: null
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["adminId"]) {
      formIsValid = false;
      errors["adminId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["contact"]) {
      formIsValid = false;
      errors["contact"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["address"]) {
      formIsValid = false;
      errors["address"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["city"]) {
      formIsValid = false;
      errors["city"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["state"]) {
      formIsValid = false;
      errors["state"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["pin"]) {
      formIsValid = false;
      errors["pin"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["country"]) {
      formIsValid = false;
      errors["country"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="institutionFormContainer">
        <div className="institutionTitle">Institute Detail</div>
        <div className="institutionForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
                <div className="formField">
                  <TextField name="adminId" value={formData.adminId || EMPTY_STRING} onChange={handleChange} required fullWidth label="Admin Id" variant="outlined" size="small" />
                  <span className="error-text">{formError["adminId"]}</span>
                </div>
                <div className="formField">
                  <TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange} required fullWidth label="Institution Name" variant="outlined" size="small" />
                  <span className="error-text">{formError["name"]}</span>
                </div>
                <div className="formField">
                  <TextField name="contact" value={formData.contact || EMPTY_STRING} onChange={handleChange} required fullWidth label="Contact Name" variant="outlined" size="small" />
                  <span className="error-text">{formError["contact"]}</span>
                </div>
                <div className="formField">
                  <TextField name="phone" value={formData.phone || EMPTY_STRING} onChange={handleChange} required fullWidth label="Phone" variant="outlined" size="small" />
                  <span className="error-text">{formError["phone"]}</span>
                </div>
                <div className="formField">
                  <TextField name="email" value={formData.email || EMPTY_STRING} onChange={handleChange} required fullWidth label="Email" variant="outlined" size="small" />
                  <span className="error-text">{formError["email"]}</span>
                </div>
                <div className="formField">
                  <TextField name="city" value={formData.city || EMPTY_STRING} onChange={handleChange} required fullWidth label="City" variant="outlined" size="small" />
                  <span className="error-text">{formError["city"]}</span>
                </div>
                <div className="formField">
                  <TextField name="pin" value={formData.pin || EMPTY_STRING} onChange={handleChange} required fullWidth label="PIN" variant="outlined" size="small" />
                  <span className="error-text">{formError["pin"]}</span>
                </div>
                <div className="formField">
                  <TextField name="country" value={formData.country || EMPTY_STRING} select label="Country" onChange={handleChange} required fullWidth variant="outlined" size="small">
                    {COUNTRIES.map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                  <span className="error-text">{formError["Country"]}</span>
                </div>
                <div className="formField">
                  <TextField name="state" value={formData.state || EMPTY_STRING} select label="State" onChange={handleChange} required fullWidth variant="outlined" size="small">
                    {STATES.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <span className="error-text">{formError["state"]}</span>
                </div>
                <div className="formField">
                  <TextField name="address" label="Address" fullWidth required multiline rows={3}
                    value={formData.address || EMPTY_STRING} onChange={handleChange} variant="outlined" size="small"
                  />
                  <span className="error-text">{formError["address"]}</span>
                </div>
                <ImageTextfield id="institution-image" image={formData.imagePath} imagePath="imagePath" handleTextChange={handleChange} 
                  imageFieldName="image" handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}>
                </ImageTextfield>
                <div className="formField sendButton">
                  {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                    Submit
                  </Button>}

                </div>
          </form>
        </div>
      </div>
    );
  }

  const getInstitutionGrid = () => {
    return (
      <div className="institutionFormContainer">
      <span>Total =  {totalCount}</span>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Contact</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>Pin</StyledTableCell>
                <StyledTableCell>State</StyledTableCell>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {institutionList.map((institution) => (
                <StyledTableRow key={institution.name}>
                  <StyledTableCell>{institution.name}</StyledTableCell>
                  <StyledTableCell>{institution.contact}</StyledTableCell>
                  <StyledTableCell>{institution.phone}</StyledTableCell>
                  <StyledTableCell>{institution.email}</StyledTableCell>
                  <StyledTableCell>{institution.address}</StyledTableCell>
                  <StyledTableCell>{institution.city}</StyledTableCell>
                  <StyledTableCell>{institution.pin}</StyledTableCell>
                  <StyledTableCell>{institution.state}</StyledTableCell>
                  <StyledTableCell>{institution.country}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editInstitution(institution) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="institutionContainer">
      {showLoader ? <div className="showLoading"><Loader /></div>
        :
        <>
          {renderContact()}
          {getInstitutionGrid()}
        </>
      }
    </div>
  );
}


export default AdminInstitutions;


