import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../app-context';
import { CONTEST_TYPE_ACTIVITY, CONTEST_TYPE_QUIZ, EMPTY_STRING, GRADE_TYPE_GROUP, GRADE_TYPE_INDIVIDUAL, INSTITUTE_NAME_CUDENTS, RANGE_1, RANGE_2, RANGE_3 } from "../../../../components/constants/constants";
import { getActivityContestGrades } from '../../activity-contest-service';
import { getQuizContestGrades } from '../../quiz-contest-service';
import { getContestLeaderboard, getContestLeaderboardRange } from '../../contest-service';
import './cp-leaderboard.styles.scss';

const ContestLeaderboard = (props) => {

  const contestId = props.match.params.contestId;
  const contestType = props.match.params.contestType;
  const gradeType = props.match.params.gradeType;
  const winnerCount = +props.match.params.winnerCount;

  const [leaderboardData, setLeaderboardData] = useState(null);
  const [leaderboardInfo, setLeaderboardInfo] = useState(null);
  const [toppersInfo, setToppersInfo] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [currentGrade, setCurrentGrade] = useState(null);
  const [currentRange, setCurrentRange] = useState(RANGE_1.value);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (gradeList.length === 0 && GRADE_TYPE_INDIVIDUAL === gradeType) {
      loadGrade();
    }
    if (appContext.userData !== null && currentGrade === null) {
      setCurrentGrade(appContext.userData.grade);
    }
  }, []);

  useEffect(() => {
    if (currentGrade !== null || currentRange !== null) {
      setToppersInfo([]);
      searchLeaderboard();
    }
  }, [currentGrade, currentRange]);

  useEffect(() => {
    if (currentGrade !== null || currentRange !== null) {
      loadLeaderBoard();
    }
  }, [page]);

  const loadGrade = () => {
    if (contestType === CONTEST_TYPE_QUIZ) {
      getQuizContestGrades(contestId).subscribe({
        next: (response) => {
          setGradeList(response.data);
        }
      });
    } else {
      getActivityContestGrades(contestId).subscribe({
        next: (response) => {
          setGradeList(response.data);
        }
      });
    }
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const searchLeaderboard = () => {
    loadLeaderBoard();
  }

  const selectGrade = (grade) => {
    setPage(1);
    setPageCount(1);
    setCurrentGrade(grade);
  }

  const selectRange = (range) => {
    setPage(1);
    setPageCount(1);
    setCurrentRange(range);
  }

  const loadLeaderBoard = () => {
    if(GRADE_TYPE_INDIVIDUAL === gradeType){
      getContestLeaderboard(contestId, currentGrade, page - 1).subscribe({
        next: (response) => {
          updateLeaderboard(response);
        }
      });
    } else {
      getContestLeaderboardRange(contestId, currentRange, page - 1).subscribe({
        next: (response) => {
          updateLeaderboard(response);
        }
      });
    }
  }

  const updateLeaderboard = (response) => {
    setLeaderboardInfo(response.data);
    setLeaderboardData(response.data.models.data);
    // Extract toppers info in the first page
    if (page === 1 && response.data.models.data.length > 0) {
      let tempToppersInfo = [];
      for (let i = 0; i < winnerCount; i++) {
        if (response.data.models.data[i]) {
          tempToppersInfo.push(response.data.models.data[i]);
        }
      }
      if (winnerCount === 3) {
        setToppersInfo([...tempToppersInfo]);
      } else if(contestType === CONTEST_TYPE_ACTIVITY) {
        setToppersInfo([...tempToppersInfo]);
      } 
  
    }
    setPageCount(response.data.models.totalPages);
  }

  const getRankRowDetail = (rankInfo) => {
    // let msg = `Score: ${rankInfo.score}, Time Taken: `;
    // let min = Math.floor(rankInfo.timeTaken / 60);
    // let sec = rankInfo.timeTaken % 60;

    // if (min > 1) {
    //   msg = msg + min + ' Mins ';
    // } else if (min === 1) {
    //   msg = msg + min + ' Min ';
    // }
    // if (sec > 1) {
    //   msg = msg + sec + ' Secs';
    // } else if (sec === 1) {
    //   msg = msg + sec + ' Sec';
    // }
    return `Score: ${rankInfo.score}`;
  }

  const renderMedal = (rank) => {
    if(winnerCount > 3 && rank <= winnerCount){
      return (<img src="/assets/images/leaderboard/gold.png" alt="" />);
    } else if(winnerCount === 3 && contestType === CONTEST_TYPE_ACTIVITY){
      return (<img src="/assets/images/leaderboard/gold.png" alt="" />);
    } else {
      if (rank === 1) {
        return (<img src="/assets/images/leaderboard/gold.png" alt="" />);
      } else if (rank === 2) {
        return (<img src="/assets/images/leaderboard/silver.png" alt="" />);
      } else if (rank === 3) {
        return (<img src="/assets/images/leaderboard/bronze.png" alt="" />);
      } else {
        return (<img src="/assets/images/leaderboard/noMedal.png" alt="" />);
      }
    }
  }

  const getClass = (item) => {
    const grade = item > 0 ? item : item === -1 ? 2 : 1;
    return item < 0 ? 'KG - ' + grade : 'Class - ' + grade;
  }

  const rankingFilters = () => {
      return (
        <>
          <div className="leaderboard-title">
            {leaderboardInfo === null || INSTITUTE_NAME_CUDENTS === leaderboardInfo.institution ? EMPTY_STRING : leaderboardInfo.institution}
          </div>
          {GRADE_TYPE_GROUP === gradeType ?
            <div className="leaderboard-filters">
              <div className={RANGE_1.value === currentRange ? "leaderboard-filter-button active-grade" : "leaderboard-filter-button"} onClick={() => { selectRange(RANGE_1.value) }}>{RANGE_1.name}</div>
              <div className={RANGE_2.value === currentRange ? "leaderboard-filter-button active-grade" : "leaderboard-filter-button"} onClick={() => { selectRange(RANGE_2.value) }}>{RANGE_2.name}</div>
              <div className={RANGE_3.value === currentRange ? "leaderboard-filter-button active-grade" : "leaderboard-filter-button"} onClick={() => { selectRange(RANGE_3.value) }}>{RANGE_3.name}</div>
            </div>
            :
            <div className="leaderboard-filters">
              {gradeList.length === 0 ? 
                <div className="leaderboard-loading">Loading...</div>
                :
                gradeList.map((grade, idx) => (
                  <div key={idx} className={grade === currentGrade ? "leaderboard-filter-button active-grade" : "leaderboard-filter-button"} onClick={() => { selectGrade(grade) }}>{getClass(grade)}</div>
                ))
              }
            </div>
          }
        </>
      )
  }

  const rankingBoard = () => {
    if (leaderboardData === null) {
      return (
        <div className="leaderboard-toppers">
          {currentGrade === null ? <div className="leaderboard-loading"> <img src="/assets/images/leaderboard/point.png" alt="" />  <div>Select a class to view the leaderboard</div></div>
            :
            <div className="leaderboard-loading">Loading...</div>
          }
        </div>
      )
    } else {
      if (leaderboardData.length === 0) {
        return (<div className="leaderboard-no-ranking">No ranking as of now.</div>)
      } else {
        return (
          <>
            {contestType === CONTEST_TYPE_ACTIVITY ? <div className="leaderboard-winner">The Winners</div> : null}
            {rankingToppers()}
            {contestType === CONTEST_TYPE_QUIZ ? rankingPersonal() : null}
            {contestType === CONTEST_TYPE_QUIZ ? rankingList() : null}
          </>
        )
      }
    }
  }

  const rankingToppers = () => {
    if (toppersInfo.length > 0 && toppersInfo.length < 4) {
      return (
        <div className="leaderboard-toppers">
          {
            toppersInfo.map((rankInfo, idx) => {
              return (
                <div key={idx} className="leaderboard-toppers-card">
                  <div className="toppers-medal">
                    {renderMedal(rankInfo.rank)}
                  </div>
                  <div className="toppers-image">
                    <img src={rankInfo.imgPath} alt="" />
                  </div>
                  <div className="toppers-name">
                    {rankInfo.name}
                  </div>
                </div>
              )
            }
            )
          }
        </div>
      )
    } else if(contestType === CONTEST_TYPE_ACTIVITY) {
      return (
        <div className="leaderboard-rank-list">
          {toppersInfo.map((rankDetails, idx) => rankRow(rankDetails, true, idx))}
        </div>
      )
    }
  }

  const rankRow = (rankInfo, showBorder = false, idx) => {
    return (
      <div key={idx} className="leaderboard-rankRow" style={showBorder ? { borderBottom: "1px solid #ccc" } : null}>
        <div className="rankRow-left">
          <div className="leaderboard-rankRow-rank">
            {rankInfo.rank}
          </div>
          <div className="leaderboard-rankRow-image">
            <img src={rankInfo.imgPath} alt="" />
          </div>
          <div className="leaderboard-rankRow-info">
            <div>{rankInfo.name}</div>
            {contestType === CONTEST_TYPE_ACTIVITY ? <></> : 
            <div className="leaderboard-rankRow-info-details">
              {getRankRowDetail(rankInfo)}
            </div>}
          </div>
        </div>
        <div className="rankRow-right">
          <div className="leaderboard-rankRow-medal">
            {renderMedal(rankInfo.rank)}
          </div>
        </div>
      </div>
    )
  }

  const rankingPersonal = () => {
    if (leaderboardInfo === null || leaderboardInfo.rank === null
      || leaderboardInfo.rank === 'NA' || leaderboardInfo.rank === 'N/A') {
      return null;
    } else {
      return (
        <div className="leaderboard-my-rank">
          <div className="my-rank-image">
            <img src={appContext.userData.profilePicPath} alt="" />
          </div>
          <div className="my-rank-info">
            <div className="my-rank-name"><span>{leaderboardInfo.rank}</span>Your Rank</div>
            <div className="my-rank-score"><span>{leaderboardInfo.score}</span>Your Score</div>
          </div>
        </div>
      )
    }
  }

  const rankingList = () => {
    return (
      <div className="leaderboard-rank-list">
        {leaderboardData.map((rankDetails, idx) => rankRow(rankDetails, true, idx))}
        <div className="leaderboard-rank-list-pagination"><Pagination count={pageCount} page={page} onChange={handleChange} variant="outlined" color="secondary" /></div>
      </div>
    )
  }

  return (
    <div className="leaderboard-container">
      <Grid justifyContent="center" container spacing={3} xs={12}>
        <Hidden only={['xs', 'sm']}>
          <Grid item xs={0} sm={0} md={4} lg={5} xl={5}>
            <div className="leaderboard-image">
              <img src="/assets/images/leaderboard/leaderboard.png" alt="Leaderboard Banner Image"></img>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
          <div className="leaderboard-list-container">
            {rankingFilters()}
            {rankingBoard()}
          </div>
        </Grid>
      </Grid>
    </div>
  );

}

export default ContestLeaderboard;


