import React, { useContext, useEffect, useState } from 'react';
import './admin-question-bank.styles.scss';
import { isNull } from '../../services/util-service';
import { AppContext } from '../../app-context';
import { saveQuestion } from '../../feature/quiz/question-service';
import NewQuestionForm from './new-question-form.component';

const QuestionPanel = (props) => {

    const appContext = useContext(AppContext);
    const [formData, updateFormData] = useState(props.selectedQuestion);
    useEffect(() => {
        if (props.selectedQuestion != null) {
            updateFormData(props.selectedQuestion);
        }
    }, [props.selectedQuestion]);

    const onSubmit = (formData) => {
        if (isNull(formData.rowId) && formData.number > 15) {
            appContext.setDialogMessage('You can\'t add more than 15 question in this level');
        } else {
            saveQuestion(formData).subscribe({
                next: (response) => {
                    updateFormData(response.data);
                    appContext.setSuccessMessage(response.message);
                    props.onQuestionBankSelection();
                }, error: (error) => {
                    appContext.setErrorMessage(error.message);
                }
            });
        }
    }

    return <NewQuestionForm selectedQuestion={formData} questionNumber={props.questionNumber} params={props.params}
        onSubmit={onSubmit} />
}
export default QuestionPanel;