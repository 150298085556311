import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getLoggerTrend } from '../../../services/logging-service';
import Loader from '../../../components/loader/loader.component';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import './logging-trend.styles.scss';

const LoggingTrend = (props) => {
  const [orderList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
      getLoggerTrend(selectedPage - 1).subscribe({
        next: (response) => {
          setReportList(response.data.data);
          setPageCount(response.data.totalPages);
          setTotalCount(response.data.totalElements);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
        }});
  }

  const getScoreReportGrid = () => {
    return (
      <div>
      <div className="total-count">
        <span className="total-label">Total Count : </span>
        <span className="total-value">{totalCount}</span>
      </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Day</StyledTableCell>
                <StyledTableCell>User Count</StyledTableCell>
                <StyledTableCell>Service Count</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((user) => (
                <StyledTableRow key={user.name}>
                  <StyledTableCell>{moment(user.date).format('lll')}</StyledTableCell>
                  <StyledTableCell>{user.day}</StyledTableCell>
                  <StyledTableCell>{user.userCount}</StyledTableCell>
                  <StyledTableCell>{user.count}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="trend">
      {showLoader ? <div className="showLoading"><Loader /></div> : getScoreReportGrid()}
    </div>
  );
}

export default LoggingTrend;