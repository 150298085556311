import { Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import Loader from '../../../components/loader/loader.component';
import './active-users-content-report.styles.scss';
import { getActiveUsersContent } from '../../../feature/user-dashboard/dashboard-service';

const ActiveUserContentReport = (props) => {
  const [orderList, setReportList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState(60);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getActiveUsersContent(searchText).subscribe({
      next: (response) => {
        setReportList(response.data);
        // setPageCount(response.data.totalPages);
        // setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  
  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off">
        <div className="userControlBox">
          <div className="user-control">
            <TextField name="searchText" value={searchText} label="Search by Days" variant="outlined"
              size="small" fullWidth onChange={handleChange} />
          </div>
          <div className="user-control formButton">
            <Button type="submit" variant="contained" color="primary" onClick={search}>
              <SearchIcon /> Search
            </Button>
          </div>
        </div>
      </form>
    );
  }

  const getScoreReportGrid = () => {
    return (
      <div>
         {getFormPanel()}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Year</StyledTableCell>
                <StyledTableCell>Week</StyledTableCell>
                <StyledTableCell>Content Type</StyledTableCell>
                <StyledTableCell>Count</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList.map((user) => (
                <StyledTableRow key={user.name}>
                <StyledTableCell>{user.year}</StyledTableCell>
                <StyledTableCell>{user.week}</StyledTableCell>
                <StyledTableCell>{user.contentType}</StyledTableCell>
                <StyledTableCell>{user.count}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="active-users-content-report">
      {showLoader ? <div className="showLoading"><Loader /></div> : getScoreReportGrid()}
    </div>
  );
}

export default ActiveUserContentReport;