import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import './my-story-listing.styles.scss';
function MyStoryListing() {

  const [genderSelcted, setGenderSelected] = useState('female');
  const [storySelected, setStorySelected] = useState(null);
  const history = useHistory();
  var kidName = null;
  var friendName = null;

  const myStoryData = [
    {
      id: "1",
      title: "Best Friends",
      gender: "female",
      image: "https://img.freepik.com/free-vector/flat-friendship-day-illustration-with-friends_23-2149481319.jpg"
    },
    {
      id: "2",
      title: "Secret Super Girl",
      gender: "female",
      image: "https://img.freepik.com/free-vector/women-s-day_1188-92.jpg"
    },
    {
      id: "3",
      title: "Secret Super Hero",
      gender: "male",
      image: "https://img.freepik.com/free-vector/boy-wearing-superhero-with-stranglehold-standing-position-cartoon-character-portrait_1308-45926.jpg"
    },
    {
      id: "4",
      title: "Best Friends",
      gender: "male",
      image: "https://img.freepik.com/free-vector/flat-international-friendship-day-illustration_23-2148970595.jpg"
    },
    {
      id: "5",
      title: "Zoo Adventure",
      gender: "male",
      image: "https://img.freepik.com/free-vector/zoo-petting-zoo-cartoon-design-open-zoo-wild-animals-visitors_33099-173.jpg"
    },
    {
      id: "6",
      title: "Adventure Ride",
      gender: "female",
      image: "https://img.freepik.com/premium-vector/happy-girl-dressed-trendy-clothes-riding-city-bicycle-with-flower-bouquet-front-basket-adorable-young-hipster-woman-bike-cute-pedaling-female-bicyclist-flat-cartoon-vector-illustration_198278-12371.jpg"
    },
    {
      id: "7",
      title: "Father's Princess",
      gender: "female",
      image: "https://img.freepik.com/free-vector/father-s-day-background-with-happy-family-watercolor-style_23-2147815728.jpg"
    },
    {
      id: "9",
      title: "Secret Super Hero",
      gender: "male",
      image: "https://img.freepik.com/free-vector/boy-wearing-superhero-with-stranglehold-standing-position-cartoon-character-portrait_1308-45926.jpg"
    },
    {
      id: "10",
      title: "Best Friends",
      gender: "male",
      image: "https://img.freepik.com/free-vector/flat-international-friendship-day-illustration_23-2148970595.jpg"
    },
    {
      id: "11",
      title: "Zoo Adventure",
      gender: "male",
      image: "https://img.freepik.com/free-vector/zoo-petting-zoo-cartoon-design-open-zoo-wild-animals-visitors_33099-173.jpg"
    }
  ]

  const selectGender = (val) => {
    setGenderSelected(val);
    setStorySelected(null);
  }


  const navigateToStory = (storyVal) => {
    history.push(`/mystoryform/${storyVal}/${genderSelcted}`);
  }

  const handleNameChange = (e) => {
    kidName = e.target.value;
  }

  const handleFriendNameChange = (e) => {
    friendName = e.target.value;
  }

  const myStoryListing = () => {
    return <div className="my-story-listing-page">
    {/* <div className="my-story-top-section">
      <Lottie className="home-page-bottom-animation" animationData={writer} loop={true} />
    </div> */}
    <div className="my-story-create-new-section">
      Create New Story
    </div>
    <div className="my-story-gender-selection">
      <div className="my-story-gender-card" onClick={() => { selectGender('female') }}>
        {genderSelcted === 'female' ?
          <div className="my-story-gender-selected">
            <img src="/assets/images/homepage/newhome/newdesign/tick_icon.png" alt="" />
          </div> : <div>&nbsp;</div>}
        <div className="my-story-gender-icon">
          <img src="/assets/images/homepage/newhome/newdesign/girl_icon.png" alt="" />
        </div>
      </div>
      <div className="my-story-gender-card" onClick={() => { selectGender('male') }}>
        {genderSelcted === 'male' ?
          <div className="my-story-gender-selected">
            <img src="/assets/images/homepage/newhome/newdesign/tick_icon.png" alt="" />
          </div> : <div>&nbsp;</div>}
        <div className="my-story-gender-icon">
          <img src="/assets/images/homepage/newhome/newdesign/boy_icon.png" alt="" />
        </div>
      </div>
    </div>
    <div className="my-story-listing">
      {
        myStoryData.map((mystory, idx) => (
          <>
            {
              genderSelcted === mystory.gender ?
                <div key={idx} className="my-story-card" onClick={() => { navigateToStory(mystory.id) }}>
                  <div className="my-story-image">
                    <img src={mystory.image} alt="" />
                  </div>
                  <div className="my-story-title">
                    {mystory.title}
                  </div>
                </div> : null
            }
          </>
        ))
      }
    </div>
    <div className="my-story-create-new-section">
      My Existing Storiesg
    </div>
    <div className="my-story-listing existing-stories">
      {
        myStoryData.map((mystory, idx) => (
          <>
            {
              genderSelcted === mystory.gender ?
                <div key={idx} className="my-story-card" onClick={() => { navigateToStory(mystory.id) }}>
                  <div className="my-story-image">
                    <img src={mystory.image} alt="" />
                  </div>
                  <div className="my-story-title">
                    {mystory.title}
                  </div>
                </div> : null
            }
          </>
        ))
      }
    </div>
    {/* <div className="my-story-form">
      <div className="my-story-form-field">
        <TextField id="standard-basic" label="Your name" value={kidName} fullWidth variant="standard" onChange={handleNameChange} />
      </div>
      <div className="my-story-form-field">
        <TextField id="standard-basic" label="Your friend's name" value={friendName} fullWidth variant="standard" onChange={handleFriendNameChange} />
      </div>
      <div className="my-story-form-button" onClick={navigateToStory}>
        <span>Create My Story</span>
      </div>
    </div> */}
  </div>
  }

  return (
    // <>{myStoryListing()}</>
    <div className="coming-soon"><img src="/assets/images/site/comingsoon.webp" alt="" /></div>
  );
}

export default MyStoryListing;