
import Lottie from "lottie-react";
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../../app-context";
import Loader from "../../../components/loader/loader.component";
import { getAllMasterContests } from "../contest-service";
import { isNotEmptyArray } from "../../../services/util-service";
import success from "./animations/success.json";
import './contest-listing.styles.scss';
function ContestListing() {

  const history = useHistory();
  const appContext = useContext(AppContext);
  const [contestList, setContestList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (contestList.length === 0) {
      loadContestList();
    }
  }, []);

  const loadContestList = () => {

    getAllMasterContests().subscribe({next: response =>{
      setContestList(response.data);
      setShowLoader(false);
    },error: error =>{
      setShowLoader(false);
    }});
  }

  const navigateTo = (contest) => {
    history.push(`contests/${contest.rowId}`);
  }

  return (
    <>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        <div className="contest-listing-page">
          {isNotEmptyArray(contestList) ? <>
            <div className="contest-listing-page-top">
              <img src="/assets/images/homepage/newhome/newdesign/curve10.png" alt="" />
            </div>

            <div className="top-animation">
              <Lottie className="contest-page-top-animation" animationData={success} loop={true} />
            </div>
            <div className="contest-listing">
              {
                contestList.map((contest, idx) => (
                  <div key={idx} className="contest-card" onClick={() => { navigateTo(contest) }}>
                    <div className="card-title">{contest.contestName}</div>
                    <div className="contest-card-image">
                      <img src={contest.imagePath} alt="" />
                    </div>
                    {/* <div className="contest-title">
                      {contest.name}
                    </div> */}
                  </div>
                ))
              }
            </div></> : <div className="coming-soon"><img src="/assets/images/site/comingsoon.webp" alt="" /></div>}
        </div>}</>
  );
}

export default ContestListing;