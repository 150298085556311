import Grid from '@material-ui/core/Grid';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import React from 'react';
import './footer.styles.scss';

const AppFooter = () => {

  return (
    <div className="footerContainer">
      <div className="topArea">
        <Grid justifyContent="center" container spacing={3}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div>
              <div className="footerHeader">About Us</div>
              <div className="footerData">Cudents is an eLearning platform focusing on gamified and fun-filled Quizzes for pre-school and primary class students.</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div>
              <div className="footerHeader">Social Network</div>
              <div className="socialLinks">
                <div><a href="https://www.youtube.com/channel/UCFcW1xKJEJCfxZZ87jxIBMg"><img src="/assets/images/footer/youtube.png" alt="" /></a></div>
                <div><a href="https://www.facebook.com/cudents"><img src="/assets/images/footer/facebook.png" alt="" /></a></div>
                <div><a href="https://www.instagram.com/cudents.official/"><img src="/assets/images/footer/instagram.png" alt="" /></a></div>
                {/* <div><img src="/assets/images/footer/twitter.png" alt=""/></div>
              <div><img src="/assets/images/footer/linkedin.png" alt=""/></div> */}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div>
              <div className="footerHeader">Contact Info</div>
              <div className="footerData"><MailOutlinedIcon /> <span className="contactInfo">info@cudents.com</span></div>
              <div className="footerData"><PhoneAndroidOutlinedIcon /> <span className="contactInfo">+91 969 233 8030</span></div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="bottomArea">
        <div><a href="https://www.cudents.com/TnC/TermsAndConditions.html" target="_">Terms & Conditions</a></div>
        <div><a href="https://www.cudents.com/TnC/PrivacyPolicy.html" target="_">Privacy Policy</a></div>
        {/* <div><a href="https://www.cudents.com/TnC/Refund.html" target="_">Refund Policy</a></div> */}
      </div>
      <div className="copyright">Copyright © 2022 All Rights Reserved by www.cudents.com</div>
    </div>
  );
}

export default AppFooter;