import Lottie from "lottie-react";
import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import Loader from "../../components/loader/loader.component";
import { logService } from "../../services/logging-service";
import { findDailyRiddle, findRiddleByDate } from "./riddle-service";
import lionmain from "./animations/lion-main.json";
import './daily-riddle.styles.scss';
function DailyRiddle(props) {

  const riddleDate = props.match.params.riddleDate;
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [riddle, setRiddle] = useState(null);
  const [previousRiddle, setPreviousRiddle] = useState(null);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (riddle === null) {
      loadRiddle();
    }
  }, []);

  const loadRiddle = () => {
    setShowLoader(true)
    if (riddleDate) {
      let date = moment(riddleDate).format('DD/MM/yyyy');
      findRiddleByDate(date).subscribe({
        next: (response) => {
          setPreviousRiddle(response.data);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
          history.goBack();
        }});

    } else {
      findDailyRiddle().subscribe({
        next: (response) => {
          setRiddle(response.data.todayRiddle);
          setPreviousRiddle(response.data.yesterdayRiddle);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
          history.goBack();
        }});
    }

    logService("Riddle", riddleDate).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });

  }


  return (<>{showLoader ? <div className="showLoading">< Loader /></div>
    :
    <div className="riddle-page">
      <div className="blank-shadow">
      <div className="riddle-top-animation">
        <Lottie className="riddle-page-top-animation" animationData={lionmain} loop={true} />
      </div></div>
      {riddleDate ? null : <>{riddle === null ?
        <div className="no-riddle"> No Riddle for Today</div>
        :
        <div className="riddle-top-section">
          <div className="riddle-title">Today's Riddle </div>
          <div className="riddle-question">
            {riddle.question}

          </div></div>
      }</>}

      {previousRiddle !== null ?
        <div className="riddle-top-section previous-riddle no-shadow">
          {riddleDate ? <div className="riddle-number">
            #{previousRiddle.rowId}
          </div> :
            <div className="riddle-title">Yesterday's Riddle </div>}
          <div className="riddle-question">
            {previousRiddle.question}
          </div>
          <div className="riddle-answer">
            {previousRiddle.answer}
          </div></div>
        : null
      }
      <div className="riddle-history-button">
        <span onClick={() => { history.push(`/riddlehistroy`); }}>Previous Riddles</span>
      </div>


    </div>

  }
  </>);
}

export default DailyRiddle;