import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React from 'react';

import './image-selection.styles.scss';


export const ImageSelection = (props) => {

  const imageList = props.imageList;
  const handleClose = (onClose) => {
    onClose();
  }

  return <Dialog {...props}>
    <div className="avatarDialogContainer">
      <div className="closeButton">
        <CloseOutlinedIcon onClick={() => { handleClose(props.onClose) }} />
      </div>
      <div className="gridList">
        {imageList.map((tile) => (
          <div className="gridImage" onClick={() => { props.changeAvatar(tile.img); handleClose(props.onClose); }}>
            <img src={tile.img} alt={tile.title} />
          </div>
        ))}
      </div>
      <div className="avatar-cancel-button">
        <Button variant="contained" onClick={() => { handleClose(props.onClose) }} color="secondary">Cancel</Button>
      </div>
    </div>
  </Dialog>
}

export default ImageSelection;