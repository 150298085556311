import { Button, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import Loader from '../../components/loader/loader.component';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { exportTransactions, getTransactionsAdmin, validatePendingPayment } from '../../feature/payment/payment-service';
import { numberFormat } from '../../services/util-service';
import { EMPTY_STRING, TXN_STATUS } from '../../components/constants/constants';
import DateComponent from '../../components/date/date.component';
import './admin-payment.styles.scss';

const AdminPayment = (props) => {

  const defaultToDate = moment();
  const defaultFromDate = moment().subtract(1, 'months');
  const initData = {
    status: 'All', orderId: EMPTY_STRING, fromDate: defaultFromDate.toDate(), toDate: defaultToDate.toDate()
  };
  const [formData, updateFormData] = useState(initData);
  const [paymentList, setPaymentList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const appContext = useContext(AppContext);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const searchData = (event) => {
    event.preventDefault();
    if(page === 1) {
      search(1);
    } else {
      setPageCount(1);
      setPage(1);
    }
  }


  const search = (selectedPage) => {
    setShowLoader(true);
    getTransactionsAdmin(formData, selectedPage - 1).subscribe({
      next: (result) => {
        setPaymentList(result.data.content);
        setTotalCount(result.data.totalElements);
        setPageCount(result.data.totalPages);
        setShowLoader(false);
      }});
  }

  const exportToExcel = (event) => {
    setShowLoader(true);
    exportTransactions(formData).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Payments.xlsx');
        document.body.appendChild(link);
        link.click();
        setShowLoader(false);
      },error : (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validatePayment = (orderId) => {
    setShowLoader(true);
    validatePendingPayment(orderId).subscribe({
      next: (response) => {
        setShowLoader(false);
        appContext.setSuccessMessage(response.message);
        setPage(page);
      }, error : (error ) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }});
  }

  const getFormPanel = () => {
    return (
      <form noValidate autoComplete="off" onSubmit={searchData}>
        <div className="controlBox">
          <div className="paymentControl">
            <TextField name="status" value={formData.status} select label="Status" variant="outlined" size="small"
              fullWidth onChange={handleChange}>
              {TXN_STATUS.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="paymentControl">
            <TextField name="orderId" label="Cudents Order Id" variant="outlined" size="small"
              fullWidth value={formData.orderId} onChange={handleChange}
            />
          </div>
          <div className="paymentControl">
            <DateComponent name="fromDate" label="From Date" onDateSelection={handleChange} defaultDate={formData.fromDate ? moment(formData.fromDate) : null} />
          </div>
          <div className="paymentControl">
            <DateComponent name="toDate" label="To Date" onDateSelection={handleChange} defaultDate={formData.toDate ? moment(formData.toDate) : null} />
          </div>
          <div className="paymentControl sendButton">
            <Button type="submit" variant="contained" color="primary">
              <SearchIcon /> Search
            </Button>
          </div>
          <div className="paymentControl sendButton">
            <Button variant="contained" color="primary" onClick={exportToExcel}>
              <GetAppOutlinedIcon /> Export To Excel
            </Button>
          </div>
          <div className="paymentControl">
            <span className="paymentLabel">Total Payment Count  : </span>
            <span className="paymentValue">{totalCount}</span>
          </div>
        </div>
      </form>
    );
  }

  const getPaymentGrid = () => {
    return (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Order Id</StyledTableCell>
                <StyledTableCell>Cudents Order Id</StyledTableCell>
                <StyledTableCell align="right">Package Price</StyledTableCell>
                <StyledTableCell align="right">Taxable Amount</StyledTableCell>
                <StyledTableCell align="right">Discount</StyledTableCell>
                <StyledTableCell align="right">Discount Amount</StyledTableCell>
                <StyledTableCell align="right">Discounted Price</StyledTableCell>
                <StyledTableCell align="right">IGST Amount (18%)</StyledTableCell>
                <StyledTableCell align="right">Invoice Amount</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentList.map((payment) => (
                <StyledTableRow key={payment.name}>
                  <StyledTableCell>{payment.name}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">{payment.orderId}</StyledTableCell>
                  <StyledTableCell>{payment.cudentsOrderId}</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.packagePrice)}</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.taxableAmount)}</StyledTableCell>
                  <StyledTableCell align="right">{payment.couponDiscountPercentage}%</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.discount)}</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.discountedPrice)}</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.igstValue)}</StyledTableCell>
                  <StyledTableCell align="right">{numberFormat(payment.invoiceValue)}</StyledTableCell>
                  <StyledTableCell>{payment.status}</StyledTableCell>
                  <StyledTableCell>{moment(payment.creationDate).format('lll')}</StyledTableCell>
                  <StyledTableCell>{payment.status === 'Pending' ?
                    <Button onClick={() => { validatePayment(payment.orderId) }} variant="contained" color="secondary">
                      Validate
                    </Button>
                    : null}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="payment-main">
       <div className="payment-title">Payment</div>
      {getFormPanel()}
      {showLoader ? <div className="showLoading"><Loader /></div> : getPaymentGrid()}
    </div>
  );
}

export default AdminPayment;