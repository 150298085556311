import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post, remove } from "../../services/http-services";

export function findAllActivitiesByContestIdAndGrade(contestId, grade, pageNumber, sort) {
    return get(`/contest/activity/get?contestId=${contestId}&grade=${grade}&page=${pageNumber}&size=${PAGE_SIZE}&sort=${sort}`);
}
export function findAllActivitiesByContestIdAndGradeRange(contestId, gradeRange, pageNumber, sort) {
    return get(`/contest/activity/get/range?contestId=${contestId}&range=${gradeRange}&page=${pageNumber}&size=${PAGE_SIZE}&sort=${sort}`);
}

export function findActivityById(id) {
    return get(`/contest/activity/byid?id=${id}`);
}

export function saveActivity(videoModel) {
    return post("/contest/activity/save", videoModel);
}

export function saveAdminActivity(videoModel) {
    return post("/contest/activity/admin/save", videoModel);
}

export function deleteActivity(id) {
    return remove(`/contest/activity?id=${id}`);
}

export function startActivityContest(contestId) {
    return post(`/contest/activity/start?contestId=${contestId}`, null);
}

export function getActivityContestGrades(contestId) {
    return get(`/contest/activity/getgrades?contestId=${contestId}`);
}