import { Directory, Filesystem } from '@capacitor/filesystem';
import download from 'downloadjs';
import { Share } from '@capacitor/share';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../app-context';
import { CONTEST_TYPE_ACTIVITY, DOT, EMPTY_STRING, INSTITUTE_NAME_CUDENTS } from '../../../../components/constants/constants';
import Loader from '../../../../components/loader/loader.component';
import { getContestCertificate, getContestCertificateByUserId } from '../../contest-service';
import './cp-certificate.styles.scss';
import { isWebPlatform } from '../../../../services/util-service';

const ContestCertificate = (props) => {

  const contestId = props.match.params.contestId;
  const contestType = props.match.params.contestType;
  const gradeType = props.match.params.gradeType;
  const winnerCount = props.match.params.winnerCount;
  const userId = props.match.params.userId;
  const [certificate, setCertificate] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [shareUrl, setShareUrl] = useState(EMPTY_STRING);
  const [showLoaderBtn, setShowLoaderBtn] = useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (certificate.length === 0) {
      loadCertificate();
    }
  }, []);

  const loadCertificate = () => {
    if(userId) {
    getContestCertificateByUserId(contestId, userId).subscribe({
      next: (response) => {
        setCertificate(response.data);
        setShowLoader(false);
      }, error: (error) => {
        history.goBack();
        appContext.setDialogMessage(error.message);
      }
    });
    } else {
    getContestCertificate(contestId).subscribe({
      next: (response) => {
        setCertificate(response.data);
        setShowLoader(false);
      }, error: (error) => {
        history.goBack();
        appContext.setDialogMessage(error.message);
      }
    });
    }
  }

  const downloadCertificate = () => {
    setShowLoaderBtn(true);
    htmlToImage.toJpeg(document.getElementById('section-to-print'))
      .then(function (dataUrl) {
        Filesystem.writeFile({
          path: 'Certificate.jpeg',
          data: dataUrl,
          directory: Directory.External
        }).then((res) => {
          console.log(res);
          appContext.setDialogMessage('File saved in your Document folder');
          setShowLoaderBtn(false);
        }, (err) => {
          console.log(err);
          appContext.setDialogMessage('Error in download');
          setShowLoaderBtn(false);
        });
      });
  }

  

  const downloadWebCertificate = () => {
    htmlToImage.toPng(document.getElementById('section-to-print'))
   .then(function (dataUrl) {
     download(dataUrl, 'Certificate.png');
   });
   }

  const shareCertificate = () => {
    setShowLoaderBtn(true);
    htmlToImage.toJpeg(document.getElementById('section-to-print'))
      .then(function (dataUrl) {
        Filesystem.writeFile({
          path: 'Certificate.jpeg',
          data: dataUrl,
          directory: Directory.Cache
        }).then((res) => {
          console.log(res);
          setShareUrl(res.uri);
          share(res.uri);
          setShowLoaderBtn(false);
        }, (err) => {
          console.log(err);
          appContext.setDialogMessage('Error in sharing');
          setShowLoaderBtn(false);
        });
      });
  }

  const share = (uri) => {
    Share.share({
      title: 'Certificate',
      text: getTitle(),
      url: uri,
      dialogTitle: 'Share with buddies',
    }).then((res) => {
      console.log('Success  :  ', res);
    }, (err) => {
      console.log('Error  :  ', err);
    });
  }

  const getTitle = () => {
    return `My achievement on Cudents. Download Cudents app to provide a great learning and engagement platform to your kids. Link ${process.env.REACT_APP_SHARE_URL}.`;
  }

  const renderCertificateView = () => (
    <>
      <div className="contest-certificate-container">
        {certificate.rank <= winnerCount ? renderRankCertificate() : renderParticipationCertificate()}
      </div>
      {showLoaderBtn ? <div className="showLoading"><Loader /></div> :
        <div className="certificate-buttons">
          <span onClick={() => { shareCertificate() }}>Share</span>
          {/* <span onClick={() => { downloadCertificate() }}>Download</span> */}
          {isWebPlatform() ? <span onClick={() => { downloadWebCertificate() }}>Download</span> : <></>}
        </div>}
    </>
  )

  const getRank = (rank) => {

    switch (rank) {
      case 1:
        return 'First';
      case 2:
        return 'Second';
      case 3:
        return 'Third';
      default:
        return 'First';
    }
  }

  const getClass = (item) => {
    const grade = item > 0 ? item : item === -1 ? 2 : 1;
    return item < 0 ? 'KG - ' + grade : 'Class - ' + grade;
  }

  const getContestDescription = () => {
    if (contestType === CONTEST_TYPE_ACTIVITY) {
      return <> for winning </>
    } else {
      return <> for getting <span>{getRank(certificate.rank)}</span> rank in</>
    }
  }

  const renderRankCertificate = () => (
    <>
      <div className="contest-certificate" id="section-to-print">
        <div className="certificate-top-image">
          <div className="shape-1">&nbsp;</div>
          <div className="shape-2">&nbsp;</div>
        </div>
        <div className="certificate-header">
          <div className="certificate-header-ribbon"><img src="/assets/images/contestplatform/certificate_badge.png" alt="" /></div>
          <div className="certificate-header-text">CERTIFICATE OF <div>ACHIEVEMENT</div></div>
        </div>
        <div className="certificate-content">
          This Certificate is awarded to
          <div className="student-name">{certificate.participantName}</div>
          of <span>{getClass(certificate.grade)}</span>{getContestDescription()} the <b>{certificate.contestName}</b> competition organized by <b>CUDENTS</b>{INSTITUTE_NAME_CUDENTS ===certificate.institutionName ? DOT:  getAssociationDetail()}
        </div>
        <div className="certificate-date-signature">
          <div className="date-signature-container">
            <div className="value">{moment(certificate.date).format('DD-MM-YYYY')}</div>
            <div className="label">DATE</div>
          </div>
          <div className="date-signature-container">
            <div className="value"><img src="/assets/images/contestplatform/dg_sign.png"></img></div>
            <div className="label">SIGNATURE</div>
          </div>
        </div>
        <div className="certificate-footer">
          <div className="footer-logo"><img src="/assets/images/site/logo.png"></img></div>
          <div className="footer-shape">
            <div className="shape-1">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  )

  const renderParticipationCertificate = () => (
    <>
      <div className="contest-certificate" id="section-to-print">
        <div className="certificate-top-image">
          <div className="shape-1">&nbsp;</div>
          <div className="shape-2">&nbsp;</div>
        </div>
        <div className="certificate-header">
        <div className="certificate-header-ribbon"><img src="/assets/images/contestplatform/certificate_badge.png" alt="" /></div>
          <div className="certificate-header-text">CERTIFICATE OF <div>PARTICIPATION</div></div>
        </div>
        <div className="certificate-content">
          This Certificate is awarded to
          <div className="student-name">{certificate.participantName}</div>
          for participating in <b>{certificate.contestName}</b> competition organized by <b>CUDENTS</b> {INSTITUTE_NAME_CUDENTS ===certificate.institutionName ? DOT:  getAssociationDetail()}
        </div>
        <div className="certificate-date-signature">
          <div className="date-signature-container">
            <div className="value">{moment(certificate.date).format('DD-MM-YYYY')}</div>
            <div className="label">DATE</div>
          </div>
          <div className="date-signature-container">
            <div className="value"><img src="/assets/images/contestplatform/dg_sign.png"></img></div>
            <div className="label">SIGNATURE</div>
          </div>
        </div>
        <div className="certificate-footer">
          <div className="footer-logo"><img src="/assets/images/site/logo.png"></img></div>
          <div className="footer-shape">
            <div className="shape-1">&nbsp;</div>
          </div>
        </div>
      </div>
    </>
  )

  const getAssociationDetail = () => {
    return <>in association with
    <div className="school-info">
      <div className="school-logo"><img src={certificate.imagePath} alt="" /></div>
      <div className="school-name">{certificate.institutionName}</div>
    </div></>
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderCertificateView()
      }
    </div>
  );

}

export default ContestCertificate;