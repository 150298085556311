import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post, remove, whitelistGet } from "../../services/http-services";

export function findAllGame(classType, pageNumber) {
    return whitelistGet(`/game/page?classType=${classType}&page=${pageNumber}&size=${PAGE_SIZE}&sort=isFree,desc`);
}

export function findAllGameByGrade(classType, grade, pageNumber) {
    return get(`/game/bygrade?grade=${grade}&classType=${classType}&page=${pageNumber}&size=100&sort=isFree,desc`);
}

export function findAllGameForAdmin(classType, pageNumber) {
    return get(`/game/admin/page?classType=${classType}&page=${pageNumber}&size=${PAGE_SIZE}&sort=isFree,desc`);
}

export function findAllGames() {
    return get(`/game/all`);
}

export function findAllGamesByType(type) {
    return get(`/game/allbytype?type=${type}`);
}

export function findGameById(id) {
    return get(`/game/byid?id=${id}`);
}

export function saveGame(videoModel) {
    return post("/game", videoModel);
}

export function deleteGame(id) {
    return remove(`/game?id=${id}`);
}
export function getSelectGameLevels(name, grade, subject, topic) {
    return get(`/game/selectgame/levels?name=${name}&grade=${grade}&subject=${subject}&topic=${topic}`);
}

export function getSubjects(name, grade) {
    return get(`/game/selectgame/subject?name=${name}&grade=${grade}`);
}

export function getTopics(name, grade, subject) {
    return get(`/game/selectgame/topic?name=${name}&grade=${grade}&subject=${subject}`);
}

export function getGameAttempt(pageNumber) {
    return get(`/game/selectgame/attempts?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function findAllSelectGameLevels(name, grade, subject, topic) {
    return get(`/game/selectgame/findall?name=${name}&grade=${grade}&subject=${subject}&topic=${topic}`);
}

export function saveGameAttempt(attemptInputModel) {
    return post(`/game/selectgame/saveattempt`, attemptInputModel);
}

export function saveSelectGameQuestion(type, questionModel) {
    return post(`/game/selectgame/save?type=${type}`, questionModel);
}

export function approveGameQuestions(name, grade, subject, topic) {
    return post(`/game/selectgame/approve?name=${name}&grade=${grade}&subject=${subject}&topic=${topic}`, null);
}

export function getGameAttemptReport(userId, pageNumber) {
    return get(`/game/selectgame/report?userId=${userId}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getSelectGameHistory(questionId) {
    return get(`/game/selectgame/history?questionId=${questionId}`);
}

export function getInfiniteSubjects(gameId, grade) {
    return get(`/game/infinitegame/subject?gameId=${gameId}&grade=${grade}`);
}

export function getInfiniteTopics(gameId, grade, subject) {
    return get(`/game/infinitegame/topic?gameId=${gameId}&grade=${grade}&subject=${subject}`);
}

export function saveInfiniteGameAttemptAdmin(gameModel) {
    return post(`/game/infinitegame/save`, gameModel);
}

export function saveInfiniteGameAttempt(gameId, grade, subject, topic, score) {
    return post(`/game/infinitegame/saveattempt?gameId=${gameId}&grade=${grade}&subject=${subject}&topic=${topic}&score=${score}`, null);
}

export function getInfiniteGame(gameId, grade, subject, topic) {
    return get(`/game/infinitegame/bygameid?gameId=${gameId}&grade=${grade}&subject=${subject}&topic=${topic}`);
}

export function getAllInfiniteGame(pageNumber) {
    return get(`/game/infinitegame/page?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}