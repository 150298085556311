import { TextField } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useState } from 'react';
import { EMPTY_STRING } from '../../components/constants/constants';
import { getWhatsApp } from '../../services/util-service';
import './admin-whatsapp.styles.scss';

const AdminWhatsapp = (props) => {

  const [wpMessage, setWPMessage] = useState(EMPTY_STRING);
  const [mobile, setMobile] = useState(EMPTY_STRING);

  const handleMsgChange = (e) => {
    setWPMessage(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };



  const getFormPanel = () => {
    return (
      <div className="controlBox">
        <form noValidate autoComplete="off">
          <div className="admin-part-control">
            <TextField name="mobile" label="WhatsApp Number" required variant="outlined" size="small" fullWidth
              value={mobile} onChange={handleMobileChange}
            />
          </div>
          <div className="admin-part-control wp-box">
            <TextField name="message" label="WhatsApp Message" required multiline rows={20} variant="outlined" size="small" fullWidth
              value={wpMessage} onChange={handleMsgChange}
            />
          </div>
          <div className="admin-part-control sendButton">
            <a href={getWhatsApp(mobile, wpMessage)} target="_blank"><WhatsAppIcon />Send Message</a> 
          </div>
        </form >
      </div >
    );
  }

  return (
    <div className="whatsapp-main">
      <div className="whatsapp-title">Whatsapp message</div>
      {getFormPanel()}
    </div>
  );
}

export default AdminWhatsapp;