import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DateComponent from '../../../components/date/date.component';
import avatarList from '../../../components/image-selection/avatar-list';
import ImageSelection from '../../../components/image-selection/image-selection.component';
import { EMPTY_STRING, GRADE_LIST, MANDATORY_FIELD_MSG, REFERRAL_CODE } from '../../../components/constants/constants';
import { doSignOut } from '../../../components/firebase/firebase.service';
import Loader from '../../../components/loader/loader.component';
import { findByUser, saveUserProfile } from '../user-service';
import './student-profile.styles.scss';
import { AppContext } from '../../../app-context';

const StudentProfile = () => {

  const initProfile = {
    userIdentification: EMPTY_STRING, name: EMPTY_STRING, fatherName: EMPTY_STRING,
    motherName: EMPTY_STRING, emailId: EMPTY_STRING, grade: 1,
    school: EMPTY_STRING, aboutMe: EMPTY_STRING, provider: EMPTY_STRING, referralCode: EMPTY_STRING
  };
  const location = useLocation();
  const history = useHistory();
  const appContext = useContext(AppContext);
  const genderList = [EMPTY_STRING, 'Male', 'Female'];
  const avatarImages = avatarList;
  const [showAvatarDialog, setShowAvatarDialog] = useState(false);
  const [formError, setFormError] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [formData, updateFormData] = useState(initProfile);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (formData.userIdentification === EMPTY_STRING) {
      loadProfile();
    }
    if (formData.referralCode === EMPTY_STRING) {
      updateReferralCode();
    }
    return () => {
      appContext.setShowHeader(true)
      appContext.setShowFooter(true)
    }
  }, []);

  const updateReferralCode = () => {
    const refCode = sessionStorage.getItem(REFERRAL_CODE);
    const code = 'referralCode';
    if (refCode) {
      updateFormData({
        ...formData,
        [code]: refCode
      });
    }
  }

  const isFirstTime = () => {
    return location.pathname === '/profile';
  }

  const loadProfile = () => {
    if (isFirstTime()) {
      appContext.setShowHeader(false);
      appContext.setShowFooter(false);
      setShowLoader(false);
    } else {
      findByUser().subscribe({
        next: (response) => {
          updateFormData(response.data);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
        }});
    }
  }

  const changeAvatar = (imgPath) => {
    updateFormData({
      ...formData,
      imagePath: imgPath
    });
  }

  const saveProfile = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      saveUserProfile(formData).subscribe({
        next: (response) => {
          updateFormData(response.data);
          appContext.setSuccessMessage(response.message);
          const tempUser = {
            ...appContext.userData,
            profilePicPath: response.data.imagePath,
            displayName: response.data.name,
            grade: response.data.grade,
            provider: response.data.provider
          };
          appContext.setUserData(tempUser);
          if (isFirstTime()) {
            setShowSuccess(true);
          } else {
            history.push('/userdashboard/userprofile');
          }
        }});
    }
  }

  const handleLogout = () => {
    doSignOut();
    onLogoutSuccess();
  }

  const onLogoutSuccess = () => {
    appContext.setUserData(null);
    localStorage.clear();
    history.push('/');
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["grade"]) {
      formIsValid = false;
      errors["grade"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const changeCoupon = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.toUpperCase()
    });
  };

  const getEditName = (label) => {
    return isFirstTime() ? `${label} (Edit ${label})` : label;
  }

  const getSaveBtnText = () => {
    return isFirstTime() ? 'Proceed' : 'Save';
  }

  const rightFormPanel = () => {
    return (<Grid item xs={12} sm={8} md={8} lg={8} xl={8} className="rightArea">
      <div>
        <form noValidate autoComplete="off">

          <div className="formField"><TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange} required fullWidth label={getEditName("Kid's Name")} variant="outlined" size="small" />
            <span className="error-text">{formError["name"]}</span>
          </div>
          <div className="formField"><TextField name="grade" value={formData.grade || EMPTY_STRING} select label={getEditName("Class")} required onChange={handleChange} fullWidth variant="outlined" size="small">
          {GRADE_LIST.map((grade) => (
            <MenuItem key={grade.value} value={grade.value}>
              {grade.name}
            </MenuItem>
          ))}
          </TextField>
            <span className="error-text">{formError["grade"]}</span>
          </div>

          {isFirstTime() ? <div className="formField"><TextField name="referralCode" value={formData.referralCode} onChange={changeCoupon} fullWidth label="Referral Code" variant="outlined" size="small" />
          </div> : <div>
            <div className="formField"><TextField name="userIdentification" value={formData.userIdentification || EMPTY_STRING} onChange={handleChange} disabled={true} required fullWidth label="User Id" variant="outlined" size="small" /></div>

            <div className="formField"><TextField name="gender" value={formData.gender} select label="Gender" onChange={handleChange} fullWidth variant="outlined" size="small">
              {genderList.map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>
            </div>
            <div className="formField">
              <DateComponent name="dateOfBirth" label="Date of Birth" onDateSelection={handleChange} defaultDate={formData.dateOfBirth ? moment(formData.dateOfBirth) : null} />
            </div>
            <div className="formField"><TextField name="fatherName" value={formData.fatherName} onChange={handleChange} fullWidth label="Father's Name" variant="outlined" size="small" /></div>
            <div className="formField"><TextField name="motherName" value={formData.motherName} onChange={handleChange} fullWidth label="Mother's Name" variant="outlined" size="small" /></div>
            <div className="formField"><TextField name="emailId" value={formData.emailId || EMPTY_STRING} onChange={handleChange} disabled={formData.provider !== 'phone'} required fullWidth label="Email Id" variant="outlined" size="small" /></div>
            <div className="formField">
              <TextField name="school" value={formData.school} onChange={handleChange} fullWidth label="School" variant="outlined" size="small" /></div>
            <div className="formField">
              <TextField name="mobile" value={formData.mobile} onChange={handleChange} fullWidth label="Mobile Number" disabled={formData.provider === 'phone'} variant="outlined" size="small" /></div>
            <div className="formField">
              <TextField name="address" value={formData.address} label="Address" fullWidth multiline rows={4} onChange={handleChange} variant="outlined" size="small" />
            </div>
            <div className="formField">
              <TextField name="aboutMe" value={formData.aboutMe} label="About Kid" fullWidth multiline rows={4} onChange={handleChange} variant="outlined" size="small" />
            </div>
          </div>
          }
          <div className="formField saveButton">
            <Button type="submit" variant="contained" color="primary" onClick={saveProfile}>
              {getSaveBtnText()}
            </Button>
          </div>
        </form>
      </div>
    </Grid>);
  }

  const leftFormPanel = () => {
    return (<Grid item xs={12} sm={4} md={4} lg={4} xl={4} className="leftArea">
      <div className="profileImage">
        <img src={formData.imagePath} alt="" />
        {!isFirstTime() ?
          <div className="editAvatar" onClick={() => (setShowAvatarDialog(true))}>Change Avatar</div>
          : null
        }
      </div>
    </Grid>);
  }



  const renderProfile = () => {
    return (
      <div>
        {isFirstTime() ?
          <>
            {/* <div className="back-gradient">&nbsp;</div> */}
            <div className="corner-image"><img src="/assets/images/profile/topcorner.png" alt="" /></div>
            <div className="left-corner-image"><img src="/assets/images/profile/leftcorner.png" alt="" /></div>
            {!showSuccess ?
              <div className="sign-up-details-form">
                <div className="welcomePanel">
                  <div className="welcome-message">Welcome to Cudents
                    <div className="welcome-free-credits">Your free trial has been activated</div>
                  </div>
                </div>
                <Grid container justify="center" xs={12}>
                  <Grid item xs={11} sm={11} md={8} lg={6} xl={6}>
                    <div className="basic-details-form">
                      <div className="welcome-instructions">Kindly fill in the basic details to proceed</div>
                      <Grid container className="formContainer" xs={12}>
                        {leftFormPanel()}
                        {rightFormPanel()}
                        <div className="display-image-title">
                          Choose Your Avatar
                        </div>
                        <Grid container xs={12}>
                          {avatarImages.map((tile) => (
                            <Grid item xs={3} sm={3} md={2} lg={1} xl={1} onClick={() => { changeAvatar(tile.img); }}>
                              <img src={tile.img} alt={tile.title} />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <div className="welcomePanel">
                        <Button onClick={handleLogout} variant="contained" color="secondary">
                          Logout
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div> :
              <div className="sign-up-details-form">
                <div className="basic-details-form">
                  <div className="formContainer profile-success">
                    <div className="profile-success-image">
                      <img src="/assets/images/profile/profileSuccess.png" alt="" />
                    </div>
                    <div className="profile-success-message">Profile updated successfully</div>
                    <div className="profile-success-buttons">
                      {/* <div className="success-button" onClick={() => { history.push('/assessment') }} ><span>Take Free Assessment</span></div> */}
                      <div className="success-button" onClick={() => { history.push('/userdashboard') }}><span>Go To Dashboard</span></div>
                    </div>
                  </div>
                </div>
              </div>
            }

          </>
          :
          <Grid container className="formContainer" xs={12}>
            {leftFormPanel()}
            {rightFormPanel()}
          </Grid>

        }
      </div >
    );
  }

  return (
    <>
      {showLoader ? <Loader /> :
        <div className={isFirstTime() ? "edit-profile-container profile-background-gradient" : "edit-profile-container"}>
          {renderProfile()}
          <ImageSelection open={showAvatarDialog} onClose={() => { setShowAvatarDialog(false) }} changeAvatar={changeAvatar} imageList={avatarImages}/>
        </div>
      }
    </>
  );
}


export default StudentProfile;


