import { Capacitor } from "@capacitor/core";
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { EMPTY_STRING } from "../components/constants/constants";

export function Rif({ boolean, ...props }) {
    const { children } = props;
    if (boolean)
        return (
            { ...children }
        );
    return null;
}

export function isNull(value) {
    return value === undefined || value === null;
}

export function isNotEmptyArray(value) {
    return !isNull(value) && value.length > 0;
}

export function isNullCheck(value) {
    return value === undefined || value === null || value === 0;
}


export function isNullData(data) {
    return data === undefined || data === null || data === EMPTY_STRING || data == {};
}

export function isNotNull(value) {
    return !isNull(value);
}

export function isEmpty(value) {
    return isNull(value) || value === EMPTY_STRING;
}

export function isNotEmpty(value) {
    return !isEmpty(value);
}

export const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(value);

export const showDataDash = (value) => {
    if (value !== null && value !== undefined && value !== '' && value !== ' ' && value !== 'Invalid date') {
        return value;
    }
    return '-';
}

export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

export function removeSpaces(array) {
    let temp = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i] !== '' && array[i] !== ' ') {
            temp.push(array[i]);
        }
    }
    return temp;
}

export function speakTheText(value) {
    TextToSpeech.speak({
        text: value,
        lang: 'en-US',
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
        category: 'ambient',
    });
};

export function replaceUnderscore(inputString) {
    let array = inputString.split(' ')
    let result = EMPTY_STRING;
    for (let i = 0; i < array.length; i++) {
        let mapping = array[i];
        if (mapping.includes('__')) {
            mapping = 'dash';
        }
        result = result.concat(mapping, ' ');
    }
    return result;
}

export function getWhatsAppMessage(phoneNumber) {

    const textMsg = 'Hi Sir/Madam,\r\n\r\n    If you have created an account in Cudents for Curathon contest, kindly follow the below steps to register for the contest:\r\n\r\n' +
        '1. Login to cudents and visit the link https://www.cudents.com/contests  (or go to menu->Contests)\r\n' +
        '2. Click on your school contest card displayed in the contests list.\r\n' +
        '3. Fill the form with required details i.e. Class, Name etc.\r\n' +
        '4. Enter the contest code provided by your school (Check the poster of contest sent by your school)\r\n' +
        '5. Click Register button.\r\n\r\n' +
        'After that you will see a deactivated Start button, which will get activated at the scheduled time of contest.\r\n\r\n' +
        'Regards,\r\n\r\nTeam Cudents'

    return getWhatsApp(phoneNumber, textMsg);
}

export function getWhatsApp(phoneNumber, message) {
    const phone = isNotEmpty(phoneNumber) ? phoneNumber : EMPTY_STRING;
    const whatsappMessage = window.encodeURIComponent(message);
    return `https://wa.me/${getCorrectPhone(phone)}?text=${whatsappMessage}`;
}

export function getCorrectPhone(phoneNumber) {
    if (phoneNumber.length === 10) {
        phoneNumber = '+91' + phoneNumber;
    }
    return phoneNumber;
}

export function isWebPlatform() {
    return Capacitor.getPlatform() === 'web';
}

