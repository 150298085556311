import { Button, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { useStyles } from '../../services/grid-style';
import { getRedemptionRequests, saveRedemptionDetail } from '../../feature/redeem/redeem-service';
import { EMPTY_STRING, REDEEM_STATUS } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import './admin-redeem.styles.scss';

const AdminRedeem = (props) => {

  const defaultToDate = moment();
  const defaultFromDate = moment().subtract(1, 'months');
  const initData = {
    status: 'Requested', redemptionId: EMPTY_STRING, fromDate: defaultFromDate.toDate(), toDate: defaultToDate.toDate()
  };
  const [expanded, setExpanded] = useState(false);
  const [formData, updateFormData] = useState(initData);
  const [redeemList, setRedeemList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const searchData = (event) => {
    event.preventDefault();
    search(1);
  }


  const search = (selectedPage) => {
    setShowLoader(true);
    getRedemptionRequests(formData.redemptionId, formData.status, selectedPage - 1).subscribe({
      next: (result) => {
        setRedeemList(result.data.data);
        setTotalCount(result.data.totalElements);
        setPageCount(result.data.totalPages);
        setShowLoader(false);
      }});
  }

  const saveRedeemDetail = (detail) => {
    setShowLoader(true);
    saveRedemptionDetail(detail).subscribe({
      next: (result) => {
        appContext.setSuccessMessage(result.message);
        setShowLoader(false);
      }, error : (error ) => {
        appContext.setErrorMessage(error.message);
      }});
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEdit = (user) => {
    const newList = redeemList.map((redeem) => {
      redeem.redemptionDetails.map((item) => {
        if (item.rowId === user.rowId) {
          const updatedItem = {
            ...item,
            status: user.status
          };

          return updatedItem;
        }

        return item;
      });
      return redeem;
    });
    setRedeemList(newList);
  };


  const getFormPanel = () => {
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={searchData}>
          <div className="redeemControl">
            <div className="redeemControl">
              <TextField name="status" value={formData.status} select label="Status" variant="outlined" fullWidth size="small" onChange={handleChange}>
                {REDEEM_STATUS.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className="redeemControl">
              <TextField name="redemptionId" label="Redeem Id" variant="outlined" size="small" fullWidth
                value={formData.redemptionId} onChange={handleChange}
              />
            </div>

            <div className="redeemControl sendButton">
              <Button type="submit" variant="contained" color="primary">
                <SearchIcon /> Search
              </Button>
            </div>
            <div className="total-count">
              <span className="total-label">Total Count : </span>
              <span className="total-value">{totalCount}</span>
            </div>
          </div>
        </form>
      </>
    );
  }

  const getRedeemGrid = () => {
    return (
      <div className="redeem-grid">
        {
          <div className="redeem-history-section">
            <div className="redeem-list">{redeemList.map(redeem => (
              <Accordion expanded={expanded === redeem.redemption.rowId} onChange={handleExpansion(redeem.redemption.rowId)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="redeem-heading">
                    <div className="heading-name">{redeem.redemptionDetails.length > 0 ? redeem.redemptionDetails[0].name : EMPTY_STRING} ({redeem.redemption.redemptionId})</div>
                    <div className="spacing"></div> <div className="headingDate">{moment(redeem.redemption.creationDate).format('lll')}</div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="message">
                  {redeem.redemptionDetails.map(detail => (
                    <>
                      <div className="redeem-list-row">
                        <div className="heading-name">Redemption Id: <span>{detail.redemptionId}</span></div>
                        <div className="heading-name">Name: <span>{redeem.redemption.name}</span></div>
                        <div className="heading-name">Mobile: <span>{redeem.redemption.mobile}</span></div>
                        <div className="heading-name">Address: <span>{redeem.redemption.address}</span></div>
                        <div className="heading-name">Value: <span>{detail.value}</span></div>
                        <div className="redeem-status-form">
                          <TextField name="status" value={detail.status} select label="Status" onChange={(e) => { detail.status = e.target.value; handleEdit(detail); }}>
                            {REDEEM_STATUS.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Button variant="contained" color="primary" onClick={() => { saveRedeemDetail(detail) }}>Save</Button>
                        </div>
                      </div>
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}</div>
          </div>
        }

      </div>
    );
  }

  const renderView = () => {
    return (
      <div>
        <Hidden only={['xs', 'sm']}>
          <div className="redeemContainer">
            {getRedeemGrid()}
          </div>
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
          <div className="redeemContainer redeemContainerMobile">
            {getRedeemGrid()}
          </div>
        </Hidden>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-redeem-container">
      {getFormPanel()}
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default AdminRedeem;