import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Hidden from '@material-ui/core/Hidden';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { findAllFeedback } from '../../feature/feedback/feedback-service';
import Loader from '../../components/loader/loader.component';
import './feedback.styles.scss';
import moment from 'moment'

const AdminFeedback = () => {

  const [expanded, setExpanded] = useState(false);
  const [feedbackList, updateFeedbackList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    loadFeebback();
  }, [page]);


  const loadFeebback = () => {
    setShowLoader(true);
    findAllFeedback(page - 1).subscribe({
      next: (response) => {
        updateFeedbackList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }});
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const renderSupport = () => {
    return (
      <div>
        {
          <div className="faqSection">
          <div className="total-count">
            <span className="total-label">Total Count : </span>
            <span className="total-value">{totalCount}</span>
          </div>
            <div className="faqList">{feedbackList.map(feedback => (
              <Accordion expanded={expanded === feedback.rowId} onChange={handleChange(feedback.rowId)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="heading"><div className="headingName">{feedback.name}</div> <div className="headingEmail">&nbsp;{"<"}{feedback.emailId}{">"}</div>
                    <div className="spacing"></div> <div className="headingDate">{moment(feedback.creationDate).format('lll')}</div></div>
                </AccordionSummary>
                <AccordionDetails className="message">
                  {feedback.message}
                </AccordionDetails>
              </Accordion>
            ))}</div>
          </div>
        }
      </div>
    );
  }

  const renderView = () => {
    return (
      <div className='admin-feedback'>
        <Hidden only={['xs', 'sm']}>
          <div className="supportContainer">
            {renderSupport()}
          </div>
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
          <div className="supportContainer supportContainerMobile">
            {renderSupport()}
          </div>
        </Hidden>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default AdminFeedback;