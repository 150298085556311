import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post, remove, whitelistGet } from "../../services/http-services";

export function findGuessMasterSubjectsAdmin() {
    return get(`/guessmaster/all/admin`);
}
export function findAllGuessMaster() {
    return whitelistGet(`/guessmaster/findall`);
}
export function findGuessMasterSubjects() {
    return whitelistGet(`/guessmaster/all`);
}

export function findAllGuessMasterTopicAdmin(pageNumber) {
    return get(`/guessmaster/alltopic/admin/page?page=${pageNumber}&size=${PAGE_SIZE}&sort=isFree,desc`);
}

export function findAllGuessMasterTopicsAdmin() {
    return get(`/guessmaster/alltopic/admin`);
}

export function findGuessMasterTopicBySubjectAdmin(subject) {
    return get(`/guessmaster/alltopicbysubject/admin?subject=${subject}`);
}

export function findGuessMasterTopic(subject) {
    return get(`/guessmaster/alltopic?subject=${subject}`);
}

export function findGuessMasterById(id) {
    return get(`/guessmaster/byid?id=${id}`);
}

export function saveGuessMaster(guessMasterModel) {
    return post("/guessmaster", guessMasterModel);
}

export function deleteGuessMaster(id) {
    return remove(`/guessmaster?id=${id}`);
}

export function findAllGuessQuestion() {
    return get(`/guessquestion/get/all`);
}

export function findAllPageGuessQuestionAdmin(guessMasterId, isReviewed, pageNumber) {
    return get(`/guessquestion/get/admin?guessMasterId=${guessMasterId}&isReviewed=${isReviewed}&page=${pageNumber}&size=${PAGE_SIZE}&sort=isFree,desc`);
}

export function findAllGuessQuestionAdmin(guessMasterId, isReviewed) {
    return get(`/guessquestion/get/admin?guessMasterId=${guessMasterId}&isReviewed=${isReviewed}`);
}

export function findAllPageGuessQuestion(guessMasterId) {
    return get(`/guessquestion/get?guessMasterId=${guessMasterId}`);
}

export function findAllAdminGuessQuestion() {
    return get(`/guessquestion/get/all`);
}

export function findGuessQuestionById(id) {
    return get(`/guessquestion/byid?id=${id}`);
}

export function saveGuessQuestion(guessQuestionModel) {
    return post("/guessquestion/save", guessQuestionModel);
}

export function deleteGuessQuestion(id) {
    return remove(`/guessquestion?id=${id}`);
}

export function approveGuessQuestions() {
    return get(`/guessquestion/approve`);
}