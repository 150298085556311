import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { default as React } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import TitlePanel from '../title-panel/title-panel.component';
import './grid.styles.scss';
import { isNotEmptyArray } from '../../services/util-service';

const GridComponent = (props) => {
  const classes = useStyles();
  const renderGrid = () => {
    return <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {props.colDef.map((col) => (<StyledTableCell>{col['headerName']}</StyledTableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isNotEmptyArray(props.gridData) ? props.gridData.map((data, index) => (
              <StyledTableRow key={index}>
                {props.colDef.map((col) => <>{col.content ? <StyledTableCell>{col.content(data)}</StyledTableCell> : <StyledTableCell>{data[col.headerObj]}</StyledTableCell>}</>)}
              </StyledTableRow>

            )) : <>No rows</>}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={props.pageCount} page={props.page} variant="outlined" color="secondary" onChange={props.handlePageChange} />
    </div>
  }

  return <TitlePanel panelBody={renderGrid()} title={props.title} />

}

export default GridComponent;