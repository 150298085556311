import { PAGE_SIZE } from "../../../components/constants/constants";
import { get } from "../../../services/http-services";

export function getAttempt(grade, subject, topic, level, attemptId) {
    return get(`/attempt?grade=${grade}&subject=${subject}&topic=${topic}&level=${level}&attemptId=${attemptId}`);
}

export function getAttempts(grade, subject, topic, level) {
    return get(`/attempt/attempts?grade=${grade}&subject=${subject}&topic=${topic}&level=${level}`);
}

export function getLeaderBoardPeriod(grade, year, month, day, pageNumber) {
    return get(`/score/leaderboardperiod?grade=${grade}&year=${year}&month=${month}&day=${day}&page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function getLeaderBoard(grade, subject, pageNumber) {
    return get(`/score/leaderboard?grade=${grade}&subject=${subject}&page=${pageNumber}&size=${PAGE_SIZE}`);
}

export function getScoreReport(pageNumber) {
    return get(`/score/report?page=${pageNumber}&size=${PAGE_SIZE}`);
}
export function getAttemptReport(pageNumber) {
    return get(`/score/details?page=${pageNumber}&size=${PAGE_SIZE}`);
}
export function getAttemptTrend(pageNumber) {
    return get(`/score/trend?page=${pageNumber}&size=${PAGE_SIZE}`);
}
export function getRank(grade, subject) {
    return get(`/score/rank?grade=${grade}&subject=${subject}`);
}
export function getRankPeriod(grade, year, month, day) {
    return get(`/score/rankperiod?grade=${grade}&year=${year}&month=${month}&day=${day}`);
}

export function getQuizAttemptReport(userId, pageNumber) {
    return get(`/score/report/quiz?userId=${userId}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getActivityTrend(period) {
    return get(`/score/activitytrend?period=${period}`);
}
