const groupImages = [
  {
    img: "https://www.cudents.com/prod/images/groups/group_img1.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img2.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img3.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img4.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img5.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img6.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img7.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img8.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img9.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img10.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img11.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img12.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img13.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img14.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img15.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img16.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img17.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img18.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img19.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img20.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img21.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img22.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img23.png"
  },
  {
    img: "https://www.cudents.com/prod/images/groups/group_img24.png"
  }
];

export default groupImages;