import './my-story-form.styles.scss';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

function MyStoryForm() {

  const myStoryData = {
    id: "1",
    title: "Best Friends",
    gender: "female",
    image: "https://img.freepik.com/free-vector/flat-friendship-day-illustration-with-friends_23-2149481319.jpg",
    summary: "This story is about two best friends. How they helped each other during a tough situation to fight back to the victory.",
    charactersNeeded: 3
  }

  var kidName = null;
  const history = useHistory();

  const handleNameChange = (e) => {
    kidName = e.target.value;
  }

  const navigateToStory = () => {
    history.push(`/mystory/${myStoryData.id}/${kidName}`);
  }

  return (
    <div className="my-story-form-page">
      <div className="my-story-form-top-image">
        <img src={myStoryData.image} alt="" />
      </div>
      <div className="my-story-form-title">
        {myStoryData.title}
      </div>
      <div className="my-story-form-summary">
        {myStoryData.summary}
      </div>
      <div className="my-story-form-container">
        <div className="my-story-form-character-name">
          <TextField id="standard-basic" label="Enter Main Character's Name" value={kidName} fullWidth variant="standard" onChange={handleNameChange} />
        </div>
        <div className="my-story-form-button">
          <span onClick={navigateToStory}>Create My Story</span>
        </div>
      </div>
    </div>
  );
}

export default MyStoryForm;