import { TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from "react-router";
import { AppContext } from "../../app-context";
import { signin } from "../../feature/user-dashboard/user-service";
import { isEmpty } from "../../services/util-service";
import { ACCESS_TOKEN, CONTEST_CODE, EMPTY_STRING, MANDATORY_FIELD_MSG } from "../../components/constants/constants";
import phoneNumber from "../../components/phone-number/phone-number";
import Lottie from "lottie-react";
import loginAnimation from "./animations/login.json";
import ChangePasswordComponent from "./change-password.component";
import './login.scss';

const PhoneSigninComponent = (props) => {

  const contestCode = sessionStorage.getItem(CONTEST_CODE);
  const initProfile = {
    name: EMPTY_STRING, confirmPassword: EMPTY_STRING, password: EMPTY_STRING
  };
  const [formError, setFormError] = useState({});
  const history = useHistory()
  const [formData, updateFormData] = useState(initProfile);
  const appContext = useContext(AppContext);
  const [phone, setPhone] = useState(props.phone);
  const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
  const [isSignin, setSignin] = useState(true);

  const onCudentsLogin = () => {

    if (handleValidation()) {
      const userModel = {
        userId: phone,
        password: formData.password
      }
      login(userModel);
    }
  }

  const login = (userModel) => {

    signin(userModel).subscribe({
      next: (response) => {
        localStorage.setItem(ACCESS_TOKEN, response.data.authToken);
        appContext.setUserData(response.data);
        if (isEmpty(contestCode)) {
          history.push('/');
        } else {
          history.push(`/contest/code/${contestCode}`);
        }
      }, error: (error) => {
        setErrorMessage(error.message);
      }
    });
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const openChangePasswordForm = () => {
    setSignin(false);
    setErrorMessage(EMPTY_STRING);
  }
  const openSigninForm = () => {
    setSignin(true);
    setErrorMessage(EMPTY_STRING);
  }

  const handleValidation = () => {
    setErrorMessage(EMPTY_STRING);
    let errors = {};
    let formIsValid = true;
    if (!phone) {
      formIsValid = false;
      errors["phoneNumber"] = MANDATORY_FIELD_MSG;
    } else if (!isValidPhoneNumber(phone)) {
      formIsValid = false;
      errors["phoneNumber"] = 'Invalid phone number'
    }
    if (!formData["password"]) {
      formIsValid = false;
      errors["password"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const onKeyPress = (e) =>{
    if (e.key === "Enter") {
      e.preventDefault();
      onCudentsLogin();
  }
  }

  const cudentsLogin = () => {
    return <form noValidate autoComplete="off">
      <div className="formField">
        <PhoneInput country="IN" defaultCountry="IN" international countryCallingCodeEditable={false} value={phone || EMPTY_STRING}
          required onChange={setPhone} limitMaxLength={5} inputComponent={phoneNumber} disabled={true}
        />
        <span className="error-text">{formError["phoneNumber"]}</span>
      </div>
      <div className="formField"><TextField name="password" value={formData.password || EMPTY_STRING} onChange={handleChange} fullWidth
        required label="Password" type="password" minLength={6} variant="outlined" size="small" autoComplete='new-password' onKeyPress={(e) => {onKeyPress(e)}}/>
        <span className="error-text">{formError["password"]}</span></div>
      <div className="tnc">By logging in you agree to our <a href="https://www.cudents.com/TnC/TermsAndConditions.html" target="_">T&C</a></div>
      <div id="customBtn" className="login-submit-button" onClick={onCudentsLogin} >
        <span className="buttonText">Login</span>
      </div>
      <div className="link-text" onClick={() => { openChangePasswordForm() }}>Forgot Password</div>
      <div className="link-text" onClick={() => { props.onBackToLogin() }}>Back</div>
    </form>
  }

  const getChangePasswordComponent = () => {
    return <div>
      <ChangePasswordComponent phone={phone} type={'forgotpassword'} firePasswordChanged={login}
        setErrorMessage={(msg) => { setErrorMessage(msg) }}></ChangePasswordComponent>
      <br />
      <div className="link-text" onClick={() => { openSigninForm() }}>Login</div>
    </div>
  }

  return (
    <div className="login-page">
      <div className="login-container-responsive">
        <div className="login-page-animation">
          <Lottie className="login-page-top-animation" animationData={loginAnimation} loop={true} />
        </div>
        <div className="login-title-container">
          <div className="login-page-title">{isSignin ? "Login" : "Forgot Password"}</div>
        </div>
        {errorMessage === EMPTY_STRING ? null : <div className="error-msg">{errorMessage}</div>}
        <div className="bottom-form">
          {isSignin ? cudentsLogin() : getChangePasswordComponent()}
        </div>
      </div>
    </div>
  );
}

export default PhoneSigninComponent;

