import React from 'react';
import './level-card.styles.scss';

const renderLevelImage = (lvl) => {
  switch (lvl.level) {
    case 1:
      return <img src="/assets/images/levels/unlockedLevel1.png" alt="Level" />
      break;
    case 2:
      if (lvl.isLocked === 0) {
        return <img src="/assets/images/levels/unlockedLevel2.png" alt="Level" />
      } else {
        return <img src="/assets/images/levels/lockedLevel2.png" alt="Level" />
      }
    case 3:
      if (lvl.isLocked === 0) {
        return <img src="/assets/images/levels/unlockedLevel3.png" alt="Level" />
      } else {
        return <img src="/assets/images/levels/lockedLevel3.png" alt="Level" />
      }
    case 4:
      if (lvl.isLocked === 0) {
        return <img src="/assets/images/levels/unlockedLevel4.png" alt="Level" />
      } else {
        return <img src="/assets/images/levels/lockedLevel4.png" alt="Level" />
      }
    case 5:
      if (lvl.isLocked === 0) {
        return <img src="/assets/images/levels/unlockedLevel5.png" alt="Level" />
      } else {
        return <img src="/assets/images/levels/lockedLevel5.png" alt="Level" />
      }
    default:
      return <img src="/assets/images/levels/unlockedLevel1.png" alt="Level" />
  }
}
export const LevelCard = (props) => {
  return (
    <div className='level-card-container'>
      {/* {props.level.isLocked === 0 ?
        <img src="/assets/images/levels/unlocked.png" alt="Level" />
        :
        <img src="/assets/images/levels/locked.png" alt="Level" />
      } */}
      {renderLevelImage(props.level)}
      <div className="levelTitle">Level {props.level.level} {1 === props.isAdmin ? 1 === props.level.isReviewed ? " : Approved" :
        15 === props.level.questionCount ? " : Pending" :
          " : " + props.level.questionCount + " Question(s)" : ""}
        {(() => {
          if (props.level.isAttempted === 1) {
            return <span>Score: {props.level.scorePercentage}%</span>
          }
        })()}</div>
    </div>
  );
}

export default LevelCard;
