import { PAGE_SIZE } from '../../components/constants/constants';
import { get, post, remove } from '../../services/http-services';

export function findAllHistory(isReviewed, pageNumber) {
    return get(`/history/findall?isReviewed=${isReviewed}&page=${pageNumber}&size=${PAGE_SIZE}&sort=historyDate,desc`);
}

export function findPreviousHistory(pageNumber) {
    return get(`/history/findprevious?page=${pageNumber}&size=${PAGE_SIZE}&sort=historyDate,desc`);
}

export function findHistoryByDate(date) {
    return get(`/history/findbydate?date=${date}`);
}

export function findHistoryByToday() {
    return get(`/history/findbytoday`);
}

export function saveHistory(data) {
    return post('/history', data);
}

export function deleteHistory(id) {
    return remove(`/history?id=${id}`);
}

export function approveHistory() {
    return get(`/history/approve`);
}

