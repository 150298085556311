import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { findAllGamesByType, getAllInfiniteGame, getInfiniteSubjects, getInfiniteTopics, saveInfiniteGameAttemptAdmin } from '../../feature/game/game-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { EMPTY_STRING, MANDATORY_FIELD_MSG, STATUS_TYPES } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import './admin-infinite-game.styles.scss';
import { GAME_TYPE_INFINITE, GRADES, INFINITE_GAME_TYPES, OTHER } from '../../components/games/game-constants';

const AdminInfiniteGame = () => {

  const appContext = useContext(AppContext);
  const initData = {
    gameId: EMPTY_STRING, name: EMPTY_STRING, tagLine: EMPTY_STRING,
    story: EMPTY_STRING, gameFolderName: EMPTY_STRING, runnerImage: EMPTY_STRING, grade: EMPTY_STRING,
    subject: EMPTY_STRING, topic: EMPTY_STRING, gameType: EMPTY_STRING
  };
  const [infiniteGameList, setInfiniteGameList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [gameList, setGameList] = useState([]);
  const [game, setGame] = useState(EMPTY_STRING);
  const [subject, setSubject] = useState(EMPTY_STRING);
  const [topic, setTopic] = useState(EMPTY_STRING);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (gameList.length === 0) {
      loadGameList();
    }
  }, []);

  useEffect(() => {
    loadSubjects();
  }, [formData.gameId, formData.grade]);

  useEffect(() => {
    loadTopics();
  }, [formData.gameId, formData.grade, subject]);

  const loadGameList = () => {
    setShowLoader(true);
    findAllGamesByType(GAME_TYPE_INFINITE).subscribe({
      next: (response) => {
        setGameList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const loadSubjects = () => {
    if (formData.gameId !== EMPTY_STRING && formData.grade !== EMPTY_STRING) {
      setShowLoader(true);
      getInfiniteSubjects(formData.gameId, formData.grade).subscribe({
        next: (response) => {
          setSubjectList([...response.data, OTHER]);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const loadTopics = () => {
    if (formData.gameId !== EMPTY_STRING && formData.grade !== EMPTY_STRING && subject !== EMPTY_STRING) {
      setShowLoader(true);
      getInfiniteTopics(formData.gameId, formData.grade, subject).subscribe({
        next: (response) => {
          setTopicList([...response.data, OTHER]);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }
  const search = (selectedPage) => {
    setShowLoader(true);
    getAllInfiniteGame(selectedPage - 1).subscribe({
      next: (response) => {
        setInfiniteGameList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveInfiniteGameAttemptAdmin(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const editInfiniteGame = (infiniteGame) => {
    updateFormData(infiniteGame);
    const game = getGameFromList(infiniteGame.gameId);
    setGame(game);
  }

  const getGameFromList = (gameId) => {
    return gameList.find(element => element.rowId === gameId);
  }

  const handleChange = (e) => {
    if(e.target.name === 'subject'){
      setSubject(e.target.value);
    }
    if(e.target.name === 'topic'){
      setTopic(e.target.value);
    }
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubjectChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
      subject: e.target.value
    });
  };

  const handleTopicChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
      topic: e.target.value
    });
  };

  const handleGameChange = (e) => {
    setGame(e.target.value);
    updateFormData({
      ...formData,
      name: e.target.value.name,
      gameId: e.target.value.rowId
    });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["isReviewed"]) {
      formIsValid = false;
      errors["isReviewed"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["grade"]) {
      formIsValid = false;
      errors["grade"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["correctAnswer"]) {
      formIsValid = false;
      errors["correctAnswer"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["wrongAnswer"]) {
      formIsValid = false;
      errors["wrongAnswer"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["isActive"]) {
      formIsValid = false;
      errors["isActive"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["subject"]) {
      formIsValid = false;
      errors["subject"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["topic"]) {
      formIsValid = false;
      errors["topic"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["gameType"]) {
      formIsValid = false;
      errors["gameType"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["runnerImage"]) {
      formIsValid = false;
      errors["runnerImage"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="infiniteGameFormContainer">
        <div className="infiniteGameTitle">Institute Detail</div>
        <div className="infiniteGameForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="name" value={game || EMPTY_STRING} select label="Name" fullWidth
                required onChange={handleGameChange} variant="outlined" size="small">
                {gameList.map((game) => (
                  <MenuItem key={game.rowId} value={game}>
                    {game.name}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["name"]}</span>
            </div>
            <div className="formField"><TextField name="grade" value={formData.grade} select label="Class"
              required onChange={handleChange} variant="outlined" fullWidth size="small">
              {GRADES.map((grade) => (
                <MenuItem key={grade.value} value={grade.value}>
                  {grade.name}
                </MenuItem>
              ))}
            </TextField>
            <span className="error-text">{formError["grade"]}</span>
            </div>
            <div className="formField">
              <TextField name="subject" value={subject} select label="Subject" fullWidth
                required onChange={handleChange} variant="outlined" size="small">
                {subjectList.map((subject) => (
                  <MenuItem key={subject} value={subject}>
                    {subject}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["subject"]}</span>
            </div>
            {subject === OTHER ? <div className="formField">
              <TextField name="otherSubject" value={formData.otherSubject} label="Other Subject" fullWidth
                required onChange={handleSubjectChange} variant="outlined" size="small" />
            </div> : null}
            <div className="formField">
              <TextField name="topic" value={formData.topic} select label="Topic" fullWidth
                required onChange={handleChange} variant="outlined" size="small">
                {topicList.map((topic) => (
                  <MenuItem key={topic} value={topic}>
                    {topic}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["topic"]}</span>
            </div>
            {topic === OTHER ? <div className="formField">
              <TextField name="otherTopic" value={formData.otherTopic} label="Other Topic" fullWidth
                required onChange={handleTopicChange} variant="outlined" size="small" />
            </div> : null}
            <div className="formField">
              <TextField name="isReviewed" value={formData.isReviewed || EMPTY_STRING} onChange={handleChange} required fullWidth label="Is Reviewed" variant="outlined" size="small" />
              <span className="error-text">{formError["isReviewed"]}</span>
            </div>
            <div className="formField">
              <TextField name="correctAnswer" value={formData.correctAnswer || EMPTY_STRING} onChange={handleChange} required fullWidth label="Correct Answer" variant="outlined" size="small" />
              <span className="error-text">{formError["correctAnswer"]}</span>
            </div>
            <div className="formField">
              <TextField name="wrongAnswer" value={formData.wrongAnswer || EMPTY_STRING} onChange={handleChange} required fullWidth label="Wrong Answer" variant="outlined" size="small" />
              <span className="error-text">{formError["wrongAnswer"]}</span>
            </div>
            <div className="formField">
              <TextField name="runnerImage" value={formData.runnerImage || EMPTY_STRING} onChange={handleChange} required fullWidth label="Runner Image" variant="outlined" size="small" />
              <span className="error-text">{formError["runnerImage"]}</span>
            </div>
            <div className="formField">
              <TextField name="gameType" value={formData.gameType || EMPTY_STRING} select label="Game Type" onChange={handleChange} required fullWidth variant="outlined" size="small">
                {INFINITE_GAME_TYPES.map((gameType) => (
                  <MenuItem key={gameType} value={gameType}>
                    {gameType}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["gameType"]}</span>
            </div>
            <div className="formField">
              <TextField name="isActive" value={formData.isActive || 0} select label="Status" onChange={handleChange} required fullWidth variant="outlined" size="small">
              {STATUS_TYPES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.name}
                </MenuItem>
              ))}
              </TextField>
              <span className="error-text">{formError["isActive"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}

            </div>
          </form>
        </div>
      </div>
    );
  }

  const getInfiniteGameGrid = () => {
    return (
      <div className="infiniteGameFormContainer">
        <span>Total =  {totalCount}</span>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Contact</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>Pin</StyledTableCell>
                <StyledTableCell>State</StyledTableCell>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infiniteGameList.map((infiniteGame) => (
                <StyledTableRow key={infiniteGame.name}>
                  <StyledTableCell>{infiniteGame.name}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.correctAnswer}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.wrongAnswer}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.runnerImage}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.address}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.isActive}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.pin}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.state}</StyledTableCell>
                  <StyledTableCell>{infiniteGame.gameType}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editInfiniteGame(infiniteGame) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="infiniteGameContainer">
      {showLoader ? <div className="showLoading"><Loader /></div>
        :
        <>
          {renderContact()}
          {getInfiniteGameGrid()}
        </>
      }
    </div>
  );
}

export default AdminInfiniteGame;


