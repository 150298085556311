import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext, useEffect, useRef, useState } from 'react';
import readXlsxFile from 'read-excel-file';
import { AppContext } from '../../app-context';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { addAdminSubscription, checkAdminSubscription } from '../../feature/payment/payment-service';
import Loader from '../../components/loader/loader.component';
import './bulk-payment.styles.scss';
import { EMPTY_STRING } from '../../components/constants/constants';

const BulkPayment = (props) => {
  const [formData, updateFormData] = useState([]);
  const appContext = useContext(AppContext);
  const [showLoader, setShowLoader] = useState(false);
  const [fileName, setFileName] = useState(EMPTY_STRING);
  const classes = useStyles();
  const inputFile = useRef(null)

  useEffect(() => {

    const input = document.getElementById('input')
    input.addEventListener('change', () => {
      if (input && input.files[0]) {
        setShowLoader(true);
        setFileName(input.files[0].name);
        readXlsxFile(input.files[0]).then((rows) => {
          // `rows` is an array of rows
          // each row being an array of cells.
          rows.map((row, index) => {
            if (index > 0) {
              const rowData = {
                name: row[0],
                displayName: row[1],
                grade: row[2],
                mobile: row[3],
                packageId: row[4],
                couponCode: row[5]
              }
              formData.push(rowData);
            }
          });
          setShowLoader(false);
        })
      }

    });
  }, [])

  const saveData = (event) => {
    // const unsubscribedStudent = [];
    // formData.map((row) => {
    //   if (row.status !== 'Valid subscription') {
    //     unsubscribedStudent.push(row);
    //   }
    // });
    setShowLoader(true);
    const purchaseList = { models: formData }
    addAdminSubscription(purchaseList).subscribe({
      next: (response) => {
        updateFormData(response.data);
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
      }});
  }

  const checkSubscriptions = () => {
    setShowLoader(true);
    const purchaseList = { models: formData }
    checkAdminSubscription(purchaseList).subscribe({
      next: (response) => {
        console.log(response.data);
        updateFormData(response.data);
        setShowLoader(false);
      }});
  }

  const renderPayment = () => {
    return (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Kids Name</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Package Id</TableCell>
                <TableCell>Coupon Code</TableCell>
                <TableCell>Validity</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.map((payment, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{payment.name}</StyledTableCell>
                  <StyledTableCell>{payment.displayName}</StyledTableCell>
                  <StyledTableCell>{payment.grade}</StyledTableCell>
                  <StyledTableCell>{payment.mobile}</StyledTableCell>
                  <StyledTableCell>{payment.packageId}</StyledTableCell>
                  <StyledTableCell>{payment.couponCode}</StyledTableCell>
                  <StyledTableCell>{payment.validity}</StyledTableCell>
                  <StyledTableCell>{payment.status}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="bulk-payment-main">
      <div className="bulk-payment-title">Bulk Payment</div>
      <input type='file' id='input' ref={inputFile} style={{ display: 'none' }} />
      <Button onClick={onButtonClick} variant="contained" color="primary" className="save-button">Choose Excel</Button>
      {fileName}
      <Button type="submit" variant="contained" color="secondary" onClick={checkSubscriptions} className="save-button">
      Check Subscription
      </Button>
      <Button type="submit" variant="contained" color="primary" onClick={saveData} className="save-button">
        Save
      </Button>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderPayment()}
    </div>
  );
}

export default BulkPayment;