import { PAGE_SIZE } from "../../components/constants/constants";
import { get, post, remove } from "../../services/http-services";

export function findAllRiddle(isReviewed, pageNumber) {
    return get(`/riddle/findall?isReviewed=${isReviewed}&page=${pageNumber}&size=${PAGE_SIZE}&sort=riddleDate,desc`);
}

export function findPreviousRiddle(pageNumber) {
    return get(`/riddle/findprevious?page=${pageNumber}&size=${PAGE_SIZE}&sort=riddleDate,desc`);
}

export function findDailyRiddle() {
    return get(`/riddle/finddailyriddle`);
}

export function findRiddleByDate(date) {
    return get(`/riddle/findbydate?date=${date}`);
}

export function saveRiddle(data) {
    return post('/riddle', data);
}

export function deleteRiddle(id) {
    return remove(`/riddle?id=${id}`);
}

export function approveRiddles() {
    return get(`/riddle/approve`);
}

