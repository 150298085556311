import Lottie from "lottie-react";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../../../app-context";
import { GRADE_LIST } from "../../../../components/constants/constants";
import { CLASS_TYPE_PRESCHOOL } from "../../../../components/games/game-constants";
import preschool from "../animations/preschool.json";
import primary from "../animations/primary.json";
import './games-selection.styles.scss';

const GamesSelection = (props) => {


    const history = useHistory();
    const appContext = useContext(AppContext);
    const [currentGrade, setCurrentGrade] = useState(null);

    useEffect(() => {
        if (appContext.userData !== null && currentGrade === null) {
            setCurrentGrade(appContext.userData.grade);
            window.screen.orientation.lock('portrait');
        }
    }, []);


    const selectGrade = (classType, grade) => {
        setCurrentGrade(grade);
        history.push(`/gameslisting/${classType}/${grade}`);
    }

    return (
        <div className="games-selection-page">
            <div className="games-selection-list">

                {/* <div className="games-selection-item">
                    Choose one from below
                </div> */}
                <div className="games-selection-item">
                    <div className="games-selection-image">
                        <Lottie className="games-selection-animation" animationData={preschool} loop={true} />
                    </div>
                    <div className="games-selection-button">
                        Pre-School
                    </div>

                    <div className="games-selection-filters">
                        {
                            GRADE_LIST.map((grade) => (
                               <> {grade.value<0 ? <div className={grade.value === currentGrade ? "games-selection-filter-button active-grade" : "games-selection-filter-button"}
                                    onClick={() => { selectGrade(CLASS_TYPE_PRESCHOOL, grade.value) }}>{grade.name}</div>: null}</>
                            ))
                        }
                    </div>

                </div>
                <div className="games-selection-item">
                    <div className="games-selection-image">
                        <Lottie className="games-selection-animation" animationData={primary} loop={true} />
                    </div>
                    <div className="games-selection-button">
                        Primary
                    </div>

                    <div className="games-selection-filters">
                        {
                            GRADE_LIST.map((grade) => (
                                <> {grade.value>0 ? <div className={grade.value === currentGrade ? "games-selection-filter-button active-grade" : "games-selection-filter-button"}
                                onClick={() => { selectGrade('PRIMARY', grade.value) }}>{grade.name}</div>: null}</>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default GamesSelection;