import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../app-context";
import { ROLE_ADMIN } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import { isNotEmpty, isNotNull } from '../../services/util-service';
import './group-main.styles.scss';
import { findGroupById, leaveGroup } from './group.service';
import GroupLeaderboard from './leaderboard/group-leaderboard.component';

const GroupMainPage = (props) => {

    const history = useHistory();
    const appContext = useContext(AppContext);
    const groupId = props.match.params.groupId;
    const date = props.match.params.date;
    const [groupDetail, setGroupDetail] = useState(null);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (groupDetail === null) {
            fetchGroupDetail();
        }
    }, []);

    const fetchGroupDetail = () => {
        findGroupById(groupId).subscribe({
            next: (response) => {
                if(isNotNull(response.data)){
                    setGroupDetail(response.data);
                    setShowLoader(false);
                } else {
                    history.push(`/group/list`);
                }
            }, error: (error) => {
                setShowLoader(false);
                appContext.setDialogMessage(error.message);
            }
        });
    }

    const leave = () => {
        leaveGroup(groupId).subscribe({
            next: (response) => {
                appContext.setDialogMessage(response.message);
                history.push(`/group/list`);
                setShowLoader(false);
            }, error: (error) => {
                setShowLoader(false);
                appContext.setDialogMessage(error.message);
            }
        });
    }

    const editGroup = () => {
        history.push(`/group/edit/${groupDetail.rowId}`);
    }

    const isEditAllowed = () => {
        const user = appContext.userData;
        return user.role === ROLE_ADMIN || user.rowId === groupDetail.createdBy;
    }

    return <>{showLoader ? <Loader /> : <div className="group-main-page">
        <div className="group-page-top">
            {/* <img src="/assets/images/homepage/newhome/newdesign/curve9.png" alt="" /> */}
        </div>
        <div className="group-image">
            <img src={groupDetail.imagePath} alt="" />
        </div>
        <div className='group-detail'>
            <div className="main-title">{groupDetail.name}</div>
            {groupDetail.isActive === 1 && <div className="sub-title">Code: {groupDetail.groupCode}</div>}
            <span className="group-button" onClick={leave}>
                Leave
            </span>
            {isEditAllowed() && <span className="group-button edit-button" onClick={editGroup}>Edit</span>}
        </div>
        {groupDetail.isActive === 1 ? <><GroupLeaderboard groupId={groupDetail.rowId} date={date}/>
            <div className="history-button">
                <span onClick={() => { history.push(`/group/leaderboard/history/${groupId}`); }}>Previous Months</span>
            </div></>
            : <div className='empty-msg'>{isNotEmpty(groupDetail.reason) ? groupDetail.reason : 'Group is pending for approval'}</div>}
    </div>}
    </>
}

export default GroupMainPage;