import Drawer from '@material-ui/core/Drawer';
import Lottie from "lottie-react";
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../app-context';
import { EMPTY_STRING } from '../../../components/constants/constants';
import { doSignOut } from '../../../components/firebase/firebase.service';
import { getWhatsApp, isWebPlatform } from '../../../services/util-service';
import checklist from "./animations/checklist.json";
import feedback from "./animations/feedback.json";
import policy from "./animations/policy.json";
import refund from "./animations/refund.json";
import support from "./animations/support.json";
import notifications from "./animations/notifications.json";
import './mobile-menu.styles.scss';

const MobileMenu = (props) => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const toggleDrawer = (drawerStatus) => {
    setDrawerOpen(drawerStatus);
    if (!isWebPlatform()) {
      window.screen.orientation.lock('portrait');
    }
  }

  const handleLogout = () => {
    doSignOut();
    navigateToHome();
    appContext.setUserData(null);
    localStorage.clear();
  }

  const navigateToHome = () => {
    history.push('/');
  }


  return (
    <div>
      {/* <IconButton style={{ color: '#333', fontS }} onClick={() => { toggleDrawer(true) }} aria-label="mobile Menu" component="span">
        <MenuOutlinedIcon />
      </IconButton> */}
      <div className="bottom-nav-list-item-icon menu-icon" onClick={() => { toggleDrawer(true) }}>
        <img src="/assets/images/homepage/bn_menu_button_new.png" alt="" />
      </div>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => { toggleDrawer(false) }}
      >
        <div className="mobileMenu">
          {/* <div className="mobileMenuTop">
            {props.currentUser ?
              <div>
                <img src={props.currentUser.profilePicPath} alt="" />
                <div className="kidName">{props.currentUser.displayName}</div>
              </div>
              :
              <div>
                <img src="/assets/images/login/mobileLogin.png" alt="" />
                <div className="not-logged-in">Looks like you are not logged in.</div>
              </div>
            }
          </div> */}
          <div className="menuList">
            {/* <div className="menu-list-item">
              <Link to="/" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/home.png" alt="" /></div>
                <div>Home</div>
              </Link>
            </div> */}
            {/* {props.currentuser ?
              <div className="menu-list-item">
                <Link to="/userdashboard" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                  <div><img src="/assets/images/homepage/newhome/icons/home.png" alt="" /></div>
                  <div>Dashboard</div>
                </Link>
              </div>
              : null} */}
            {/* <div className="menu-list-item">
              <Link to="/language" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/bn_language.png" alt="" /></div>
                <div>Language</div>
              </Link>
            </div> */}
            {/* <div className="menu-list-item">
              <Link to="/pricing" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div className="menu-animation-container"><Lottie className="menu-animation" animationData={pricing} loop={true} /></div>
                <div>Pricing</div>
              </Link>
            </div> */}
            <div className="menu-list-item">
              <Link to="/feedback" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div className="menu-animation-container"><Lottie className="menu-animation" animationData={feedback} loop={true} /></div>
                <div>Feedback</div>
              </Link>
            </div>
            <div className="menu-list-item">
              <Link to="/support" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div className="menu-animation-container"><Lottie className="menu-animation" animationData={support} loop={true} /></div>
                <div>FAQs</div>
              </Link>
            </div>
            <div className="menu-list-item">
              <a href={getWhatsApp('9692338030', EMPTY_STRING)} className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div className="menu-animation-container"><Lottie className="menu-animation" animationData={refund} loop={true} /></div>
                <div>Contact Us</div>
              </a>
            </div>
            <div className="menu-list-item">
              <Link to="/notification" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div className="menu-animation-container"><Lottie className="menu-animation" animationData={notifications} loop={true} /></div>
                <div>Notifications</div>
              </Link>
            </div>
            {/* <div className="menu-list-item">
              {appContext.userData ?
                <Link className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); handleLogout(); }}>
                  <div><img src="/assets/images/homepage/newhome/icons/login.png" alt="" /></div>
                  <div>Logout</div>
                </Link>
                :
                <Link to="/signin" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                  <div><img src="/assets/images/homepage/newhome/icons/login.png" alt="" /></div>
                  <div>Login</div>
                </Link>
              }
            </div> */}
            <div className="menu-list-item">
              <a href="https://www.cudents.com/TnC/TermsAndConditions.html" target="_"><div className="menu-animation-container"><Lottie className="menu-animation" animationData={checklist} loop={true} /></div></a>
              <div>T&C</div>
            </div>
            <div className="menu-list-item">
              <a href="https://www.cudents.com/TnC/PrivacyPolicy.html" target="_"><div className="menu-animation-container"><Lottie className="menu-animation" animationData={policy} loop={true} /></div></a>
              <div>Privacy Policy</div>
            </div>
            {/* <div className="menu-list-item">
              <a href="https://www.cudents.com/TnC/Refund.html" target="_"><div className="menu-animation-container"><Lottie className="menu-animation" animationData={refund} loop={true} /></div></a>
                <div>Refund Policy</div>
            </div> */}
            {/* 
            <div className="menu-list-item">
              <Link to="/game/arrangeinsequence" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>Sequence</div>
              </Link>
            </div>

            <div className="menu-list-item">
              <Link to="/game/searchmaster" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>Search</div>
              </Link>
            </div>

            <div className="menu-list-item">
              <Link to="/game/infinitegame/0" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>Vowel Game</div>
              </Link>
            </div>

            <div className="menu-list-item">
              <Link to="/game/infinitegame/1" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>The Pumpkins</div>
              </Link>
            </div>

            <div className="menu-list-item">
              <Link to="/game/infinitegame/2" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>Pet Animals</div>
              </Link>
            </div>

            <div className="menu-list-item">
              <Link to="/game/infinitegame/3" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <div><img src="/assets/images/homepage/newhome/icons/contact.png" alt="" /></div>
                <div>Junk Food</div>
              </Link>
            </div> */}

            {/* <Rif boolean={props.currentUser}>
              <Link to="/userdashboard" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileProfileButton">
                  <ListItemIcon><DashboardIcon /></ListItemIcon>
                  <ListItemText className="mobileMenuText">Dashboard </ListItemText>
                </ListItem>
              </Link>
            </Rif>
            <List>
              <Rif boolean={props.currentUser}>
                <Link to="/userdashboard" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                  <ListItem button key="mobileProfileButton">
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText className="mobileMenuText">Dashboard </ListItemText>
                  </ListItem>
                </Link>
              </Rif>
              <Link to="/" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileHomeButton">
                  <ListItemIcon><HomeOutlinedIcon></HomeOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Home </ListItemText>
                </ListItem>
              </Link>
              <Link to="/leaderboard" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileLeaderboardButton">
                  <ListItemIcon><EmojiEventsOutlinedIcon></EmojiEventsOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Leaderboard </ListItemText>
                </ListItem>
              </Link>
              <Link to="/game/arrangeinsequence" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileLeaderboardButton">
                  <ListItemIcon><EmojiEventsOutlinedIcon></EmojiEventsOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Game </ListItemText>
                </ListItem>
              </Link>
              <Link to="/pricing" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobilePricingButton">
                  <ListItemIcon><AttachMoneyOutlinedIcon></AttachMoneyOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Pricing </ListItemText>
                </ListItem>
              </Link>
              <Link to="/contests" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileContestsButton">
                  <ListItemIcon><RedeemIcon></RedeemIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Contests </ListItemText>
                </ListItem>
              </Link>
              <Link to="/feedback" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileContactButton">
                  <ListItemIcon><EmailOutlinedIcon></EmailOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText">Contact</ListItemText>
                </ListItem>
              </Link>
              <Link to="/rhymes" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                <ListItem button key="mobileSupportButton">
                  <ListItemIcon><HelpOutlineOutlinedIcon></HelpOutlineOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Rhymes </ListItemText>
                </ListItem>
              </Link>
              <Rif boolean={!props.currentUser}>
                <Link to="/signin" className="mobileMenuItem" style={{ textDecoration: 'none' }} onClick={() => { toggleDrawer(false); }}>
                  <ListItem button key="mobileHomeButton">
                    <ListItemIcon><LockOutlinedIcon></LockOutlinedIcon></ListItemIcon>
                    <ListItemText className="mobileMenuText"> Login </ListItemText>
                  </ListItem>
                </Link>
              </Rif>
              {props.currentUser ?
                <ListItem button key="mobileSupportButton" onClick={() => { toggleDrawer(false); props.logout(); }}>
                  <ListItemIcon><ExitToAppOutlinedIcon></ExitToAppOutlinedIcon></ListItemIcon>
                  <ListItemText className="mobileMenuText"> Logout </ListItemText>
                </ListItem>
                :
                null
              }
            </List> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileMenu;