import { get, whitelistGet } from "../../services/http-services";

export function getGrades(isAdmin) {
    return whitelistGet("/category/grade?isAdmin="+isAdmin);
}

export function getSubjects(grade, isAdmin) {
    return get("/category/subject?grade=" + grade+'&isAdmin=' + isAdmin);
}

export function getTopics(grade, subject, isAdmin) {
    return get("/category/topic?grade=" + grade +'&subject=' + subject+'&isAdmin=' + isAdmin);
}