import { PAGE_SIZE } from "../../components/constants/constants";
import { downloadPost, get, post } from "../../services/http-services";

export function getAllMasterContests() {
    return get(`/contest/master/get/all`);
}
export function getAllContests(pageNumber) {
    return get(`/contest/get/all?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function saveContest(contestModel) {
    return post(`/contest`, contestModel);
}

export function getContestById(contestId) {
    return get(`/contest?contestId=${contestId}`);
}

export function getContestByCode(contestCode) {
    return get(`/contest/get?contestCode=${contestCode}`);
}

export function getActiveContests(masterContestId) {
    return get(`/contest/get/active?masterContestId=${masterContestId}`);
}

export function getCompletedContests(masterContestId) {
    return get(`/contest/get/completed?masterContestId=${masterContestId}`);
}

export function getContestsPartner() {
    return get(`/contest/get/partner`);
}

export function getContestsAdmin(contestType) {
    return get(`/contest/get/admin?contestType=${contestType}`);
}


export function getAllContestsAdmin(contestType, pageNumber) {
    return get(`/contest/getall/admin?contestType=${contestType}&page=${pageNumber}&size=${PAGE_SIZE}&sort=startDate,desc`);
}

export function getContestLeaderboard(contestId, grade, pageNumber) {
    return get(`/contest/leaderboard?contestId=${contestId}&grade=${grade}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getContestLeaderboardRange(contestId, range, pageNumber) {
    return get(`/contest/leaderboard/range?contestId=${contestId}&range=${range}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getParticipantsPartner(contestCode, pageNumber) {
    return get(`/contest/participant/partner?contestCode=${contestCode}&page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function getParticipantsAdmin(contestCode, pageNumber, sort) {
    return get(`/contest/participant/admin?contestCode=${contestCode}&page=${pageNumber}&size=${PAGE_SIZE}&sort=${sort}`);
}

export function exportParticipantsAdmin(contestCode) {
    return downloadPost(`/contest/participant/admin/export?contestCode=${contestCode}`, null);
}

export function exportParticipantsPartner(contestCode) {
    return downloadPost(`/contest/participant/partner/export?contestCode=${contestCode}`, null);
}

export function getPosterData(contestCode) {
    return get(`/contest/posterdata?contestCode=${contestCode}`);
}

export function saveSubscription(contestId) {
    return post(`/contest/savesubscription?contestId=${contestId}`, null);
}

export function getContestCertificateByUserId(contestId, userId) {
    return get(`/contest/participant/certificate?contestId=${contestId}&userId=${userId}`);
}

export function getContestCertificate(contestId) {
    return get(`/contest/certificate?contestId=${contestId}`);
}

export function startContest(contestId, grade, code) {
    return post(`/contest/start?contestId=${contestId}&grade=${grade}&code=${code}`, null);
}
