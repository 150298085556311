import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { findAllHomeBanner, saveHomeBanner } from '../../feature/home-banner/home-banner-service';
import { isEmpty } from '../../services/util-service';
import { EMPTY_STRING, ROLE_ADMIN, ROLE_USER, STATUS_TYPES } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import ImageTextfield from '../../components/text-field/image-textfield.component';
import DateComponent from '../../components/date/date.component';
import './admin-home-banner.styles.scss';
const AdminHomeBanner = () => {

  const [showLoader, setShowLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [homeBannerList, setHomeBannerList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const initFormData = {
    fromDate: null,
    toDate: null,
    description: EMPTY_STRING,
    name: EMPTY_STRING,
    isActive: 0,
  };
  const [userRole, setRole] = useState(ROLE_USER);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (appContext.userData) {
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  useEffect(() => {
    if (appContext.userData) {
      getAllHomeBanner(page);
    }
  }, [page]);

  const onSubmit = (event) => {
    if (isValidQuestion()) {
      setShowLoader(true);
      saveHomeBanner(formData).subscribe({
        next: (response) => {
          getAllHomeBanner(1);
          updateFormData(initFormData);
          appContext.setMessage(response.message);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const handleToggle = (e) => {
    setPage(1);
    getAllHomeBanner(e.target.checked, 1)
  };

  const getAllHomeBanner = (isReviewed, pageNumber) => {
    setShowLoader(true);
    findAllHomeBanner(pageNumber - 1).subscribe({
      next: (response) => {
        setHomeBannerList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    updateFormData({
      ...formData,
      image: null,
      url: null
    });
  };


  const isValidQuestion = () => {
    let valid = false;
    if (isEmpty(formData.name)) {
      appContext.setDialogMessage('Please add \'Name\' to add the HomeBanner');
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  const onEditQuestion = (event) => {
    updateFormData(event);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const headerFormPanel = () => {
    return <div className="header-panel">
      <div className="header-field">
        <TextField name="name" value={formData.name} label="Name" fullWidth
          required onChange={handleChange} variant="outlined" size="small">
        </TextField>
      </div>
      <div className="header-field">
        <TextField name="description" value={formData.description} label="Description" fullWidth rows="3" multiline="true"
          required onChange={handleChange} variant="outlined" size="small">
        </TextField>
      </div>
      <div className="header-field">
        <DateComponent name="fromDate" label="From Date" onDateSelection={handleChange} value={formData.fromDate || EMPTY_STRING}
          defaultDate={formData.fromDate ? moment.utc(formData.fromDate) : null} variant="outlined" size="small" />
      </div>
      <div className="header-field">
        <DateComponent name="toDate" label="To Date" onDateSelection={handleChange} value={formData.toDate || EMPTY_STRING}
          defaultDate={formData.toDate ? moment.utc(formData.toDate) : null} variant="outlined" size="small" />
      </div>
      <ImageTextfield id="home-banner-image" image={formData.imagePath} handleTextChange={handleChange}
        imageFieldName="image" handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage}
        imagePath="imagePath">
      </ImageTextfield>
      <div className="formField">
        <TextField name="isActive" value={formData.isActive || 0} select label="Status" onChange={handleChange} fullWidth variant="outlined" size="small">
          {STATUS_TYPES.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={onSubmit}> Submit </Button>
      </div>
    </div>
  }

  const getBannerList = () => {
    return <div className="home-banner-list"><div className="home-banner-answers-details">
      {homeBannerList.map((homeBanner, index) => (
        <div className="home-banner-answers-row">
          <div className="home-banner-answers-label">
            {userRole === ROLE_ADMIN || homeBanner.isReviewed === 0 ? <span className="questionListIcon">
              <EditOutlinedIcon className="icon" onClick={() => { onEditQuestion(homeBanner) }} />
            </span> : null}
          </div>
          <div className="home-banner-answers-question">
            Name: {homeBanner.name === EMPTY_STRING ? null : homeBanner.name}
          </div>
          <div className="home-banner-answers-question">
            {homeBanner.description === EMPTY_STRING ? null : homeBanner.description}
          </div>
          <img src={homeBanner.imagePath} ></img>
          Status: {homeBanner.isActive === 1 ? 'ACTIVE' : 'INACTIVE'}
        </div>
      ))}
    </div>
    </div>
  }

  const renderMain = () => {
    return <div className="home-banner-container">
      {headerFormPanel()}
      <div className='bottom-panel'>
        <div className="home-banner-request-title">Home Banner</div>
        <span>Total =  {totalCount}</span>
        <Grid justifyContent="center" container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {homeBannerList.length > 0 ? getBannerList() : null}
          </Grid>
        </Grid>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      </div>
    </div>
  }

  return (
    <>
      {showLoader ? <Loader /> :
        renderMain()
      }
    </>
  );
}

export default AdminHomeBanner;
