import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Lottie from "lottie-react";
import searching from "../animations/searching.json";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AdComponent from '../../../components/google-ads/google-ads.component';
import Loader from '../../../components/loader/loader.component';
import { getRedemptions } from '../redeem-service';
import './redeem-history.styles.scss';

const RedeemHistory = () => {

  const [expanded, setExpanded] = useState(false);
  const [redeemHistoryList, updateRedeemHistoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [showAds, setShowAds] = useState(false);

  const handlePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    loadRedeemHistoryList();
  }, [page]);

  const loadRedeemHistoryList = () => {
    setShowLoader(true);
    getRedemptions(page - 1).subscribe({
      next: (response) => {
        updateRedeemHistoryList(response.data.data);
        setPageCount(response.data.totalPages);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getRedeemGrid = () => {
    return (
      <div className="redeem-grid">
        <div className="redeem-history-section">
          <div className="redeem-history-title">
            Redeem History
          </div>
          <div className="redeem-list">{redeemHistoryList.map(redeem => (

            <Accordion expanded={expanded === redeem.redemption.rowId} onChange={handleExpansion(redeem.redemption.rowId)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="redeem-heading">
                  {/* <div className="heading-name">Name: <span>{redeem.redemption.name}</span></div>
                    <div className="heading-name">Mobile: <span>{redeem.redemption.mobile}</span></div>
                    <div className="heading-name">Status: <span>{redeem.redemption.mobile}</span></div> */}
                  <div className="heading-name">{redeem.redemptionDetails[0].name} - <span>{redeem.redemption.status}</span></div>
                  <div className="spacing"></div> <div className="headingDate">{moment(redeem.redemption.creationDate).format('lll')}</div></div>
              </AccordionSummary>
              <AccordionDetails className="message">
                {redeem.redemptionDetails.map(detail => (
                  <div className="redeem-list-row">
                    <div className="heading-name">Redemption Id: <span>{redeem.redemption.redemptionId}</span></div>
                    <div className="heading-name">Status: <span>{detail.status}</span></div>
                    <div className="heading-name">Name: <span>{redeem.redemption.name}</span></div>
                    <div className="heading-name">Address: <span>{redeem.redemption.address}</span></div>
                    <div className="heading-name">Value: <span>{detail.value}</span></div>
                  </div>
                ))}
                {redeem.message}
              </AccordionDetails>
            </Accordion>
          ))}</div>
        </div>
      </div>
    );
  }

  const renderView = () => {
    return    <>
        <div className='redeem-history-not-found'>
      <Lottie className="redeem-history-page-top-animation" animationData={searching} loop={true} />
      </div>
        {redeemHistoryList.length > 0 ?
          <>
            <div>
              {getRedeemGrid()}
            </div>
            <div className="redeem-pagination"><Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} /></div>
          </>
          :
          <div className='redeem-history-not-found'>
            <div className="no-redeem-message">Looks like you have not redeemed anything yet</div>
          </div>
        }
      </>
  }

  return (
    <div className="redeem-container">
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}

      {showAds ? <><AdComponent></AdComponent></> : null}
    </div>
  );
}

export default RedeemHistory;