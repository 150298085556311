import Grid from '@material-ui/core/Grid';
import React from 'react';
import VideoPlayerComponent from '../../../components/video-player/video-player.component';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './video-section.styles.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { findAllVideo } from '../../../services/video-service';

const VideoSection = (props) => {

  const [videoList, setVideoList] = useState([]);
  const responsiveVideos = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };

  useEffect(() => {
    if (videoList.length === 0) {
      loadvideos();
    }
  }, []);

  const loadvideos = () => {
    findAllVideo(props.videoType, 0).subscribe({
      next: (response) => {
        setVideoList(response.data.content);
      }, error : (error ) => {
      }});
  }

  const renderVideo = () => {

    return (
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsiveVideos}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        transitionDuration={500}
        renderButtonGroupOutside={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        centerMode={true}
      >
            {
                videoList.map((video, index) => (
                  <div className="video-card" key={index}>
                    <div className="video-card-image">
                    <a href={process.env.REACT_APP_PLAYSTORE_URL}>
                      <img src={video.thumbnailImagePath} alt="" /></a>
                    </div>
                    <div className="video-title">
                      {video.name}
                    </div>
                  </div>
                ))
              }
      </Carousel>
    );

  }

  return (
    <div className="video-listing">
      {renderVideo()}
    </div>
  );
}

export default VideoSection;