import Lottie from "lottie-react";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getGrades } from '../category-service';
import examresult from "./animations/examresult.json";
import './quizzes.styles.scss';

const Quizzes = () => {

  const history = useHistory();
  const [gradeList, setGradeList] = useState([]);


  useEffect(() => {
    if (gradeList) {
      loadGrade();
    }
  }, []);


  const loadGrade = () => {
    getGrades(0).subscribe({
      next: response => {
        setGradeList(response.data);
      }, error: error => {
        console.log(error);
      }});
  }

  const getGradeImage = (grade) => {
    switch (grade) {
      case 1:
        return <img src="/assets/images/homepage/quiz/class1.png" alt="class 1 pic"></img>
      case 2:
        return <img src="/assets/images/homepage/quiz/class2.png" alt="class 2 pic"></img>
      case 3:
        return <img src="/assets/images/homepage/quiz/class3.png" alt="class 3 pic"></img>
      case 4:
        return <img src="/assets/images/homepage/quiz/class4.png" alt="class 4 pic"></img>
      case 5:
        return <img src="/assets/images/homepage/quiz/class5.png" alt="class 5 pic"></img>
      default:
        return <img src="/assets/images/homepage/quiz/class1.png" alt="class 1 pic"></img>
    }
  }
  
  const selectGrade = (grade) => {
    history.push(`/grade/${grade}`);
  }

  const renderGrade = () => {
    return (
      <>{
        gradeList.map((grd, idx) => (
          <div key={idx} className="quiz-card" onClick={() => { selectGrade(grd.grade) }}>
            <div className="quiz-image">{getGradeImage(grd.grade)}</div>
            <div className="quiz-name">Class {grd.grade}</div>
            <div className="quiz-tag">{Math.floor((grd.quizCount * 15) / 100) * 100}+ Questions</div>
          </div>
        ))
      }
        <div className="quiz-card-empty">&nbsp;</div></>
    );
  }

  return (
    <div className="quiz-user-dashboard-listing">
      <div className="quiz-listing-page-top">
        <img src="/assets/images/homepage/newhome/newdesign/curve3.png" alt="" />
      </div>
      <div className="quiz-page-top">
        <Lottie className="quiz-page-top-animation" animationData={examresult} loop={true} />
      </div>
      {renderGrade()}
    </div>
  );
}

export default Quizzes;