import { PAGE_SIZE } from "../../components/constants/constants";
import { get, put, remove } from "../../services/http-services";


export function getAllGroupForAdmin(pageNumber) {
    return get(`/group/admin/page?page=${pageNumber}&size=${PAGE_SIZE}&sort=creationDate,desc`);
}

export function updateGroup(groupModel) {
    return put(`/group`, groupModel);
}

export function deleteGroup(groupId) {
    return remove(`/group?id=${groupId}`);
}