import { default as React } from 'react';
import './title-panel.styles.scss';

const TitlePanel = (props) => {


  return <div className="title-panel">
    <div className="panel-title">{props.title}</div>
    <div className="panel-body">
      {props.panelBody}
    </div>
  </div>
}

export default TitlePanel;