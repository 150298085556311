import { PAGE_SIZE } from "../../components/constants/constants";
import { downloadPost, get, post } from "../../services/http-services";

export function getQuizPackage() {
    return get("/quizpackage");
}

export function createOrder(packageId, couponCode) {
    return post(`/purchase/createorder?packageId=${packageId}&couponCode=${couponCode}`, null);
}

export function calculateAmount(packageId, couponCode) {
    return get(`/purchase/calculateamount?packageId=${packageId}&couponCode=${couponCode}`);
}

export function validatePayment(purchaseModel) {
    return post("/purchase/validatepayment", purchaseModel);
}

export function getTransactions(pageNumber) {
    return get('/purchase/transactions?page=' + pageNumber + '&size=' + PAGE_SIZE + '&sort=creationDate,desc');
}

export function getTransactionsAdmin(formData, pageNumber) {
    return post('/purchase?page=' + pageNumber + '&size=' + PAGE_SIZE + '&sort=creationDate,desc', formData);
}

export function exportTransactions(formData) {
    return downloadPost('/purchase/export', formData);
}

export function validatePendingPayment(orderId) {
    return post("/purchase/validatependingpayment?orderId=" + orderId, null);
}

export function getInvoice(orderId) {
    return get("/purchase/invoice?orderId=" + orderId);
}

export function getPartnerSales(pageNumber) {
    return get("/purchase/partnersales?page=" + pageNumber + "&size=" + PAGE_SIZE + "&sort=creationDate,desc");
}

export function getAdminSales(pageNumber) {
    return get("/purchase/sales?page=" + pageNumber + "&size=" + PAGE_SIZE + "&sort=creationDate,desc");
}

export function getSalesTrend(period) {
    return get(`/purchase/salestrend?period=${period}`);
}

export function addAdminSubscription(purchaseList) {
    return post("/purchase/addsubscription", purchaseList);
}

export function checkAdminSubscription(purchaseList) {
    return post("/purchase/checksubscription", purchaseList);
}
