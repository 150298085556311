
import { Button } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/loader/loader.component';
import { useStyles } from '../../../services/grid-style';
import { getTransactions } from '../../payment/payment-service';
import { numberFormat } from '../../../services/util-service';
import './order-list.styles.scss';

const OrderList = () => {

  const [orderList, updateOrderList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    loadOrderList();
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const loadOrderList = () => {
    setShowLoader(true);
    getTransactions(page - 1).subscribe({
      next: (response) => {
        updateOrderList(response.data.models);
        setPageCount(response.data.size);
        setShowLoader(false);
      }, error : (error ) => {
        setShowLoader(false);
      }});
  }

  const goToInvoice = (orderId) => {
    history.push(`/userdashboard/invoice/${orderId}`);
  }

  const renderOrders = () => {
    return (
      // <TableContainer component={Paper}>
      //   <Table className={classes.table} aria-label="customized table">
      //     <TableHead>
      //       <TableRow>
      //         <TableCell>Order ID</TableCell>
      //         <TableCell>Description</TableCell>
      //         <TableCell align="right">Amount&nbsp;(INR)</TableCell>
      //         <TableCell>Status</TableCell>
      //         <TableCell>Date</TableCell>
      //         <TableCell>Action</TableCell>
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       {orderList.map((order) => (
      //         <StyledTableRow key={order.packageName}>
      //           <StyledTableCell component="th" scope="row">
      //             {order.cudentsOrderId}
      //           </StyledTableCell>
      //           <StyledTableCell>{order.details}</StyledTableCell>
      //           <StyledTableCell align="right">{numberFormat(order.invoiceValue)}</StyledTableCell>
      //           <StyledTableCell>{order.status}</StyledTableCell>
      //           <StyledTableCell>{moment(order.creationDate).format('lll')}</StyledTableCell>
      //           <StyledTableCell>{order.status === 'Successful' ?
      //             <Button onClick={() => { goToInvoice(order.cudentsOrderId) }} variant="contained" color="secondary">
      //               DETAILS
      //             </Button>
      //             : null}</StyledTableCell>
      //         </StyledTableRow>
      //       ))}
      //     </TableBody>
      //   </Table>
      // </TableContainer>
      <div className="order-list-container">
        <div className="order-list">
          {orderList.map((order, idx) => {
            return (
              <div key={idx} className="order-list-row">
                <div className="order-info">
                  <div className="order-list-order-id">
                    <div className="order-list-label">Order Id</div>
                    {order.cudentsOrderId}
                  </div>
                  <div className="order-list-order-amount">
                    <div className="order-list-label">Order Amount</div>
                    {numberFormat(order.invoiceValue)}
                  </div>
                </div>
                <div className="order-list-order-description">
                  <div className="order-list-label">Description</div>
                  {order.details}
                </div>
                <div className="order-info">
                  <div className="order-list-status">
                    <div className="order-list-label">Status</div>
                    {order.status}
                  </div>
                  <div className="order-list-date">
                    <div className="order-list-label">Date</div>
                    {moment(order.creationDate).format('lll')}
                  </div>
                </div>
                <div className="order-list-action">
                  {order.status === 'Successful' ?
                    <>
                      <div className="order-list-label">Action</div>
                      <Button onClick={() => { goToInvoice(order.cudentsOrderId) }} variant="contained" color="secondary">
                        DETAILS
                      </Button> </>
                    : null}
                </div>

              </div>
            )
          })}
        </div>
      </div>
    );
  }

  const renderView = () => {
    return (
      <div>
        {orderList.length > 0 ?
          <>
            <div className="orders-list">
              {renderOrders()}
            </div>
            <div className="orders-container-pagination">
              <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handleChange} />
            </div>
          </>
          :
          <div className="no-orders-data">
            <img src="/assets/images/errors/nodata.png" alt="" />
            No Transactions Found
          </div>
        }
      </div>
    );
  }

  return (
    <div className="orders-container">
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default OrderList;