import { Button, FormControl, Grid, Switch, TextField } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { approveHistory, findAllHistory, saveHistory } from '../../feature/history/history-service';
import { isEmpty } from '../../services/util-service';
import { EMPTY_STRING, ROLE_ADMIN, ROLE_USER } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import ImageTextfield from '../../components/text-field/image-textfield.component';
import DateComponent from '../../components/date/date.component';
import './admin-tdty.styles.scss';
const AdminTDTY = () => {

  const [showLoader, setShowLoader] = useState(false);
  const appContext = useContext(AppContext);
  const [tdtyList, setTdtyList] = useState([]);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [isReviewed, setIsReviewed] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const initFormData = {
    historyDate: null,
    description: EMPTY_STRING,
    language: 'English',
    isActive: 0,
    isFree: 1
  };
  const [userRole, setRole] = useState(ROLE_USER);
  const [formData, updateFormData] = useState(initFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (appContext.userData) {
      setRole(appContext.userData.role);
    }
  }, [appContext]);

  useEffect(() => {
    if (appContext.userData) {
      getAllHistory(isReviewed, page);
    }
  }, [page]);

  const onSubmit = (event) => {
    setShowLoader(true);
    if (isValidQuestion()) {
      saveHistory(formData).subscribe({
        next: (response) => {
          getAllHistory(isReviewed, 1);
          updateFormData(initFormData);
          appContext.setMessage(response.message);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const handleToggle = (e) => {
    setIsReviewed(e.target.checked);
    setPage(1);
    getAllHistory(e.target.checked, 1)
  };

  const getAllHistory = (isReviewed, pageNumber) => {
    const reviewed = isReviewed === true ? 1 : 0;
    setShowLoader(true);
    findAllHistory(reviewed, pageNumber - 1).subscribe({
      next: (response) => {
        setTdtyList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleImageChange = (e, binaryImage) => {
    updateFormData({
      ...formData,
      [e.target.name]: binaryImage
    });
  };

  const handleDeleteImage = (imageFieldName) => {
    updateFormData({
      ...formData,
      image: null,
      url: null
    });
  };


  const isValidQuestion = () => {
    let valid = false;
    if (isEmpty(formData.description)) {
      appContext.setDialogMessage('Please add \'Description\' to add the TDTY');
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  const onApprove = () => {
    setMessage('Do you want to approve this level ?');
    setShowMsgDialog(true);
  }

  const onOkClick = () => {
    approveHistory().subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        getAllHistory(true, 1);
        setIsReviewed(true);
      }
    });
  }

  const onEditQuestion = (event) => {
    updateFormData(event);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const headerFormPanel = () => {
    return <div className="header-panel">
      <div className="header-field">
        <DateComponent name="historyDate" label="TDTY Date" onDateSelection={handleChange} value={formData.historyDate || EMPTY_STRING}
          defaultDate={formData.historyDate ? moment.utc(formData.historyDate) : null} variant="outlined" size="small" />
      </div>
      <div className="header-field">
        <TextField name="description" value={formData.description} label="Description" fullWidth rows="3" multiline="true"
          required onChange={handleChange} variant="outlined" size="small">
        </TextField>
      </div>
      <ImageTextfield id="tdty-image" image={formData.url} handleTextChange={handleChange} 
        imageFieldName="image" handleImageChange={(e, binaryImg) => { handleImageChange(e, binaryImg) }} onDeleteImage={handleDeleteImage} 
        imagePath="url">
      </ImageTextfield>
      <div className="header-field">
        <Button variant="contained" color="secondary" onClick={onSubmit}> Submit </Button>
      </div>
    </div>
  }

  const getQuestionList = () => {
    return <div className="tdty-list"><div className="tdty-answers-details">
      {tdtyList.map((tdty, index) => (
        <div className="tdty-answers-row">
          <div className="tdty-answers-label">TDTY: {tdty.historyDate}
            {userRole === ROLE_ADMIN || tdty.isReviewed === 0 ? <span className="questionListIcon">
              <EditOutlinedIcon className="icon" onClick={() => { onEditQuestion(tdty) }} />
            </span> : null}
          </div>
          <div className="tdty-answers-question">
            {tdty.description === EMPTY_STRING ? null : tdty.description}
          </div>
          <img src={tdty.url} ></img>
        </div>
      ))}
    </div>
    </div>
  }

  const renderMain = () => {
    return <div className="tdty-container">
      {headerFormPanel()}
      <div className='bottom-panel'>
        <div className="tdty-request-title">This Day That Year</div>
        <div>Is Reviewed <Switch checked={isReviewed} onChange={handleToggle} />
          <span>
            <FormControl className="header-field">
              {userRole === ROLE_ADMIN && !isReviewed ? <Button variant="contained" color="primary" onClick={onApprove} > Approve All </Button> : null}
            </FormControl>
          </span>
          <span>Total =  {totalCount}</span>
        </div>
        <Grid justifyContent="center" container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {tdtyList.length > 0 ? getQuestionList() : null}
          </Grid>
        </Grid>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} variant="outlined" color="secondary" />
      </div>
      <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
    </div>
  }

  return (
    <>
      {showLoader ? <Loader /> :
        renderMain()
      }
    </>
  );
}

export default AdminTDTY;
