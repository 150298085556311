
import { Accordion, AccordionDetails, AccordionSummary, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { TabPanel, a11yProps, useStyles } from '../services/tab-util-service';
import { TREND_ACTIVITY, TREND_DAILY, TREND_MONTHLY, TREND_USER } from '../components/constants/constants.js';
import './admin-dashboard.styles.scss';
import AdminFeedback from './admin-feedback/feedback.component';
import AdminRedeem from './admin-redeem/admin-redeem.component';
import AdminReferral from './admin-referral/admin-referral.component';
import AdminSales from './admin-sales/admin-sales.component';
import AdminTrend from './admin-trend/admin-trend.component';
import QuestionCounts from './question-counts/question-counts.component';
import QuestionReport from './question-report/question-report.component';
import FreeSubscriptionComponent from './subscription-payment/subscription-payment.component';
import UserList from './user-list/user-list.component';

const AdminDashboard = () => {

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderView = () => {
    return (
      <div className={classes.root}>
        <Tabs
          className="tab-header"
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="User" {...a11yProps(0)} />
          <Tab label="Graphs" {...a11yProps(1)} />
          <Tab label="Redeem" {...a11yProps(2)} />
          <Tab label="Feedback" {...a11yProps(3)} />
          <Tab label="Transactions" {...a11yProps(4)} />
          <Tab label="Referral" {...a11yProps(5)} />
          <Tab label="Question report" {...a11yProps(6)} />
          <Tab label="Question counts" {...a11yProps(7)} />
          <Tab label="Free subscription" {...a11yProps(8)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="dashboard-container-box">
            <UserList></UserList>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {getTrendGraph()}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="dashboard-container-box">
            <AdminRedeem></AdminRedeem>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="dashboard-container-box">
            <AdminFeedback></AdminFeedback>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="dashboard-container-box">
            <AdminSales></AdminSales>
          </div>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <div className="dashboard-container-box">
            <AdminReferral></AdminReferral>
          </div>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <div className="dashboard-container-box">
            <QuestionReport></QuestionReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <div className="dashboard-container-box">
            <QuestionCounts></QuestionCounts>
          </div>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <div className="dashboard-container-box">
            <FreeSubscriptionComponent></FreeSubscriptionComponent>
          </div>
        </TabPanel>
      </div>
    );
  }
  const getTrendGraph = () => {
    return<div className='graph-panel'> 
      <Grid justifyContent="center" container spacing={3}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            User Monthly Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_MONTHLY} trend={TREND_USER} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            User Daily Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_DAILY} trend={TREND_USER} />
          </div>
        </div>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            Sales Monthly Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_MONTHLY} trend={TREND_SALES} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            Sales Daily Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_DAILY} trend={TREND_SALES} />
          </div>
        </div>
      </Grid> */}
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            Activity Monthly Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_MONTHLY} trend={TREND_ACTIVITY} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="dashboard-container-box">
          <div className="dashboard-card-title">
            Activity Daily Trend
          </div>
          <div className="dashboard-card-content">
            <AdminTrend chartType={TREND_DAILY} trend={TREND_ACTIVITY} />
          </div>
        </div>
      </Grid>
    </Grid>
    </div>
  }


  const renderMobileView = () => {
    return <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">User</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <UserList></UserList>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Graphs</div>
        </AccordionSummary>
        <AccordionDetails>
          {getTrendGraph()}
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Redeem</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AdminRedeem></AdminRedeem>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Feedback</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AdminFeedback></AdminFeedback>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Transaction</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AdminSales></AdminSales>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Referral</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AdminReferral></AdminReferral>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleAccordionChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Question Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <QuestionReport></QuestionReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleAccordionChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Question Counts</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <QuestionCounts></QuestionCounts>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleAccordionChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Free Subscription</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <FreeSubscriptionComponent></FreeSubscriptionComponent>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  }

  return (
    <div>
      <Hidden only={['xs', 'sm']}>
        {renderView()}
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        {renderMobileView()}
      </Hidden>
    </div>
  );
}

export default AdminDashboard;