import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import './result-dialog.styles.scss';
import { Rif, isNotEmpty } from '../../../services/util-service';

export const ResultDialog = (props) => {

  const onOkClick = (onClose) => {
    onClose();
  }

  const getAnswerClass = (isOptionMarked, isOptionCorrect) => {
    // Return the color class for answer: Purple if selected, Green for correct
    let answerClass = 'answer';
    if (props.result[isOptionMarked] === 1) {
      answerClass = 'selectedAnswer';
    }
    if (props.result[isOptionCorrect] === 1) {
      answerClass = 'selectedAnswer correctAnswer';
    }
    return answerClass;
  }

  const renderQuiz = (props) => {
    return (
      <div >
        <div className="questionNumber">Question {props.result.number}</div>
        <div className='resultQuestion'>{props.result.questionText}</div>
        <div className="resultImage">
          {(() => {
            if (props.result.questionImagePath !== null) {
              return <img src={props.result.questionImagePath} alt="" />
            }
          })()}

        </div>
        <div className="resultAnswers">
          <Grid container spacing={3}>
            <Rif boolean={isNotEmpty(props.result.option1Text) || isNotEmpty(props.result.option1ImagePath)}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={getAnswerClass('isOption1Marked', 'isOption1Correct')} > A. &nbsp; {props.result.option1Text}
                {(() => {
                  if (props.result.option1ImagePath !== null) {
                    return <img src={props.result.option1ImagePath} alt="O" />
                  }
                })()}</div>
            </Grid>
            </Rif>
            <Rif boolean={isNotEmpty(props.result.option2Text) || isNotEmpty(props.result.option2ImagePath)}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={getAnswerClass('isOption2Marked', 'isOption2Correct')} > B. &nbsp; {props.result.option2Text}
                {(() => {
                  if (props.result.option2ImagePath !== null) {
                    return <img src={props.result.option2ImagePath} alt="O" />
                  }
                })()}</div>
            </Grid>
            </Rif>
            <Rif boolean={isNotEmpty(props.result.option3Text) || isNotEmpty(props.result.option3ImagePath)}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={getAnswerClass('isOption3Marked', 'isOption3Correct')} > C. &nbsp; {props.result.option3Text}
                {(() => {
                  if (props.result.option3ImagePath !== null) {
                    return <img src={props.result.option3ImagePath} alt="O" />
                  }
                })()}</div>
            </Grid> 
            </Rif>
            <Rif boolean={isNotEmpty(props.result.option4Text) || isNotEmpty(props.result.option4ImagePath)}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className={getAnswerClass('isOption4Marked', 'isOption4Correct')} > D. &nbsp; {props.result.option4Text}
                {(() => {
                  if (props.result.option4ImagePath !== null) {
                    return <img src={props.result.option4ImagePath} alt="O" />
                  }
                })()}</div>
            </Grid>
            </Rif>
          </Grid>
        </div>
        <div className="resultButtons">
          <Button variant="contained" onClick={() => { onOkClick(props.onClose) }} color="secondary">Close</Button>
        </div>
      </div>

    );
  }

  return (
    <Dialog maxWidth={false} {...props}>
      <Hidden only={['xs']}>
        <div className="result-container">
          {renderQuiz(props)}
        </div>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md', 'sm']}>
        <div className="result-container result-containerMobile">
          {renderQuiz(props)}
        </div>
      </Hidden>
    </Dialog>
  );
}

export default ResultDialog;