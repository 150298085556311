import { Button, TextField } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { AppContext } from "../../app-context";
import { EMPTY_STRING } from "../../components/constants/constants";
import Loader from "../../components/loader/loader.component";
import { calculateAmount, createOrder } from "./payment-service";
import { Rif, isNotEmpty, numberFormat } from "../../services/util-service";
// import { Checkout } from 'capacitor-razorpay';
import './payment.styles.scss';
const PaymentComponent = (props) => {

  const paymentId = props.match.params.paymentId;
  const couponCodeParam = props.match.params.couponCode;
  const appContext = useContext(AppContext);
  const [purchaseDetail, setPurchaseDetail] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [showPayment, setShowPayment] = useState(true);
  const [editable, setEditable] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const coupon = couponCodeParam ? couponCodeParam : EMPTY_STRING;
  const [couponCode, setCouponCode] = useState(coupon);
  const history = useHistory();

  useEffect(() => {
    if (!appContext.userData) {
      appContext.setDialogMessage('Please login to proceed for payment.');
      history.push('/signin');
    }
    if (appContext.userData && orderDetail !== null) {
      paymentHandler();
    }
  }, [orderDetail]);

  useEffect(() => {
    if (appContext.userData && purchaseDetail === null && editable) {
      fetchPurchaseDetail();
    }
  }, []);

  const fetchPurchaseDetail = () => {
    calculateAmount(paymentId, couponCode).subscribe({
      next: (response) => {
        setPurchaseDetail(response.data);
        setShowLoader(false);
        if (isNotEmpty(couponCodeParam)) {
          setEditable(false);
        }
      }, error: (error) => {
        setShowLoader(false);
        appContext.setDialogMessage(error.message);
      }
    });
  }

  const processOrder = (e) => {
    e.preventDefault();
    if (purchaseDetail.couponCode === couponCode) {
      process();
    } else {
      const message = firstTime ? 'Please apply the coupon code to recalculate the final price.' : 'You have changed the coupon code. Please apply to recalculate the final price.'
      appContext.setDialogMessage(message);
      setFirstTime(false);
    }
  }

  const process = () => {
    setShowLoader(true);
    if (paymentId && orderDetail === null) {
      createOrder(paymentId, couponCode).subscribe({
        next: (response) => {
          setOrderDetail(response.data);
          setShowLoader(false);
          setEditable(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
        }
      });
    } else {
      paymentHandler();
    }
  }

  const handleChange = (e) => {
    setCouponCode(e.target.value.toUpperCase());
  };

  const applyCoupon = (e) => {
    fetchPurchaseDetail();
  };

  const paymentHandler = () => {
    const options = {
      key: orderDetail.key,
      amount: purchaseDetail.invoiceValue * 100,
      name: 'Payments',
      description: `Payment of ${purchaseDetail.invoiceValue}`,
      order_id: orderDetail.purchaseModel.orderId,
      modal: {
        "ondismiss": function () {
          setShowLoader(false);
        }
      }
    };
    // from(Checkout.open(options)).subscribe({
    //   next: (res) => {
    //     let response = res.response;
    //     const purchaseModel = orderDetail.purchaseModel;
    //     purchaseModel.externalPaymentId = response['razorpay_payment_id'];
    //     purchaseModel.externalOrderId = response['razorpay_order_id'];
    //     purchaseModel.paymentProviderSignature = response['razorpay_signature'];
    //     if (purchaseDetail) {
    //       setShowLoader(true);
    //       validatePayment(purchaseModel).subscribe({
    //         next: (result) => {
    //           setShowPayment(false);
    //           setShowLoader(false);
    //         }, error: (error) => {
    //           setShowLoader(false);
    //           if (error.message) {
    //             appContext.setDialogMessage(error.message);
    //           } else {
    //             appContext.setDialogMessage('Payment Failed. Please Retry');
    //           }

    //         }
    //       });
    //     }
    //   }, error: (err) => {
    //     if (err.description) {
    //       appContext.setDialogMessage(err.description);
    //     } else {
    //      const error = JSON.parse(err);
    //       appContext.setDialogMessage(error.description);
    //     }
    //     setShowLoader(false);
    //   }
    // });

  }

  const renderView = () => {
    return (
      <div className="wrapper">
        <div className="payments">
          <div className="payments-form">
            <form onSubmit={processOrder}>

              {
                showPayment ?
                  <div>
                    <div className="payments-title">
                      Order Summary
                    </div>
                    <div className="formLabel"><span className="paymentLabel">Package Name : </span><span className="paymentValue">{purchaseDetail.packageName}</span></div>
                    <div className="formLabel"><span className="paymentLabel">Package Price : </span><span className="paymentValue">{numberFormat(purchaseDetail.packagePrice)}</span></div>
                    <div className="line-break">&nbsp;</div>
                    <div className="formLabel"><span className="paymentLabel">Taxable Amount : </span><span className="paymentValue">{numberFormat(purchaseDetail.taxableAmount)}</span></div>
                    <Rif boolean={purchaseDetail.couponDiscountPercentage > 0 && purchaseDetail.isValidCoupon === 1}>
                      <div className="formLabel"><span className="paymentLabel">Discount ({purchaseDetail.couponDiscountPercentage}%) : </span><span className="paymentValue">{numberFormat(purchaseDetail.discount)}</span></div>
                    </Rif>
                    <div className="formLabel"><span className="paymentLabel">IGST (18%) : </span><span className="paymentValue">{numberFormat(purchaseDetail.igstValue)}</span></div>
                    <div className="line-break">&nbsp;</div>
                    <div className="formLabel"><span className="paymentLabel">Final Amount : </span><span className="paymentValue">{numberFormat(purchaseDetail.invoiceValue)}</span></div>

                    {/* {showCouponField || isNotEmpty(couponCodeParam) ? */}
                    <div className="formField coupon-code">
                      <TextField name="name" value={couponCode} disabled={!editable} onChange={handleChange} label="Apply coupon code" variant="outlined" size="small" />
                      <Button variant="contained" onClick={applyCoupon} color="secondary" disabled={!editable} >
                        Apply
                      </Button>
                      <Rif boolean={purchaseDetail.isValidCoupon === 0}>
                        <div className="error-msg">
                          Invalid Coupon Code
                        </div>
                      </Rif>
                    </div>
                    {/* : <div className="apply-coupon" onClick={() => { setShowCouponField(true) }}>Have a coupon? Apply here.</div>} */}
                    <div className="purchaseButtonContainer"><Button type="submit" variant="contained" color="secondary" className="purchaseButton">Confirm and Pay</Button></div>
                  </div>
                  :
                  <div>
                    <div className="payments-success">
                      <img src="/assets/images/packages/successful.png" alt="" />
                      <br />
                      Thank you.
                      <br></br>Your order has been completed successfully!!
                      <div className="purchaseButtonContainer">
                        {/* <Button variant="contained" color="secondary" className="purchaseButton" href="/">Home</Button> */}
                        <Button variant="contained" color="secondary" className="redirect-button" href="/userdashboard">Go To Dashboard</Button>
                      </div>
                    </div>
                  </div>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="order-summary-page">
      <Grid justifyContent="center" container spacing={3} xs={12}>
        <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
          <div className="order-summary-container">
            {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default PaymentComponent;