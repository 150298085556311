import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAttempts } from '../scorecard/score-service';
import Loader from '../../../components/loader/loader.component';
import './scorecard.styles.scss';

const AttemptList = (props) => {

  const grade = props.match.params.grade;
  const subject = props.match.params.subject;
  const topic = props.match.params.topic;
  const level = props.match.params.level;
  const [attemptList, setAttemptList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const history = useHistory();


  useEffect(() => {
    if (attemptList.length === 0) {
      fetchAttempts();
    }
  }, []);


  const fetchAttempts = () => {
    getAttempts(grade, subject, topic, level).subscribe({
      next: (response) => {
        // if (response.data.length === 1) {
        //   goToScorecard(response.data[0].rowId);
        // } else {
        setAttemptList(response.data);
        setShowLoader(false);
        // }
      }});
  }

  const goToScorecard = (attemptId) => {
    history.push(`/scorecard/${grade}/${subject}/${topic}/${level}/${attemptId}`);
  }

  const renderAttemptList = () => {
    return <div>
      {attemptList.length > 0 ?

        <div className="scorecard-page-common">
          <Grid container justify="center" xs={12}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <div className="scorecard-previous-attempts-common">
                <div className="attempts-image">
                  <img src="/assets/images/scorecard/attempt.png" alt="" />
                </div>
                <div className="previous-attempt-subject">
                  {attemptList[0].subject} - {attemptList[0].topic} (Level {attemptList[0].level})
                </div>
                <div className="previous-attempt-header">
                  Select the attempt for which you want to see the scorecard
                </div>
                <div className="previous-attempt-list-header">
                  <Grid container justify="center" xs={12}>
                    <Grid item xs={2}>
                      #
                    </Grid>
                    <Grid item xs={6}>
                      Date
                    </Grid>
                    <Grid item xs={2}>
                      Score (%)
                    </Grid>
                    <Grid item xs={2}>
                      &nbsp;
                    </Grid>
                  </Grid>
                </div>
                <div className="previous-attempt-list-data">

                  {attemptList.map((attempt, index) => (
                    <Grid key={attempt.rowId} className="attempt-row" onClick={() => { goToScorecard(attempt.rowId) }} container justify="center" xs={12}>
                      <Grid item xs={2}>
                        {index + 1}
                      </Grid>
                      <Grid item xs={6}>
                        {attempt.creationDate ? moment(attempt.creationDate).format('lll') : null}
                      </Grid>
                      <Grid item xs={2}>
                        {attempt.scorePercentage ? attempt.scorePercentage : 0}
                      </Grid>
                      <Grid item xs={2}>
                        <span>View</span>
                      </Grid>
                    </Grid>
                  ))
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        :
        <div className="scorecard-page"> No result found</div>}
    </div >
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> :
        renderAttemptList()
      }
    </div>
  );

}

export default AttemptList;