import Lottie from "lottie-react";
import { useContext, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AppContext } from "../../app-context";
import { isUserExist, saveUserProfile, socialSignin } from "../../feature/user-dashboard/user-service";
import { ACCESS_TOKEN, CONTEST_CODE, EMPTY_STRING, MANDATORY_FIELD_MSG, REFERRAL_CODE } from "../../components/constants/constants";
import { doSignInWithGoogle } from "../../components/firebase/firebase.service";
import phoneNumber from "../../components/phone-number/phone-number";
import loginAnimation from "./animations/login.json";
import CudentsSignupComponent from "./cudents-signup.component";
import './login.scss';
import PhoneSigninComponent from "./phone-signin.component";


const Login = (props) => {

    const contestCode = props.match.params.contestCode;
    const referralCode = props.match.params.referralCode;
    const refCode = sessionStorage.getItem(REFERRAL_CODE);
    const appContext = useContext(AppContext);
    const [phone, setPhone] = useState(EMPTY_STRING);
    const history = useHistory();
    const [formError, setFormError] = useState({});
    const [title, setTitle] = useState('Login');
    const [errorMessage, setErrorMessage] = useState(EMPTY_STRING);
    const [isSignin, setSignin] = useState(false);
    const [isSignup, setSignup] = useState(false);
    const [showWelcomePage, setShowWelcomePage] = useState(false);


    useEffect(() => {
        if (contestCode) {
            sessionStorage.setItem(CONTEST_CODE, contestCode);
        }
        if (referralCode) {
            sessionStorage.setItem(REFERRAL_CODE, referralCode);
        }
        if (appContext.userData) {
            if (contestCode) {
                history.push(`/contest/code/${contestCode}`);
            } else {
                history.push('/');
            }
        } else {
            if (localStorage.getItem(ACCESS_TOKEN)) {
                localStorage.removeItem(ACCESS_TOKEN);
            }
        }
    }, []);

    const onGoogleLogin = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        doSignInWithGoogle()
            .then(socialAuthUser => {
                handleSocialLogin(socialAuthUser, 'google');
            })
            .catch(error => {
                console.log(error.message);
            });

    }

    const handleSocialLogin = (socialAuthUser, provider) => {
        const userModel = {
            providerId: socialAuthUser.additionalUserInfo.profile.id,
            name: socialAuthUser.additionalUserInfo.profile.name,
            userId: socialAuthUser.additionalUserInfo.profile.email,
            provider: provider,
            authToken: socialAuthUser.credential.accessToken
        }
        socialSignin(userModel).subscribe({
            next: (response) => {
                localStorage.setItem(ACCESS_TOKEN, response.data.authToken);
                if (response.data.hasDisplayName) {
                    appContext.setUserData(response.data);
                    history.push('/userdashboard');
                } else {
                    saveProfile(response.data);
                }
            }});
    }


    const saveProfile = (data) => {
        const tempPath = 'https://www.cudents.com/prod/images/avatars/avatar' + (Math.floor(Math.random() * 24) + 1) + '.png';
        const displayName = data.rowId ? 'Cudent' + data.rowId : 'Cudent';
        const userModel = {
            imagePath: tempPath,
            name: displayName,
            referralCode: refCode,
            grade: 1
        }
        onSaveUserProfile(userModel);
    }

    const onSaveUserProfile = (userModel) => {
        saveUserProfile(userModel).subscribe({
            next: (response) => {
                appContext.setSuccessMessage(response.message);
                const tempUser = {
                    ...appContext.userData,
                    profilePicPath: response.data.imagePath,
                    displayName: response.data.name,
                    grade: response.data.grade,
                    provider: response.data.provider
                };
                appContext.setUserData(tempUser);
                setShowWelcomePage(true);
            }});
    }

    const onProceedLogin = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        if (handleValidation()) {
            isUserExist(phone).subscribe({
                next: (response) => {
                    setSignin(response.data);
                    setSignup(!response.data);
                }, error : (error ) => {
                    setErrorMessage(error.message);
                }});
        }
    }

    const handleValidation = () => {
        setErrorMessage(EMPTY_STRING);
        let errors = {};
        let formIsValid = true;
        if (!phone) {
            formIsValid = false;
            errors["phoneNumber"] = MANDATORY_FIELD_MSG;
        } else if (!isValidPhoneNumber(phone)) {
            formIsValid = false;
            errors["phoneNumber"] = 'Invalid phone number'
        }
        setFormError(errors);
        return formIsValid;
    }

    const googleLogin = () => {
        return <div >
            <div id="customBtn" onClick={onGoogleLogin}>
                <span className="icon"><img src="https://www.cudents.com/prod/images/login/google-logo.png" alt="" /></span>
                <span className="buttonText">Login with Google</span>
            </div>
        </div>
    }
    
  const onKeyPress = (e) =>{
    if (e.key === "Enter") {
        e.preventDefault();
        onProceedLogin();
  }
  }

    const cudentsLogin = () => {
        return <form noValidate autoComplete="off">
            <div className="formField">
                <PhoneInput country="IN" defaultCountry="IN" international countryCallingCodeEditable={false} value={phone || EMPTY_STRING}
                    required onChange={setPhone} limitMaxLength={5} inputComponent={phoneNumber} onKeyPress={(e) => {onKeyPress(e)}}
                />
                <span className="error-text">{formError["phoneNumber"]}</span>
            </div>
            <div className="tnc">By logging in you agree to our <a href="https://www.cudents.com/TnC/TermsAndConditions.html" target="_">T&C</a></div>
            <div id="customBtn" className="login-submit-button" onClick={onProceedLogin} >
                <span className="buttonText">PROCEED</span>
            </div>
            <Link to="/feedback" className="mobileMenuItem">
                <div>Contact us</div>
              </Link>
        </form>
    }

    const clientLogin = () => {
        return (
            <>
                <div className="login-container-responsive">
                    <div className="login-page-animation">
                        <Lottie className="login-page-top-animation" animationData={loginAnimation} loop={true} />
                    </div>
                    <div className="login-title-container">
                        <div className="login-page-title">{title}</div>
                    </div>
                    {errorMessage === EMPTY_STRING ? null : <div className="error-msg">{errorMessage}</div>}
                    <div className="bottom-form">
                        {cudentsLogin()}
                        {/* {isEmpty(contestCode) ? <><div className="or-separation">or</div>{googleLogin()}</> : null} */}
                    </div>
                </div>
            </>
        );
    }

    const backToLogin = () => {
        setSignin(false);
        setSignup(false);
    }

    const getLoginPage = () => {
        return <div className="login-page">
            {!isSignin && !isSignup ? <span>{clientLogin()}</span> : null}
            {isSignin ? <span><PhoneSigninComponent phone={phone} onBackToLogin={backToLogin}></PhoneSigninComponent></span> : null}
            {isSignup ? <span><CudentsSignupComponent phone={phone} onSaveUserProfile={(userModel) => { onSaveUserProfile(userModel) }}
            onBackToLogin={backToLogin}></CudentsSignupComponent></span> : null}
      <div className="bottomArea">
        
      </div>
        </div>
    }

    const getWelcomePage = () => {
        return <div className="login-page">
            <div className="basic-details-form">
                <div className="formContainer profile-success">
                    <div className="profile-success-image">
                        <img src="https://www.cudents.com/prod/images/profile/profileSuccess.png" alt="" />
                    </div>
                    <div className="profile-success-buttons">
                        {/* <div className="success-button" onClick={() => { history.push('/assessment') }} ><span>Take Free Assessment</span></div> */}
                        <div className="success-button" onClick={() => { history.push('/userdashboard') }}><span>Go To Dashboard</span></div>
                    </div>
                </div>
            </div>
        </div>
    }

    return <>{showWelcomePage ? getWelcomePage() : getLoginPage()}</>
}

export default Login;
