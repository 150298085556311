
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useState } from 'react';
import { a11yProps, TabPanel, useStyles } from '../../services/tab-util-service';
import './admin-attempts-dashboard.styles.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Hidden from '@material-ui/core/Hidden';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttemptReport from './attempt/attempt.component.jsx';
import AssessAttempt from './assess-attempt/assess-attempt.component.jsx';
import ScoreReport from '../score-report/score-report.component.jsx';
import GameAttempt from './game-attempt/game-attempt.component.jsx';
import AttemptTrend from './attempt-trend/attempt-trend.component.jsx';

const AdminAttemptsDashboard = () => {

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderView = () => {
    return (
      <div className={classes.root}>
        <Tabs
          className="tab-header"
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Attempt Trend" {...a11yProps(0)} />
          <Tab label="Attempt Report" {...a11yProps(1)} />
          <Tab label="Assess Attempt" {...a11yProps(2)} />
          <Tab label="Score Report" {...a11yProps(3)} />
          <Tab label="Game Attempt" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="dashboard-container-box">
            <AttemptTrend></AttemptTrend>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="dashboard-container-box">
            <AttemptReport></AttemptReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="dashboard-container-box">
            <AssessAttempt></AssessAttempt>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="dashboard-container-box">
            <ScoreReport></ScoreReport>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="dashboard-container-box">
            <GameAttempt></GameAttempt>
          </div>
        </TabPanel>
      </div>
    );
  }

  const renderMobileView = () => {
    return <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Attempt Trend</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AttemptTrend></AttemptTrend>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Attempt Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AttemptReport></AttemptReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Assess Attempt</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <AssessAttempt></AssessAttempt>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Score Report</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <ScoreReport></ScoreReport>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="heading">Game Attempt</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="dashboard-container-box">
            <GameAttempt></GameAttempt>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  }

  return (
    <div>
         <Hidden only={['xs', 'sm']}>
            {renderView()}
        </Hidden>
        <Hidden only={['xl', 'lg', 'md']}>
            {renderMobileView()}
        </Hidden>
    </div>
  );
}

export default AdminAttemptsDashboard;