import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import videojs from 'video.js';
import { EMPTY_STRING, VIDEO_TYPE_LEARNING, VIDEO_TYPE_NEWS, VIDEO_TYPE_RHYME, VIDEO_TYPE_STORY, VIDEO_TYPE_TOYS } from "../../components/constants/constants";
import Loader from "../../components/loader/loader.component";
import { logService } from '../../services/logging-service';
import { findVideoById } from "../../services/video-service";
import './cudents-video-player.styles.scss';
import VideoJS from './video-js.component';
import { isWebPlatform } from '../../services/util-service';

function CudentsVideoPlayer(props) {
  const playerRef = React.useRef(null);
  const videoId = props.match.params.videoId;
  const type = props.match.params.type;
  const [videoData, setVideoData] = useState(null);
  const [videoJsOptions, setVideoJsOptions] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (videoData === null) {
      loadVideo();
      if(!isWebPlatform()){
        window.screen.orientation.unlock();
      }
    }
  }, []);

  const loadVideo = () => {
    findVideoById(videoId).subscribe({
      next: (response) => {
        setVideoData(response.data);
        logVideo(response.data);
        setVideoJsOptions({
          autoplay: false,
          controls: true,
          responsive: true,
          fluid: true,
          sources: [{
            src: response.data.url,
            type: 'video/mp4'
          }]
        });
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const logVideo = (data) => {
    logService(data.type, data.name).subscribe({
      next: (response) => {
      }, error: (error) => {
        console.log(error.message);
      }
    });
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  const fullZoom = () => {

  }

  const getParentStyle = () => {
    let className = "cudents-video-player-page";
    switch (type) {
      case VIDEO_TYPE_NEWS:
        className = "news-video-player-page";
        break;
      case VIDEO_TYPE_TOYS:
        className = "toys-video-player-page";
        break;
      case VIDEO_TYPE_RHYME:
        className = "rhyme-video-player-page";
        break;
      case VIDEO_TYPE_STORY:
        className = "story-video-player-page";
        break;
      case VIDEO_TYPE_LEARNING:
        className = "learning-video-player-page";
        break;
      default:
        break;
    }
    return className;
  }

  return (<>
    {showLoader ? <div className="showLoading"><Loader /></div> :
      <div className={getParentStyle()}>
        <div className="video-player-container">          
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} thrumbnailImage={videoData.thumbnailImagePath}/>
        </div>
        {/* <div className="video-details-container">
          <div className="video-like-container">
            <Lottie className="video-like-animation" animationData={likevideo} loop={false} />
            <div className="like-icon"><ThumbUpOutlinedIcon></ThumbUpOutlinedIcon></div>
            <span>Like</span>
            &nbsp;
          </div>
          <div className="video-like-container">
            <Lottie className="video-like-animation" animationData={likevideo} loop={false} />
            <div className="like-icon"> <ShareOutlinedIcon></ShareOutlinedIcon> </div>
            <span>Share</span>
            &nbsp;
          </div>
        </div> */}
        <div className="video-description-container">
          <div className="video-description-title">
            Name
          </div>
          <div className="video-description-content">
            {videoData.name}
          </div>
        </div>
        <div className="video-description-container">
          <div className="video-description-title">
            Description
          </div>
          <div className="video-description-content">
          <TextField name="description" value={videoData.description || EMPTY_STRING} fullWidth rows="50" multiline="true"
               disabled size="small"/>
            {/* {videoData.description} */}
          </div>
        </div>

      </div>}</>
  );
}

export default CudentsVideoPlayer;