import Lottie from "lottie-react";
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import preschool from "./animations/preschool.json";
import primary from "./animations/primary.json";
import './group-landing.styles.scss';
import { AppContext } from "../../app-context";

const GroupLandingPage = (props) => {


    const appContext = useContext(AppContext);
    const history = useHistory();
    const [currentGrade, setCurrentGrade] = useState(null);

    useEffect(() => {
        if (appContext.userData !== null && currentGrade === null) {
            setCurrentGrade(appContext.userData.grade);
            window.screen.orientation.lock('portrait');
        }
    }, []);

    const navigateTo = (whereTo) => {
        switch (whereTo) {
            case 'new':
                history.push('/group/new');
                break;
            case 'mygroup':
                history.push('/group/list');
                break;
            default:
                break;
        }
    }



    return <div className="group-landing-page">
       <div className="group-landing-inner">
         <div className="group-banner">
            <img src="https://www.cudents.com/prod/images/groups/group-home.webp"/>
        </div>
        <div className="group-list">
            <div className="group-item">
                <div className="group-image">
                    <Lottie className="group-animation" animationData={primary} loop={true} />
                </div>
                <div className="group-button" onClick={() => { navigateTo('new') }}>
                    Create Group
                </div>
            </div>
            <div className="group-item">
                <div className="group-image">
                    <Lottie className="group-animation" animationData={preschool} loop={true} />
                </div>
                <div className="group-button" onClick={() => { navigateTo('mygroup') }}>
                    My Groups
                </div>
            </div>
            </div>
        </div>
    </div>
}

export default GroupLandingPage;