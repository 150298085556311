import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { getAllContests, getAllMasterContests, saveContest } from '../../feature/contest/contest-service';
import { StyledTableCell, StyledTableRow, useStyles } from '../../services/grid-style';
import { getAllInstitutions } from '../service/institution-service';
import { CONTEST_GRADE_TYPE, EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import DateTimeComponent from '../../components/date-time/date-time.component';
import './admin-contest.styles.scss';

const AdminContest = () => {
  const initData = {
    institutionId: EMPTY_STRING, contestCode: EMPTY_STRING, contestName: EMPTY_STRING, contestType: EMPTY_STRING, gradeType: EMPTY_STRING,
    winnerCount: EMPTY_STRING, tncLink: EMPTY_STRING, primaryOwnerId: EMPTY_STRING, secondaryOwnerId: EMPTY_STRING
  };
  const [contestList, setContestList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();
  const [formData, updateFormData] = useState(initData);
  const [formError, setFormError] = useState({});
  const [institutionList, setInstitutionList] = useState([]);
  const [institution, setInstitution] = useState(EMPTY_STRING);
  const [masterContestList, setMasterContestList] = useState([]);
  const [masterContest, setMasterContest] = useState(EMPTY_STRING);
  const appContext = useContext(AppContext);

  useEffect(() => {
    search(page);
  }, [page]);

  useEffect(() => {
    if (institutionList.length === 0) {
      loadAllInstitute();
    }
    if (contestList.length === 0) {
      loadContestList();
    }
  }, []);

  const loadContestList = () => {

    getAllMasterContests().subscribe({
      next: response => {
        setMasterContestList(response.data);
        setShowLoader(false);
      }, error: error => {
        setShowLoader(false);
      }
    });
  }

  const handlePage = (event, value) => {
    setPage(value);
  };

  const loadAllInstitute = () => {
    setShowLoader(true);
    getAllInstitutions().subscribe({
      next: (response) => {
        setInstitutionList(response.data);
      }
    });
  }

  const search = (selectedPage) => {
    setShowLoader(true);
    getAllContests(selectedPage - 1).subscribe({
      next: (response) => {
        setContestList(response.data.content);
        setPageCount(response.data.totalPages);
        setTotalCount(response.data.totalElements);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const savedata = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      setShowLoader(true);
      saveContest(formData).subscribe({
        next: (response) => {
          search(page);
          updateFormData(initData);
          appContext.setSuccessMessage(response.message);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
          appContext.setErrorMessage(error.message);
        }
      });
    }
  }

  const editContest = (contest) => {
    updateFormData(contest);
    const institution = getInstitutionFromList(contest.institutionId);
    setInstitution(institution);
    const masterContest = getMasterContestFromList(contest.masterContestId);
    setMasterContest(masterContest);
  }

  const getMasterContestFromList = (masterContestId) => {
    return masterContestList.find(element => element.rowId === masterContestId);
  }

  const getInstitutionFromList = (institutionId) => {
    return institutionList.find(element => element.rowId === institutionId);
  }

  const handleStartDateChange = (e) => {
    const startDate = moment(e.target.value);
    const endDate = moment(e.target.value).add(moment.duration(1, 'hours'));
    updateFormData({
      ...formData,
      [e.target.name]: startDate,
      endDate: endDate
    });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleInstitution = (e) => {
    setInstitution(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.rowId
    });
  };

  const handleMasterContest = (e) => {
    setMasterContest(e.target.value);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.rowId,
      contestName: e.target.value.contestName,
      contestType: e.target.value.contestType
    });
  };


  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["gradeType"]) {
      formIsValid = false;
      errors["gradeType"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["winnerCount"]) {
      formIsValid = false;
      errors["winnerCount"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["contestCode"]) {
      formIsValid = false;
      errors["contestCode"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["startDate"]) {
      formIsValid = false;
      errors["startDate"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["endDate"]) {
      formIsValid = false;
      errors["endDate"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["primaryOwnerId"]) {
      formIsValid = false;
      errors["primaryOwnerId"] = MANDATORY_FIELD_MSG;
    }
    if (!formData["secondaryOwnerId"]) {
      formIsValid = false;
      errors["secondaryOwnerId"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const renderContact = () => {
    return (
      <div className="contestFormContainer">
        <div className="contestTitle">Contest Details</div>
        <div className="contestForm">
          <form noValidate autoComplete="off" onSubmit={savedata}>
            <div className="formField">
              <TextField name="institutionId" value={institution || EMPTY_STRING} select label="Institution" onChange={handleInstitution} fullWidth variant="outlined" size="small">
                {institutionList.map((institution) => (
                  <MenuItem key={institution.rowId} value={institution}>
                    {institution.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="masterContestId" value={masterContest || EMPTY_STRING} select label="Master Contest" onChange={handleMasterContest} fullWidth variant="outlined" size="small">
                {masterContestList.map((masterContest) => (
                  <MenuItem key={masterContest.rowId} value={masterContest}>
                    {masterContest.contestName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="formField">
              <TextField name="contestCode" value={formData.contestCode || EMPTY_STRING} onChange={handleChange} required fullWidth label="Contest Code" variant="outlined" size="small" />
              <span className="error-text">{formError["contestCode"]}</span>
            </div>
            <div className="formField">
              <TextField name="winnerCount" value={formData.winnerCount || EMPTY_STRING} onChange={handleChange} required fullWidth label="Winner Count" variant="outlined" size="small" />
              <span className="error-text">{formError["winnerCount"]}</span>
            </div>
            <div className="formField">
              <TextField name="gradeType" value={formData.gradeType || EMPTY_STRING} select label="Grade Type" onChange={handleChange} fullWidth variant="outlined" size="small">
                {CONTEST_GRADE_TYPE.map((gradeType) => (
                  <MenuItem key={gradeType} value={gradeType}>
                    {gradeType}
                  </MenuItem>
                ))}
              </TextField>
              <span className="error-text">{formError["gradeType"]}</span>
            </div>
            <div className="formField">
              <DateTimeComponent name="startDate" label="Start Time" onDateSelection={handleStartDateChange} defaultDate={formData.startDate ? moment(formData.startDate) : null} />
              <span className="error-text">{formError["startDate"]}</span>
            </div>
            <div className="formField">
              <DateTimeComponent name="endDate" label="End Time" onDateSelection={handleChange} defaultDate={formData.endDate ? moment(formData.endDate) : null} />
              <span className="error-text">{formError["endDate"]}</span>
            </div>
            <div className="formField">
              <TextField name="tncLink" value={formData.tncLink || EMPTY_STRING} onChange={handleChange} required fullWidth label="TnC Link" variant="outlined" size="small" />
              <span className="error-text">{formError["tncLink"]}</span>
            </div>
            <div className="formField">
              <TextField name="primaryOwnerId" value={formData.primaryOwnerId || EMPTY_STRING} onChange={handleChange} required fullWidth label="Primary Owner" variant="outlined" size="small" />
              <span className="error-text">{formError["primaryOwnerId"]}</span>
            </div>
            <div className="formField">
              <TextField name="secondaryOwnerId" value={formData.secondaryOwnerId || EMPTY_STRING} onChange={handleChange} required fullWidth label="Secondary Owner" variant="outlined" size="small" />
              <span className="error-text">{formError["secondaryOwnerId"]}</span>
            </div>
            <div className="formField sendButton">
              {showLoader ? <div className="showLoading"><Loader /></div> : <Button type="submit" variant="contained" color="secondary">
                Submit
              </Button>}
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getContestsGrid = () => {
    return (
      <div className="contestFormContainer">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Contest Code</StyledTableCell>
                <StyledTableCell>Contest Name</StyledTableCell>
                <StyledTableCell>Institution Name</StyledTableCell>
                <StyledTableCell>Primary Owner Name</StyledTableCell>
                <StyledTableCell>Grade Type</StyledTableCell>
                <StyledTableCell>Start Date</StyledTableCell>
                <StyledTableCell>End Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contestList.map((contest) => (
                <StyledTableRow key={contest.contestCode}>
                  <StyledTableCell>{contest.contestCode}</StyledTableCell>
                  <StyledTableCell>{contest.contestName}</StyledTableCell>
                  <StyledTableCell>{contest.institutionName}</StyledTableCell>
                  <StyledTableCell>{contest.primaryOwnerName}</StyledTableCell>
                  <StyledTableCell>{contest.gradeType}</StyledTableCell>
                  <StyledTableCell>{moment(contest.startDate).format('DD-MM-YYYY hh:mm:ss A')}</StyledTableCell>
                  <StyledTableCell>{moment(contest.endDate).format('DD-MM-YYYY hh:mm:ss A')}</StyledTableCell>
                  <StyledTableCell>
                    <Button onClick={() => { editContest(contest) }} variant="contained" color="secondary">
                      Edit
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }

  return (
    <div className="admin-contest-details">
      {showLoader ? <div className="showLoading"><Loader /></div>
        : <>
          {renderContact()}
          {getContestsGrid()}
        </>
      }
    </div>
  );
}


export default AdminContest;


