import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import Loader from "../../loader/loader.component";
import PortraitLandscape from "../../portrait-to-landscape/portrait-to-landscape";
import { isNotEmpty, isNotEmptyArray, isNotNull } from "../../../services/util-service";
import cuteTiger from "./animations/cute_tiger.json";
import faceFly from "./animations/face_fly.json";
import levelClearCelebrate from "./animations/level_clear.json";
import "./match-game.styles.scss";

function MatchGame(props) {

  const [optionsList, setOptionsList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [answerData, setAnswerData] = useState({});
  const [showAnimation, setShowAnimation] = useState(false);
  const [correctAnswerCelebrate, setCorrectAnswerCelebrate] = useState(false);
  const [backgroundMusic, setBackgroundMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/background.mp3`));
  const [correctAnswerMusic, setCorrectAnswerMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/correct.mp3`));
  const [winLevelMusic, setWinLevelMusic] = useState(new Audio(`https://www.cudents.com/prod/assets/images/games/thecorrectsequence/levelwin.mp3`));

  const resetGame = () => {
    setOptionsList([]);
    setTimeout(() => {
      const options = props.questionDetails.options.filter(item => isNotEmpty(item.imagePath));
      const answer = props.questionDetails.options.filter(item => item.isCorrect === 1);
      if (isNotEmptyArray(answer)) {
        setAnswerData({ imagePath: answer[0].imagePath });
      }
      setOptionsList(options);
      setShowLoader(false);
    }, 500);
  }

  useEffect(() => {
    setShowLoader(true);
    resetGame();
  }, [props.questionDetails]);

  useEffect(() => {
    setShowAnimation(true);
    setCorrectAnswerCelebrate(false);
  }, [props.currentLevel])

  const getRandomAnimation = () => {
    if (props.currentLevel % 2 === 0) {
      return <Lottie animationData={cuteTiger} loop={true} />
    } else if (props.currentLevel % 3 === 0) {
      return <Lottie animationData={faceFly} loop={true} />
    } else {
      return <Lottie animationData={faceFly} loop={true} />
    }
  }

  // Verify if the level is complte. If yes then show celebration animation and next level button
  const verifyLevelClear = (currentOption) => {
    if (currentOption.isCorrect === 1) {
      if (props.music) {
        backgroundMusic.pause();
        winLevelMusic.volume = .2;
        winLevelMusic.play();
      }
      setCorrectAnswerCelebrate(true);
      setShowAnimation(false);
      props.correctAnswer();
    } else {
      setTimeout(() => {
        setCorrectAnswerCelebrate(false)
      }, 1000);
    }

  }

  // If right pic placed then show that pic in the answer sequence
  const displayAnswer = (option) => {
    if (option) {
      const matchedOption = option.dragData;
      if (props.music) {
        correctAnswerMusic.play();
      }
      setAnswerData(matchedOption);
      setCorrectAnswerCelebrate(option.isCorrect === 1);
      // verify if the level is complete?
      verifyLevelClear(matchedOption);
    }
  }

  // const getBGPath = () => {
  //   return 
  // }

  return (
    <>
      <div className="match-game-container">
        <div className="match-game-play-area">
          <div className="match-game-bg">
            <img src={`https://www.cudents.com/prod/assets/images/games/${props.gameFolderName}/game_bg.webp`} alt="" />
          </div>
          <div className="match-game-level-button">
            Level {props.currentLevel}
          </div>
          {/* Placeholder for the options to choose from */}
          {showLoader ? <div className="showLoading"><Loader /></div> : <>
            <div className="match-game-list-container">
              <div className="match-game-list-backdrop">
                {props.nextLevelButton ?
                  <div className="match-game-level-clear">
                    <div className="match-game-level-clear-animation">
                      <Lottie animationData={levelClearCelebrate} loop={true} />
                    </div>
                    <div className="match-game-next-level">
                      <div className="match-game-next-level-button" onClick={props.playNextLevel}>
                        <img src="https://www.cudents.com/prod/assets/images/games/thecorrectsequence/nextLevelButton.webp" alt="" />
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    {/* <div className="match-game-list-title">
                      Choose Any option
                    </div> */}
                    <div className="match-game-list-item">
                      {isNotNull(optionsList) ?
                        optionsList.map((option, index) => (
                          <> {isNotNull(option.imagePath) ?
                            <DragDropContainer targetKey={option.imagePath} dragData={option}>
                              <div className="match-game-list-item-image">
                                <img src={option.imagePath} alt="" />
                              </div> </DragDropContainer> : null
                          } </>
                        )) : <></>
                      }
                    </div>
                  </>
                }
              </div>
            </div>              
            {correctAnswerCelebrate ? null :
              <div className="match-game-question-answer-container">
                <div className="match-game-question">
                  {props.questionDetails.questionText}
                </div>
                <div className="match-game-image-container">
                <div className="match-game-question-container">
                  <div className="match-game-question-backdrop">
                    <div className="match-game-question-image">
                      <img src={props.questionDetails.questionImagePath} alt="" />
                    </div>
                  </div>
                </div>
                {/* Placeholder for the correct options to be dropped */}
                <div className="match-game-answer-container">
                  <div className="match-game-answer-backdrop">
                    {answerData != null ?
                      <DropTarget targetKey={answerData.imagePath} onHit={displayAnswer}>
                        <div className="match-game-answer-image">
                          {answerData.isCorrect === 1 ?
                            <img src={answerData.imagePath} alt="" />
                            :
                            <div className="match-game-answer-blank-box"></div>
                          }
                        </div>
                      </DropTarget> : <></>}
                  </div>
                </div>
                </div>
              </div>
              }
          </>}
        </div>
      </div>
      <div className="match-game-portrait">
        <PortraitLandscape></PortraitLandscape>
      </div>
    </>
  );
}

export default MatchGame;