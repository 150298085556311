import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app-context';
import { ROLE_ADMIN, SUPPORT_TYPE } from '../../components/constants/constants';
import Loader from '../../components/loader/loader.component';
import YesNoDialog from '../../components/message-dialog/yes-no-dialog.component';
import { deleteSupport, findAllSupport } from './support-service';
import './support.styles.scss';

const Support = (props) => {

  const [expanded, setExpanded] = useState(false);
  const [faqMap, updateFaqList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const appContext = useContext(AppContext);
  const [showMessageDialog, setShowMsgDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [currDeletedId, setCurrDeletedId] = useState('');
  const [currRow, setCurrRow] = useState('');

  useEffect(() => {
    loadSupportList();
  }, [props.row, currRow]);


  const loadSupportList = () => {
    setShowLoader(true);
    findAllSupport().subscribe({
      next: (response) => {
        updateFaqList(response.data);
        setShowLoader(false);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const deleteRow = (id) => {
    setCurrDeletedId(id);
    setMessage('Do you want to delete this question ?');
    setShowMsgDialog(true);

  }

  const onOkClick = () => {
    setShowLoader(true);
    deleteSupport(currDeletedId).subscribe({
      next: (response) => {
        appContext.setSuccessMessage(response.message);
        setShowLoader(false);
        setCurrRow(response);
      }, error: (error) => {
        setShowLoader(false);
      }
    });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  // const renderVideo = () => {

  //   return (
  //     <Grid justifyContent="center" container className="video-section">
  //       <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
  //         <VideoPlayerComponent videoImage="/assets/images/videosupport/register.png" videoTitle="Register User" videoLink="https://www.youtube.com/embed/a2XUvHUJ7Kg">
  //         </VideoPlayerComponent>
  //       </Grid>
  //       <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
  //         <VideoPlayerComponent videoImage="/assets/images/videosupport/editprofile.png" videoTitle="Edit Profile" videoLink="https://www.youtube.com/embed/ME0sBAgTP84">
  //         </VideoPlayerComponent>
  //       </Grid>
  //       <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
  //         <VideoPlayerComponent videoImage="/assets/images/videosupport/game.png" videoTitle="Play Game" videoLink="https://www.youtube.com/embed/SREN3-32WAo">
  //         </VideoPlayerComponent>
  //       </Grid>
  //       <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
  //         <VideoPlayerComponent videoImage="/assets/images/videosupport/purchase.png" videoTitle="Purchase Package" videoLink="https://www.youtube.com/embed/qZDqbJzSwyE">
  //         </VideoPlayerComponent>
  //       </Grid>
  //       <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
  //         <VideoPlayerComponent videoImage="/assets/images/videosupport/redeem.png" videoTitle="Redeem Gift" videoLink="https://www.youtube.com/embed/yFUC2DDEj6w">
  //         </VideoPlayerComponent>
  //       </Grid>
  //     </Grid>
  //   );

  // }

  const renderSupport = () => {
    return (
      <div>
        <Hidden only={['xl', 'lg', 'md']}>
          <div className="Title">
            FAQs
          </div>
        </Hidden>
        {
          SUPPORT_TYPE.map((type, idx) => (
            faqMap[type] !== undefined ?
              <div key={idx} className="faqSection">
                <div className="TitleSmall">{type}</div>
                <div className="faqList">{faqMap[type].map((questionAns, idx1) => (
                  <Accordion key={idx1} expanded={expanded === questionAns.rowId} onChange={handleChange(questionAns.rowId)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      {props.role === ROLE_ADMIN ?
                        <DeleteForeverOutlinedIcon onClick={() => { deleteRow(questionAns.rowId) }} className="deleteIcon" />
                        : null}
                      <Typography sx={{ width: '95%', flexShrink: 0 }}>
                        <div className="heading">{questionAns.question}</div>
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails className="faqAnswers">
                      {questionAns.description}
                    </AccordionDetails>
                  </Accordion>

                ))}</div>
              </div>
              : <></>
          ))
        }
      </div>
    );
  }
  const renderView = () => {
    return (
      <div className="support-container">
        {/* <div className="video-title">
          Support Video
        </div> */}
        {/* {renderVideo()} */}
        <Grid justifyContent="center" container spacing={3} xs={12}>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={12} sm={12} md={4} lg={5} xl={5}>
              <div className="support-image"><img src="/assets/images/support/faq.png" alt="" /></div>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={8} lg={7} xl={5}>
            <div className="support-container-content">
              {renderSupport()}
            </div>
          </Grid>
        </Grid>
        <YesNoDialog open={showMessageDialog} onClose={() => { setShowMsgDialog(false) }} onOk={() => { setShowMsgDialog(false); onOkClick(); }} message={message} />
      </div>
    );
  }

  return (
    <div>
      {showLoader ? <div className="showLoading"><Loader /></div> : renderView()}
    </div>
  );
}

export default Support;