import Button from '@material-ui/core/Button';
import Lottie from "lottie-react";
import React, { useEffect, useState } from 'react';
import { isNotEmpty } from '../../../services/util-service';
import Loader from '../../loader/loader.component';
import gamedialog from "./animations/gamedialog.json";
import './filter-dialog.styles.scss';
import { getSubjects, getTopics } from '../../../feature/game/game-service';
import { EMPTY_STRING } from '../../constants/constants';

export const SelectGameFilter = (props) => {

  const game = props.game;
  const grade = props.grade;
  const gameName = game.name;
  const selectedGrade = game.gradeType === 'NONE' ? 0 : grade;
  const [subjectSelected, setSubjectSelected] = useState(EMPTY_STRING);
  const [topicSelected, setTopicSelected] = useState(EMPTY_STRING);
  const [filterSubject, setFilterSubject] = useState([]);
  const [filterTopics, setFilterTopics] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    loadSubjects();
  }, [selectedGrade]);

  useEffect(() => {
    loadTopics();
  }, [selectedGrade, subjectSelected]);

  const loadSubjects = () => {
    if (gameName !== null && selectedGrade >= 0) {
      setShowLoader(true);
      getSubjects(gameName, selectedGrade).subscribe({
        next: (response) => {
          setFilterSubject(response.data);
          setSubjectSelected(response.data && response.data.length > 0 ? response.data[0] : EMPTY_STRING);
          setShowLoader(false);
        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }

  const loadTopics = () => {
    if (gameName !== null && selectedGrade >= 0 && subjectSelected !== EMPTY_STRING) {
      setShowLoader(true);
      getTopics(gameName, selectedGrade, subjectSelected).subscribe({
        next: (response) => {
          if (response.data.length === 1) {
            props.goToGame(selectedGrade, subjectSelected, response.data[0], game.rowId, game.type);
          } else {
            setFilterTopics(response.data);
            setTopicSelected(response.data && response.data.length > 0 ? response.data[0] : EMPTY_STRING);
            setShowDialog(true);
          }
          setShowLoader(false);

        }, error: (error) => {
          setShowLoader(false);
        }
      });
    }
  }


  const onCancelClick = () => {
    setTopicSelected(EMPTY_STRING);
    props.onClose();
  }

  const inputValid = () => {
    if (selectedGrade >= 0 && isNotEmpty(topicSelected)) {
      return true;

    } else {
      return false;
    }
  }

  const selectTopic = (val) => {
    setTopicSelected(val.item);
  }
  
  const getBGStyle = () => {
    if (window.screen.orientation.type === 'portrait') {
      return 'game-filters-dialog bg-horizontal';
    } else {
      return 'game-filters-dialog bg-vertical';
    }
  }

  const renderMain = () => {
    return (
      <>{!showDialog ? <div className="showLoading"><Loader /></div> : <>
        <div className={getBGStyle()}>
          {(filterTopics && filterTopics.length > 0) ?
            <div className="game-filters">
      <div className="message-box-animation"><Lottie className="message-box-top-animation" animationData={gamedialog} loop={true} /></div>
              <div className="filters-title">Select Topic</div>
              <div className="filters-list">
                {
                  filterTopics.map((item) => (
                    <div className={item !== topicSelected ? "filter-item" : "filter-item selected-item"} onClick={() => { selectTopic({ item }) }}>{item}</div>
                  ))
                }
              </div>
            </div>
            : null
          }
          <div className="filter-button">
            <Button variant="contained" color="secondary" onClick={onCancelClick}>Cancel</Button>
            <Button variant="contained" color="primary" disabled={!inputValid()} onClick={() => { props.goToGame(selectedGrade, subjectSelected, topicSelected, game.rowId, game.type) }}>Go To Game</Button>
          </div>
        </div>
      </>}</>
    );
  }

  return (
    <>
      {
        renderMain()
      }
    </>
  );
}

export default SelectGameFilter;