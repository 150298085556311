import { PAGE_SIZE } from '../../components/constants/constants';
import { get, whitelistPost, whitelistRemove } from '../../services/http-services';


export function findAllFeedback(pageNumber) {
    return get('/feedback/page?page=' + pageNumber+ '&size=' + PAGE_SIZE +'&sort=creationDate,desc');
}

export function saveFeedback(feedback) {
    return whitelistPost('/feedback/save', feedback);
}

export function deleteFeedback(id) {
    return whitelistRemove('/feedback?id=', id);
}

