import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImageSelection from '../../components/image-selection/image-selection.component';
import { EMPTY_STRING, MANDATORY_FIELD_MSG } from '../../components/constants/constants';
import groupImages from '../../components/image-selection/group-images';
import Loader from '../../components/loader/loader.component';
import './group-form.styles.scss';
import { findGroupById, saveGroup, userUpdate } from './group.service';
import { AppContext } from '../../app-context';
import { isNotNull, isNull } from '../../services/util-service';

const GroupForm = (props) => {

  const groupId = props.match.params.groupId;
  const initProfile = {
    rowId: groupId,
    name: EMPTY_STRING,
    imagePath: 'https://www.cudents.com/prod/images/groups/group_img1.png',
    description: EMPTY_STRING
  };
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showAvatarDialog, setShowAvatarDialog] = useState(false);
  const [formError, setFormError] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [formData, updateFormData] = useState(initProfile);
  const groupImageList = groupImages

  useEffect(() => {
    if (isNotNull(groupId)) {
      setShowLoader(true);
      findGroupById(groupId).subscribe({
        next: (response) => {
          updateFormData(response.data);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
          appContext.setDialogMessage(error.message);
        }});
    }
  }, []);

  const changeAvatar = (imgPath) => {
    updateFormData({
      ...formData,
      imagePath: imgPath
    });
  }

  const navigateTo = (group) => {
    // history.push(`/group/view/${group.rowId}`);
    history.push(`/group/list`);
  }
  const cancel = () => {
    history.push(`/group/list`);
  }

  const createGroup = (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (handleValidation()) {
      if(isNull(groupId)){
        saveGroup(formData).subscribe({
          next: (response) => {
            setShowLoader(false);
            appContext.setDialogMessage(response.message);
            navigateTo(response.data);
          }, error: (error) => {
            setShowLoader(false);
            appContext.setDialogMessage(error.message);
          }
        });
      }else{
        userUpdate(formData).subscribe({
          next: (response) => {
            setShowLoader(false);
            appContext.setDialogMessage(response.message);
            navigateTo(response.data);
          }, error: (error) => {
            setShowLoader(false);
            appContext.setDialogMessage(error.message);
          }
        });
      }
    }else{
      setShowLoader(false);
    }
  }

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData["name"]) {
      formIsValid = false;
      errors["name"] = MANDATORY_FIELD_MSG;
    }
    setFormError(errors);
    return formIsValid;
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }

  const formPanel = () => {
    return (<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
      <div className="group-title ">
              {isNull(groupId)  ? 'Create Group' : 'Edit Group'}
            </div>
      <div className="group-image">
        <img src={formData.imagePath} alt="" />
        <div className="editGroupImage" onClick={() => (setShowAvatarDialog(true))}>Change Group Image</div>
      </div>
      <div className="formField"><TextField name="name" value={formData.name || EMPTY_STRING} onChange={handleChange} required fullWidth label="Enter Group Name" variant="outlined" size="small" />
        <span className="error-text">{formError["name"]}</span>
      </div>
      {/* <div className="formField">
        <TextField name="description" value={formData.description} label="Description" fullWidth multiline rows={4} onChange={handleChange} variant="outlined" size="small" />
      </div> */}
      <div className="formField">
        <Button type="button" className="saveButton" variant="contained" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button type="submit"  className="saveButton" variant="contained" color="primary" onClick={createGroup}>
          Save
        </Button>
      </div>
    </Grid>);
  }


  return <>
    {showLoader ? <Loader /> :
      <div className="new-group-container">
        {formPanel()}
        <ImageSelection open={showAvatarDialog} onClose={() => { setShowAvatarDialog(false) }} changeAvatar={changeAvatar} imageList={groupImageList}/>
      </div>
    }
  </>

}


export default GroupForm;


