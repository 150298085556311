import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { AppContext } from "../../../app-context";
import Loader from "../../../components/loader/loader.component";
import { findAllPageGuessQuestion } from "../guess-master-service";
import { isNotEmptyArray, isNotNull } from "../../../services/util-service";
import bear from "../animations/bear.json";
import next from "../animations/next.json";
// import plane from "../animations/plane.json";
import './guess-master-player.styles.scss';
function GuessMasterPLayer(props) {

    const guessMasterId = props.match.params.guessMasterId;
    const appContext = useContext(AppContext);
    const [showLoader, setShowLoader] = useState(false);
    const [guessData, setGuessData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [counter, setCounter] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        if (guessData === null) {
            //Load the data and hide the loader
            findAllPageGuessQuestion(guessMasterId).subscribe({
                next: (response) => {
                    setGuessData(response.data);
                    setShowLoader(false);
                }, error: (error) => {
                    setShowLoader(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (guessData !== null) {
            setCurrentQuestion(guessData[counter]);
        }
    }, [guessData]);

    const previousQuestion = () => {
        if (isNotFirst()) {
            setCurrentQuestion(guessData[counter - 1]);
            setCounter(counter - 1);
            setShowAnswer(false);
            // }else{
            //     appContext.setDialogMessage('You are in First level');
        }
    }

    const nextQuestion = () => {
        if (isNotLast()) {
            setCurrentQuestion(guessData[counter + 1]);
            setCounter(counter + 1);
            setShowAnswer(false);
            // } else {
            //     appContext.setDialogMessage('You are in Last level');
        }
    }

    const isNotLast = () => {
        return isNotEmptyArray(guessData) && counter !== guessData.length - 1;
    }
    const isNotFirst = () => {
        return counter !== 0;
    }

    return (<>{showLoader ? <div className="showLoading">< Loader /></div>
        :
        <div className="guess-master-player">
            {/* <div className="guess-master-background-animation">
                <Lottie animationData={plane} loop={true} />
            </div> */}
            <div className="guess-master-background">
                <img src="/assets/images/guess/background.png" alt="" />
            </div>
            <div className="guess-master-top-area">
                <div className="guess-master-top-animation">
                    <Lottie animationData={bear} loop={true} />
                </div>
            </div>
            <div className="guess-master-question-area">
                <div className="guess-master-question">
                    {currentQuestion !== undefined && currentQuestion !== null ? <img src={currentQuestion.questionImagePath} alt="" /> : <Loader></Loader>}
                </div>
            </div>
            {showAnswer ?
                <div className="guess-master-answer-container">
                    <div className="guess-master-answer-image">
                        <img src={currentQuestion.answerImagePath} alt="" />
                    </div>
                    <div className="guess-master-answer-text">
                        {currentQuestion.answerText}
                    </div>
                </div>
                :
                <div className="guess-master-answer-button">
                    {isNotNull(currentQuestion) ? <span onClick={() => { setShowAnswer(true) }}>Show Answer</span> : null}
                </div>
            }
            <div className="guess-master-buttons">
                {isNotFirst() ? <div className="guess-master-previous-button" onClick={previousQuestion}>
                    <Lottie className="guess-master-button-animation" animationData={next} loop={true} />
                </div> : <></>}
                {isNotLast() ? <div className="guess-master-next-button" onClick={nextQuestion}>
                    <Lottie className="guess-master-button-animation" animationData={next} loop={true} />
                </div> : <></>}
            </div>
        </div>
    }
    </>);
}

export default GuessMasterPLayer;