import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow, useStyles } from '../../../services/grid-style';
import { getAssessAttempt } from '../../../feature/quiz/question-service';
import Loader from '../../../components/loader/loader.component';
import './assess-attempt.styles.scss';

const AssessAttempt = (props) => {
  const [assesAttemptList, setAssesAttemptList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    search(page);
  }, [page]);

  const handlePage = (event, value) => {
    setPage(value);
  };

  const search = (selectedPage) => {
    setShowLoader(true);
    getAssessAttempt(selectedPage - 1).subscribe({
      next: (response) => {
        setAssesAttemptList(response.data.data);
          setPageCount(response.data.totalPages);
          setTotalCount(response.data.totalElements);
          setShowLoader(false);
        }, error : (error ) => {
          setShowLoader(false);
      }});
  }

  const getScoreReportGrid = () => {
    return (
      <div>
      <div className="total-count">
        <span className="total-label">Total Count : </span>
        <span className="total-value">{totalCount}</span>
      </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display Name</StyledTableCell>
                <StyledTableCell>Class</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assesAttemptList.map((user) => (
                <StyledTableRow key={user.name}>
                  <StyledTableCell>{user.userId}</StyledTableCell>
                  <StyledTableCell>{user.name}</StyledTableCell>
                  <StyledTableCell>{user.displayName}</StyledTableCell>
                  <StyledTableCell>{user.grade}</StyledTableCell>
                  <StyledTableCell>{moment(user.attemptDate).format('lll')}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={pageCount} page={page} variant="outlined" color="secondary" onChange={handlePage} />
      </div>
    );
  }


  return (
    <div className="assesment-attempt">
      {showLoader ? <div className="showLoading"><Loader /></div> : getScoreReportGrid()}
    </div>
  );
}

export default AssessAttempt;